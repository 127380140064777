﻿.sublayout-product-details{ 
	a.redbutton { background: none repeat scroll 0 0 #CC0F12; border: 3px solid #E7E7E7; border-radius: 17px; color: #FFFFFF; display: inline-block;
    font: bold 14px/17px Verdana,Helvetica,sans-serif;
    margin: 15px;
    padding: 7px 12px; }
	h1{ font-size:(20em/10.8); font-weight: bold; color: #CB0C0F; padding-bottom: .5em;}
	h2{ font-size:(14em/10.8); font-weight: bold; color: #CB0C0F;}
	
	.container-1{background: url("/App_Themes/Default/Images/General/navigation-bg.gif") repeat-y; overflow: hidden; min-height: 400px;}
	
	#column-1{width: 220px; padding: 13px 7px 15px 0; float: left;}
	#column-2{width: 410px; padding: 13px 20px 15px 23px; float: left;
		h1{ width: 360px;}
		h2{ color: #525252;}

		.content-header{ padding-bottom: 15px; }
		.content{font-size: (12em/10.8); line-height: 1.4em;
			a.arrow{ padding-left: 12px; background: url("/App_Themes/Default/Images/General/red-arrow.gif") no-repeat left 2px;  }
		}

		.product-hero{ display: inline-block; float: right; margin-left: 30px; margin-bottom: 30px; max-width: 280px; }

		.sublayout-product-detail-footer{
			ul.social-nav{ padding-top: 10px;
				li{ float: left;
					a{display: block; padding: 0 15px; 
						&:link, &:visited{ color: #CB0C0F; cursor: pointer; }
						&:hover{ text-decoration: underline!important; }
					}
					&.email-friend a{background: url("/App_Themes/Default/Images/General/email-friend.gif") no-repeat left center;}
					&.print-page a{ padding-left: 20px; background: url("/App_Themes/Default/Images/General/print.gif") no-repeat left center;}
				}

			}
		}
	}
	#column-3{width: 280px; padding: 13px 0 15px 0; float: left; font-family:Verdana,Arial, Helvetica, sans-serif; text-align: center;
		.product-hero{ padding-bottom: 30px;
			img{ max-width: 280px; }
		}
		.call-to-action-button,
		.no-action-button{ display: inline-block; margin: 3px;  padding:0 7px 2px 7px; background: #CB0C0F; color: #FFF; border: #CB0C0F 3px solid; @include border-radius(4px);}
		.call-to-action-button{ padding-right: 12px; background-image: url("/App_Themes/Default/Images/General/btn-arrow.gif"); background-position: center right; background-repeat: no-repeat;}
	}
}
.sublayout-product-detail-product-association-list,
.sublayout-product-detail-guidebook-association-list{
	h3{ font-size:(14em/10.8); font-weight: bold; padding-bottom: 15px;}
	ul{ padding-bottom: 15px; }
}
