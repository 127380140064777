﻿.products-sublayout-wrapper {

strong { font-weight: bold; }
#content { font-family: Arial,Helvetica,sans-serif; position: relative; 
	h2{ font-weight: bold; }

	.indent{ padding-left: 15px; }
}


.slideshow {
  position: relative;
  z-index: 1; }

.jeslideshow {
  position: relative;
  top: 0;
  left: 0;
  .jeslideshow-slide {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 960px; } }

.jeslideshow-buttons {
  list-style: none;
  height: 25px;
  li {
    float: left;
    display: block;
    width: 10px;
    height: 10px;
    margin: 5px;
    background: #FFF;
    border: #000 solid 1px;
    &.selected {
      background: #cb0c0f;
      width: 8px;
      height: 8px;
      border: #FFF solid 2px; }
    span {
      display: none; } } }

.expand-list {
  padding: 4px 10px 0 0;
  li {
    padding: 0 0 2px 0;
    list-style: none;
    h3 {
      padding: 0 0 0 17px;
      line-height: 28px;
      font-size: 12px;
      cursor: pointer;
      font-weight: bold;
      background: transparent url(/App_Themes/Default/Images/expand-list-arrow.png) 0px 8px no-repeat; }
    li {
      background: none;
      list-style: disc; }
    .expand-content {
      display: none; }
    &.selected {
      h3 {
        background-position: 0px -18px; }
      .expand-content {
        display: block;
        padding: 0 0 0 17px; } } } }

.list {
  list-style: none;
  li {
    padding: 0 0 0 8px;
    background: transparent url(/App_Themes/Default/Images/products/red-arrow.gif) left center no-repeat;
    a {
      display: inline; } } }

a.go {
  background: url(/App_Themes/Default/Images/chevrons.png) no-repeat scroll right center transparent;
  line-height: 16px;
  padding-right: 20px; }

.nspired-learning-interior {
  #content {
    background: transparent url(/App_Themes/Default/Images/nspired-learning/navigation-sidebar-bg.gif) -10px top scroll repeat-y; }
  #navigation-sidebar {
    float: left;
    width: 147px;
    padding: 23px 15px 0 15px;
    min-height: 656px; } }

* #navigation-sidebar {
  height: 656px; }

.nspired-learning-interior {
  #navigation-sidebar {
    h2 {
      line-height: 1.425em;
      a {
        color: #cb0c0f;
        font-weight: bold; } }
    ul {
      padding: 20px 0 0 0;
      background: transparent url(/App_Themes/Default/Images/nspired-learning/navigation-sidebar-divider.gif) 0 13px scroll no-repeat;
      li {
        list-style-type: none;
        line-height: 1.2em;
        padding: 13px 0 16px 0;
        background: transparent url(/App_Themes/Default/Images/nspired-learning/navigation-sidebar-divider.gif) 0 bottom scroll no-repeat;
        color: #666;
        font-weight: bold;
        display: block;
        a {
          color: #666;
          font-weight: bold;
          display: block; }
        &:hover, &:focus, &:active {
          color: #c00; }
        a {
          &:hover, &:focus, &:active {
            color: #c00; } } }
	 li.active {
		a {
			color: #c00; } } } }
  #content-inner {
    float: left;
    width: 783px;
    min-height: 722px;
    background: transparent url(/App_Themes/Default/Images/nspired-learning/content-bg.gif) 0 304px scroll repeat-x; height: auto; } }

* #content-inner {
  height: 722px; }

.nspired-learning-interior {
  #content-inner {
    #hero {
      width: 783px;
      height: 303px;
      border-bottom: solid 1px #fff; }
    #content-inner-left {
      float: left;
      width: 476px;
      padding: 25px 11px 0 31px;
      color: #424242;
      background: transparent url(/App_Themes/Default/Images/nspired-learning/content-divider.gif) right 27px scroll no-repeat;
      h3 {
        color: #cb0c0f;
        font-size: 1.7em;
        line-height: 1.15em;
        font-weight: bold;
        padding: 0 0 13px 0; }
      p {
        font-size: 1.1em;line-height: 1.4em; 
        padding: 0 0 1.5em 0; } }
    #content-inner-right {
      float: left;
      width: 243px;
      padding: 20px 0 0 22px;
      color: #525252;
      img {
        padding: 13px 31px; }
      h3 {
        color: #525252;
        font-size: 1.2em;
        font-weight: bold;
        padding: 0 0 3px 0;
		img {
			vertical-align: bottom;
			padding: 0; } }
      p {
        font-size: 1.1em;
        line-height: 1.6em; }
      #contact-ti {
        margin: 38px 0 0 2px;
        padding: 22px 0 0 1px;
        width: 209px;
        background: transparent url(/App_Themes/Default/Images/nspired-learning/content-hr.gif) 0 0 scroll no-repeat;
        position: relative;
        a {
          display: block;
          width: 131px;
          height: 27px;
          text-align: center;
          color: #fff;
          margin: 0 auto;
          padding: 7px 0 0 0;
          font-style: oblique;
          font-weight: bold;
          background: transparent url(/App_Themes/Default/Images/nspired-learning/contact-button.gif) 0 0 scroll no-repeat;
          position: relative;
          z-index: 102; }
        div {
          color: #4c4c4c;
          border: solid 3px #ececec;
          -moz-border-radius: 8px 3px 8px 8px;
          -webkit-border-radius: 8px 3px 8px 8px;
          -khtml-border-radius: 8px 3px 8px 8px;
          border-radius: 8px 3px 8px 8px;
          background: transparent url(/App_Themes/Default/Images/nspired-learning/contact-panel-bg.gif) 0 bottom scroll repeat-x;
          position: absolute;
          left: -55px;
          top: 51px;
          padding: 5px 12px 10px 12px;
          font-style: oblique;
          font-weight: bold;
          z-index: 101;
          a {
            display: inline;
            width: auto;
            height: auto;
            color: #177fce;
            background: none; }
		  p {
		    white-space: nowrap; } } } } }
  a.more {
    display: block;
    display: -moz-inline-stack;
    display: inline-block;
    color: #1973b4;
    font-weight: bold;
    background: transparent url(/App_Themes/Default/images/home/INDIA/learn-more.gif) right 0 scroll no-repeat;
    margin: 2px 0 0 0;
    padding: 0 17px 0 0; } }

.nspired-learning-one-column #content-inner {
  background-position: 0 0;
  padding: 25px 30px 0 28px;
  width: 725px;
  float: left;
  h3 {
    color: #cb0c0f;
    font-size: 1.7em;
    line-height: 1.15em;
    font-weight: bold;
    padding: 0 0 13px 0; }
  ul.bbq {
    overflow: hidden;
    li {
      float: left;
      list-style: none;
      a {
        font-weight: bold;
        font-size: 13px;
        color: #5c5c5c;
        &:hover {
          text-decoration: none; } } } }
  #grades-list {
    margin-bottom: 20px;
    li {
      padding: 0 20px;
      border-right: #5c5c5c 1px solid;
      &:last-child, &.last-child {
        border-right: none; }
      &.selected a {
        color: #CB0C0F; } } }
  #subjects-list {
    background: url(/App_Themes/Default/images/nspired-learning/activities/subjects-bg.png) repeat-x;
    height: 27px;
    padding: 0px 8px;
    display: inline-block;
    margin-bottom: 10px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -khtml-border-radius: 8px;
    border-radius: 8px;
    li {
      margin: 4px 30px 0px 0px;
      padding: 2px 10px;
      &.selected {
        background: #FFF;
        -moz-border-radius: 8px;
        -webkit-border-radius: 8px;
        -khtml-border-radius: 8px;
        border-radius: 8px; }
      &:last-child, &.last-child {
        margin-right: 0px; } } }
  span.pagination-label {
    padding-left: 10px;
    float: left;
    font-weight: bold;
    font-size: 13px;
    color: #5c5c5c; }
  #pagination-list {
    padding-bottom: 3px;
    li {
      padding: 0 5px;
      border-right: #CACACA solid 1px;
      a {
        color: #1973B4; }
      &:last-child, &.last-child {
        border-right: none; }
      &.selected a {
        color: #5c5c5c; } } }
  #activities-table {
    thead th {
      height: 27px;
      background: url(/App_Themes/Default/images/nspired-learning/activities/table-header-bg.png) repeat-x;
      color: white;
      font-size: 1.3em;
      font-weight: bold;
      text-align: center;
      padding: 0 10px;
      &:first-child, &.first-child {
        text-align: left;
        width: 485px;
        -moz-border-radius: 4px 0 0 0;
        -webkit-border-radius: 4px 0 0 0;
        -khtml-border-radius: 4px 0 0 0;
        border-radius: 4px 0 0 0; }
      &:last-child, &.last-child {
        width: 200px;
        -moz-border-radius: 0 4px 0 0;
        -webkit-border-radius: 0 4px 0 0;
        -khtml-border-radius: 0 4px 0 0;
        border-radius: 0 4px 0 0; } }
    tbody tr {
      &.no-activities td {
        border-left: none!important; }
      td {
        padding: 5px 10px;
        vertical-align: top;
        width: 325px;
        h4 {
          font-size: 1.3em;
          font-weight: bold; }
        p {
          font-size: 1.1em; }
        &:first-child, &.first-child {
          width: 150px;
          padding-right: 0px; }
        &:last-child, &.last-child {
          border-left: #999 1px solid;
          text-align: center;
          width: 200px; }
        a.launch-button.doc-player {
          background: url(/App_Themes/Default/images/nspired-learning/activities/doc-player-icon.png) no-repeat;
          line-height: 15px;
          padding-left: 18px;
          display: inline-block; }
        .downloads {
          display: inline-block;
          overflow: hidden;
          li {
            float: left;
            height: 32px;
            width: 32px;
            margin: 0 3px;
            list-style: none;
            background: url(/App_Themes/Default/images/nspired-learning/activities/download-icons.png) no-repeat 0 0;
            a {
              width: 32px;
              height: 32px;
              display: block; }
            &.pdf {
              background-position: 0px 0px; }
            &.doc {
              background-position: -42px 0px; }
            &.tns {
              background-position: -84px 0px; }
            &.tnsp {
              background-position: -123px 0px; }
            &.zip {
              background-position: -162px 0px; }
            &.youtube {
              background-position: -200px 0px; } } } } } } }

#nspired-learning-cce #nav-cce,
#nspired-learning-mathematics #nav-mathematics,
#nspired-learning-science #nav-science,
#nspired-learning-cac #nav-cac,
#nspired-learning-stem #nav-stem,
#nspired-learning-activities #nav-activities,
.products-sublayout-wrapper .nspired-learning-interior #navigation-sidebar ul li.active {
  color: #cb0c0f;
  a {
    color: #cb0c0f; } }

.activities #content {
  padding: 0;
  width: 960px;
  min-height: 453px;
  background: transparent url(/App_Themes/Default/images/activities/navigation-sidebar-bg.gif) top left scroll repeat-y;
  #navigation-sidebar {
    float: left;
    width: 147px;
    padding: 16px 15px 0 15px;
    min-height: 656px; } }

* #navigation-sidebar {
  height: 656px; }

.activities #navigation-sidebar {
  h2 {
    line-height: 1.425em;
    a {
      color: #cb0c0f;
      font-weight: bold; } }
  ul {
    padding: 0 0 0 0;
    li {
      list-style-type: none;
      line-height: 1.2em;
      padding: 6px 0 16px 0;
      color: #666;
      font-weight: bold;
      display: block;
      a {
        color: #666;
        font-weight: bold;
        display: block; }
      &:hover, &:focus, &:active {
        color: #c00; }
      a {
        &:hover, &:focus, &:active {
          color: #c00; } } }
    #nav-switch-language {
      text-align: center;
      a {
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        background: #e5e5e5 url(/App_Themes/Default/images/activities/button-bg.gif) 0 0 scroll repeat-x;
        border: solid 1px #ccc;
        padding: 7px 20px;
        margin: 9px 5px 0 0;
        color: #5c5c5c;
        cursor: pointer;
        clear: none;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        -khtml-border-radius: 6px;
        border-radius: 6px;
        font-weight: bold; } } } }

#activities-home {
  &.lang-ar th {
    &:first-child, &.first-child {
      text-align: right;
      -moz-border-radius: 0 4px 0 0;
      -webkit-border-radius: 0 4px 0 0;
      -khtml-border-radius: 0 4px 0 0;
      border-radius: 0 4px 0 0; }
    &:last-child, &.last-child {
      -moz-border-radius: 4px 0 0 0;
      -webkit-border-radius: 4px 0 0 0;
      -khtml-border-radius: 4px 0 0 0;
      border-radius: 4px 0 0 0; } }
  #content-inner {
    background-position: 0 0;
    padding: 16px 30px 0 28px;
    width: 725px;
    float: left;
    h3 {
      color: #cb0c0f;
      font-size: 1.7em;
      line-height: 1.15em;
      font-weight: bold;
      padding: 0 0 7px 6px; }
    p {
      font-size: 1.1em;
      padding-left: 6px; }
    ul.bbq {
      overflow: hidden;
      li {
        float: left;
        list-style: none;
        a {
          font-weight: bold;
          font-size: 13px;
          color: #5c5c5c;
          &:hover {
            text-decoration: none; } } } }
    #subjects-list {
      background: url(/App_Themes/Default/images/activities/subjects-bg.png) repeat-x;
      height: 27px;
      padding: 0px 8px;
      display: inline-block;
      margin: 2px 0 10px -2px;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
      -khtml-border-radius: 8px;
      border-radius: 8px;
      font-size: 1.2em;
      li {
        margin: 4px 30px 0px 0px;
        padding: 2px 10px;
        &.selected {
          background: #FFF;
          -moz-border-radius: 8px;
          -webkit-border-radius: 8px;
          -khtml-border-radius: 8px;
          border-radius: 8px; }
        &:last-child, &.last-child {
          margin-right: 0px; } } }
    #grades-list {
      margin: 0 0 20px 0;
      font-size: 1.1em;
      display: inline-block;
      li {
        padding: 0 20px;
        border-right: #5c5c5c 1px solid;
        &:last-child, &.last-child {
          border-right: none; }
        &.selected a {
          color: #cb0c0f; } } }
    span.pagination-label {
      padding-left: 10px;
      float: left;
      font-weight: bold;
      font-size: 13px;
      color: #5c5c5c; }
    #pagination-list {
      padding-bottom: 3px;
      li {
        padding: 0 5px;
        border-right: solid 1px #cacaca;
        a {
          color: #1973B4; }
        &:last-child, &.last-child {
          border-right: none; }
        &.selected a {
          color: #5c5c5c; } } }
    #activities-table {
      thead th {
        height: 27px;
        background: url(/App_Themes/Default/images/activities/table-header-bg.png) repeat-x;
        color: white;
        font-size: 1.3em;
        font-weight: bold;
        text-align: center;
        padding: 0 10px;
        &:first-child, &.first-child {
          text-align: left;
          width: 485px;
          -moz-border-radius: 4px 0 0 0;
          -webkit-border-radius: 4px 0 0 0;
          -khtml-border-radius: 4px 0 0 0;
          border-radius: 4px 0 0 0; }
        &:last-child, &.last-child {
          width: 200px;
          -moz-border-radius: 0 4px 0 0;
          -webkit-border-radius: 0 4px 0 0;
          -khtml-border-radius: 0 4px 0 0;
          border-radius: 0 4px 0 0; } }
      tbody tr {
        &.no-activities td {
          border-left: none!important; }
        td {
          padding: 5px 10px;
          vertical-align: top;
          width: 325px;
          h4 {
            font-size: 1.3em;
            font-weight: bold; }
          p {
            padding-left: 0; }
          &:first-child, &.first-child {
            width: 150px;
            padding-right: 0px; }
          &:last-child, &.last-child {
            border-left: #999 1px solid;
            text-align: center;
            width: 200px; }
          a.launch-button.doc-player {
            background: url(/App_Themes/Default/images/activities/doc-player-icon.png) no-repeat;
            line-height: 15px;
            padding-left: 18px;
            display: inline-block; }
          .downloads {
            display: inline-block;
            overflow: hidden;
            li {
              float: left;
              height: 32px;
              width: 32px;
              margin: 0 3px;
              list-style: none;
              background: url(/App_Themes/Default/images/activities/download-icons.png) no-repeat 0 0;
              a {
                width: 32px;
                height: 32px;
                display: block; }
              &.pdf {
                background-position: 0px 0px; }
              &.doc {
                background-position: -42px 0px; }
              &.tns {
                background-position: -84px 0px; }
              &.tnsp {
                background-position: -123px 0px; }
              &.zip {
                background-position: -162px 0px; }
              &.youtube {
                background-position: -200px 0px; } } } } } } } }

.product-family #content {
  padding: 0;
  width: 960px;
  min-height: 453px;
  h2 {
    padding: 0 0 0.4em 0; font-size: 1.3em; }
  #navigation-section {
    position: absolute;
    font-size: 1.2em;
    z-index: 103;
    padding: 20px 0 0 16px;
    width: 223px;
    height: 242px;
    h2 {
      color: #fdfdfd; }
    ul {
      list-style-type: none;
      li {
        font-size: 1.1em;
        padding: 13px 0 12px 0;
        a {
          color: #0c63a3;
          padding: 0 0 0 21px;
          display: block;
          &:hover, &:active, &:focus {
            color: #e3171f;
            background: transparent url(/App_Themes/Default/images/product-family/translucent-nav-arrow.png) 4px 2px scroll no-repeat; } } } } }
  .panels, .rise-panels {
    width: 944px;
    height: 490px;
    padding: 0 8px;
    position: relative;
    overflow: hidden;
    color: #5e5e5e;
    top: 0;
    left: 0;
    z-index: 102; }
  .panels li {
    &.panel-1, &.panel-2, &.panel-3, &.panel-4 {
      position: relative;
      list-style-type: none;
      float: left;
      width: 196px;
      top: 190px;
      margin: 0 5px 0 0;
      padding: 190px 18px 206px 18px;
      background: white url(/App_Themes/Default/images/product-family/rise-panel-bg.gif) 0 0 scroll no-repeat;
      -moz-border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px 3px 0 0;
      -khtml-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
      z-index: 101; } }
  .rise-panels li {
    &.panel-1, &.panel-2, &.panel-3, &.panel-4 {
      position: relative;
      list-style-type: none;
      float: left;
      width: 196px;
      top: 190px;
      margin: 0 5px 0 0;
      padding: 190px 18px 206px 18px;
      background: white url(/App_Themes/Default/images/product-family/rise-panel-bg.gif) 0 0 scroll no-repeat;
      -moz-border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px 3px 0 0;
      -khtml-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
      z-index: 101; } }
  .panels li {
    &.panel-1 h3, &.panel-2 h3, &.panel-3 h3, &.panel-4 h3 {
      position: absolute;
      left: 0;
      top: 0;
      padding: 8px 32px 8px 12px;
      font-size: 1.15em;
      line-height: 1.3em; } }
  .rise-panels li {
    &.panel-1 h3, &.panel-2 h3, &.panel-3 h3, &.panel-4 h3 {
      position: absolute;
      left: 0;
      top: 0;
      padding: 8px 32px 8px 12px;
      font-size: 1.15em;
      line-height: 1.3em; } }
  .panels li {
    &.panel-1 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } }
    &.panel-2 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } }
    &.panel-3 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } }
    &.panel-4 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } } }
  .rise-panels li {
    &.panel-1 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } }
    &.panel-2 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } }
    &.panel-3 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } }
    &.panel-4 h3 {
      color: #5e5e5e;
      font-weight: bold;
      display: block;
      width: 188px;
      height: 173px;
      text-decoration: none;
      a {
        color: #5e5e5e;
        font-weight: bold;
        display: block;
        width: 188px;
        height: 173px;
        text-decoration: none;
        &:hover, &:link, &:active, &:focus {
          color: #5e5e5e;
          font-weight: bold;
          display: block;
          width: 188px;
          height: 173px;
          text-decoration: none; } } } }
  .panels li {
    &.panel-1 em, &.panel-2 em, &.panel-3 em, &.panel-4 em {
      color: #c62510;
      font-weight: bold;
      font-style: normal;
      text-decoration: none;
      text-transform: uppercase;
      display: -moz-inline-stack;
      display: inline-block;
      padding: 0 2px 0 0; } }
  .rise-panels li {
    &.panel-1 em, &.panel-2 em, &.panel-3 em, &.panel-4 em {
      color: #c62510;
      font-weight: bold;
      font-style: normal;
      text-decoration: none;
      text-transform: uppercase;
      display: -moz-inline-stack;
      display: inline-block;
      padding: 0 2px 0 0; } }
  .panels li {
    &.panel-1 p, &.panel-2 p, &.panel-3 p, &.panel-4 p {
      font-size: 1.1em;
      line-height: 1.56em; } }
  .rise-panels li {
    &.panel-1 p, &.panel-2 p, &.panel-3 p, &.panel-4 p {
      font-size: 1.1em;
      line-height: 1.56em; } }
  .panels li {
    &.panel-1 a.more, &.panel-2 a.more, &.panel-3 a.more, &.panel-4 a.more {
      display: -moz-inline-stack;
      display: inline-block;
      text-indent: -9999px;
      overflow: hidden;
      width: 18px;
      height: 9px;
      padding: 1px 4px;
      background: transparent url(/App_Themes/Default/images/more-arrows.gif) center center scroll no-repeat; } }
  .rise-panels li {
    &.panel-1 a.more, &.panel-2 a.more, &.panel-3 a.more, &.panel-4 a.more {
      display: -moz-inline-stack;
      display: inline-block;
      text-indent: -9999px;
      overflow: hidden;
      width: 18px;
      height: 9px;
      padding: 1px 4px;
      background: transparent url(/App_Themes/Default/images/more-arrows.gif) center center scroll no-repeat; } } }

.product-family #content {
  .panels li.panel-4, .rise-panels li.panel-4 {
    margin-right: 0; }
  .panels li {
    &.panel-1, &.panel-2, &.panel-3, &.panel-4 {
      background-image: url(/App_Themes/Default/images/product-family/panel-bg.gif);
      top: 262px; }
    &.panel-1 h3, &.panel-2 h3, &.panel-3 h3, &.panel-4 h3 {
      width: 208px;
      padding: 8px 12px; } } }

#fat-footer {
  position: relative;
  clear: both;
  background: transparent url(/App_Themes/Default/images/product-family/fat-footer-bg.gif) left top scroll no-repeat;
  margin: 8px 0 0 0;
  width: 960px;
  padding: 40px 0 0 24px;
  h2 {
    font-size: 1.5em;
    position: absolute;
    left: 18px;
    top: 13px;
    clear: both;
    color: #717171;
    float: none;
    padding: 0;
    width: auto;
    font-weight: bold;
    a {
      clear: both;
      color: #717171;
      float: none;
      padding: 0;
      width: auto;
      font-weight: bold; } }
  h3, a {
    color: #919191; }
  h3 {
    font-weight: bold;
    font-size: 1em;
    float: none;
    padding: 0;
    width: auto; }
  ul {
    line-height: 1.6em;
    padding: 0 0 1.4em 0;
    li {
      list-style-type: none;
      width: 314px;
      display: -moz-inline-stack;
      display: inline-block;

      vertical-align: top; } } }

ul {
  padding: 0;
  li {
    float: none;
    width: auto;
    height: auto;
    display: block;
    display: list-item;
    padding: 0; } }

.product-family-interior #content {
  
  min-height: 0; }



.product-family-interior .section-wrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 960px;
  min-height: 480px;
  h2, h3 {
    font-size: 1.5em;
    text-shadow: 0 0 10px #80807f; }
  h2, h3 {
    color: #fdfefe;
    font-weight: bold;
    a {
      color: #fdfefe;
      font-weight: bold; } }
  #navigation-slide {
    width: 208px;
    height: 223px;
    padding: 20px 16px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
	font-size: 10.8px;
    h2 {
      padding-left: 10px; }
    ul {
      padding: 0.7em 0 0 0;
      li {
        list-style-type: none;
        a {
          font-size: 1.4em;
          line-height: 1.2em;
          display: block;
          background: transparent none 0 3px scroll no-repeat;
          padding: 0 0 0.7em 29px;
          color: #0c63a3;
          &:hover, &:focus {
            color: #e3171f; } } } } }
  .section-content {
    position: relative;
    left: 0;
    top: 0;
    width: 960px;
    height: auto;
    background-repeat: no-repeat; } }

.product-family-interior .section-content {
  a {
    &.purchase, &.more {
      display: -moz-inline-stack;
      display: inline-block;
      text-transform: uppercase;
      color: #c1272d;
      font-size: 0.8em;
      width: auto;
      padding: 5px 19px 5px 0;
      background: transparent none right 6px scroll no-repeat; } }
  h4 {
    color: #3088c8;
    font-size: 1.4em;
    padding: 6px 17px 0 17px;
    position: absolute;
    left: 0;
    top: 265px; }
  #features.columns-2 {
    li {
	  width: 47%;
	}
  }
  #features.columns-3 {
    li {
	  width: 30.5%;
	}
  }
  #features {
    clear: left;
    position: relative;
    left: 0;
    top: 30px;
    padding: 0 8px;
    li {
      list-style-type: none;
      float: left;
      padding: 39px 15px 0 11px;
      color: #5f5f5f;
      position: relative;
      width: 22%;
      min-height: 146px;
      background: transparent url(/App_Themes/Default/images/product-family/features-divider.gif) 0 3px scroll no-repeat;
      .popup-enlarged {
        z-index: 4;
        position: absolute;
        left: 11px;
        top: 40px;
        cursor: pointer; }
      &.first-child, &:first-child {
        background-image: none; }
      h5 {
        z-index: 1;
        position: absolute;
        top: 9px;
        left: 2px;
        width: 220px;
        padding: 0 0 0 14px;
        color: #777;
        font-size: 1.225em;
        font-weight: bold;
        background-repeat: no-repeat;
        background-position: left bottom; }
	  img.panel-image {
	    float: left; 
		display: inline; }
      h6 {
        z-index: 1;
        position: absolute;
        top: 25px;
        left: 2px;
        width: 205px;
        padding: 0 0 0 14px;
        color: #777;
        font-weight: bold; }
      p {
        line-height: 1.55em;
		font-size: 1.112em;
        z-index: 2;
        position: relative; }
      a {
		font-size: 1em;
        position: relative;
        z-index: 2;
        &.enlarge {
          display: block;
          width: 18px;
          height: 18px;
          overflow: hidden;
          text-indent: -9999px;
          background: transparent url(/App_Themes/Default/images/product-family/features-zoom.gif) 0 0 scroll no-repeat;
          position: absolute;
          left: 20px;
          top: 103px; }
        &.more {
          background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png);
          background-position: right 6px; } } } } 
  .section-banner {
    height: 263px; } }

.orphan-page #fat-footer {
  position: relative;
  clear: both;
  background: white url(/App_Themes/Default/images/product-family/fat-footer-bg.gif) left top scroll no-repeat;
  margin: 8px 0 0 0;
  width: 960px;
  padding: 40px 0 0 24px;
  font-size: 10.8px;
  h2 {
    font-size: 1.5em;
    position: absolute;
    left: 21px;
    top: 13px;
    clear: both;
    color: #717171;
    float: none;
    padding: 0;
    width: auto;
    font-weight: bold;
    a {
      clear: both;
      color: #717171;
      float: none;
      padding: 0;
      width: auto;
      font-weight: bold; } }
  h3, a {
    color: #717171; }
  h3 {
    font-weight: bold;
    font-size: 1em;
    float: none;
    padding: 0;
    width: auto; }
  ul {
    line-height: 1.6em;
    li {
      list-style-type: none;
      width: 314px;
      display: -moz-inline-stack;
      display: inline-block;
      padding: 0 0 1.4em 0;
      vertical-align: top; } } }

.orphan-page ul {
  padding: 0;
  li {
    float: none;
    width: auto;
    height: auto;
    display: block;
    display: list-item;
    padding: 0; } }

.orphan-page #footnotes {
  font-size: 11px; }

#nspire-family-home {
  #content {
    background: transparent url(/App_Themes/Default/images/nspire-family/home-bg.jpg) left top scroll no-repeat; }
  #flash-placeholder-li {
    position: absolute;
    left: 0;
    top: 0;
    width: 960px;
    height: 331px;
    padding: 0;
    margin: 0;
    z-index: 100; }
  #flash-placeholder-object {
    width: 960px;
    height: 331px;
    position: relative;
    z-index: 100; }
  .rise-panels h3, .panels h3 {
    background: transparent none 1px 28px scroll no-repeat; } }

#nspire-family-interior-try-nspire #testing {
  color: red;
  text-decoration: underline; }

.product-loan #contact-us {
  clear: both;
  margin: 0 0 0 41px;
  font-size: 1.1em;
  position: relative;
  top: -4px;
  p {
    color: #003a66;
    line-height: 1.5em;
    .line-1 {
      display: block;
      font-size: 1.1em; }
    .line-2 {
      display: block; }
    .line-3 {
      display: block;
      font-size: 1.6em;
      line-height: 1.4em; } }
  table {
    color: #525252;
    margin: -4px 0 24px -3px;
    tbody tr {
      th, td {
        vertical-align: top;
        font-weight: normal; }
      th {
        padding: 5px 10px 4px 0;
        background: transparent url(/App_Themes/Default/images/loan/table-divider.gif) right -9px scroll no-repeat;
        text-align: right; }
      td {
        padding: 5px 0 4px 7px;
        a.phone {
          display: block;
          color: #cb0c0f;
          font-size: 1.5em;
          margin-top: -4px; }
        .note {
          font-size: .8em;
          line-height: .8em;
          letter-spacing: -0.04mm; } }
      &:first-child th, &.first-child th {
        background-position: right 0; }
      &:last-child th {
        background-position: right -69px; }
      &.last-child th {
        background-position: right bottom; } } } }

.product-loan-form #content {
  background: white url(/App_Themes/Default/images/loan/loan-content-bg.gif) 0 0 scroll no-repeat;
  padding: 109px 0 0 0;
  form {
    overflow: visible; }
  h2 {
    position: absolute;
    left: 18px;
    top: 19px;
    color: #fff;
    font-size: 2.4em;
    font-weight: normal;
    letter-spacing: 0.14mm;
    a {
      color: #fff;
      font-weight: normal;
      letter-spacing: 0.14mm; } }
  #navigation-section {
    background: transparent url(/App_Themes/Default/images/loan/navigation-section-bg.gif) 0 0 scroll repeat-x;
    width: 960px;
    height: 29px;
    -moz-border-radius: 6px 0 0 0;
    -webkit-border-radius: 6px 0 0 0;
    -khtml-border-radius: 6px 0 0 0;
    border-radius: 6px 0 0 0;
    position: absolute;
    top: 80px;
    left: 0;
    ul {
      list-style-type: none;
      overflow: hidden;
      height: 29px;
      li {
        display: block;
        float: left;
        a {
          display: block;
          height: 15px;
          color: #777;
          font-weight: bold;
          padding: 7px 20px;
          -moz-border-radius: 6px 6px 0 0;
          -webkit-border-radius: 6px 6px 0 0;
          -khtml-border-radius: 6px 6px 0 0;
          border-radius: 6px 6px 0 0; } } } } }

.product-loan-form #content {
  #navigation-section a {
    &:hover, &:active {
      color: #cb0c0f; } }
  h3 {
    color: #cb0c0f;
    padding: 15px 0 0 20px;
    font-size: 1.9em;
    letter-spacing: -0.13mm; }
  strong {
    color: #cb0c0f; }
  .column-left {
    float: left;
    width: 370px;
    padding: 0 0 0 20px;
    font-size: 1.1em;
    p {
      line-height: 1.5em;
      padding: 0 0 1.5em 0;
      &:first-child {
        color: #003a66;
        font-size: 1.4em;
        padding: 0 0 2px 0; }
      &.access-granted {
        color: #006602;
        font-size: 1.4em;
        padding: 0 0 8px 0; } } }
  .column-right {
    float: left;
    width: 350px;
    padding: 0 0 0 107px;
    font-size: 1.1em;
    position: relative;
    z-index: 13;
    p {
      line-height: 1.5em; }
    ul {
      padding: 0 0 0 29px;
      margin: 0 0 18px 0;
      li {
        padding: 0 0 0 7px;
        line-height: 1.5em;
		list-style: disc; } } }
  fieldset {
    position: relative;
    width: 836px;
    top: -30px;
    clear: left;
    legend {
      font-size: 1.9em;
      position: relative;
      color: #cb0c0f;
      text-decoration: none;
      a {
        color: #cb0c0f;
        text-decoration: none;
        &:hover, &:active, &:link, &:visited {
          color: #cb0c0f;
          text-decoration: none; } } } }
  .validator {
    display: none;
    color: #cb0c0f; }
  #fieldset-contact-information {
    -moz-border-radius: 12px 12px 0 0;
    -webkit-border-radius: 12px 12px 0 0;
    -khtml-border-radius: 12px 12px 0 0;
    border-radius: 12px 12px 0 0;
    padding: 0 4px 45px 20px;
    background: #d5d5d5 url(/App_Themes/Default/images/loan/contact-information-bg.gif) 0 0 scroll repeat-x;
    z-index: 10;
    left: 18px;
    top: -41px;
    legend {
      margin: 0 0 5px -7px;
      padding: 60px 0 0 6px;
      letter-spacing: -0.11mm; }
    .column-1, .column-2, .column-3 {
      width: 262px;
      padding: 0 0 0 18px;
      float: left; }
    .column-1 label, .column-2 label, .column-3 label {
      font-weight: bold;
      display: block;
      overflow: hidden;
      width: 262px;
      padding: 0 0 4px 0;
      letter-spacing: 0.06mm; }
    .column-1 label {
      input, select, textarea {
        display: block;
        margin: 1px 0 0 0;
        padding: 1px;
        border: solid 1px #789eb9; } }
    .column-2 label {
      input, select, textarea {
        display: block;
        margin: 1px 0 0 0;
        padding: 1px;
        border: solid 1px #789eb9; } }
    .column-3 label {
      input, select, textarea {
        display: block;
        margin: 1px 0 0 0;
        padding: 1px;
        border: solid 1px #789eb9; } }
    .column-1 input, .column-2 input, .column-3 input {
      width: 258px;
      height: 17px; }
    .column-1 .date-wrapper, .column-2 .date-wrapper, .column-3 .date-wrapper {
      position: relative;
      width: 112px;
      cursor: pointer; }
    .column-1 img, .column-2 img, .column-3 img {
      position: absolute;
      right: 2px;
      top: 2px;
      cursor: pointer; }
    .column-1 input.date, .column-2 input.date, .column-3 input.date {
      width: 108px; }
    .column-1 select, .column-2 select, .column-3 select {
      width: 262px;
      height: 21px; }
    .column-1 textarea, .column-2 textarea, .column-3 textarea {
      width: 258px;
      height: 137px;
      line-height: 1.9em; }
    .column-1 {
      padding: 0; } } }

* .column-3 {
  padding-left: 10px; }

.product-loan-form #content {
  #fieldset-contact-information {
    .note {
      clear: both;
      margin: 0 0 27px 0; }
    p {
      line-height: 1.2em;
      &:last-child, &.last-child {
        margin-bottom: 27px; } } }
  #fieldset-tabs {
    position: relative;
    top: -25px;
    padding: 0 0 0 18px;
    z-index: 20;
    span.or {
      color: #525252; }
    #fieldset-nspire, #fieldset-graphing {
      padding: 0 4px 0 20px;
      margin: 15px 0 -10px 0;
      background: transparent url(/App_Themes/Default/images/loan/nspire-bg.gif) 0 -40px scroll repeat-x;
      position: relative;
      z-index: 11; }
    #fieldset-nspire legend, #fieldset-graphing legend {
      margin: 0 0 0 -21px;
      overflow: hidden; }
    #fieldset-nspire legend label, #fieldset-graphing legend label {
      float: left;
      display: block;
      background: transparent url(/App_Themes/Default/images/loan/nspire-bg.gif) 0 0 scroll repeat-x;
      -moz-border-radius: 12px 12px 0 0;
      -webkit-border-radius: 12px 12px 0 0;
      -khtml-border-radius: 12px 12px 0 0;
      border-radius: 12px 12px 0 0;
      padding: 11px 18px 20px 21px;
      /* letter-spacing: -0.11mm; */ }
    #fieldset-nspire input, #fieldset-graphing input {
      margin: 0;
      vertical-align: baseline; }
    #fieldset-nspire span.or, #fieldset-graphing span.or {
      float: left;
      font-size: .7em;
      color: #525252; }
    #fieldset-nspire a.switch-tab, #fieldset-graphing a.switch-tab {
      float: left;
      display: block;
      color: #1973b4;
      font-weight: bold;
      font-size: .7em;
      padding: 17px 19px; }
    #fieldset-nspire {
      legend span.or {
        padding: 17px 0 0 21px; }
      p {
        color: #003a66;
        font-size: 1.6em;
        padding: 2px 0 0 0;
        line-height: 1.5em;
        strong {
          font-size: .7em;
          line-height: .7em; } }
      #handheld-cx-cas {
        display: inline-block;
        margin: 18px 0 0 0;
        padding: 34px 0 0 76px;
        height: 73px;
        background: transparent url(/App_Themes/Default/images/loan/handheld-cx-cas.png) -40px 0 scroll no-repeat;
        position: relative;
        font-weight: bold;
        font-size: 1.1em; } } } }

* #handheld-cx-cas {
  height: 107px; }

.product-loan-form input {
  vertical-align: sub; }

.product-loan-form #handheld-cx {
  display: inline-block;
  margin: 18px 0 0 0;
  padding: 34px 0 0 76px;
  height: 73px;
  background: transparent url(/App_Themes/Default/images/loan/handheld-cx.png) 0 0 scroll no-repeat;
  position: relative;
  font-weight: bold;
  font-size: 1.1em; }

* #handheld-cx {
  height: 107px; }

.product-loan-form input {
  vertical-align: sub; }

.product-loan-form #handheld-cx-wrapper {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0 15px 0 0;
  #handheld-cx-cas {
    padding: 34px 76px 0 0;
    background-position: right 0;
    vertical-align: bottom; }
  #handheld-cx-divider {
    display: inline-block;
    margin: 18px 0 0 0;
    padding: 0;
    width: 1px;
    height: 107px;
    background: transparent url(/App_Themes/Default/images/loan/handheld-cx-divider.png) 0 0 scroll no-repeat;
    vertical-align: bottom; } }


.product-loan-form {
  #content {
    #fieldset-tabs {
      #fieldset-nspire {
        #handheld-cx-wrapper #handheld-cx {
          vertical-align: bottom; }
        &.selected-cx-cas {
          #handheld-cx-wrapper #handheld-cx-cas {
            background-position: right -107px; }
          #table-cx {
            display: none; } }
        &.selected-cx {
          #handheld-cx-wrapper #handheld-cx {
            background-position: 0 -107px; }
          #table-cx-cas {
            display: none; } }
        &.selected-none {
          #table-cx-cas, #table-cx {
            display: none; } }
        table {
          position: relative;
          width: 820px;
          font-size: 1.1em;
          color: #525252;
          margin: 14px 0 0 0;
          padding: 0 34px 0 0;
          caption {
            text-align: left;
            position: absolute;
            padding: 0;
            margin: -33px 0 0 8px;
            font-size: 1.7em;
            font-weight: bold;
            color: #003a66;
            letter-spacing: -0.14mm; }
          thead tr {
            th, td {
              text-align: left;
              border-right: solid 1px #b2b2b2;
              border-bottom: solid 1px #b2b2b2;
              vertical-align: top;
              padding: 0; }
            th {
              font-size: 1.3em;
              width: 129px;
              label {
                color: #003a66;
                font-weight: bold;
                display: block;
                padding: 5px 0 9px 15px;
                position: relative;
                white-space: nowrap;
                input {
                  &[type="radio"], &.radio {
                    padding: 0 4px 0 0;
                    vertical-align: baseline; }
                  &[type="text"] {
                    border: solid 1px #789eb9;
                    width: 21px;
                    text-align: right;
                    font-size: small;
                    padding: 1px 4px 2px;
                    margin: 1px 0 0 5px; }
                  &.text {
                    border: solid 1px #789eb9;
                    width: 21px;
                    text-align: right;
                    font-size: small; } } } }
            td {
              width: 265px; } }
          tbody {
            tr {
              &:nth-child(even), &.even {
                background-color: transparent; }
              &:nth-child(odd), &.odd {
                background-color: #efefef; }
              th, td {
                border-right: solid 1px #b2b2b2;
                line-height: 25px;
                vertical-align: top; }
              th {
                text-align: left;
                font-weight: normal;
                width: 257px;
                padding: 0 0 0 8px; }
              td {
                text-align: center;
                width: 129px;
                padding: 0; }
              &:last-child {
                td, th {
                  border-bottom: solid 1px #b2b2b2; } }
              &.last-child {
                td, th {
                  border-bottom: solid 1px #b2b2b2; } } }
            &.divider tr {
              &:nth-child(even), &.even {
                background-color: #efefef; }
              &:nth-child(odd), &.odd {
                background-color: transparent; }
              th {
                padding: 0 0 0 23px;
                width: 242px; }
              &:first-child th, &.first-child th {
                border-bottom: solid 1px #b2b2b2;
                border-right: hidden 1px;
                width: auto;
                line-height: 22px;
                font-weight: bold;
                padding: 0 0 0 8px;
                font-size: 1.2em; } } } } }
      #fieldset-graphing {
        background: transparent url(/App_Themes/Default/images/loan/graphing-bg.png) 0 -40px scroll no-repeat;
        min-height: 269px;
        legend {
          label {
            background-image: url(/App_Themes/Default/images/loan/graphing-bg.png); }
          span.or {
            padding: 17px 21px 0 0; } }
        .fieldset-content label {
          display: block;
          overflow: hidden;
          width: 600px;
          padding: 6px 0 3px 2px;
          clear: both;
          font-weight: bold;
          input {
            text-align: right;
            border: solid 1px #789eb9;
            font-size: small;
            padding: 1px 1px 2px;
            margin: 0 9px 0 0;
            width: 37px;
            float: right;
            position: relative;
            top: -4px; }
          .note {
            display: block;
            width: 432px;
            float: right;
            font-weight: normal;
            font-style: italic;
            font-size: 1em; } } } }
    .selected-nspire #fieldset-nspire, .selected-graphing #fieldset-graphing {
      top: -66px;
      margin-top: 0;
      margin-bottom: -91px;
      -moz-border-radius: 0 9px 0 0;
      -webkit-border-radius: 0 9px 0 0;
      -khtml-border-radius: 0 9px 0 0;
      border-radius: 0 9px 0 0; }
    .selected-nspire legend, .selected-graphing legend {
      background-image: none;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      -khtml-border-radius: 0;
      border-radius: 0;
      padding: 0;
      top: -16px;
      margin-top: -23px;
      margin-bottom: 6px; }
    /*.selected-nspire input, .selected-graphing input, .selected-nspire #fieldset-graphing, .selected-graphing #fieldset-nspire {
      display: none; }*/
    input {
      &[type="submit"] {
        float: right;
        margin-right: 102px;
        position: relative;
        z-index: 22; }
      &.submit {
        float: right;
        margin-right: 102px;
        position: relative;
        z-index: 21; } }
    #fieldset-probes {
      width: 820px;
      margin: 5px 38px 10px;
      top: 0;
      left: 0;
      z-index: 21;
      legend {
        margin: 0 0 5px 0 !important;
        color: #003a66;
        overflow: hidden;
        width: 820px;
        cursor: pointer;
        a {
          display: block;
          float: left;
          margin: 0 8px 0 0;
          padding: 0 4px;
          background-color: #cb0c0f;
          color: #fff;
          line-height: 20px;
          width: 12px;
          text-align: center; } }
      .fieldset-content {
        overflow: hidden;
        width: 820px;
        strong {
          margin: -1px 0 5px 0;
          display: block; }
        label {
          display: block;
          float: left;
          width: 259px;
          padding: 10px 180px 3px 9px;
          overflow: hidden;
          font-weight: bold;
          position: relative;
          input {
            float: right;
            text-align: right;
            border: solid 1px #789eb9;
            font-size: small;
            width: 37px;
            padding: 1px 1px 2px;
            margin-top: -4px; }
          &:nth-child(even), &.even {
            padding-right: 0; }
          &:nth-child(odd), &.odd {
            clear: left; } } } } }
  &#product-loan-form-teacher a {
    //background-color: #fff;
    color: #cb0c0f;
    &:hover, &:active, &:link, &:visited {
      //background-color: #fff;
      color: #cb0c0f; } }
  &#product-loan-form-enter-code a {
    //background-color: #fff;
    color: #cb0c0f;
    &:hover, &:active, &:link, &:visited {
      //background-color: #fff;
      color: #cb0c0f; } }
  &#product-loan-form-t3-instructor a {
    //background-color: #fff;
    color: #cb0c0f;
    &:hover, &:active, &:link, &:visited {
      //background-color: #fff;
      color: #cb0c0f; } }
  &.product-loan-form-thank-you-teacher a {
    //background-color: #fff;
    color: #cb0c0f;
    &:hover, &:active, &:link, &:visited {
      //background-color: #fff;
      color: #cb0c0f; } }
  &.product-loan-form-thank-you-t3-instructor a {
    //background-color: #fff;
    color: #cb0c0f;
    &:hover, &:active, &:link, &:visited {
      //background-color: #fff;
      color: #cb0c0f; } }
  &#product-loan-form-teacher .column-right {
    margin: -135px 0 0 0;
    padding: 212px 95px 0 107px;
    background: transparent url(/App_Themes/Default/images/loan/teacher-right-bg.png) right top scroll no-repeat;
    width: 350px; } }

.product-loan-form #content {
  .selected-nspire legend, .selected-graphing legend {
    background-image: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    border-radius: 0;
    padding: 0;
    top: -16px;
    margin-top: -23px;
    margin-bottom: 6px; } }

* .column-right {
  width: 565px; }

.product-loan-form {
  &#product-loan-form-teacher {
    #fieldset-tabs {
      #fieldset-nspire table {
        padding-bottom: 21px;
        border-bottom: solid 1px #b2b2b2; }
      #fieldset-graphing {
        border-bottom: solid 1px #b2b2b2; } }
    .selected-graphing input {
      &[type="submit"], &.submit {
        margin-right: 81px; } } }
  &#product-loan-form-enter-code #content {
    .column-right {
      margin: -29px 0 -36px 109px;
      width: 272px;
      height: 216px;
      background: transparent url(/App_Themes/Default/Images/loan/instructor-right-bg.gif) right top scroll no-repeat; }
    fieldset {
      background: url(/App_Themes/Default/images/loan/code-left.gif) no-repeat left bottom, url(/App_Themes/Default/images/loan/code-right.gif) no-repeat right bottom, url(/App_Themes/Default/images/loan/code-center.gif) repeat-x center bottom;
      padding: 0 0 13px;
      width: auto;
      display: inline-block;
      clear: both;
      margin: 20px 0 0 22px;
      float: left; } } }

.product-loan-form {
  &#product-loan-form-enter-code {
    #content fieldset {
      .wrapper-center {
        background: url(/App_Themes/Default/images/loan/code-center.gif) repeat-x center bottom;
        .wrapper-left {
          background: url(/App_Themes/Default/images/loan/code-left.gif) no-repeat left bottom;
          .wrapper-right {
            background: url(/App_Themes/Default/images/loan/code-right.gif) no-repeat right bottom;
            padding-bottom: 13px;
            white-space: nowrap; } } }
      p {
        width: 327px;
        font-size: 1.5em;
        margin: 0 0 0 -2px;
        padding: 0 0 2px 0;
        color: #003a66;
        .validator {
          position: absolute;
          white-space: nowrap;
          padding: 0 6px; } }
      input {
        border: 0 none;
        background: transparent;
        float: none;
        height: 42px;
        line-height: 42px;
        margin: 0;
        padding: 0;
        font-weight: normal;
        &[type="text"], &.text {
          color: #525252;
          width: 258px;
          padding: 0 13px;
          margin: 0 10px 0 0;
          font-size: medium; }
        &[type="submit"], &.submit {
          color: #fff;
          padding: 0 76px 0 18px;
          font-size: large; } } }
    .error-invalid-code .validator {
      display: inline; } }
  &#product-loan-form-t3-instructor {
    .column-right {
      margin: -26px 0 0 0;
      padding: 191px 161px 0 107px;
      background: transparent url(/App_Themes/Default/images/loan/code-right-bg.jpg) right top scroll no-repeat;
      width: 220px; }
    #fieldset-probes {
      padding-bottom: 17px;
      border-bottom: solid 1px #b2b2b2; } }
  &#product-loan-form-thank-you #content-inner {
    padding: 15px 42px;
    font-size: 1.2em; } }

.msie #content {
  fieldset {
    top: 0; }
  #fieldset-contact-information {
    top: 0;
    legend {
      margin-top: 0;
      padding-top: 12px; } }
  .selected-nspire #fieldset-nspire, .selected-graphing #fieldset-graphing {
    top: 0;
    margin-bottom: -13px; }
  .selected-nspire legend, .selected-graphing legend {
    margin-top: -25px;
    margin-bottom: -15px; } }

#product-loan-home #content {
  padding: 25px 25px 0px 20px;
  background: url(/App_Themes/Default/images/loan/loan-landing-hero-bg.jpg) no-repeat;
  .inner-content {
    font-size: 10px;
    overflow: hidden;
    h2 {
      color: #FFF;
      font-size: 2.545em;
      font-weight: normal;
      margin-bottom: 55px; }
    h3 {
      font-size: 2.4em;
      line-height: 1em;
      padding-bottom: 5px;
      color: #cb0c0f; }
    a {
      font-size: 1.2em; }
    p {
      font-size: 1.2em;
      line-height: 1.7em;
      &.p1 {
        max-width: 410px;
        font-size: 2.0em;
        line-height: 1.333em;
        color: #003a66; }
      &.p2 {
        max-width: 355px;
        min-height: 90px;
        padding-bottom: 10px; }
      strong {
        font-weight: normal; } }
    #borrowing-equipment {
      background: url(/App_Themes/Default/images/loan/loan-landing-borrowing-bg.png) no-repeat;
      padding: 15px 17px 0 17px;
      width: 572px; }
    #what-next {
      overflow: hidden;
      padding: 25px 15px 0 17px;
      float: left;
      li {
        float: left;
        list-style: none;
        padding-right: 25px;
        width: auto;
        &.first-child {
          width: 155px; }
        &.last-child {
          padding-left: 25px;
          padding-right: 0;
          background: url(/App_Themes/Default/images/loan/loan-landing-what-next-divider.png) no-repeat left center; }
        a {
          display: inline-block;
          padding-top: 35px;
          span {
            float: right;
            display: inline-block;
            padding: 15px 0 7px 15px;
            font-weight: bold;
            background: url(/App_Themes/Default/images/chevrons.png) right bottom no-repeat;
            line-height: 1em; } } } } } }

#product-loan-home #content {
  #what-next em {
    display: block;
    font-weight: normal;
    font-style: normal;
    font-size: 1.667em;
    line-height: 1em; }
  #contact-us {
    float: right;
    margin: 0;
    a, .line-3 {
      line-height: 1em; }
    table {
      font-size: 1.2em;
      a {
        font-size: 1em; }
      tbody a.phone {
        font-size: 1.4em; } } } }

#product-loan-home #contact-us {
  clear: both;
  margin: 0 0 0 41px;
  font-size: 1.1em;
  position: relative;
  top: -4px;
  p {
    color: #003a66;
    line-height: 1.5em;
    .line-1 {
      display: block;
      font-size: 1.1em; }
    .line-2 {
      display: block; }
    .line-3 {
      display: block;
      font-size: 1.6em;
      line-height: 1.4em; } }
  table {
    color: #525252;
    margin: -4px 0 24px -3px;
    tbody tr {
      th, td {
        vertical-align: top;
        font-weight: normal; }
      th {
        padding: 5px 10px 4px 0;
        background: transparent url(/App_Themes/Default/images/loan/table-divider.gif) right -9px scroll no-repeat;
        text-align: right; }
      td {
        padding: 5px 0 4px 7px;
        a.phone {
          display: block;
          color: #cb0c0f;
          font-size: 1.5em;
          margin-top: -4px; }
        .note {
          font-size: .8em;
          line-height: .8em;
          letter-spacing: -0.04mm; } }
      &:first-child th, &.first-child th {
        background-position: right 0; }
      &:last-child th {
        background-position: right -69px; }
      &.last-child th {
        background-position: right bottom; } } } }

.product-family-interior #content {
              #section-content-cx-handhelds {
                background-position: right top;
							h4 { font-size: 15px; }
                            h3 {
                              width: 664px;
                              padding: 20px 28px 11px 268px; }
                            #hero-copy {
                              width: 267px;
                              height: 213px;
                              padding: 0 0 0 268px;
                              color: #0c63a3;
                              font-size: 1.225em;
                              p {
                                line-height: 1.35em; } }
                            #hero-links {
                              width: 267px;
                              padding: 0 12px 15px 12px;
                                  li {
                                    float: left;
                                    list-style-type: none;
                                    width: 130px;
                                    position: relative;
                                        a {
                                              &:last-child, &.last-child, &.more {
                                                background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png); } 
                                            } 
                                        } 
                                    }
                  }
                  
              #section-content-right{
                background-position: right top;
                            h3 {
                              width: 664px;
                              padding: 20px 28px 11px 268px; }
                            #hero-copy {
                              width: 267px;
                              height: 213px;
                              padding: 0 0 0 268px;
                              color: #0c63a3;
                              font-size: 1.225em;
                              p {
                                line-height: 1.35em; } }
                            #hero-links {
                              width: 267px;
                              padding: 0 12px 15px 12px;
                                  li {
                                    float: left;
                                    list-style-type: none;
                                    width: 130px;
                                    position: relative;
                                        a {
                                              &:last-child, &.last-child {
                                                background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png); } 
                                            } 
                                        } 
                                    }
                  }                  
                  
              #section-content-software {
                background-position: right top;
                            h3 {
                              width: 337px;
                              padding: 20px 355px 11px 268px; }
                            #hero-copy {
                              width: 267px;
                              height: 200px;
                              padding: 0 0 0 268px;
                              color: #0c63a3;
                              font-size: 1.225em;
                                  p {
                                    line-height: 1.35em; } 
                                }
                            #hero-links-teacher, #hero-links-student {
                              width: 267px;
                              padding: 0 12px 15px 12px; }
      
                   } 
       
       }

.product-family-interior {
  #content {
	#section-content-software {
	  #hero-links-teacher li, #hero-links-student li {
		float: left;
		list-style-type: none;
		width: 130px;
		position: relative; }
	  #hero-links-teacher a.purchase, #hero-links-student a.purchase {
		background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png); } }

	#section-content-navigator {
	  background-position: 239px top;
	              h3 {
		            width: 265px;
		            padding: 20px 28px 11px 665px; }
	                  #hero-copy {
		                width: 267px;
		                height: 200px;
		                padding: 0 0 0 665px;
		                color: #0c63a3;
		                font-size: 1.225em;
		                        p {
		                          line-height: 1.35em; } 
		                  }
	                  #hero-links {
		                width: 267px;
		                padding: 0 12px 15px 12px;
		                            li {
		                              float: left;
		                              list-style-type: none;
		                              width: 130px;
		                              position: relative;
		                                              a.purchase {
			                                            background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png); }
			                             }
			                  }
		 }
		 
	#section-content-left {
	  background-position: 239px top;
	              h3 {
		            width: 267px;
		            padding: 20px 28px 11px 665px; }
	                  #hero-copy {
		                width: 267px;
		                height: 213px;
		                padding: 0 0 0 665px;
		                color: #0c63a3;
		                font-size: 1.225em;
		                        p {
		                          line-height: 1.35em; } 
		                  }
	                  #hero-links {
		                width: 267px;
		                padding: 0 12px 15px 12px;
		                            li {
		                              float: left;
		                              list-style-type: none;
		                              width: 130px;
		                              position: relative;
		                                              a.purchase {
			                                            background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png); }
			                             }
			                  }
	                  #features h5 {
		                height: 139px; }
		 }		 
		 

	#section-content-data-collection {
	  background-position: right top;
	  h3 {
		width: 347px;
		padding: 20px 345px 11px 268px; }
	  #hero-copy {
		width: 267px;
		height: 213px;
		padding: 0 0 0 268px;
		color: #0c63a3;
		font-size: 1.225em;
		p {
		  line-height: 1.35em; } }
	  #hero-links {
		width: 267px;
		padding: 0 12px 15px 12px;
		li {
		  float: left;
		  list-style-type: none;
		  width: 130px;
		  position: relative;
		  &:last-child a, &.last-child a {
			background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png); } } }	  
	  #features li {		
		h5, h6 {
		  width: 300px; } } } }

  #section-wrapper-cx-handhelds h2 a, #section-wrapper-left h2 a, #section-wrapper-right h2 a, #section-wrapper-software h2 a, #section-wrapper-navigator h2 a, #section-wrapper-data-collection h2 a {
	font-size: 1.2em;
	color: #FDFEFE; }


  #section-wrapper-cx-handhelds a, #section-wrapper-right a, #section-wrapper-left a, #section-wrapper-software a, #section-wrapper-navigator a, #section-wrapper-data-collection a {
	background-image: none;
	color: #E3171F; 
	&.more{
		background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows-sm.png);
	}
	
	} 
  .etfootermoduletheme-classic {
    background-position : 0 30px;
    padding : 60px 0 0; } }

 

.nspired-school #feedback-bar {
  clear: both;
  background: #fdfdfd url(/App_Themes/Default/images/nspired-school/feedback-bar-bg.gif) bottom center scroll repeat-x;
  margin: 10px 0 0 0;
  padding: 5px 5px 1px 5px;
  text-align: center;
  border: solid 1px #c4c4c4;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  -khtml-border-radius: 7px;
  border-radius: 7px;
  p {
    line-height: 17px;
    a {
      display: -moz-inline-stack;
      display: inline-block;
      padding: 0 0 1px 8px;
      background: transparent url(/App_Themes/Default/images/products/red-arrow.gif) 1px 5px scroll no-repeat; } } }

.nspired-school-auxiliary #navigation-sidebar {
  float: left;
  width: 147px;
  padding: 24px 15px 0 15px;
  min-height: 656px;
  background: transparent url(/App_Themes/Default/images/nspired-school/navigation-sidebar-bg.gif) top right scroll no-repeat; }

* #navigation-sidebar {
  height: 656px; }

.nspired-school-auxiliary {
  #navigation-sidebar {
    h2 {
      line-height: 1.425em;
      a {
        color: #cb0c0f;
        font-weight: bold; } }
    ul {
      padding: 28px 0 0 0;
      background: transparent url(/App_Themes/Default/images/nspired-school/navigation-sidebar-divider.gif) 0 23px scroll no-repeat;
      li {
        list-style-type: none;
        line-height: 1.625em;
        padding: 11px 0 14px 0;
        background: transparent url(/App_Themes/Default/images/nspired-school/navigation-sidebar-divider.gif) 0 bottom scroll no-repeat;
        color: #666;
        font-weight: bold;
        a {
          color: #666;
          font-weight: bold; }
        &:hover, &:focus, &:active {
          color: #c00; }
        a {
          &:hover, &:focus, &:active {
            color: #c00; } } } } }
  #content-inner {
    float: left;
    width: 783px;
    min-height: 656px; height: auto; } }

* #content-inner {
  height: 656px; }

#product-navigator-home #content {
  a.more {
    font-weight: bold;
    color: #b5252a;
    background: transparent url(/App_Themes/Default/images/navigator/more-arrows.gif) right 2px scroll no-repeat;
    font-size: 1em;
    padding-right: 22px;
    display: -moz-inline-stack;
    display: inline-block; }
  #feature {
    position: relative;
    width: 960px;
    height: 264px;
    overflow: hidden; } }

* #feature {
  overflow: hidden; }

#product-navigator-home #content {
  #feature {
    .jeslideshow, .jecarousel {
      padding: 0;
      margin: 0;
      width: 960px;
      height: 264px; }
    .jeslideshow {
      .panel-1, .panel-2, .panel-3, .panel-4, .panel-5 {
        font-size: 1.2em;
        list-style-type: none;
        margin: 0;
        height: 226px;
        background-repeat: no-repeat; } }
    .jecarousel {
      .panel-1, .panel-2, .panel-3, .panel-4, .panel-5 {
        font-size: 1.2em;
        list-style-type: none;
        margin: 0;
        height: 226px;
        background-repeat: no-repeat; } }
    .jeslideshow {
      .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2, .panel-5 h2 {
        color: #535353;
        font-size: 1.6em;
        background-position: 0 0;
        background-repeat: no-repeat;
        margin: -13px 0 0 -59px;
        padding: 13px 0 8px 59px; } }
    .jecarousel {
      .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2, .panel-5 h2 {
        color: #535353;
        font-size: 1.6em;
        background-position: 0 0;
        background-repeat: no-repeat;
        margin: -13px 0 0 -59px;
        padding: 13px 0 13px 59px; } }
    .jeslideshow {
      .panel-1 p, .panel-2 p, .panel-3 p, .panel-4 p, .panel-5 p {
        color: #535353;
        font-size: 1.2em;
        line-height: 1.9em;
        margin-bottom: 3px; } }
    .jecarousel {
      .panel-1 p, .panel-2 p, .panel-3 p, .panel-4 p, .panel-5 p {
        color: #535353;
        font-size: 1.2em;
        line-height: 1.9em;
        margin-bottom: 3px; } }
    .jeslideshow {
      .panel-1 a.play, .panel-2 a.play, .panel-3 a.play, .panel-4 a.play, .panel-5 a.play {
        display: block;
        color: #535353;
        background: transparent url(/App_Themes/Default/images/navigator/button-play.gif) 0 0 scroll no-repeat;
        min-height: 20px;
        font-weight: bold;
        padding: 2px 0 0 29px;
		line-height: 20px; } }
    .jecarousel {
      .panel-1 a.play, .panel-2 a.play, .panel-3 a.play, .panel-4 a.play, .panel-5 a.play {
        display: block;
        color: #535353;
        background: transparent url(/App_Themes/Default/images/navigator/button-play.gif) 0 0 scroll no-repeat;
        min-height: 20px;
        font-weight: bold;
        padding: 2px 0 0 29px;
		line-height: 20px; } }
    .jeslideshow {
      .panel-1 a.more, .panel-2 a.more, .panel-3 a.more, .panel-4 a.more, .panel-5 a.more {
        background-position: right 12px;
        font-size: .7em; } }
    .jecarousel {
      .panel-1 a.more, .panel-2 a.more, .panel-3 a.more, .panel-4 a.more, .panel-5 a.more {
        background-position: right 12px;
        font-size: .7em; } }
    .jeslideshow {
      .panel-1, .panel-2, .panel-3, .panel-4, .panel-5 {
        position: absolute;
        left: 0;
        top: 0; } }
    .jecarousel-buttons {
      list-style: none;
      height: 25px;
      position: absolute;
      bottom: auto;
      right: 15px;
      top: 238px;
      z-index: 101;
      li {
        float: left;
        display: block;
        width: 8px;
        height: 8px;
        margin: 0 0 0 5px;
        background: transparent;
        border: solid 1px #525252;
        &.selected {
          background: #cb0c0f;
          width: 8px;
          height: 8px; }
        span {
          display: none; } } } }
  #content-inner {
    width: 960px;
    overflow: hidden;
    padding: 4px 0 0 0;
	height: auto;
	background: transparent url(/App_Themes/Default/images/navigator/panels-3-1.gif) 0 0 scroll no-repeat;
    #section-nspire-navigator, #section-navigator {
      float: left;
      position: relative;
      min-height: 150px; }
    #section-nspire-navigator h2, #section-navigator h2 {
      position: absolute;
      top: 0;
      color: #646464;
      font-size: 1.4em;
      line-height: 1.55em;
      padding: 0 60px 0 0; }
    #section-nspire-navigator {
      .panels-1, .panels-2, .panels-3 {
        list-style-type: none;
        padding: 26px 0 0 0; } }
    #section-navigator {
      .panels-1, .panels-2, .panels-3 {
        list-style-type: none;
        padding: 26px 0 0 0; } }
    #section-nspire-navigator {
      .panels-1 {
        .panel-1, .panel-2, .panel-3 {
          list-style-type: none;
          width: 117px;
          min-height: 150px;
          padding: 47px 11px 10px 109px;
          float: left;
          position: relative;
          background-repeat: no-repeat;
          background-position: 2px 42px; } }
      .panels-2 {
        .panel-1, .panel-2, .panel-3 {
          list-style-type: none;
          width: 117px;
          min-height: 150px;
          padding: 47px 11px 10px 109px;
          float: left;
          position: relative;
          background-repeat: no-repeat;
          background-position: 2px 42px; } }
      .panels-3 {
        .panel-1, .panel-2, .panel-3 {
          list-style-type: none;
          width: 117px;
          min-height: 150px;
          padding: 47px 11px 10px 109px;
          float: left;
          position: relative;
          background-repeat: no-repeat;
          background-position: 2px 42px; 
        } } }
    #section-navigator {
      .panels-1 {
        .panel-1, .panel-2, .panel-3 {
          list-style-type: none;
          width: 117px;
          min-height: 150px;
          padding: 47px 11px 10px 109px;
          float: left;
          position: relative;
          background-repeat: no-repeat;
          background-position: 2px 42px; } }
      .panels-2 {
        .panel-1, .panel-2, .panel-3 {
          list-style-type: none;
          width: 117px;
          min-height: 150px;
          padding: 47px 11px 10px 109px;
          float: left;
          position: relative;
          background-repeat: no-repeat;
          background-position: 2px 42px; } }
      .panels-3 {
        .panel-1, .panel-2, .panel-3 {
          list-style-type: none;
          width: 117px;
          min-height: 150px;
          padding: 47px 11px 10px 109px;
          float: left;
          position: relative;
          background-repeat: no-repeat;
          background-position: 2px 42px; } } }
    #section-nspire-navigator {
      .panels-1 {
        .panel-1 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-2 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-3 {
          &:first-child, &.first-child {
            padding-left: 110px; } } }
      .panels-2 {
        .panel-1 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-2 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-3 {
          &:first-child, &.first-child {
            padding-left: 110px; } } }
      .panels-3 {
        .panel-1 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-2 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-3 {
          &:first-child, &.first-child {
            padding-left: 110px; } } } }
    #section-navigator {
      .panels-1 {
        .panel-1 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-2 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-3 {
          &:first-child, &.first-child {
            padding-left: 110px; } } }
      .panels-2 {
        .panel-1 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-2 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-3 {
          &:first-child, &.first-child {
            padding-left: 110px; } } }
      .panels-3 {
        .panel-1 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-2 {
          &:first-child, &.first-child {
            padding-left: 110px; } }
        .panel-3 {
          &:first-child, &.first-child {
            padding-left: 110px; } } } }
    #section-nspire-navigator {
      .panels-1 {
        .panel-1 h3, .panel-2 h3, .panel-3 h3 {
          position: absolute;
          left: 13px;
          top: 10px;
          font-weight: bold;
          line-height: 1.55em; } }
      .panels-2 {
        .panel-1 h3, .panel-2 h3, .panel-3 h3 {
          position: absolute;
          left: 13px;
          top: 10px;
          font-weight: bold;
          line-height: 1.55em; } }
      .panels-3 {
        .panel-1 h3, .panel-2 h3, .panel-3 h3 {
          position: absolute;
          left: 13px;
          top: 10px;
          font-weight: bold;
          line-height: 1.55em; } } }
    #section-navigator {
      .panels-1 {
        .panel-1 h3, .panel-2 h3, .panel-3 h3 {
          position: absolute;
          left: 13px;
          top: 10px;
          font-weight: bold;
          line-height: 1.55em; } }
      .panels-2 {
        .panel-1 h3, .panel-2 h3, .panel-3 h3 {
          position: absolute;
          left: 13px;
          top: 10px;
          font-weight: bold;
          line-height: 1.55em; } }
      .panels-3 {
        .panel-1 h3, .panel-2 h3, .panel-3 h3 {
          position: absolute;
          left: 13px;
          top: 10px;
          font-weight: bold;
          line-height: 1.55em; } } }
    #section-nspire-navigator {
      .panels-1 {
        .panel-1 p, .panel-2 p, .panel-3 p {
          line-height: 1.5em;
          min-height: 131px; } }
      .panels-2 {
        .panel-1 p, .panel-2 p, .panel-3 p {
          line-height: 1.5em;
          min-height: 131px; } }
      .panels-3 {
        .panel-1 p, .panel-2 p, .panel-3 p {
          line-height: 1.5em;
          min-height: 131px; } } }
    #section-navigator {
      .panels-1 {
        .panel-1 p, .panel-2 p, .panel-3 p {
          line-height: 1.5em;
          min-height: 131px; } }
      .panels-2 {
        .panel-1 p, .panel-2 p, .panel-3 p {
          line-height: 1.5em;
          min-height: 131px; } }
      .panels-3 {
        .panel-1 p, .panel-2 p, .panel-3 p {
          line-height: 1.5em;
          min-height: 131px; } } }
    background: transparent url(/App_Themes/Default/images/navigator/panels-3-1.gif) 0 0 scroll no-repeat;
    &.panels-3-1 {
      background: transparent url(/App_Themes/Default/images/navigator/panels-3-1.gif) 0 0 scroll no-repeat; }
    &.panels-3-0, &.panels-0-3 {
      background-image: url(/App_Themes/Default/images/navigator/panels-3.gif); }
    &.panels-3-0 {
      #section-nspire-navigator .panels-3 {
        .panel-1, .panel-2, .panel-3 {
          width: 194px; } }
      #section-navigator .panels-3 {
        .panel-1, .panel-2, .panel-3 {
          width: 194px; } } }
    &.panels-0-3 {
      #section-nspire-navigator .panels-3 {
        .panel-1, .panel-2, .panel-3 {
          width: 194px; } }
      #section-navigator .panels-3 {
        .panel-1, .panel-2, .panel-3 {
          width: 194px; } } }
    &.panels-2-0, &.panels-0-2 {
      background-image: url(/App_Themes/Default/images/navigator/panels-2.gif); }
    &.panels-2-0 {
      #section-nspire-navigator .panels-2 {
        .panel-1, .panel-2 {
          width: 351px; } }
      #section-navigator .panels-2 {
        .panel-1, .panel-2 {
          width: 351px; } } }
    &.panels-0-2 {
      #section-nspire-navigator .panels-2 {
        .panel-1, .panel-2 {
          width: 351px; } }
      #section-navigator .panels-2 {
        .panel-1, .panel-2 {
          width: 351px; } } }
    &.panels-1-0, &.panels-0-1 {
      background-image: url(/App_Themes/Default/images/navigator/panels-1.gif); }
    &.panels-1-0 {
      #section-nspire-navigator .panel-1, #section-navigator .panel-1 {
        width: 823px; } }
    &.panels-0-1 {
      #section-nspire-navigator .panel-1, #section-navigator .panel-1 {
        width: 823px; } }
    &.panels-3-0 #section-nspire-navigator, &.panels-2-0 #section-nspire-navigator, &.panels-1-0 #section-nspire-navigator, &.panels-0-3 #section-navigator, &.panels-0-2 #section-navigator, &.panels-0-1 #section-navigator {
      width: 944px; }	  
	&.panels-2-1 {
      background: transparent url(/App_Themes/Default/images/navigator/panels-2-1.gif) 0 0 scroll no-repeat; 
	  #section-nspire-navigator .panels-2, #section-navigator .panels-2 {
        .panel-1, .panel-2 {
          width: 235px; } } }
    #section-nspire-navigator {
      padding: 0 0 0 8px;
      width: 712px;
      h2 {
        left: 20px; font-weight: bold; } }
    #section-navigator {
      padding: 0 0 0 2px;
      width: 238px;
      h2 {
        left: 16px; font-weight: bold;
        padding-right: 0; } } } }

.product-showcase #slideshow {
  position: relative;
  width: 960px;
  height: 267px;
  overflow: visible; }

* #slideshow {
  overflow: hidden; }

.product-showcase #content {
  #slideshow {
    .jeslideshow {
      padding: 0;
      margin: 0;
      width: 960px;
      height: 433px;
      background: #e9e9e9 url(/App_Themes/Default/images/showcase/slideshow-bg.gif) 0 0 scroll no-repeat;
      .panel-1, .panel-2, .panel-3, .panel-4 {
        font-size: 1.2em;
        list-style-type: none;
        margin: 0;
        padding: 16px 496px 0 46px;
        position: absolute;
        left: 0;
        top: 0;
        width: 418px;
        height: 417px;
        background-repeat: no-repeat; }
      .panel-1 {
        h2, h3 {
          color: #fff;
          font-weight: bold; } }
      .panel-2 {
        h2, h3 {
          color: #fff;
          font-weight: bold; } }
      .panel-3 {
        h2, h3 {
          color: #fff;
          font-weight: bold; } }
      .panel-4 {
        h2, h3 {
          color: #fff;
          font-weight: bold; } }
      .panel-1 {
        h2 a, h3 a {
          color: #fff;
          font-weight: bold; } }
      .panel-2 {
        h2 a, h3 a {
          color: #fff;
          font-weight: bold; } }
      .panel-3 {
        h2 a, h3 a {
          color: #fff;
          font-weight: bold; } }
      .panel-4 {
        h2 a, h3 a {
          color: #fff;
          font-weight: bold; } }
      .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2 {
        font-size: 1.75em;
        margin-left: -16px;
        padding: 0 0 11px 0; }
      .panel-1 h3, .panel-2 h3, .panel-3 h3, .panel-4 h3 {
        font-size: 1.3em;
        padding: 0 0 10px 0; }
      .panel-1 p, .panel-2 p, .panel-3 p, .panel-4 p {
        line-height: 1.6em;
        color: #5a5a5a; } }
    .jeslideshow-buttons {
      list-style: none;
      height: 25px;
      position: absolute;
      bottom: auto;
      right: 17px;
      top: 249px;
      z-index: 101;
      li {
        float: left;
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 0 0 8px;
        background: #fdfefe;
        border: transparent none 0;
        &.selected {
          background: #bf1400;
          width: 10px;
          height: 10px;
          border: transparent none 0; }
        span {
          display: none; } } } }
  #panels {
    clear: both;
    margin: 0 0 0 2px;
    position: relative;
    z-index: 102;
    .panel-1, .panel-2, .panel-3, .panel-4 {
      list-style-type: none;
      position: relative;
      float: left;
      width: 104px;
      min-height: 88px;
      margin: 0 0 30px 4px;
      padding: 63px 15px 31px 114px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      -khtml-border-radius: 3px;
      border-radius: 3px;
      background: white url(/App_Themes/Default/images/showcase/panel-bg.gif) 0 0 scroll no-repeat;
	  height: auto; } } }

* #panels {
  .panel-1, .panel-2, .panel-3, .panel-4 {
    height: 88px; } }

.product-showcase #panels {
  .panel-1 {
    h2, h3 {
      position: absolute; } }
  .panel-2 {
    h2, h3 {
      position: absolute; } }
  .panel-3 {
    h2, h3 {
      position: absolute; } }
  .panel-4 {
    h2, h3 {
      position: absolute; } }
  .panel-1 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-2 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-3 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-4 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-1 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-2 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-3 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-4 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2 {
    left: 0;
    top: 7px;
    padding: 0 9px;
    height: 173px;
    width: 215px;
    background-repeat: no-repeat;
    background-position: 3px 45px; }
  .panel-1 h3, .panel-2 h3, .panel-3 h3, .panel-4 h3 {
    left: 0;
    top: 27px;
    padding: 0 40px 0 12px; }
  .panel-1 p, .panel-2 p, .panel-3 p, .panel-4 p {
    position: relative;
    z-index: 103;
	line-height: 1.4em; }
  .panel-1 a.more, .panel-2 a.more, .panel-3 a.more, .panel-4 a.more {
    font-weight: bold;
    display: block;
    text-align: right;
    color: #c1272d;
    background: transparent url(/App_Themes/Default/images/chevrons.png) right 7px scroll no-repeat;
    position: absolute;
    bottom: 0;
    right: 9px;
    padding: 5px 22px; } }

.product-science #slideshow {
  position: relative;
  width: 960px;
  height: 267px;
  overflow: visible; }

* #slideshow {
  overflow: hidden; }

.product-science #content {
  #slideshow {
    .jeslideshow {
      padding: 0;
      margin: 0;
      width: 960px;
      height: 433px;
      background: #e9e9e9 url(/App_Themes/Default/images/science/slideshow-bg.gif) 0 0 scroll no-repeat;
      .panel-1, .panel-2, .panel-3, .panel-4, .panel-5, .panel-6, .panel-7, .panel-8 {
        font-size: 1.2em;
        list-style-type: none;
        margin: 0;
        padding: 16px 496px 0 46px;
        position: absolute;
        left: 0;
        top: 0;
        width: 418px;
        height: 417px;
        background-repeat: no-repeat; }
      .panel-1 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-2 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-3 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-4 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-5 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-6 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-7 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-8 {
        h2, h3 {
          color: #444;
          font-weight: bold; } }
      .panel-1 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-2 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-3 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-4 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-5 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-6 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-7 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-8 {
        h2 a, h3 a {
          color: #444;
          font-weight: bold; } }
      .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2, .panel-5 h2, .panel-6 h2, .panel-7 h2, .panel-8 h2 {
        font-size: 1.6em;
        margin-left: -16px;
        padding: 0 0 11px 0; }
      .panel-1 h3, .panel-2 h3, .panel-3 h3, .panel-4 h3, .panel-5 h3, .panel-6 h3, .panel-7 h3, .panel-8 h3 {
        font-size: 1.2em;
        padding: 0 0 10px 0; }
      .panel-1 p, .panel-2 p, .panel-3 p, .panel-4 p, .panel-5 p, .panel-6 p, .panel-7 p, .panel-8 p {
        line-height: 1.5em;
        color: #5a5a5a;
        margin-right: 10px; }
      .panel-1 a.more, .panel-2 a.more, .panel-3 a.more, .panel-4 a.more, .panel-5 a.more, .panel-6 a.more, .panel-7 a.more, .panel-8 a.more {
        font-weight: bold;
        display: inline-block;
        text-align: right;
        color: #c1272d;
        background: transparent url(/App_Themes/Default/images/chevrons.png) right 9px scroll no-repeat;
        padding: 5px 22px 5px 0; } }
    .jeslideshow-buttons {
      list-style: none;
      height: 25px;
      position: absolute;
      bottom: auto;
      right: 17px;
      top: 249px;
      z-index: 101;
      li {
        float: left;
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 0 0 8px;
        background: #fdfefe;
        border: transparent none 0;
        &.selected {
          background: #bf1400;
          width: 10px;
          height: 10px;
          border: transparent none 0; }
        span {
          display: none; } } } }
  #panels {
    clear: both;
    margin: 0 0 0 2px;
    position: relative;
    z-index: 102;
    .panel-1, .panel-2, .panel-3, .panel-4 {
      list-style-type: none;
      position: relative;
      float: left;
      min-height: 100px;
      margin: 0 0 30px 4px;
      padding: 30px 10px 31px 114px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      -khtml-border-radius: 3px;
      border-radius: 3px;
	  height: auto; } } }

* #panels {
  .panel-1, .panel-2, .panel-3, .panel-4 {
    height: 88px; } }

.product-science #panels {
  .panel-1 {
    h2, h3 {
      font-size: 1em;
      position: absolute; } }
  .panel-2 {
    h2, h3 {
      font-size: 1em;
      position: absolute; } }
  .panel-3 {
    h2, h3 {
      font-size: 1em;
      position: absolute; } }
  .panel-4 {
    h2, h3 {
      font-size: 1em;
      position: absolute; } }
  .panel-1 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-2 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-3 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-4 {
    h2, h3 {
      color: #6d6b6c;
      font-weight: bold;
      a {
        color: #6d6b6c;
        font-weight: bold; } } }
  .panel-1 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-2 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-3 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-4 {
    h2 em, h3 em {
      font-style: normal;
      font-weight: bold;
      color: #c62510; } }
  .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2 {
    left: 0;
    top: 7px;
    padding: 0 9px;
    height: 173px;
    background-repeat: no-repeat;
    background-position: 3px 15px; }
  .panel-1 h3, .panel-2 h3, .panel-3 h3, .panel-4 h3 {
    left: 0;
    top: 27px;
    padding: 0 40px 0 12px; }
  .panel-1 p, .panel-2 p, .panel-3 p, .panel-4 p {
    position: relative;
    z-index: 103; line-height: 1.4em; }
  .panel-1 a.more, .panel-2 a.more, .panel-3 a.more, .panel-4 a.more {
    font-weight: bold;
    display: block;
    text-align: right;
    color: #c1272d;
    background: transparent url(/App_Themes/Default/images/chevrons.png) right 7px scroll no-repeat;
    position: absolute;
    bottom: 0;
    right: 9px;
    padding: 5px 22px; }
  &.panels-4 {
    .panel-1, .panel-2, .panel-3, .panel-4 {
      width: 104px;
      background: white url(/App_Themes/Default/images/science/panel-bg.gif) 0 0 scroll no-repeat; }
    .panel-1 h2, .panel-2 h2, .panel-3 h2, .panel-4 h2 {
      width: 215px; } }
  &.panels-3 {
    .panel-1, .panel-2, .panel-3 {
      width: 183px;
      background: white url(/App_Themes/Default/images/science/panel-bg-3col.gif) 0 0 scroll no-repeat; }
    .panel-1 h2, .panel-2 h2, .panel-3 h2 {
      width: 284px; } } }

#nspired-school-home {
  #flash-placeholder-object {
    margin: 0 0 1px 0; }
  #promos {
    margin: 0 -5px;
    padding: 0;
    width: 970px;
    height: 121px;
    background: transparent url(/App_Themes/Default/images/nspired-school/home-promo-bg.gif) 0 0 scroll no-repeat;
    li {
      &.panel-1 {
        padding: 5px 8px 0 6px; }
      &.panel-2 {
        padding: 5px 8px 0 5px; }
      &.panel-3 {
        padding: 5px 0 0 5px; }
      &.panel-1, &.panel-2, &.panel-3 {
        list-style-type: none;
        float: left;
        width: 311px;
        height: 109px; } } } }

#nspired-school-about {
  #photo-gallery {
    display: none; }
  #navigation-sidebar #nav-about {
    color: #c00;
    a {
      color: #c00; } }
  #content-inner {
    background: transparent url(/App_Themes/Default/images/nspired-school/about-content-bg.gif) 0 304px scroll repeat-x;
    #slideshow {
      position: relative;
      height: 303px;
      margin: 0 0 34px 0;
      li {
        list-style-type: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 783px;
        height: 303px; } }
    p {
      padding: 0 140px 1.4em 0;
      color: #000;
      line-height: 1.4em;
      font-size: 1.1em; }
    #content-copy {
      padding: 0 0 164px 38px;
      margin: 0 0 18px 0;
      background-position: center bottom;
      background-repeat: no-repeat;
      h3 {
        color: #000;
        font-weight: bold; } }
    #ads {
      padding: 0 0 32px 36px;
      h3 {
        color: #c00;
        font-weight: bold;
        font-size: 1.4em;
        padding: 0 0 14px 0; }
      ul {
        height: 91px;
        li {
          list-style-type: none;
          float: left;
          margin: 3px 0 0 17px;
          &:first-child, &.first-child {
            margin-left: 0; } } } }
    .jeslideshow-buttons {
      list-style: none;
      height: 24px;
      position: absolute;
      bottom: auto;
      right: 18px;
      top: 282px;
      z-index: 101;
      li {
        float: left;
        display: block;
        width: 9px;
        height: 9px;
        margin: 0 0 0 6px;
        background: #ccc;
        border: #666 solid 1px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        -khtml-border-radius: 2px;
        border-radius: 2px;
        &.selected {
          background: #c00;
          width: 7px;
          height: 7px;
          border: #efeeee solid 2px; }
        span {
          display: none; } } } } }

.nspired-school-story {
  #navigation-sidebar #nav-story {
    color: #c00;
    a {
      color: #c00; } }
  #content-inner #content-copy {
    padding: 8px 290px 0 25px;
    p {
      font-size: 1.15em;
      line-height: 1.65em;
      color: #666; }
    h2 {
      color: #c00;
      font-size: 2em; }
    h3 {
      color: #c00;
      font-size: 1.2em;
      margin: 0 0 9px 0; }
    a.expand-collapse {
      padding: 0 0 0 5px;
      color: #fd0202; }
    blockquote {
      &.left, &.right {
        position: relative;
        width: 207px;
        padding: 10px 0 34px 0;
        margin: 4px 0 9px 0;
        clear: both; }
      &.left div.top, &.right div.top {
        position: absolute;
        left: 0;
        top: 0;
        width: 207px;
        height: 10px;
        background: transparent url(/App_Themes/Default/images/nspired-school/story-quote-top.gif) 0 0 scroll no-repeat; margin: 0; }
      &.left div.middle, &.right div.middle {
        background: transparent url(/App_Themes/Default/images/nspired-school/story-quote-middle.gif) 0 0 scroll repeat-y;
        padding: 1px 13px; }
      &.left p, &.right p {
        color: #818181;
        font-weight: bold;
        line-height: 1.5em;
        padding: 3px 0 4px 0; }
      &.left div.bottom, &.right div.bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 207px;
        height: 34px;
        background-repeat: no-repeat; }
      &.left {
        float: left;
        margin: 0 19px 0 -73px;
        div.bottom {
          background-image: url(/App_Themes/Default/images/nspired-school/story-quote-bottom-left.gif); } }
      &.right {
        float: right;
        margin: 0 -129px 0 19px;
        div.bottom {
          background-image: url(/App_Themes/Default/images/nspired-school/story-quote-bottom-right.gif); } } }
    .section {
      padding: 12px 0 0 1px;
      margin: 0 0 0 -1px;
      background: transparent url(/App_Themes/Default/images/nspired-school/story-section-divider.gif) 0 3px scroll no-repeat;
      clear: both;
      p {
        padding-bottom: 2em; } } } }

#product-showcase-graphing #panels {
  .panel-1 h3, .panel-2 h3, .panel-3 h3, .panel-4 h3 {
    text-align: right;
    left: auto;
    right: 0;
    top: 25px;
    padding: 0 12px 0 40px; } }

#os-update-page #content {
  font-size: 12px;
  background: white url(/App_Themes/Default/images/orphan/os-update/content-bg.png) left 237px repeat-x;
  padding: 0; line-height: 1.4em;
  h2 {
    padding: 0;
    clear: none; 
	line-height: 1.1em;
	}
  #main-column {
    width: 620px;
    margin: 15px 10px;
    padding: 15px 10px 15px 20px;
    background: #FFF;
    -moz-border-radius: 7px 7px 0 0;
    -webkit-border-radius: 7px 7px 0 0;
    -khtml-border-radius: 7px 7px 0 0;
    border-radius: 7px 7px 0 0;
    h2 {
      position: static;
      font-weight: bold;
      font-size: 18px;
      color: #cb0c0f; } }
  #right-column {
    width: 275px;
    margin: 15px 10px 15px 0;
    background: #FFF;
    -moz-border-radius: 7px 7px 0 0;
    -webkit-border-radius: 7px 7px 0 0;
    -khtml-border-radius: 7px 7px 0 0;
    border-radius: 7px 7px 0 0; float: right; position: relative; overflow: hidden;
    h2 {
      position: static;
      display: block;
      font-weight: normal;
      line-height: 37px;
      font-size: 24px;
      color: #fff;
      background: white url(/App_Themes/Default/images/orphan/os-update/h2-bg.png) repeat-x right center;
      padding-left: 18px;
      margin: 3px 3px 10px 3px;
      -moz-border-radius: 7px 7px 0 0;
      -webkit-border-radius: 7px 7px 0 0;
      -khtml-border-radius: 7px 7px 0 0;
      border-radius: 7px 7px 0 0; }
    p {
      margin: 0 10px 20px 21px; }
    ul {
      margin: 0 0 20px 21px;
      list-style: none; 
	  li {
		padding: 0 0 0 8px;
		background: transparent url(/App_Themes/Default/images/products/red-arrow.gif) left center no-repeat; }}
    h3 {
      margin: 0 0 5px 21px;
      font-size: 12px;
      font-weight: bold; } }
  .slideshow-tabs {
    list-style: none;
    position: relative;
    z-index: 4;
    li {
      float: left;
      font-size: 13px;
      color: #777;
      height: 20px;
      padding: 10px 12px;
      &.selected {
        background: transparent url(/App_Themes/Default/images/orphan/os-update/slideshow-tab-selected-bg.png) repeat-x;
        color: #cb0c0f;
        -moz-border-radius: 7px 7px 0 0;
        -webkit-border-radius: 7px 7px 0 0;
        -khtml-border-radius: 7px 7px 0 0;
        border-radius: 7px 7px 0 0; } } }
  .slideshow {
    height: 237px; }
  .slideshow-tabs {
    position: absolute;
    bottom: 0;
    left: 20px;
    cursor: pointer; }
  .jeslideshow {
    height: 237px; }
  .jeslideshow-slide {
    height: 217px;
    width: 930px;
    padding: 20px 0 0 30px;
    h2 {
      color: #FFF;
      font-size: 25px;
      padding: 0 0 10px 25px;
      text-indent: -25px;
      width: 595px;
      position: static; }
    h3 {
      color: #3f3f3f;
      font-weight: bold;
      padding: 0 0 15px 25px;
      font-size: 16px;
      width: 440px; }
    p {
      padding: 0 0 5px 25px;
      width: 410px;
      a {
        padding: 0 0 0 16px;
        background: transparent url(/App_Themes/Default/images/products/red-arrow.gif) 8px center no-repeat; } }
    .list {
      padding: 15px 0 0 25px;
      li {
        padding: 3px 0 3px 8px; } }
    a {
      color: #525252;
      display: inline; } }  
  .jeslideshow-buttons {
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 101; }
  .expand-list li {
    background: #FFF;
    > h3 {
      color: #525252;
      font-size: 18px;
      font-weight: normal; }
    &.selected h4 {
      font-size: 13px;
      font-weight: bold; } }
  #update-path {
    padding: 10px 20px;
    background: white url(/App_Themes/Default/images/orphan/os-update/update-path-bg.png) left bottom repeat-x;
    border: #cbcbcb solid 1px;
    overflow: hidden;
    h3 {
      color: #cb0c0f;
      font-size: 14px;
      font-weight: bold; }
    ul {
      overflow: hidden;
      list-style: none;
      padding-top: 10px;
      li {
        float: left;
        width: 90px;
        padding: 0 5px;
        text-align: center;
        &:first-child, &.first-child {
          padding-left: 0px; }
        h4 {
          text-align: center; } } } } }

#document-player-page #content {
  background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-bg.png) left 143px;
  padding: 0;
  h3 {
    font-size: 20px;
    color: #cb0c0f;
    padding-bottom: 5px; }
  .page-header {
    position: relative;
    background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-hdr-bg.jpg) no-repeat;
    height: 320px;
    padding: 25px 0 0 30px;
    .hdr-text {
      width: 375px; }
    h2 {
      padding: 0px;
      font-size: 26px;
      color: #FFF; }
    h3 {
      text-align: right;
      color: #FFF;
      font-size: 11px;
      font-style: italic;
      padding: 0; }
    p {
      position: absolute;
      top: 155px;
      width: 530px;
      color: #003A66;
      font-size: 18px; }
    .notify {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      width: 515px;
      label {
        display: block; }
      .verify-email {
        padding: 5px 15px;
        background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-notify-textbox.png) no-repeat;
        height: 45px;
        width: 255px;
        display: inline-block;
        input {
          &.text, &[type="text"] {
            background: transparent;
            border: 0;
            font-size: 20px;
            vertical-align: middle;
            margin: 0;
            width: 255px; } } }
      #notify-text {
        display: block;
        margin: 0 auto;
        padding: 0 0 20px 0;
        position: static;
        text-align: center; }
      #document-button, #notify-button {
        float: right;
        padding: 0 15px;
        margin: 0 15px 20px 0;
        display: block;
        font-size: 20px;
        font-weight: normal;
        border: 0;
        line-height: 40px;
        text-align: center;
        color: #FFF;
        background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-notify-button-bg.png) repeat-x;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        -khtml-border-radius: 10px;
        border-radius: 10px; } } }
  .promo-list {
    list-style: none;
    overflow: hidden;
    li {
      float: left;
      width: 410px;
      margin-left: 20px;
      padding: 20px;
      height: 155px;
      background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-promo-bucket-bg.png) no-repeat;
      p {
        width: 245px;
        padding-bottom: 20px; }
      a {
        max-width: 245px;
        display: inline-block; } }
    .promo-1 {
      background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-promo-bg-1.jpg) no-repeat; }
    .promo-2 {
      background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-promo-bg-2.jpg) no-repeat; } }
  .promo {
    height: 180px;
    padding-top: 110px;
    background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-promo-bg-3.jpg) no-repeat;
    p {
      width: 290px;
      padding: 0 0 25px 25px;
      color: #003a66;
      font-size: 16px; } }
  .sys-req {
    overflow: hidden;
    padding: 20px 20px 40px 20px;
    background: white url(/App_Themes/Default/images/orphan/doc-player/dp-sys-req-bg.jpg) center top no-repeat;
    ul {
      float: left;
      list-style: none;
      padding: 10px 40px 0 0; }
    .help-button {
      float: right;
      font-size: 20px;
      line-height: 40px;
      width: 106px;
      padding-left: 14px;
      color: #999;
      background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-help-button-bg.png);
      a {
        color: #999; } }
    div.note {
      padding-top: 10px;
      clear: both;
      font-style: italic; } }
  #fat-footer {
    margin-top: 0; }
  table {
    td {
      text-align: center; }
    th.column-1, td.column-1 {
      padding-left: 20px;
      width: 355px; }
    th.column-2, td.column-2 {
      width: 275px; }
    th.column-3, td.column-3 {
      width: 315px; }
    th.column-3 .note, td.column-3 .note {
      font-size: 11px; }
    thead th {
      font-size: 18px;
      vertical-align: top;
      padding: 15px 0 5px 0;
      font-weight: normal;
      &.column-1 {
        text-align: left;
        color: #cb0c0f;
        background: white url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-hdr-1.png) left top no-repeat; }
      &.column-2 {
        background: white url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-hdr-1.png) right top no-repeat;
		text-align: center; }
      &.column-3 {
        color: #1973b4;
        background: white url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-hdr-2.png) left top no-repeat;
		text-align: center; } }
    tbody tr {
      &.odd {
        th {
          &:first-child, &.first-child {
            background: transparent url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-odd-row-1.png) repeat-y left top; } }
        td {
          background: transparent url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-odd-row-1.png) repeat-y right top;
          &:last-child, &.last-child {
            background: transparent url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-odd-row-2.png) repeat-y right top; } } }
      &.even {
        th {
          &:first-child, &.first-child {
            background: transparent url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-even-row-1.png) repeat-y left top; } }
        td {
          background: transparent url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-even-row-1.png) repeat-y right top;
          &:last-child, &.last-child {
            background: transparent url(/App_Themes/Default/images/products/orphan/doc-player/dp-tbl-even-row-2.png) repeat-y right top; } } }
      th, td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px; }
      th {
        text-align: left;
        padding-left: 20px;
        font-weight: normal;
        color: #222;
        ul {
          list-style: none;
          padding-left: 40px;
		  line-height: 1.25em;
          li {
            font-size: 12px;
            color: #525252; } } }
      td {
        color: #cb0c0f;
        font-weight: bold; } } } }

#site-selection-page #content {
  font-size: 12px;
  h1 {
    font-weight: bold;
    font-size: 20px;
    color: #cb0c0f; }
  #page-banner {
    height: 122px;
    background: white url(/App_Themes/Default/images/orphan/site/banner-bg.jpg) no-repeat;
    padding: 15px 20px 10px 15px;
    #document-player-title {
      display: none; }
    &.doc-player-title {
      #other-title {
        display: none; }
      #document-player-title {
        display: block; } }
    p {
      color: #FFF; } }
  #remember-country {
    margin: 0 0 3px 20px; }
  ul.site-categories {
    list-style: none;
    margin-left: 20px;
    padding: 5px 0;
    border-top: #700709 1px solid;
    overflow: hidden;
    > li {
      float: left;
      width: 188px; }
    ul {
      list-style: none; } } }

.lang-zh {
  &.product-family-interior #content {
    #hero-links, #hero-links-student, #hero-links-teacher {
      font-size: 1.1em; }
    a.more {
      background-position: right 9px; }
    #features li {
      h6, p, a.more {
        font-size: 1.1em; } }
    #footnotes {
      font-size: 1.1em; } }
  &.product-family #content {
    #fat-footer li {
      h3, li {
        font-size: 1.1em; } }
    #footnotes {
      font-size: 1.1em; } } }

.website-AUS-NZ li {
  width: 237px; }

.website-AUS-NZ li {
  width: auto; }

.website-FRANCE li {
  width: 237px; }

.website-FRANCE li {
  width: auto; }

.website-US {
  &#document-player-page #notify-button {
    background: transparent url(/App_Themes/Default/images/orphan/doc-player/dp-notify-button.png) center center no-repeat;
    width: 220px;
    margin-bottom: 0;
    padding: 0 0 15px 0;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -khtml-border-radius: 0px;
    border-radius: 0px; }
  &#os-update-page #right-column {
    padding-bottom: 145px;
    background: white; } }

.product-family-interior {
  #section-wrapper-cx-handhelds li {
    &#nspire-nav-cx-handhelds a, &.selected a {
      background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
      color: #e3171f; }
    &.deselected a {
      background-image: none!important;
      color: #0c63a3!important; } }
  #section-wrapper-software li {
    &#nspire-nav-software a, &.selected a {
      background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
      color: #e3171f; }
    &.deselected a {
      background-image: none!important;
      color: #0c63a3!important; } }
      
  #section-wrapper-left li {
    &#nspire-nav a, &.selected a {
      background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
      color: #e3171f; }
    &.deselected a {
      background-image: none!important;
      color: #0c63a3!important; } }
  #section-wrapper-right li {
    &#nspire-nav a, &.selected a {
      background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
      color: #e3171f; }
    &.deselected a {
      background-image: none!important;
      color: #0c63a3!important; } }            
      
  #section-wrapper-navigator li {
    &#nspire-nav-navigator a, &.selected a {
      background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
      color: #e3171f; }
    &.deselected a {
      background-image: none!important;
      color: #0c63a3!important; } }
  #section-wrapper-data-collection li {
    &#nspire-nav-data-collection a, &.selected a {
      background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
      color: #e3171f; }
    &.deselected a {
      background-image: none!important;
      color: #0c63a3!important; } }
  #content {
    background: transparent url(/App_Themes/Default/images/product-family/interior-bg.png) center top scroll no-repeat; } }

.website-ASIA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-AUS-NZ.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-CHINA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-EE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-US.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-LATINOAMERICA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-MEA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-ASIA.product-family-interior a, .website-AUS-NZ.product-family-interior a, .website-CHINA.product-family-interior a, .website-EE.product-family-interior a, .website-US.product-family-interior a, .website-LATINOAMERICA.product-family-interior a, .website-MEA.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-ASIA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-AUS-NZ.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-CHINA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-EE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-US.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-LATINOAMERICA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-MEA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-ASIA.product-family-interior a, .website-AUS-NZ.product-family-interior a, .website-CHINA.product-family-interior a, .website-EE.product-family-interior a, .website-US.product-family-interior a, .website-LATINOAMERICA.product-family-interior a, .website-MEA.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-ASIA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-AUS-NZ.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-CHINA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-EE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-US.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-LATINOAMERICA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-MEA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-ASIA.product-family-interior a, .website-AUS-NZ.product-family-interior a, .website-CHINA.product-family-interior a, .website-EE.product-family-interior a, .website-US.product-family-interior a, .website-LATINOAMERICA.product-family-interior a, .website-MEA.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-ASIA.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-AUS-NZ.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-CHINA.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-EE.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-US.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-LATINOAMERICA.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-MEA.product-family-interior li {
  &#nspire-nav-data-collection a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #e3171f; } }

.website-ASIA.product-family-interior a, .website-AUS-NZ.product-family-interior a, .website-CHINA.product-family-interior a, .website-EE.product-family-interior a, .website-US.product-family-interior a, .website-LATINOAMERICA.product-family-interior a, .website-MEA.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-ASIA.product-family-interior #content, .website-AUS-NZ.product-family-interior #content, .website-CHINA.product-family-interior #content, .website-EE.product-family-interior #content, .website-US.product-family-interior #content, .website-LATINOAMERICA.product-family-interior #content, .website-MEA.product-family-interior #content {
  background: transparent url(/App_Themes/Default/images/product-family/interior-bg.png) center top scroll no-repeat; }

.website-BELGIE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-BELGIQUE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-UK.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-DANMARK.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-DEUTSCHLAND.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-ESPANA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-FRANCE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-INDIA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-ITALIA.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-NEDERLAND.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-NORGE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-OESTERREICH.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-PORTUGAL.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SCHWEIZ.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SUISSE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SUOMI.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SVERIGE.product-family-interior li {
  &#nspire-nav-cx-handhelds a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-BELGIE.product-family-interior a, .website-BELGIQUE.product-family-interior a, .website-UK.product-family-interior a, .website-DANMARK.product-family-interior a, .website-DEUTSCHLAND.product-family-interior a, .website-ESPANA.product-family-interior a, .website-FRANCE.product-family-interior a, .website-INDIA.product-family-interior a, .website-ITALIA.product-family-interior a, .website-NEDERLAND.product-family-interior a, .website-NORGE.product-family-interior a, .website-OESTERREICH.product-family-interior a, .website-PORTUGAL.product-family-interior a, .website-SCHWEIZ.product-family-interior a, .website-SUISSE.product-family-interior a, .website-SUOMI.product-family-interior a, .website-SVERIGE.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-BELGIE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-BELGIQUE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-UK.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-DANMARK.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-DEUTSCHLAND.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-ESPANA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-FRANCE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-INDIA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-ITALIA.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-NEDERLAND.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-NORGE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-OESTERREICH.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-PORTUGAL.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SCHWEIZ.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SUISSE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SUOMI.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SVERIGE.product-family-interior li {
  &#nspire-nav-software a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-BELGIE.product-family-interior a, .website-BELGIQUE.product-family-interior a, .website-UK.product-family-interior a, .website-DANMARK.product-family-interior a, .website-DEUTSCHLAND.product-family-interior a, .website-ESPANA.product-family-interior a, .website-FRANCE.product-family-interior a, .website-INDIA.product-family-interior a, .website-ITALIA.product-family-interior a, .website-NEDERLAND.product-family-interior a, .website-NORGE.product-family-interior a, .website-OESTERREICH.product-family-interior a, .website-PORTUGAL.product-family-interior a, .website-SCHWEIZ.product-family-interior a, .website-SUISSE.product-family-interior a, .website-SUOMI.product-family-interior a, .website-SVERIGE.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-BELGIE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-BELGIQUE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-UK.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-DANMARK.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-DEUTSCHLAND.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-ESPANA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-FRANCE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-INDIA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-ITALIA.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-NEDERLAND.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-NORGE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-OESTERREICH.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-PORTUGAL.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SCHWEIZ.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SUISSE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SUOMI.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-SVERIGE.product-family-interior li {
  &#nspire-nav-navigator a, &.selected a {
    background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
    color: #c1272d; } }

.website-BELGIE.product-family-interior a, .website-BELGIQUE.product-family-interior a, .website-UK.product-family-interior a, .website-DANMARK.product-family-interior a, .website-DEUTSCHLAND.product-family-interior a, .website-ESPANA.product-family-interior a, .website-FRANCE.product-family-interior a, .website-INDIA.product-family-interior a, .website-ITALIA.product-family-interior a, .website-NEDERLAND.product-family-interior a, .website-NORGE.product-family-interior a, .website-OESTERREICH.product-family-interior a, .website-PORTUGAL.product-family-interior a, .website-SCHWEIZ.product-family-interior a, .website-SUISSE.product-family-interior a, .website-SUOMI.product-family-interior a, .website-SVERIGE.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

#navigation-slide a, .website-BELGIE.product-family-interior a, #navigation-slide a, .website-BELGIQUE.product-family-interior a, #navigation-slide a, .website-UK.product-family-interior a, #navigation-slide a, .website-DANMARK.product-family-interior a, #navigation-slide a, .website-DEUTSCHLAND.product-family-interior a, #navigation-slide a, .website-ESPANA.product-family-interior a, #navigation-slide a, .website-FRANCE.product-family-interior a, #navigation-slide a, .website-INDIA.product-family-interior a, #navigation-slide a, .website-ITALIA.product-family-interior a, #navigation-slide a, .website-NEDERLAND.product-family-interior a, #navigation-slide a, .website-NORGE.product-family-interior a, #navigation-slide a, .website-OESTERREICH.product-family-interior a, #navigation-slide a, .website-PORTUGAL.product-family-interior a, #navigation-slide a, .website-SCHWEIZ.product-family-interior a, #navigation-slide a, .website-SUISSE.product-family-interior a, #navigation-slide a, .website-SUOMI.product-family-interior a, #navigation-slide a, .website-SVERIGE.product-family-interior a {
  background-image: url(/App_Themes/Default/images/product-family/translucent-red-arrows.png);
  color: #c1272d; }

.website-BELGIE.product-family-interior a, .website-BELGIQUE.product-family-interior a, .website-UK.product-family-interior a, .website-DANMARK.product-family-interior a, .website-DEUTSCHLAND.product-family-interior a, .website-ESPANA.product-family-interior a, .website-FRANCE.product-family-interior a, .website-INDIA.product-family-interior a, .website-ITALIA.product-family-interior a, .website-NEDERLAND.product-family-interior a, .website-NORGE.product-family-interior a, .website-OESTERREICH.product-family-interior a, .website-PORTUGAL.product-family-interior a, .website-SCHWEIZ.product-family-interior a, .website-SUISSE.product-family-interior a, .website-SUOMI.product-family-interior a, .website-SVERIGE.product-family-interior a {
  background-image: none!important;
  color: #0c63a3!important; }

.website-BELGIE.product-family-interior #content, .website-BELGIQUE.product-family-interior #content, .website-UK.product-family-interior #content, .website-DANMARK.product-family-interior #content, .website-DEUTSCHLAND.product-family-interior #content, .website-ESPANA.product-family-interior #content, .website-FRANCE.product-family-interior #content, .website-INDIA.product-family-interior #content, .website-ITALIA.product-family-interior #content, .website-NEDERLAND.product-family-interior #content, .website-NORGE.product-family-interior #content, .website-OESTERREICH.product-family-interior #content, .website-PORTUGAL.product-family-interior #content, .website-SCHWEIZ.product-family-interior #content, .website-SUISSE.product-family-interior #content, .website-SUOMI.product-family-interior #content, .website-SVERIGE.product-family-interior #content {
  background: transparent url(/App_Themes/Default/images/product-family/interior-bg-europe.gif) center top scroll no-repeat; }

.website-BELGIE.product-family-interior h4, .website-BELGIQUE.product-family-interior h4, .website-UK.product-family-interior h4, .website-DANMARK.product-family-interior h4, .website-DEUTSCHLAND.product-family-interior h4, .website-ESPANA.product-family-interior h4, .website-FRANCE.product-family-interior h4, .website-INDIA.product-family-interior h4, .website-ITALIA.product-family-interior h4, .website-NEDERLAND.product-family-interior h4, .website-NORGE.product-family-interior h4, .website-OESTERREICH.product-family-interior h4, .website-PORTUGAL.product-family-interior h4, .website-SCHWEIZ.product-family-interior h4, .website-SUISSE.product-family-interior h4, .website-SUOMI.product-family-interior h4, .website-SVERIGE.product-family-interior h4 {
  color: #777; }

/*-------------- Nspire Landing page --------------*/

#nspire-family-home {
  #content {
    background-image: url(/App_Themes/Default/images/nspire/US-home-bg.jpg) !important; }
  .rise-panels {
    .panel-1 h3 {
      background-image: url(/App_Themes/Default/images/nspire/US-home-slide-1.png); }
    .panel-2 h3 {
      background-image: url(/App_Themes/Default/images/nspire/US-home-slide-2.png); }
    .panel-3 h3 {
      background-image: url(/App_Themes/Default/images/nspire/US-home-slide-3-v32.png); }
    .panel-4 h3 {
      background-image: url(/App_Themes/Default/images/nspire/US-home-slide-4.png); } } }

/*------------ Teachers -------------*/
.product-loan-form {
  #fieldset-tabs input {
    display: none; }
  #content {
    .selected-nspire #fieldset-nspire, .selected-graphing #fieldset-graphing {
      border-radius: 0 9px 0 0;
      margin-bottom: -91px !important;
      margin-top: 0; }
    .selected-nspire legend, .selected-graphing legend {
      background-image: none;
      border-radius: 0 0 0 0!important;
      margin-bottom: 6px!important;
      margin-top: -23px !important;
      padding: 0;
      top: -16px; }
    #fieldset-tabs #handheld-cx-cas {
      background-position: right 0!important;
      padding: 34px 76px 0 0!important;
      vertical-align: bottom!important; } }
  #handheld-cx-wrapper #handheld-cx {
    background: url(/App_Themes/Default/Images/loan/handheld-cx.png) no-repeat scroll 0 0 transparent !important;
    display: inline-block!important;
    font-size: 1.1em;
    font-weight: bold;
    height: 73px;
    margin: 18px 0 0;
    padding: 34px 0 0 76px!important;
    position: relative;
    float: none!important; }
  #content #handheld-cx-cas {
    float: none!important; }
  #fieldset-tabs label {}
  #content h2 {
    a {
    color: #fff!important; } } }

#product-loan-form-teacher .active {
  &#nav-section-teacher a, &#nav-section-t3-instructor a {
    background-color: #fff;
    color: #CB0C0F !important; } }

.product-loan-form {
  #content strong {
    font-weight: bold; }
  #handheld-cx-wrapper {
    #handheld-cx-cas input[type="radio"], #handheld-cx input[type="radio"] {
      display: inline; } }
  table {
    &#table-cx label, &#table-cx-cas label {
      background: none!important; } }
  #content {
    .selected-nspire #fieldset-graphing, .selected-graphing #fieldset-nspire {
      display: none; } } }


.product-loan-form input {
  display: inline!important; }

.product-loan-form #contact-us {
  clear: both;
  font-size: 1.1em;
  margin: 0 0 0 41px;
  position: relative;
  top: -4px;
  p {
    color: #003a66;
    line-height: 1.5em;
    .line-1 {
      display: block;
      font-size: 1.1em; }
    .line-2 {
      display: block; }
    .line-3 {
      display: block;
      font-size: 1.6em;
      line-height: 1.4em; } }
  table {
    color: #525252;
    margin: -4px 0 24px -3px;
    tbody tr {
      &.first-child th {
        background-position: right 0; }
      th {
        background: url(/App_Themes/Default/Images/loan/table-divider.gif) no-repeat scroll right -9px transparent;
        padding: 5px 10px 4px 0;
        text-align: right;
        font-weight: normal;
        vertical-align: top; }
      td {
        font-weight: normal;
        vertical-align: top;
        padding: 5px 0 4px 7px;
        a.phone {
          color: #cb0c0f;
          display: block;
          font-size: 1.5em;
          margin-top: -4px; }
        .note {
          font-size: 0.8em;
          letter-spacing: -0.04mm;
          line-height: 0.8em; } }
      &.last-child th {
        background-position: right bottom; } } } }

.product-loan-form #fieldset-tabs #fieldset-nspire legend input { display: none!important; }
.product-loan-form #fieldset-tabs #fieldset-graphing legend input { display: none!important; }

/*------------- CX-Handhelds -------------*/

.product-family-interior {





		
	.section-content {
		h4 { font-weight: bold; }
	}
}

/*------------------ Navigator styling ----------------*/

#feature {
  .panel-1 {
    width: 350px;
    padding: 38px 50px 0 560px;
    h2 {
      background-image: url(/App_Themes/Default/Images/navigator/US-home-slide-1-bug.gif); } }
  .panel-2 {
    width: 350px;
    padding: 38px 519px 0 91px;
    h2 {
      background-image: url(/App_Themes/Default/Images/navigator/US-home-slide-2-bug.gif); } }
  .panel-3 {
    width: 350px;
    padding: 38px 50px 0 560px;
    h2 {
      background-image: url(/App_Themes/Default/Images/navigator/US-home-slide-3-bug.gif); } }
  .panel-4 {
    width: 350px;
    padding: 38px 519px 0 91px;
    h2 {
      background-image: url(/App_Themes/Default/Images/navigator/US-home-slide-4-bug.gif); } }
  .panel-5 {
    width: 350px;
    padding: 38px 50px 0 560px;
    h2 {
      background-image: url(/App_Themes/Default/Images/navigator/US-home-slide-5-bug.gif); } } }

/*-------------------- Science styling ---------------------*/


/*-------------- Document Player styling -------------------*/

#document-player-page {
  &.website-US #notify-button.mac-button {
    width: auto;
    display: block;
    background: url("http://education.ti.com/calculators/products/images/orphan/doc-player/dp-notify-button-bg.png") repeat-x scroll 0 0 transparent;
    border: 0 none;
    border-radius: 10px 10px 10px 10px;
    color: #FFFFFF;
    float: right;
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;
    margin: 0 15px 20px 0;
    padding: 0 15px;
    text-align: center; }
  #content .sys-req {
    h3 {
      clear: left; }
    .lists-wrapper {
      overflow: hidden;
      padding-bottom: 10px; } }
  &.website-US #notify-button.mac-button {
    width: auto;
    display: block;
    border: 0 none;
    border-radius: 10px 10px 10px 10px;
    color: #FFFFFF;
    float: right;
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;
    margin: 0 15px 20px 0;
    padding: 0 15px;
    text-align: center; } }

#page-content, .page-content {
  top: -9px; }

#page-header {
  background: url(/App_Themes/Default/Images/document-player/dp-hdr-bg.jpg) no-repeat scroll 0 0 transparent;
  height: 370px;
  padding: 0;
  position: relative;
  width: 960px;
  background-repeat: no-repeat;
  top: -10px; }

.msg_list {
  margin: 0px;
  padding: 0px;
  width: 383px;
  position: relative;
  top: -10px; }

.msg_head {
  cursor: pointer;
  margin: 0px;
  padding: 0;
  position: relative;
  width: 960px;
  color: #CB0C0F;
  background-color: white; }

.msg_body {
  padding: 0;
  width: 960px; }

#document-player-page.website-US {
  .player-laptop {
    width: 230px;
    height: 230px;
    background-repeat: no-repeat;
    background-image: url(/App_Themes/Default/Images/document-player/document-player-laptop.png);
    position: relative;
    float: left; }
  .player-handheld {
    width: 230px;
    height: 230px;
    background-repeat: no-repeat;
    background-image: url(/App_Themes/Default/Images/document-player/document-player-hh.png);
    position: relative;
    float: left; }
  .player-science-handheld {
    width: 230px;
    height: 230px;
    background-repeat: no-repeat;
    background-image: url(/App_Themes/Default/Images/document-player/document-player-hh-science.png);
    position: relative;
    float: left; }
  .features-screenshot {
    width: 305px;
    height: 245px;
    background-repeat: no-repeat;
    background-image: url(/App_Themes/Default/Images/document-player/document-player-screenshot.png);
    position: relative;
    float: left; }
  .features-monitor {
    width: 305px;
    height: 245px;
    background-repeat: no-repeat;
    background-image: url(/App_Themes/Default/Images/document-player/document-player-monitor.png);
    position: relative;
    float: left; } }

#document-player-page .tmpSlideshowControlHeader-1 { float: left; width: 220px; height: 25px; }
#document-player-page .tmpSlideshowControlHeader-2 { float: left; width: 160px; height: 25px; }
#document-player-page #tmpSlideshowControls { left: 20px; position: relative; top: 180px; width: 500px; }
#document-player-page .compare-features { width: 960px; background: url(/App_Themes/Default/Images/document-player/document-player-compare-features-background.png) no-repeat 0 0 #fff; height: 245px; position: relative; }
#document-player-page .compare-features .features-screenshot { background: url(/App_Themes/Default/Images/document-player/document-player-screenshot.png) no-repeat 0 0; float: left; height: 245px; position: relative; width: 305px; }
#document-player-page .compare-features .features-monitor { background: url(/App_Themes/Default/Images/document-player/document-player-monitor.png) no-repeat 0 0; float: left; height: 245px; position: relative; width: 305px; }

#document-player-page .see-details .msg_head { margin-bottom: 20px; font-weight: bold; }
#document-player-page .see-details .msg_body { margin-bottom: 20px; }
#document-player-page .system-requirements .msg_head { margin-bottom: 20px; font-weight: bold; }

/*------------------- Data Collection styling ------------------*/



/*--------------Nspired School ----------------*/

#nspired-school-home.website-US #feedback-bar {
  background: url(/App_Themes/Default/Images/products/nspired-school/feedback-bar-bg.gif) repeat-x center bottom #fdfdfd;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  clear: both;
  margin: 10px 0 0;
  padding: 5px 5px 1px;
  text-align: center;
  p {
    line-height: 17px;
    a {
      background: url(/App_Themes/Default/Images/products/red-arrow.gif) no-repeat 1px 5px transparent;
      display: inline-block;
      padding: 0 0 1px 8px; } } }

}

/*------------- Document Player v2 --------------------*/

#document-player-page {
  &.website-US #notify-button.mac-button {
    width: auto;
    display: block;
    background: url(/App_Themes/Default/Images/document-player/dp-notify-button-bg.png) repeat-x scroll 0 0 transparent;
    border: 0 none;
    border-radius: 10px 10px 10px 10px;
    color: #FFFFFF;
    float: right;
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;
    margin: 0 15px 20px 0;
    padding: 0 15px;
    text-align: center; }
  #content .sys-req {
    h3 {
      clear: left; }
    .lists-wrapper {
      overflow: hidden;
      padding-bottom: 10px; } }
  &.website-US #notify-button.mac-button {
    width: auto;
    display: block;
    background: url(/App_Themes/Default/Images/document-player/dp-notify-button-bg.png) repeat-x scroll 0 0 transparent;
    border: 0 none;
    border-radius: 10px 10px 10px 10px;
    color: #FFFFFF;
    float: right;
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;
    margin: 0 15px 20px 0;
    padding: 0 15px;
    text-align: center; }
  .compare-features-1, .compare-features-2 {
    float: left;
    height: 245px;
    width: 305px;
    position: relative; }
  #tmpSlideshowControls ul {
    width: 160px;
    float: left;
    margin-top: 15px;
    margin-right: 60px;
    li {
      list-style: none; } }
  #page-header {
    height: 350px; } }

/*------------- Nspried School Story Photo Gallery --------------------*/
a {
    &.gallery-left {
      float: left;
      display: block;
      width: 13px;
      height: 22px;
      background: transparent url(/App_Themes/Default/Images/nspired-school/gallery-left-on.gif) center center scroll no-repeat;
      cursor: pointer;
      text-decoration: none;
      &:hover, &:active, &:focus {
        text-decoration: none; }
      &.off {
        background-image: url(/App_Themes/Default/Images/nspired-school/gallery-left-off.gif);
        cursor: default; } }
    &.gallery-right {
      float: left;
      display: block;
      width: 13px;
      height: 22px;
      background: transparent url(/App_Themes/Default/Images/nspired-school/gallery-right-on.gif) center center scroll no-repeat;
      cursor: pointer;
      text-decoration: none;
      &:hover, &:active, &:focus {
        text-decoration: none; }
      &.off {
        background-image: url(/App_Themes/Default/Images/nspired-school/gallery-right-off.gif);
        cursor: default; } } }
  #photo-gallery {
    display: none;
    padding: 4px 13px;
    h2 {
      clear: both;
      color: #777;
      font-weight: bold;
      font-size: 1.7em;
      padding: 0 0 12px 1px; }
    #photo-gallery-content {
      color: #777;
      #photo-gallery-image-wrapper {
        width: 400px;
        height: 300px;
        border: solid 1px #000;
        overflow: hidden;
        float: left;
        margin: 0 16px 24px 100px;
        img {
          display: block;
          margin: 0 auto; } }
      #photo-gallery-information {
        float: left;
        width: 170px;
        display: none;
        /* temporarily disabled */
        h4 {
          color: #777;
          font-weight: bold;
          font-size: 1.3em;
          line-height: 1.425em;
          margin: 0 0 7px 0; }
        div {
          &.location {
            color: #777;
            font-size: 1.2em;
            margin: 0 0 3px 0;
            padding: 0 0 0 1px; }
          &.date {
            color: #777;
            font-size: 1.2em;
            padding: 0 0 0 1px; }
          &.description {
            border-top: 1px dotted #ccc;
            margin: 7px 0 0 0;
            padding: 7px 0 0 0;
            p {
              color: #777;
              font-size: 1.1em;
              line-height: 2em; } } } }
      #photo-gallery-previous {
        clear: both;
        margin: 0 10px 0 279px; }
      #photo-gallery-next {
        margin: 0 0 0 10px; } }
    h3 {
      clear: both;
      color: #777;
      font-weight: bold;
      font-size: 1.3em;
      border-top: 1px dotted #ccc;
      margin: 19px 0 15px 0;
      padding: 12px 0 0 0; }
    #photo-gallery-left {
      margin: 0 12px 0 0;
      padding: 30px 0; }
    #photo-gallery-scroll {
      overflow: hidden;
      width: 557px;
      height: 80px;
      float: left;
      margin: 0 0 15px 0;
      ul {
        padding: 0 557px;
        li {
          list-style-type: none;
          float: left;
          width: 80px;
          height: 80px;
          margin: 0 21px;
          a {
            display: block;
            width: 74px;
            height: 80px;
            img {
              display: block;
              margin: 0 auto; } } } } }
    #photo-gallery-right {
      margin: 0 0 0 12px;
      padding: 30px 0; } }

.products-sublayout-wrapper .product-loan-form#product-loan-form-enter-code { min-height: 525px; }
#document-player-page div#tmpSlideshow { height: 335px; margin: 10px; padding: 30px 0 0; position: relative; width: 755px; }
#document-player-page div#tmpSlideshow h4 { color: #CB0C0F; font: 14px Arial,sans-serif; margin: 10px 0; }
#document-player-page div.tmpSlide { display: none; height: 332px; left: 0; position: absolute; top: 0; width: 730px; }
#document-player-page div.tmpSlide img { float: left; margin: 20px 0 0 505px; width: 380px; }
#document-player-page div.tmpSlideshowControl { color: #554D31; cursor: pointer; float: left; font: 12px Arial,sans-serif; height: 25px; margin: 0; padding-left: 30px; padding-right: 30px; text-align: left; width: 160px; }
#document-player-page div.tmpSlideshowControl span { line-height: 36px; vertical-align: middle; }
#document-player-page .doc-player-header { color: white; position: absolute; z-index: 100; }
#document-player-page .doc-player-header .doc-player-title { font-size: 25px ; font-weight: bold; position: relative; top: 30px; left: 30px; line-height: 1.2em;}
#document-player-page .doc-player-header .doc-player-caption { font-size: 14px; font-style: italic; position: relative; top: 30px; left: 145px; }
#document-player-page .doc-player-header .doc-player-subtitle { color: #555555; font-size: 18px; position: relative; top: 20px; left: 30px; line-height: 1.225em; }
#document-player-page .doc-player-header .launch-player-container { left: 148px; position: relative; top: 24px; }
html:lang(de) #document-player-page .doc-player-header .launch-player-container, html:lang(zh-CN) #document-player-page .doc-player-header .launch-player-container { left: 55px; }
html:lang(fr) #document-player-page .doc-player-header .launch-player-container, html:lang(pt) #document-player-page .doc-player-header .launch-player-container { left: 100px; }
html:lang(es) #document-player-page .doc-player-header .launch-player-container { left: 70px; }
html:lang(fi) #document-player-page .doc-player-header .launch-player-container { left: 48px; }
#document-player-page #tmpSlideshow #tmpSlideshowControls .mac-attention-message { margin-top: -45px; width: 462px;font-size: 14px; line-height: 1.4em; }
#document-player-page #tmpSlideshow #tmpSlideshowControls .mac-attention-message > p { padding-bottom: 10px; }
#document-player-page #tmpSlideshow #tmpSlideshowControls #spanHeading h4 { color:#525252; margin-bottom: 0px; }
#document-player-page #tmpSlideshow #tmpSlideshowControls .tmpSlideshowControl > img { float:left; right:0px; position:relative; left:-8px; top:15px; text-decoration:none; }
#document-player-page .msg_list .msg_head {background-color: #dad9d9; padding-bottom: 15px; font-size: 12px;font-family: Arial, Helvetica, sans-serif; }
#document-player-page .msg_list .msg_head > img { float: left; padding-right: 20px;position: relative; left: 10px; top: -2px; text-decoration: none; color: #CB0C0F; }
#document-player-page .msg_list .msg_body { display:none; }
#document-player-page .msg_list .msg_body ul { list-style-type: disc; }
#document-player-page .msg_list .msg_body ul ul { list-style-type: circle; }
#document-player-page .msg_list .msg_body #MsgMac { font-size:12px; background-color: #DAD9D9; padding: 0 0 10px 20px; margin-top: 0; line-height: 1.4em; }
#document-player-page .two-column-container { width: 960px; height: auto; background-color: white; background-repeat: no-repeat;background-image: url(/App_Themes/Default/images/document-player/document-player-top-shadow.png);position: relative; font-size: 12px; font-family: Arial, Helvetica, sans-serif; }
#document-player-page .two-column-container .two-column-inner { width: 960px; height: 195px; position: relative; background-color: white; }
#document-player-page .two-column-container .two-column-inner .left-column { background-repeat: no-repeat; background-image: url(/App_Themes/Default/images/document-player/bg-panel-2-1.png);width: 270px; height: 195px; padding-left: 210px; position: relative; float: left; }
#document-player-page .two-column-container .two-column-inner .right-column { background-repeat: no-repeat; background-image: url(/App_Themes/Default/images/document-player/bg-panel-2-2.png);width: 260px; padding-left: 220px; height: 195px; position: relative; float: left; }
#document-player-page .two-column-container .compare-features { width: 960px; background-repeat: no-repeat; height: 245px; position: relative;background-color: white; }
.products-sublayout-wrapper #product-science-home #content .jeslideshow .panel-1 a.play, .products-sublayout-wrapper #product-science-home #content  .jeslideshow .panel-2 a.play, .products-sublayout-wrapper #product-science-home #content  .jeslideshow .panel-3 a.play, .products-sublayout-wrapper #product-science-home #content  .jeslideshow .panel-4 a.play, .products-sublayout-wrapper #product-science-home #content  .jeslideshow .panel-5 a.play { display: block; color: #535353; background: transparent url(/App_Themes/Default/Images/navigator/button-play.gif) 0 0 scroll no-repeat; min-height: 20px; font-weight: bold; padding: 2px 0 0 29px; line-height: 20px; }
#product-showcase-home #slideshow .panel-1 { background-position: 482px 0; }
#product-showcase-home #slideshow .panel-2 { background-position: 482px 0; }
#product-showcase-home #slideshow .panel-3 { background-position: 482px 0; }
#product-showcase-home #slideshow .panel-4 { background-position: 395px 0; }
#product-showcase-graphing #slideshow .panel-1 { background-position: 482px 0; }
#product-showcase-graphing #slideshow .panel-2 { background-position: 482px 0; }
#product-showcase-graphing #slideshow .panel-3 { background-position: 482px 0; }
#product-showcase-graphing #slideshow .panel-4 { background-position: 482px 0; }
#product-showcase-graphing #panels .panel-1 h2 em, #product-showcase-graphing #panels .panel-1 h3 em, #product-showcase-graphing #panels .panel-2 h2 em, #product-showcase-graphing #panels .panel-2 h3 em, #product-showcase-graphing #panels .panel-3 h2 em, #product-showcase-graphing #panels .panel-3 h3 em, #product-showcase-graphing #panels .panel-4 h2 em, #product-showcase-graphing #panels .panel-4 h3 em { color: #C62510; font-style: normal; font-weight: bold; position: relative; top: 15px; }
#product-showcase-graphing #panels .panel-1, #product-showcase-graphing #panels .panel-2, #product-showcase-graphing #panels .panel-3, #product-showcase-graphing #panels .panel-4 { padding: 63px 11px 31px 118px !important; }
#product-showcase-scientific #slideshow .panel-1 { background-position: 482px 0; }
#product-showcase-scientific #slideshow .panel-2 { background-position: 482px 0; }
#product-showcase-scientific #slideshow .panel-3 { background-position: 482px 0; }
#product-showcase-scientific #slideshow .panel-4 { background-position: 482px 0; }
#product-showcase-solutions #slideshow .panel-1 { background-position: 395px 0; }
#product-showcase-solutions #slideshow .panel-2 { background-position: 482px 0; }
#product-showcase-solutions #slideshow .panel-3 { background-position: 482px 0; }
#product-showcase-solutions #slideshow .panel-4 { background-position: 395px 0; }

.products-sublayout-wrapper .nspired-learning #content { overflow: hidden; }
.products-sublayout-wrapper .nspired-school-auxiliary #navigation-sidebar ul li.active a { color: #c00; }
.products-sublayout-wrapper .product-family-interior .section-wrapper #navigation-slide ul li.active a { background-image: url(/App_Themes/Default/Images/product-family/translucent-red-arrows.png); color: #e3171f; }
.nspired-learning-interior .content-inner-nohero #content-inner { background-position: 0 0; }

.navigationSideBar #navigation-sidebar { float: left; width: 147px; min-height: 656px; font-family: Arial; }
.navigationSideBar #navigation-sidebar h2 { padding: 0 0 0.4em 0; line-height: 1.425em; margin: 0; }
.navigationSideBar #navigation-sidebar h2 a { color: #cb0c0f; font-weight: bold; }
.navigationSideBar #navigation-sidebar ul { padding: 20px 0 0 0; background: transparent url(/App_Themes/Default/images/nspired-learning/navigation-sidebar-divider.gif) 0 13px scroll no-repeat; }
.navigationSideBar #navigation-sidebar ul li { list-style-type: none; line-height: 1.2em; padding: 13px 0 16px 0; background: transparent url(/App_Themes/Default/Images/nspired-learning/navigation-sidebar-divider.gif) 0 bottom scroll no-repeat; color: #666; font-weight: bold; display: block; }
.navigationSideBar #navigation-sidebar ul li a { color: #666; font-weight: bold; display: block; }
.navigationSideBar #navigation-sidebar ul li a:hover, .navigationSideBar #navigation-sidebar ul li a:focus, .navigationSideBar #navigation-sidebar ul li a:active ,.navigationSideBar #navigation-sidebar ul li.active a { color: #c00; }

#icon-list {
    list-style-type: none;
}
#icon-list li {
    clear: left;
    overflow: hidden;
    width: 476px;
}
#icon-list li img {
    margin-right: 5px;
    width: 110px;
}
#icon-list li img, #icon-list li p, #icon-list li h4 {
    float: left;
}
#icon-list li h4, #icon-list li p {
    clear: none;
    margin: 0 0 1.4em;
    width: 361px;
}
#icon-list li h4 {
    color: #000000;
    font-weight: bold;
    margin: 12px 0 0.6em;
}

#testimonials-home #content
{
    background: #fff url(/App_Themes/Default/Images/media/testimonials-top-background.png) scroll 0 0 no-repeat;
}
#testimonials-home #content #content-upper
{
    height: 295px;
    font-size: 1.3em;
    padding: 26px 559px 0 56px;
    color: #2e2e2e;
    overflow: hidden;
}
#testimonials-home #content #content-upper h2
{
    font-size: 24px;
    font-weight: normal;
    padding: 0;
    margin: 0 0 13px -25px;
    color: #db2b15;
    line-height: 1.3em;
}
#testimonials-home #content #content-upper p
{
    line-height: 1.5em;
    padding: 0;
    margin: 0 0 17px;
}
#testimonials-home #content #content-upper p strong
{
    display: inline-block;
    padding: 0 64px 0 0;
}
#testimonials-home #content #content-upper .hero
{
    position: absolute;
    left: 466px;
    top: 35px;
}
#testimonials-home #content #content-upper .hero:link:before, #testimonials-home #content #content-upper .hero:visited:before
{
    position: absolute;
    left: 174px;
    top: 83px;
    display: block;
    content: " ";
    width: 95px;
    height: 68px;
    background: transparent url(/App_Themes/Default/Images/media/icon-play-large.png) 0 -68px scroll no-repeat;
    z-index: 10;
}
#testimonials-home #content #content-upper .hero:link:active:before, #testimonials-home #content #content-upper .hero:link:hover:before, #testimonials-home #content #content-upper .hero:link:focus:before, #testimonials-home #content #content-upper .hero:visited:active:before, #testimonials-home #content #content-upper .hero:visited:hover:before, #testimonials-home #content #content-upper .hero:visited:focus:before
{
    background-position: 0 0;
}
#testimonials-home #content #content-upper .lightbox-button
{
    position: relative;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    font-size: 0.9em;
    padding: 0 0 0 33px;
    margin: 6px 0 0;
    white-space: nowrap;
    background: transparent url(/App_Themes/Default/Images/blue-button-left.png) scroll 0 0 no-repeat;
    color: #fff;
    float: right;
}
.generatedcontent #testimonials-home #content #content-upper .lightbox-button:after
{
    position: absolute;
    display: block;
    height: 35px;
    width: 16px;
    content: url(/App_Themes/Default/Images/blue-button-right.png);
    right: -16px;
    top: 0;
}
.no-generatedcontent #testimonials-home #content #content-upper .lightbox-button-wrapper
{
    display: inline-block;
    background: transparent url(/App_Themes/Default/Images/blue-button-right.png) scroll right 0 no-repeat;
    padding: 0 16px 0 0;
    margin: 6px 0 0;
    float: right;
}
.no-generatedcontent #testimonials-home #content #content-upper .lightbox-button-wrapper .lightbox-button
{
    display: inline-block;
    margin: 0;
    float: none;
}
#testimonials-home #content #content-panels
{
    overflow: hidden;
    width: 944px;
    list-style-type: none;
    margin: 1px 8px 0;
    color: #464646;
}
#testimonials-home #content #content-panels li
{
    position: relative;
    display: block;
    float: left;
    background: transparent url(/App_Themes/Default/Images/media/list-item-separator.png) 0 0 scroll no-repeat;
    margin: 0 0 0 -5px;
    padding: 7px 5px 30px 164px;
    width: 150px;
    min-height: 165px;
    font-size: 1.1em;
}
#testimonials-home #content #content-panels li h3
{
    margin: 0 0 10px -140px;
    font-size: 1.4em;
}
#testimonials-home #content #content-panels li p
{
    line-height: 1.5em;
}
#testimonials-home #content #content-panels li img
{
    position: absolute;
    bottom: 0;
    left: 5px;
}
#testimonials-home #content #content-panels li a.go-lightbox, #testimonials-home #content #content-panels li a.go-link
{
    display: block;
    position: absolute;
    right: 11px;
    bottom: 20px;
    text-align: right;
    color: #d5121a;
}
#testimonials-home #content #content-panels li a.go-link
{
    background: transparent url(/App_Themes/Default/Images/media/2-chevrons-go.gif) scroll 0 5px no-repeat;
    padding: 4px 0 6px 16px;
}
#testimonials-home #content #content-panels li a.go-lightbox
{
    background: transparent url(/App_Themes/Default/Images/media/icon-play-small.png) scroll 0 0 no-repeat;
    padding: 4px 0 6px 24px;
}
#testimonials-home #content #content-lower
{
    background: #fff url(/App_Themes/Default/Images/media/testimonials-bottom-background.png) scroll 6px 0 no-repeat;
    width: 960px;
    min-height: 300px;
    overflow: hidden;
    margin: 0 0 9px;
}
#testimonials-home #content #content-lower .quote p
{
    line-height: 1.4em;
}
#testimonials-home #content #content-lower .column-1
{
    position: relative;
    float: left;
    padding: 334px 73px 0 69px;
    width: 174px;
    background: transparent url(/App_Themes/Default/Images/media/wheretobuy-background.png) scroll 69px 334px no-repeat;
}
#testimonials-home #content #content-lower .column-1 h2
{
    margin: 0;
    padding: 0;
}
#testimonials-home #content #content-lower .column-1 h2 a
{
    display: block;
    color: #fff;
    padding: 7px 48px 7px 11px;
    font-weight: bold;
	font-size: 14px;
}
#testimonials-home #content #content-lower .column-1 p
{
    font-size: 1.1em;
}
#testimonials-home #content #content-lower .column-1 p a
{
    color: #282727;
    display: block;
    padding: 7px 0 0 37px;
}
#testimonials-home #content #content-lower .column-2
{
    position: relative;
    float: left;
    width: 307px;
    background: transparent url(/App_Themes/Default/Images/media/gradient-se.png) scroll 0 54px no-repeat;
    padding: 61px 10px 0 11px;
    font-size: 1.5em;
}
#testimonials-home #content #content-lower .column-2 h2
{
    position: absolute;
    left: 15px;
    top: 16px;
    font-weight: normal;
    color: #db2b15;
}
#testimonials-home #content #content-lower .column-2 h3
{
    line-height: 1.4em;
    padding: 0 40px 0 4px;
    color: #202020;
}
#testimonials-home #content #content-lower .column-2 ul
{
    padding: 0;
    margin: 9px 0 -12px;
    font-size: 0.8em;
    list-style-type: none;
    color: #2e2e2e;
}
#testimonials-home #content #content-lower .column-2 ul li
{
    padding: 3px 0 0 18px;
    border-bottom: solid 1px #b8b8b8;
    margin: 0 0 11px;
}
#testimonials-home #content #content-lower .column-2 ul li h4
{
    margin: 0 0 0 -14px;
}
#testimonials-home #content #content-lower .column-2 ul li .quote
{
    padding: 0 8px 8px 4px;
}
#testimonials-home #content #content-lower .column-2 ul li .quote blockquote
{
    margin: 3px 0 -3px;
}
#testimonials-home #content #content-lower .column-2 ul li .quote blockquote p
{
    line-height: 1.65em;
}
#testimonials-home #content #content-lower .column-2 ul li .quote p cite
{
    font-weight: bold;
}
#testimonials-home #content #content-lower .column-3
{
    position: relative;
    float: left;
    width: 306px;
    padding: 54px 10px 0 0;
    font-size: 1.1em;
}
#testimonials-home #content #content-lower .column-3 .reviews, #testimonials-home #content #content-lower .column-3 .compare, #testimonials-home #content #content-lower .column-3 .quicklook, #testimonials-home #content #content-lower .column-3 .social-media
{
    position: relative;
}
#testimonials-home #content #content-lower .column-3 .reviews
{
    padding: 6px 0 14px;
    background: transparent url(/App_Themes/Default/Images/media/gradient-se.png) scroll 0 0 no-repeat;
}
#testimonials-home #content #content-lower .column-3 .reviews table
{
    border-collapse: collapse;
    width: 306px;
}
#testimonials-home #content #content-lower .column-3 .reviews table caption
{
    font-size: 1.4em;
    line-height: 1.35em;
    padding: 0 190px 11px 17px;
    text-align: left;
    background: transparent url(/App_Themes/Default/Images/media/retailer-review-background.gif) scroll 9px bottom no-repeat;
}
#testimonials-home #content #content-lower .column-3 .reviews table thead tr td
{
    width: 110px;
    padding: 2px 0 7px 17px;
}
#testimonials-home #content #content-lower .column-3 .reviews table thead tr th
{
    width: 90px;
    padding: 4px 0;
    font-weight: normal;
    line-height: 1.2em;
	text-align: center;
}
#testimonials-home #content #content-lower .column-3 .reviews table thead tr th:last-child
{
    padding: 0 6px 0 0;
}
#testimonials-home #content #content-lower .column-3 .reviews table thead tr th.last-child
{
    padding: 0 6px 0 0;
}
#testimonials-home #content #content-lower .column-3 .reviews table tbody tr th
{
    width: 110px;
    text-align: left;
    font-weight: normal;
    padding: 2px 0 7px 17px;
}
#testimonials-home #content #content-lower .column-3 .reviews table tbody tr td
{
    width: 90px;
    text-align: center;
    background: transparent url(/App_Themes/Default/Images/media/review-cell-background.png) scroll 19px 1px no-repeat;
    vertical-align: middle;
}
#testimonials-home #content #content-lower .column-3 .reviews table tbody tr td a
{
    display: block;
    height: 20px;
    overflow: hidden;
    text-indent: -9999px;
    background: transparent url(/App_Themes/Default/Images/media/2-chevrons-go.gif) scroll center 6px no-repeat;
    margin: 0 0 4px 3px;
}
#testimonials-home #content #content-lower .column-3 .reviews table tbody tr td:last-child
{
    padding: 0 6px 0 0;
}
#testimonials-home #content #content-lower .column-3 .reviews table tbody tr td.last-child
{
    padding: 0 6px 0 0;
}
#testimonials-home #content #content-lower .column-3 .compare
{
    padding: 57px 20px 16px 89px;
    background: transparent url(/App_Themes/Default/Images/media/gradient-se-sm.png) scroll 0 0 no-repeat;
}
#testimonials-home #content #content-lower .column-3 .compare h3
{
    height: 115px;
    position: absolute;
    left: 17px;
    top: 9px;
    font-size: 1.2em;
    line-height: 1.3em;
    padding: 0 90px 0 0;
    z-index: 9;
    background: transparent url(/App_Themes/Default/Images/media/icon-compare.gif) scroll 6px 49px no-repeat;
}
#testimonials-home #content #content-lower .column-3 .compare p
{
    line-height: 1.6em;
    position: relative;
    z-index: 10;
}
#testimonials-home #content #content-lower .column-3 .compare p a.go
{
    display: inline-block;
    height: 1em;
    overflow: hidden;
    text-indent: -9999px;
    background: transparent url(/App_Themes/Default/Images/media/2-chevrons-go.gif) scroll center 2px no-repeat;
}
#testimonials-home #content #content-lower .column-3 .quicklook
{
    padding: 40px 20px 38px 89px;
    background: transparent url(/App_Themes/Default/Images/media/gradient-se-sm.png) scroll 0 0 no-repeat;
}
#testimonials-home #content #content-lower .column-3 .quicklook h3
{
    height: 115px;
    position: absolute;
    left: 17px;
    top: 12px;
    font-size: 1.3em;
    line-height: 1.3em;
    padding: 0 90px 0 0;
    z-index: 9;
    background: transparent url(/App_Themes/Default/Images/media/icon-quicklook.gif) scroll 6px 31px no-repeat;
}
#testimonials-home #content #content-lower .column-3 .quicklook p
{
    line-height: 1.5em;
    position: relative;
    z-index: 10;
}
#testimonials-home #content #content-lower .column-3 .quicklook p a.go
{
    display: inline-block;
    height: 1em;
    overflow: hidden;
    text-indent: -9999px;
    background: transparent url(/App_Themes/Default/Images/media/2-chevrons-go.gif) scroll center 2px no-repeat;
}
#testimonials-home #content #content-lower .column-3 .social-media
{
    height: 135px;
    background: transparent url(/App_Themes/Default/Images/media/gradient-se-sm.png) scroll 0 0 no-repeat;
}
#testimonials-home #content #content-lower .column-3 .social-media h3
{
    position: absolute;
    left: 17px;
    top: 14px;
    font-size: 1.3em;
    line-height: 1.3em;
    padding: 0 90px 0 0;
}
#testimonials-home #content #content-lower .column-3 .social-media ul
{
    list-style-type: none;
    overflow: hidden;
    width: 196px;
    padding: 0;
    position: absolute;
    left: 15px;
    top: 46px;
}
#testimonials-home #content #content-lower .column-3 .social-media ul li
{
    float: left;
    width: 28px;
    height: 32px;
    padding: 0 17px 0 4px;
}
#testimonials-home #content #content-lower .column-3 .social-media ul li a
{
    display: block;
    width: 28px;
    height: 32px;
    background: transparent url(/App_Themes/Default/Images/home/icons-social.png) 28px 0 scroll no-repeat;
    overflow: hidden;
    text-indent: -9999px;
}
#testimonials-home #content #content-lower .column-3 .social-media ul li.facebook a
{
    background-position: 0 0;
}
#testimonials-home #content #content-lower .column-3 .social-media ul li.twitter a
{
    background-position: -28px 0;
}
#testimonials-home #content #content-lower .column-3 .social-media ul li.pinterest a
{
    background-position: -56px 0;
}
#testimonials-home #content #content-lower .column-3 .social-media ul li.youtube a
{
    background-position: -84px 0;
}
#testimonials-home #content #footnotes
{
    padding: 0 19px 18px;
    font-size: 1.4em;
}
#testimonials-home #content .cx-comparison, #testimonials-home #content .media-list, #testimonials-home #content .media-list-wrapper
{
    display: none;
}
#testimonials-studentspotlight #content
{
    background: #fff url(/App_Themes/Default/Images/media/studentspotlight-background.png) scroll 0 0 no-repeat;
}
#testimonials-studentspotlight #content #content-upper
{
    height: 298px;
    font-size: 1.3em;
    padding: 23px 560px 0 54px;
    color: #2e2e2e;
    overflow: hidden;
}
#testimonials-studentspotlight #content #content-upper h2
{
    font-size: 2em;
    font-weight: normal;
    padding: 0;
    margin: 0 0 13px -25px;
    color: #db2b15;
    line-height: 1.45em;
    width: 422px;
}
#testimonials-studentspotlight #content #content-upper h2 span
{
    display: block;
    font-size: 0.9em;
}
#testimonials-studentspotlight #content #content-upper p
{
    line-height: 1.5em;
    padding: 0;
    margin: 0 0 17px;
}
#testimonials-studentspotlight #content #content-upper p strong
{
    display: inline-block;
    padding: 0 64px 0 0;
}
#testimonials-studentspotlight #content #content-upper .hero
{
    position: absolute;
    left: 466px;
    top: 35px;
}
#testimonials-studentspotlight #content #content-upper .hero:link:before, #testimonials-studentspotlight #content #content-upper .hero:visited:before
{
    position: absolute;
    left: 174px;
    top: 83px;
    display: block;
    content: " ";
    width: 95px;
    height: 68px;
    background: transparent url(/App_Themes/Default/Images/media/icon-play-large.png) 0 -68px scroll no-repeat;
    z-index: 10;
}
#testimonials-studentspotlight #content #content-upper .hero:link:active:before, #testimonials-studentspotlight #content #content-upper .hero:link:hover:before, #testimonials-studentspotlight #content #content-upper .hero:link:focus:before, #testimonials-studentspotlight #content #content-upper .hero:visited:active:before, #testimonials-studentspotlight #content #content-upper .hero:visited:hover:before, #testimonials-studentspotlight #content #content-upper .hero:visited:focus:before
{
    background-position: 0 0;
}
#testimonials-studentspotlight #content #content-upper .lightbox-button
{
    position: relative;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    font-size: 0.9em;
    padding: 0 0 0 33px;
    margin: 34px 0 0;
    white-space: nowrap;
    background: transparent url(/App_Themes/Default/Images/blue-button-left.png) scroll 0 0 no-repeat;
    color: #fff;
    float: right;
}
.generatedcontent #testimonials-studentspotlight #content #content-upper .lightbox-button:after
{
    position: absolute;
    display: block;
    height: 35px;
    width: 16px;
    content: url(/App_Themes/Default/Images/blue-button-right.png);
    right: -16px;
    top: 0;
}
.no-generatedcontent #testimonials-studentspotlight #content #content-upper .lightbox-button-wrapper
{
    display: inline-block;
    background: transparent url(/App_Themes/Default/Images/blue-button-right.png) scroll right 0 no-repeat;
    padding: 0 16px 0 0;
    margin: 34px 0 0;
    float: right;
}
.no-generatedcontent #testimonials-studentspotlight #content #content-upper .lightbox-button-wrapper .lightbox-button
{
    display: inline-block;
    margin: 0;
    float: none;
}
#testimonials-studentspotlight #content #content-lower
{
    list-style-type: none;
    width: 948px;
    overflow: hidden;
    margin: 0 2px 0 10px;
    font-size: 1.3em;
    padding: 0 0 63px;
}
#testimonials-studentspotlight #content #content-lower li
{
    float: left;
    width: 270px;
    padding: 10px 29px 0 17px;
    color: #454545;
}
#testimonials-studentspotlight #content #content-lower li h3
{
    font-size: 1.1em;
    font-weight: bold;
    margin: 0 0 5px;
}
#testimonials-studentspotlight #content #content-lower li .hero
{
    display: block;
}
#testimonials-studentspotlight #content #content-lower li .hero img
{
    display: block;
}
#testimonials-studentspotlight #content #content-lower li .thumbnails
{
    list-style-type: none;
    overflow: hidden;
    width: 297px;
    margin: 12px 0 30px;
}
#testimonials-studentspotlight #content #content-lower li .thumbnails li
{
    width: 44px;
    padding: 0 13px;
}
#testimonials-studentspotlight #content #content-lower li .name
{
    color: #999;
    line-height: 1.6em;
}
#testimonials-studentspotlight #content #content-lower li .name span
{
    display: block;
    color: #454545;
    font-size: 1.2em;
    font-weight: bold;
}
#testimonials-studentspotlight #content #content-lower li p
{
    line-height: 1.2em;
    padding: 0 0 11px;
}
#testimonials-studentspotlight #content #content-lower li p q
{
    font-style: italic;
}
#testimonials-studentspotlight #content #content-lower li blockquote
{
    margin: -10px 0 4px;
}
#testimonials-studentspotlight #content #content-lower li blockquote p
{
    /*font-style: italic;*/
}
#testimonials-studentspotlight #content .media-list, #testimonials-studentspotlight #content .media-list-wrapper
{
    display: none;
}
.scroll-arrow
{
    display: block;
    position: absolute;
    width: 47px;
    height: 49px;
    background-image: url(/App_Themes/Default/Images/media/scroll-arrows.png);
    background-repeat: no-repeat;
    overflow: hidden;
    text-indent: -9999px;
}
.scroll-arrow.scroll-arrow-off
{
    cursor: default;
}
.scroll-arrow.scroll-arrow-on
{
    cursor: pointer;
}
.scroll-arrow.scroll-arrow-left:link, .scroll-arrow.scroll-arrow-left:visited
{
    background-position: 0 -47px;
}
.scroll-arrow.scroll-arrow-left:link.scroll-arrow-on:hover, .scroll-arrow.scroll-arrow-left:visited.scroll-arrow-on:hover
{
    background-position: 0 0;
}
.scroll-arrow.scroll-arrow-right:link, .scroll-arrow.scroll-arrow-right:visited
{
    background-position: -51px -47px;
}
.scroll-arrow.scroll-arrow-right:link.scroll-arrow-on:hover, .scroll-arrow.scroll-arrow-right:visited.scroll-arrow-on:hover
{
    background-position: -51px 0;
}
#videos-quicklook #content
{
    background: #fff url(/App_Themes/Default/Images/media/quicklook-background.png) scroll 0 0 no-repeat;
    color: #2e2e2e;
}
#videos-quicklook #content .lightbox-button
{
    position: relative;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    font-size: 0.9em;
    padding: 0 0 0 33px;
    margin: 6px 16px 0 -6px;
    white-space: nowrap;
    background: transparent url(/App_Themes/Default/Images/blue-button-left.png) scroll 0 0 no-repeat;
    color: #fff;
}
.generatedcontent #videos-quicklook #content .lightbox-button:after
{
    position: absolute;
    display: block;
    height: 35px;
    width: 16px;
    content: url(/App_Themes/Default/Images/blue-button-right.png);
    right: -16px;
    top: 0;
}
.no-generatedcontent #videos-quicklook #content .lightbox-button-wrapper
{
    display: inline-block;
    background: transparent url(/App_Themes/Default/Images/blue-button-right.png) scroll right 0 no-repeat;
    padding: 0 16px 0 0;
    margin: 6px 0 0 -6px;
}
.no-generatedcontent #videos-quicklook #content .lightbox-button-wrapper .lightbox-button
{
    display: inline-block;
    margin: 0;
}
#videos-quicklook #content #content-inner
{
    height: 297px;
    padding: 25px 65px 0 555px;
    font-size: 1.4em;
}
#videos-quicklook #content #content-inner h2
{
    font-size: 24px;
    line-height: 1.425em;
    text-indent: -24px;
    color: #db2b15;
}
#videos-quicklook #content #content-inner b, #videos-quicklook #content #content-inner strong
{
    font-size: 1.1em;
}
#videos-quicklook #content #content-inner p
{
    margin: 2px 0 6px;
    line-height: 1.475em;
}
#videos-quicklook #content #content-inner .hero
{
    position: absolute;
    left: 42px;
    top: 35px;
}
#videos-quicklook #content #content-inner .hero:link:before, #videos-quicklook #content #content-inner .hero:visited:before
{
    position: absolute;
    left: 174px;
    top: 92px;
    display: block;
    content: " ";
    width: 95px;
    height: 68px;
    background: transparent url(/App_Themes/Default/Images/media/icon-play-large.png) 0 -68px scroll no-repeat;
    z-index: 10;
}
#videos-quicklook #content #content-inner .hero:link:active:before, #videos-quicklook #content #content-inner .hero:link:hover:before, #videos-quicklook #content #content-inner .hero:link:focus:before, #videos-quicklook #content #content-inner .hero:visited:active:before, #videos-quicklook #content #content-inner .hero:visited:hover:before, #videos-quicklook #content #content-inner .hero:visited:focus:before
{
    background-position: 0 0;
}
#videos-quicklook #content .media-list
{
    margin: 0 0 0 9px;
    overflow: hidden;
    font-size: 1.2em;
}
#videos-quicklook #content .media-list li
{
    position: relative;
    display: block;
    width: 221px;
    height: 38px;
    float: left;
    background: transparent url(/App_Themes/Default/Images/media/list-item-separator.png) 0 0 scroll no-repeat;
    margin: 0 0 0 -5px;
    padding: 157px 0 0 21px;
}
#videos-quicklook #content .media-list li img
{
    position: absolute;
    left: 46px;
    top: 24px;
    border: 4px solid #bcbcbc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
}
#videos-quicklook #content .media-list li a
{
    display: block;
    color: #2e2e2e;
    background: transparent url(/App_Themes/Default/Images/media/icon-play-small.png) 0 0 scroll no-repeat;
    padding: 1px 0 5px 28px;
    line-height: 1.2em;
}
#videos-quicklook #content .media-list li .lightbox-description
{
    display: none;
}
#videos-quicklook #content .media-list-wrapper
{
    margin: 0 9px;
}
#videos-quicklook #content .media-list-wrapper, #videos-quicklook #content .media-list-wrapper .scroll-wrapper
{
    overflow: hidden;
    position: relative;
    height: 195px;
}
#videos-quicklook #content .media-list-wrapper .scroll-arrow
{
    z-index: 10;
    top: 61px;
}
#videos-quicklook #content .media-list-wrapper .scroll-arrow.scroll-arrow-left
{
    left: 0;
}
#videos-quicklook #content .media-list-wrapper .scroll-arrow.scroll-arrow-right
{
    right: 0;
}
#videos-quicklook #content .media-list-wrapper .media-list
{
    margin: 0;
}
#videos-quicklook #content .media-list-wrapper .media-list li
{
    cursor: pointer;
}
.spotlight-lightbox-wrapper
{
	z-index: 1000;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    font-family: Arial, Helvetica, Verdana, sans-serif;
}
.spotlight-lightbox-wrapper .lightbox-backdrop
{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: #000;
    z-index: 9997;
    opacity: 0.4;
}
.spotlight-lightbox-wrapper .lightbox-close
{
    width: 24px;
    height: 24px;
    position: absolute;
    left: 908px;
    top: 55px;
    background: transparent url(/App_Themes/Default/Images/lightbox-close.png) scroll 0 0 no-repeat;
    z-index: 9999;
}
.spotlight-lightbox-wrapper .spotlight-lightbox
{
    overflow: hidden;
    position: absolute;
    left: 51px;
    top: 53px;
    color: #646363;
    border: solid 2px #505050;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    width: 877px;
    padding: 0 0 8px;
    background: #ebebeb;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    border-radius: 7px;
    z-index: 9998;
}
.spotlight-lightbox-wrapper .spotlight-lightbox.with-thumbnails
{
    padding-bottom: 158px;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content
{
    width: 877px;
    min-height: 418px;
    overflow: hidden;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-media-container
{
    position: relative;
    margin: 34px 27px 0 35px;
    float: left;
    width: 510px;
    height: 510px;
    background: #bcbcbc;
    border: 4px solid #bcbcbc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-media-container:before, .spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-media-container:after
{
    content: ' ';
    display: inline-block;
    height: 510px;
    width: 0;
    vertical-align: middle;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-media-container .youtube-video
{
    display: block;
    margin: 0 auto;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-media-container img
{
    display: inline;
    margin: 0 auto;
    vertical-align: middle;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-description-container
{
    float: left;
    width: 290px;
    font-size: 1.6em;
    padding: 50px 7px 0 0;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-content .lightbox-description-container .name
{
    padding: 0;
    margin: 0 0 6px;
    font-size: 1.5em;
    color: #d5121a;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-thumbnails
{
    margin: 0;
    height: 158px;
    min-width: 877px;
	z-index: 1;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-thumbnails li
{
    cursor: pointer;
    position: relative;
    display: block;
    width: 177px;
    float: left;
    padding: 21px;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-thumbnails li img
{
    display: block;
    margin: 21px auto 10px;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-thumbnails li.selected img
{
    border-color: #db2b15;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .lightbox-thumbnails li a
{
    display: block;
    color: #646363;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
}
.spotlight-lightbox-wrapper .spotlight-lightbox .thumbnails
{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 183px;
}
.comparison-lightbox-wrapper
{
	z-index: 1000;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    font-family: Arial, Helvetica, Verdana, sans-serif;
}
.comparison-lightbox-wrapper .lightbox-backdrop
{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: #000;
    z-index: 9997;
    opacity: 0.4;
}
.comparison-lightbox-wrapper .lightbox-close
{
    width: 24px;
    height: 24px;
    position: absolute;
    left: 908px;
    top: 55px;
    background: transparent url(/App_Themes/Default/Images/lightbox-close.png) scroll 0 0 no-repeat;
    z-index: 9999;
}
.testimonials-home .comparison-lightbox-wrapper .comparison-lightbox {
    height: 530px;
    overflow: auto;
    padding: 0 18px 0 0;
}
.comparison-lightbox-wrapper .comparison-lightbox
{
    overflow: hidden;
    position: absolute;
    left: 50px;
    top: 54px;
    color: #646363;
    border: solid 2px #505050;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    width: 879px;
    height: 793px;
    padding: 0;
    background: #ebebeb;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    border-radius: 7px;
    z-index: 9998;
    font-size: 1.2em;
}
.comparison-lightbox-wrapper .comparison-lightbox img
{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9997;
}
.comparison-lightbox-wrapper .comparison-lightbox h2
{
    position: absolute;
    left: 34px;
    top: 18px;
    font-size: 1.6em;
    font-weight: bold;
    color: #d5121a;
    z-index: 9998;
}
.comparison-lightbox-wrapper .comparison-lightbox .description
{
    position: absolute;
    left: 35px;
    top: 51px;
    width: 370px;
    line-height: 1.75em;
    z-index: 9998;
}
.comparison-lightbox-wrapper .comparison-lightbox .legal
{
    position: absolute;
    left: 98px;
    top: 734px;
    width: 725px;
    line-height: 1.5em;
    z-index: 9998;
}
.media-lightbox-wrapper
{
	z-index: 1000;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    font-family: Arial, Helvetica, Verdana, sans-serif;
}
.media-lightbox-wrapper .lightbox-backdrop
{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: #000;
    z-index: 9997;
    opacity: 0.4;
}
.media-lightbox-wrapper .lightbox-close
{
    width: 24px;
    height: 24px;
    position: absolute;
    left: 908px;
    top: 66px;
    background: transparent url(/App_Themes/Default/Images/lightbox-close.png) scroll 0 0 no-repeat;
    z-index: 9999;
}
.media-lightbox-wrapper .media-lightbox
{
    overflow: hidden;
    position: absolute;
    left: 51px;
    top: 65px;
    color: #646363;
    border: solid 2px #505050;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    width: 877px;
    padding: 0 0 8px;
    background: #ebebeb;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    border-radius: 7px;
    z-index: 9998;
}
.media-lightbox-wrapper .media-lightbox.with-media-list
{
    padding-bottom: 183px;
}
.media-lightbox-wrapper .media-lightbox .lightbox-content
{
    width: 877px;
    min-height: 418px;
    overflow: hidden;
}
.media-lightbox-wrapper .media-lightbox .lightbox-content .lightbox-media-container
{
    padding: 34px 27px 0 35px;
    float: left;
    width: 518px;
}
.media-lightbox-wrapper .media-lightbox .lightbox-content .lightbox-media-container .youtube-video, .media-lightbox-wrapper .media-lightbox .lightbox-content .lightbox-media-container img
{
    border: 4px solid #bcbcbc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
}
.media-lightbox-wrapper .media-lightbox .lightbox-content .lightbox-description-container
{
    float: left;
    width: 269px;
    font-size: 1.7em;
    padding: 50px 20px 0 0;
    line-height: 1.4em;
}
.media-lightbox-wrapper .media-lightbox .lightbox-content .lightbox-description-container h2
{
    margin: 0 0 8px;
    font-size: 1.4em;
    color: #d5121a;
    line-height: 1.2em;
}
.media-lightbox-wrapper .media-lightbox .lightbox-media-list
{
    margin: 0;
    background: transparent url(/App_Themes/Default/Images/media/lightbox-media-list-background.gif) 0 0 scroll repeat-x;
    height: 183px;
    padding: 21px 0 0;
    min-width: 877px;
}
.media-lightbox-wrapper .media-lightbox .lightbox-media-list li
{
    cursor: pointer;
    position: relative;
    display: block;
    width: 251px;
    height: 38px;
    float: left;
    padding: 0 21px;
}
.media-lightbox-wrapper .media-lightbox .lightbox-media-list li img
{
    display: block;
    margin: 0 auto 10px;
    border: 4px solid #bcbcbc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
}
.media-lightbox-wrapper .media-lightbox .lightbox-media-list li.selected img
{
    border-color: #db2b15;
}
.media-lightbox-wrapper .media-lightbox .lightbox-media-list li a
{
    display: block;
    color: #646363;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
}
.media-lightbox-wrapper .media-lightbox .lightbox-media-list li .lightbox-description
{
    display: none;
}
.media-lightbox-wrapper .media-lightbox .media-list-wrapper
{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
.media-lightbox-wrapper .media-lightbox .media-list-wrapper, .media-lightbox-wrapper .media-lightbox .media-list-wrapper .scroll-wrapper
{
    overflow: hidden;
    height: 183px;
}
.media-lightbox-wrapper .media-lightbox .media-list-wrapper .scroll-wrapper
{
    position: relative;
}
.media-lightbox-wrapper .media-lightbox .media-list-wrapper .scroll-arrow
{
    z-index: 10;
    top: 61px;
}
.media-lightbox-wrapper .media-lightbox .media-list-wrapper .scroll-arrow.scroll-arrow-left
{
    left: 0;
}
.media-lightbox-wrapper .media-lightbox .media-list-wrapper .scroll-arrow.scroll-arrow-right
{
    right: 0;
}