﻿.sublayout-etdownloadsactivitieslanding { width: 960px; height: 468px; position: relative;
	.sublayout-etdownloadsactivitiescontent { position: absolute; left: 0; top: 0; width: 960px; height: 300px; overflow: visible; cursor: pointer;
		.etdownloadsactivitiescontent-teaser { position: absolute; top: 300px; height: 168px;
			&::-webkit-details-marker { display: none; }

			.hgroup { padding: 11px 0 11px 1px; min-height: 29px;
				h1 { color: #6d6b6c; font-weight: bold; font-size: 1.3em; }

				h2 { color: #6d6b6c; font-weight: bold; font-size: 1.1em; padding: 3px 0 0; }

			}

			.etdownloadsactivitiescontent-teaserbody { min-height: 72px; padding: 0 0 6px; @include clearfix;
				.etteaserimage { float: left; width: 70px; }

				.etteasercontent { float: left; }

			}

			.etcalltoaction { display: block; width: 185px; color: #c1272d; font-size: 1.1em; line-height: 1.1em; font-weight: bold; text-align: right; padding-right: 25px; background: url(/App_Themes/Default/Images/General/chevrons.png) scroll right center no-repeat transparent; }

		}

		.etdownloadsactivitiescontent-body { position: absolute; left: 0; top: 0; width: 960px; height: 300px; background-repeat: no-repeat; background: #cdcdcd;
			h2 { font-size: 2.3em; line-height: 1.25em; font-weight: bold; letter-spacing: 0.02mm; text-indent: -12px; color: #fff; padding: 18px 0 10px 33px; width: 470px; }

			.etmaincontent	 { font-weight: bold; color: #3e3e3e; width: 280px; line-height: 1.55em; font-size: 1.1em; padding: 0 0 0 34px; margin: -4px 0 0; }

			.sublayout-etdownloadssearch { width: 300px; margin: 14px 0 0 13px;
				label { display: block; font-weight: bold; text-align: right; font-size: 1.2em; padding: 7px 0;
					select { width: 198px; padding: 1px 0; margin: 0 0 0 1px; border: 1px solid #a5acb2;

					}

				}
				
				a.submit { margin: 5px 0 0 100px; }

			}

			.sublayout-etactivitiessearchcriteria-subjectarea { width: 300px; margin: 0 0 0 3px;
				label { display: block; font-weight: bold; text-align: right; font-size: 1.2em; padding: 4px 0 6px;
					select { width: 146px; padding: 0; margin: 0 0 0 6px; border: 2px inset #f0f0f0;

					}

				}
				
				input[type="submit"] { margin: 13px 0 0 150px; }
			}

			.sublayout-etmydownloadsandactivitiesbutton { margin: 29px 0 0 34px; }

			.sublayout-etcalloutstack { position: absolute; left: 757px; top: 26px; width: 203px;
				h1 { color: #fff; font-size: 1.6em; padding: 0 0 0 28px; margin: 0 0 5px; line-height: 1.25em; }

				a { color: #fff; }

				.etcalloutstack-list {
					.etcalloutstack-listitem { padding: 0 0 1px;
						.etcallout {
							header { font-weight: bold; background: url(/App_Themes/Default/Images/Downloads-Activities/callout-header-bg.png) scroll no-repeat 0 0 transparent; padding: 11px 0 11px 30px; font-size: 1.1em;
								h1 { font-size: 1em; padding: 0; margin: 0; }

							}

							.ethyperlinklist { margin: -7px 0 6px;
								.jump-list {
									ul {
										li { padding: 0 0 4px 30px;
											a { font-size: 1.1em; line-height: 1.25em; }
										}

									}

								}

							}

						}

					}

				}

			}

		}

		&.selected { cursor: default;
			.etdownloadsactivitiescontent-teaser { background: #fff;
				.hgroup {
					h1 { color: #c21600;

					}

				}
				
			}

		}

	}

	&.panes-3 { background: url(/App_Themes/Default/Images/Downloads-Activities/landing-teaser-bg-3.gif) scroll 0 300px no-repeat #fff;
		.sublayout-etdownloadsactivitiescontent {
			.etdownloadsactivitiescontent-teaser { width: 300px; padding: 4px 10px 0 10px;
				.etdownloadsactivitiescontent-teaserbody { width: 300px;
					.etteasercontent { width: 227px; }
				}
				.etcalltoaction { width: 275px; padding-left: 0; }

			}
			&.pane-1 { z-index: 3;
				.etdownloadsactivitiescontent-teaser { left: 0;}				

			}

			&.pane-2 { z-index: 2;
				.etdownloadsactivitiescontent-teaser { left: 320px; }
						
			}

			&.pane-3 { z-index: 1;
				.etdownloadsactivitiescontent-teaser { left: 640px; }

			}

		}

	}

	&.panes-4 { background: url(/App_Themes/Default/Images/Downloads-Activities/landing-teaser-bg-4.gif) scroll 0 300px no-repeat #fff;
		.sublayout-etdownloadsactivitiescontent {
			.etdownloadsactivitiescontent-teaser { width: 219px; padding: 4px 10px 0 10px;
				.etdownloadsactivitiescontent-teaserbody { width: 231px;
					.etteasercontent { width: 141px; }
				}

				.etcalltoaction { padding-left: 12px; }

			}

			&.pane-1 { z-index: 4;
				.etdownloadsactivitiescontent-teaser { left: 0; width: 211px;
					.etcalltoaction { padding-left: 7px; }

				}				

			}

			&.pane-2 { z-index: 3;
				.etdownloadsactivitiescontent-teaser { left: 239px; }
						
			}

			&.pane-3 { z-index: 2;
				.etdownloadsactivitiescontent-teaser { left: 483px; }

			}

			&.pane-4 { z-index: 1;
				.etdownloadsactivitiescontent-teaser { left: 726px; width: 211px;
					.etcalltoaction { padding-left: 0; }

				}

			}

		}

	}

}

