﻿.etcarousel-wrapper { position: relative; padding: 0 0 10px 0; overflow: hidden;
	.etcarousel { position: absolute; right: 0; bottom: 0; left: 0; top: 0; background: #fff;
		.etcarousel-slide { position: absolute; left: 0; top: 0; overflow: hidden;
		}
	}

	.etcarousel-buttons { position: absolute; list-style-type: none; @include clearfix; z-index: 100; right: 0; bottom: 0;
		.etcarousel-button { position: relative; background: #dcdcdc; height: 7px; width: 8px; margin: 0 5px 0 0; display: block; float: left; cursor: pointer;
			&.etcarousel-selected { background: #af1200; }

			span { display: none; cursor: pointer; }

		}

	}

}