﻿.etcallout-style-home {
	.sublayout-etcalloutstackgroup {
		> .scLooseFrameZone { overflow: visible; }
	}

	.sublayout-etcalloutstack {
		h1 { color: #af1200; font-weight: bold; font-size: 1.1em; }

		.etcalloutstack-list { padding: 4px 0 0; margin: 0 0 0 -6px;
			.etcalloutstack-listitem { margin: 0 0 12px 0; padding: 0 0 0 6px; background: url(/App_Themes/Default/Images/Callout/home-promo-separator.gif) no-repeat scroll 0 bottom transparent; }

			.etcalloutstack-editlistitem {
				.webedit-helper { display: block; text-align: center; margin: 4px; padding: 0 0 0 6px; }

			}


		}

		> .scLooseFrameZone { overflow: visible; }

		.etslideshow,
		.etcarousel { background: url(/App_Themes/Default/Images/Callout/home-promo-separator.gif) no-repeat scroll 0 bottom #fff; margin: 0 0 15px;
			.etcalloutstack-listitem { background: none;
				.etslideshow-inner-1,
				.etcarousel-inner-1 { padding: 0 0 0 6px; }

			}

		}

		&:last-child {
			.etcalloutstack-list {
				.etcalloutstack-listitem:last-child { background: none; }

			}

			.etslideshow-wrapper:last-child .etslideshow,
			.etcarousel-wrapper:last-child .etcarousel { background: none; }

		}

	}

	.etcallout-image {
		a { display: block;
			img { display: block; margin: 0 auto; }

		}

		.etslideshow-inner-1 { display: table-cell; vertical-align: middle;
			.etslideshow-inner-2 { margin: -10px auto 0; }

		}

	}

	.etcallout-content { text-align: left;

		section { padding: 0 0 11px 0; min-height: 55px;
			a,
			a:link,
			a:visited {
				header { position: relative; margin: 10px 0 0 13px; padding: 4px 0 3px;
					.hgroup {
						h1 { font-weight: bold; text-align: left; color: #585858; font-size: (12em/12); }

						h2 { font-weight: bold; text-align: left; color: #585858; font-size: (11em/12); margin-top: .5em; }

					}

				}

				.etcallout-body {
					.content { color: #737171; padding: 0 13px 5px 13px; line-height: 1.5em; }

				}

			}

			a:link,
			a:visited {
				&:focus,
				&:active,
				&:hover { text-decoration: none; }

			}

			.ethyperlinklist { padding: 5px 14px 8px 0; margin-left: 45px;
				nav { padding: 3px 0 0;
					select { max-width: 100%; padding: 1px; border: inset 1px #f0f0f0; }

					ul {
						li {
							a { display: block; padding: 0 0 0 11px; line-height: 14px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 0 3px transparent; }

							a:link,
							a:visited {
								&:focus,
								&:active,
								&:hover { text-decoration: underline; }

							}

						}

					}

				}

				> .scLooseFrameZone { overflow: visible; }

				.webedit-helper { display: block; text-align: center; margin: 4px; }

			}

			> .scLooseFrameZone { overflow: visible; }

		}

	}

	.etcallout-productenhancement { text-align: left;
		section { padding: 0 0 11px 0;
			header { position: relative; margin: 10px 0 0 13px; padding: 4px 0 3px;
				h1 { font-weight: bold; text-align: left; color: #585858; font-size: (12em/12); }

			}

			.etproductenhancement { position: relative; float: left; margin: 0 5px;
				a { display: block; width: 80px; padding-top: 100px; text-align: center;
					h2 { color: #1973b4; font-size: 1em; font-weight: normal; }

					img { position: absolute; top: 0; left: -1px; border: solid 1px #e2e7ec; }

				}

			}

			> .scLooseFrameZone { display: block; overflow: hidden; margin-left: -5px; padding-left: 5px; }

			.webedit-helper { display: block; text-align: center; margin: 4px 4px 4px -1px; }

		}

	}

	.etcallout-content {
		.teaserimage-top {
			a,
			a:link,
			a:visited {
				.etcallout-body {
					.etcallout-teaserimage {
						img { display: block; margin: 0 auto; }

					}

				}

			}

		}

		.teaserimage-right {
			a,
			a:link,
			a:visited {
				.etcallout-body { position: relative;
					.content { margin-right: 53px; padding-right: 0; }

					.etcallout-teaserimage { position: absolute; right: 0; top: 0; }

				}

			}

		}

		.teaserimage-bottom {
			a,
			a:link,
			a:visited {
				.etcallout-body { position: relative;
					.etcallout-teaserimage {
						img { display: block; margin: 0 auto; }

					}

				}

			}

		}

		.teaserimage-left {
			a,
			a:link,
			a:visited {
				header { left: 32px; margin-right: 32px; }

				.etcallout-body  { position: relative;
					.content { margin-left: 45px; padding-left: 0; }

					.etcallout-teaserimage { position: absolute; left: -4px; top: -15px; }

				}

			}

		}

	}

	.etslideshow-wrapper .etslideshow-buttons,
	.etcarousel-wrapper .etcarousel-buttons { right: 10px; top: -10px; }

}

.etcallout-style-home2 { position: relative; margin-top: -96px;
	.sublayout-etcalloutstackgroup {
		> .scLooseFrameZone { overflow: visible; }
	}

	.sublayout-etcalloutstack {
		h1 { color: #af1200; font-weight: bold; font-size: 1.1em; }

		.etcalloutstack-list { padding: 4px 0 0; margin: 0 0 0 -3px; width: 713px; overflow: hidden;
			.etcalloutstack-listitem { width: 219px; padding: 0 9px; margin: 0 0 12px 0; float: left; background: url(/App_Themes/Default/Images/Callout/home-promo-separator-vertical.gif) no-repeat scroll 0 17px transparent; }

			.etcalloutstack-editlistitem {
				.webedit-helper { display: block; text-align: center; margin: 4px; padding: 0 0 0 6px; }

			}


		}

		> .scLooseFrameZone { overflow: visible; }


		.etcallout-teaserimage { margin-bottom: 6px; }

	}

	.etcallout-image {
		a { display: block;
			img { display: block; margin: 0 auto; }

		}

		.etslideshow-inner-1 { display: table-cell; vertical-align: middle;
			.etslideshow-inner-2 { margin: -10px auto 0; }

		}

	}

	.etcallout-content { text-align: left;

		section { padding: 0 0 11px 0; min-height: 55px;
			a,
			a:link,
			a:visited {
				header { position: relative; margin: 10px 0 0 13px; padding: 4px 0 3px;
					.hgroup {
						h1 { font-weight: bold; text-align: left; color: #585858; font-size: 12px; line-height: 17px; }

						h2 { font-weight: bold; text-align: left; color: #585858; font-size: 11px; margin-top: .5em; }

					}

				}

				.etcallout-body {
					.content { color: #737171; padding: 0 13px 5px 13px; line-height: 1.5em;
						p { margin-bottom: 0; }

						.learn-more { display: inline-block; color: #cb0c0f; background: transparent url(/App_Themes/Default/Images/chevrons.png) right 5px scroll no-repeat; padding-right: 21px; }

					}

				}

			}

			a:link,
			a:visited {
				&:focus,
				&:active,
				&:hover { text-decoration: none; }

			}

			.ethyperlinklist { padding: 5px 14px 8px 0; margin-left: 45px;
				nav { padding: 3px 0 0;
					select { max-width: 100%; padding: 1px; border: inset 1px #f0f0f0; }

					ul {
						li {
							a { display: block; padding: 0 0 0 11px; line-height: 14px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 0 3px transparent; }

							a:link,
							a:visited {
								&:focus,
								&:active,
								&:hover { text-decoration: underline; }

							}

						}

					}

				}

				> .scLooseFrameZone { overflow: visible; }

				.webedit-helper { display: block; text-align: center; margin: 4px; }

			}

			> .scLooseFrameZone { overflow: visible; }

		}

		.teaserimage-top {
			a,
			a:link,
			a:visited {
				.etcallout-body {
					.etcallout-teaserimage {
						img { display: block; margin: 0 auto; }

					}

				}

			}

		}

		.teaserimage-right {
			a,
			a:link,
			a:visited {
				.etcallout-body { position: relative;
					.content { margin-right: 53px; padding-right: 0; }

					.etcallout-teaserimage { position: absolute; right: 0; top: 0; }

				}

			}

		}

		.teaserimage-bottom {
			a,
			a:link,
			a:visited {
				.etcallout-body { position: relative;
					.etcallout-teaserimage {
						img { display: block; margin: 0 auto; }

					}

				}

			}

		}

		.teaserimage-left {
			a,
			a:link,
			a:visited {
				header { left: 32px; margin-right: 32px; }

				.etcallout-body  { position: relative;
					.content { margin-left: 45px; padding-left: 0; }

					.etcallout-teaserimage { position: absolute; left: -4px; top: -15px; }

				}

			}

		}

	}

	.etslideshow-wrapper .etslideshow-buttons,
	.etcarousel-wrapper .etcarousel-buttons { right: 10px; top: -10px; }

}

.etcallout-style-home-v2 {
	$image-width: 93px;
	$content-padding-left: 8px;
	$content-padding-right: 13px;

	.sublayout-etcalloutstack {
		h1 { color: #af1200; font-weight: bold; font-size: 1.1em; }

		.etcalloutstack-list { padding: 0; margin: 0 0 0 -3px; width: 960px; overflow: hidden;
			.etcalloutstack-listitem { float: left; background: url(/App_Themes/Default/Images/Callout/home-promo-separator-vertical.gif) no-repeat scroll 0 0 transparent;
				&:first-child { background: none transparent; }

			}

			.etcalloutstack-editlistitem {
				.webedit-helper { display: block; text-align: center; margin: 4px; padding: 0 0 0 6px; }

			}

			@for $column-count from 0 through 4 {
				$effective-column-count: max( $column-count, 1 );
				$column-padding-top: 4px;
				$column-padding-right: 9px;
				$column-padding-bottom: 12px;
				$column-padding-left: 9px;
				$outer-width: 960px;
				$column-width: ( ( $outer-width - ( ( $column-padding-left + $column-padding-right ) * $effective-column-count ) ) ) / $effective-column-count;
				&.listitems-#{$column-count} {
					.etcalloutstack-listitem { width: $column-width; padding: #{$column-padding-top} #{$column-padding-right} #{$column-padding-bottom} #{$column-padding-left}; min-height: 178px - $column-padding-top - $column-padding-bottom;
						> .scLooseFrameZone { margin: #{-$column-padding-top} #{-$column-padding-right} #{-$column-padding-bottom} #{-$column-padding-left}; padding: #{$column-padding-top} #{$column-padding-right} #{$column-padding-bottom} #{$column-padding-left}; }

						.etcallout-content .etcallout-body .content { width: $column-width - $image-width - $content-padding-left - $content-padding-right; }
					}

					@if $effective-column-count < 4 {
						.etcallout-content section {
							a,
							a:link,
							a:visited {
								header { margin: 20px 0 0 ($image-width + $content-padding-left + 16px); }

							.etcallout-body {
									.content { padding: 0 0 0 ($content-padding-left + $image-width + 7px); }

									.etcallout-teaserimage { position: absolute; left: 14px; top: -4px; }

								}

							}

						}

					}

				}

			}

		}

		> .scLooseFrameZone { overflow: visible; }


		.etcallout-teaserimage { margin-bottom: 6px; }

	}

	.etcallout-image { width: $image-width;
		a { display: block;
			img { display: block; margin: 0 auto; }

		}

		.etslideshow-inner-1 { display: table-cell; vertical-align: middle;
			.etslideshow-inner-2 { margin: -10px auto 0; }

		}

	}

	.etcallout-content { text-align: left;
		section { padding: 0 0 11px 0; min-height: 55px; position: relative;
			a,
			a:link,
			a:visited {
				header { position: relative; margin: 10px 0 0 8px; padding: 1px 0 4px;
					h1 { font-weight: bold; text-align: left; color: #585858; font-size: 12px; line-height: 17px; }

					h2 { font-weight: bold; text-align: left; color: #585858; font-size: 11px; margin-top: .5em; }

				}

				.etcallout-body { padding: 0 0 0 9px; min-height: 40px;
					.content { color: #737171; padding: 0 $content-padding-right 5px $content-padding-left; line-height: 1.5em; float: left;
						p { margin-bottom: 0; }

						.learn-more { display: inline-block; color: #cb0c0f; background: transparent url(/App_Themes/Default/Images/chevrons.png) right 5px scroll no-repeat; padding-right: 21px; }

					}

				}

				&:focus,
				&:active,
				&:hover { text-decoration: none; }

			}

			.ethyperlinklist { padding: 5px 14px 8px 0; margin-left: 45px;
				nav { padding: 3px 0 0;
					select { max-width: 100%; padding: 1px; border: inset 1px #f0f0f0; }

					ul {
						li {
							a { display: block; padding: 0 0 0 11px; line-height: 14px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 0 3px transparent; }

							a:link,
							a:visited {
								&:focus,
								&:active,
								&:hover { text-decoration: underline; }

							}

						}

					}

				}

				> .scLooseFrameZone { overflow: visible; }

				.webedit-helper { display: block; text-align: center; margin: 4px; }

			}

			> .scLooseFrameZone { overflow: visible; }

		}

		.teaserimage-top {
			a,
			a:link,
			a:visited {
				.etcallout-body {
					.etcallout-teaserimage {
						img { display: block; margin: 0 auto; }

					}

				}

			}

		}

		.teaserimage-right {
			a,
			a:link,
			a:visited {
				.etcallout-body { position: relative;
					.content { margin-right: 53px; padding-right: 0; }

					.etcallout-teaserimage { position: absolute; right: 0; top: 0; }

				}

			}

		}

		.teaserimage-bottom {
			a,
			a:link,
			a:visited {
				.etcallout-body {
					.etcallout-teaserimage {
						img { display: block; margin: 0 auto; }

					}

				}

			}

		}

		.teaserimage-left {
			a,
			a:link,
			a:visited {
				.etcallout-body  { overflow: hidden; width: 100%;
					.content { float: left; }

					.etcallout-teaserimage { float: left; }

				}

			}

		}

	}

	.etslideshow-wrapper .etslideshow-buttons,
	.etcarousel-wrapper .etcarousel-buttons { right: 10px; top: -10px; }

}

.etcallout-style-home-stack {
	.sublayout-etcalloutstack {
		h1 { color: #af1200; font-weight: bold; font-size: 1.1em; }

		.etcalloutstack-list { padding: 0; width: 100%;

			.etcalloutstack-editlistitem {
				.webedit-helper { display: block; text-align: center; margin: 4px; padding: 0 0 0 6px; }

			}

		}

		> .scLooseFrameZone { overflow: visible; }


		.etcallout-teaserimage { margin-bottom: 6px; }

	}

	.etcallout-content { text-align: left; border-bottom: solid 1px #525252; margin: 40px 0 0;
		$body-padding-left: 37px;
		$body-padding-right: 37px;

		section { padding: 0 0 11px 0; min-height: 55px; position: relative;
			a,
			a:link,
			a:visited {
				header { position: relative; margin: 10px 0 0 8px; padding: 1px 0 0;
					h1 { font-weight: bold; text-align: left; color: #585858; font-size: 12px; line-height: 17px; }

					h2 { font-weight: bold; text-align: left; color: #585858; font-size: 11px; margin-top: .5em; }

				}

				.etcallout-body { padding: 0 $body-padding-right 0 $body-padding-left; min-height: 40px;
					.content { color: #737171; padding: 0 0 5px; line-height: 1.5em;
						p { margin-bottom: 0; }

						.learn-more { display: inline-block; color: #cb0c0f; background: transparent url(/App_Themes/Default/Images/chevrons.png) right 5px scroll no-repeat; padding-right: 21px; }

					}

				}

				&:focus,
				&:active,
				&:hover { text-decoration: none; }

			}

			.ethyperlinklist { padding: 5px 14px 8px 0; margin-left: 45px;
				nav { padding: 3px 0 0;
					select { max-width: 100%; padding: 1px; border: inset 1px #f0f0f0; }

					ul {
						li {
							a { display: block; padding: 0 0 0 11px; line-height: 14px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 0 3px transparent; }

							a:link,
							a:visited {
								&:focus,
								&:active,
								&:hover { text-decoration: underline; }

							}

						}

					}

				}

				> .scLooseFrameZone { overflow: visible; }

				.webedit-helper { display: block; text-align: center; margin: 4px; }

			}

			> .scLooseFrameZone { overflow: visible; }

		}

		.teaserimage-left { position: relative; @include clearfix;
			$image-width: 27.8%;
			$image-padding-right: 33px;

			&:before { content: ""; display: block; float: left; padding-top: $image-width; width: 1px; margin-right: -1px; }

			a,
			a:link,
			a:visited { float: left;
				.etcallout-body,
				header { padding-left: calc(#{$image-width} + #{$body-padding-left} + #{$image-padding-right}); margin: 0; }

				header { margin: 0 0 13px;
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 { font-size: 23.5px; line-height: 1.1; }
				}

				.etcallout-body {
					.etcallout-teaserimage { position: absolute; top: 0; left: $body-padding-left; width: $image-width;
						img { width: 100%; height: auto; }

					}

					.content { font-size: 23px; line-height: 1.4; }

				}

			}

		}
		
	}

}