﻿.sublayout-multi-generic-content,
.sublayout-ti-nspire-legacy-product-detail-page{
	h1{ font-size:(20em/10.8); font-weight: bold; color: #CB0C0F; padding-bottom: .5em;}
	h2{ font-size:(14em/10.8); font-weight: bold; color: #CB0C0F;}
	
	.container-1{background: url("/App_Themes/Default/Images/General/navigation-bg.gif") repeat-y; overflow: hidden; min-height: 400px;}
	#column-1{width: 220px; padding: 13px 7px 15px 0; float: left;}
	#column-2{width: 410px; padding: 13px 20px 15px 23px; float: left;
		h2,h3{ color: #525252;}
		
		.content-header{ padding-bottom: 15px; }
		.content{font-size: (12em/10.8); line-height: 1.4em;
			a.arrow{ padding-left: 12px; background: url("/App_Themes/Default/Images/General/red-arrow.gif") no-repeat left 2px;  }
		}

		.generic-content-hero{ display: inline-block; float: right; margin-left: 30px; margin-bottom: 30px; }

		.sublayout-generic-content-footer{
			ul.social-nav{ padding-top: 10px;
				li{ float: left;
					a{display: block; padding: 0 15px; 
						&:link, &:visited{ color: #CB0C0F; cursor: pointer; }
						&:hover{ text-decoration: underline!important; }
					}
					&.email-friend a{background: url("/App_Themes/Default/Images/General/email-friend.gif") no-repeat left center;}
					&.print-page a{ padding-left: 20px; background: url("/App_Themes/Default/Images/General/print.gif") no-repeat left center;}
				}

			}
		}
	}
	#column-3{width: 280px; padding: 13px 0 15px 0; float: left; font-family:Verdana,Arial, Helvetica, sans-serif; text-align: center;}
	
	&.no-column-3{
		#column-2{ width: 690px;}
		#column-3{ display: none;}
	}

	.sublayout-side-navigation{
		h2{ font-size:(16em/10.8); padding: 3px 0 6px 16px; }
		ul{ padding-left: 16px;
			li{ 
				&  a{ padding:2px 0 1px 2px; background: none;
					&:link, &:visited{font-weight: normal;}
					&:only-child{background: none;}
				}
				& a:hover,
				&.selected a{background: #A4CDEF; text-decoration: none; }
			}		
		}
	}

}

.sublayout-generic-content{
	
	h1{ color: #c00; font-family: Lato-Light;font-weight:normal; font-size: 34px;line-height: 1.1; padding-bottom: .5em }
	h2{ color: #c00; font-family: Lato-Light;font-weight: normal; font-size: 26px; }
	
	
	.container-1{overflow: hidden; min-height: 400px;}
	#column-1{width: 645px; padding: 13px 20px 15px 15px; float: left;
		
		h2{ color: #c00; font-family: Lato-Light;font-weight: normal; font-size: 26px; line-height: 1.1; }
		h3 {color: #333; font-size: 20px; font-family: lato-light; }

		
		.content-header h2{ padding-bottom: 15px; }
		.content{font-size: (12em/10.8); line-height: 1.4em;
			a.arrow{ padding-left: 12px; background: url("/App_Themes/Default/Images/General/red-arrow.gif") no-repeat left 2px;  }
		}

		.generic-content-hero{ display: inline-block; float: right; margin-left: 30px; margin-bottom: 30px; }

		.sublayout-generic-content-footer{
			ul.social-nav{ padding-top: 10px;
				li{ float: left;
					a{display: block; padding: 0 15px; 
						&:link, &:visited{ color: #CB0C0F; cursor: pointer; }
						&:hover{ text-decoration: underline!important; }
					}
					&.email-friend a{background: url("/App_Themes/Default/Images/General/email-friend.gif") no-repeat left center;}
					&.print-page a{ padding-left: 20px; background: url("/App_Themes/Default/Images/General/print.gif") no-repeat left center;}
				}

			}
		}
	}
	#column-2{width: 280px; padding: 13px 0 15px 0; float: left; font-family:Lato-Light; text-align: center;}
	&.no-column-2{ 
		#column-1{ width: 925px; }
		#column-2{ display: none; }
	}
}

.sublayout-content-detail-footer{
	ul.social-nav{ padding-top: 10px;
		li{ float: left;
			a{display: block; padding: 0 15px; 
				&:link, &:visited{ color: #CB0C0F; cursor: pointer; }
				&:hover{ text-decoration: underline!important; }
			}
			&.email-friend a{background: url("/App_Themes/Default/Images/General/email-friend.gif") no-repeat left center;}
			&.print-page a{ padding-left: 20px; background: url("/App_Themes/Default/Images/General/print.gif") no-repeat left center;}
		}

	}
}

.solutions-page {
  .container-1 {
    background: url("/App_Themes/Default/Images/press/v-line.png") no-repeat scroll 218px 0 transparent;
  }
  .sublayout-side-navigation {
    h2 {
      border-bottom: 1px solid #CCCCCC;
      margin: 0 0 0 10px;
      padding: 10px 0;
    }
    ul {
      padding-left: 10px;
      background: none;
      li {
        background: none;
        a:hover, &.selected a {
          color: #CB0C0F !important;
          background: none;
        }
        a:only-child {
          color: #525252;
        }
      }
    }
  }
  #column-1 {
    padding: 13px 12px 15px 0;
  }
  #column-2 {
    width: 400px;
  }
  &.no-column-3 #column-2 {
    width: 680px;
  }
  #column-2 .ethyperlinklist .jump-list ul li a {
    background: #cc0f12;
    color: #fff;
    font: 14px/17px Verdana, Helvetica, sans-serif;
    font-weight: bold;
    margin: 15px 0 0;
    padding: 7px 12px 7px 12px;
    border: solid 3px #e7e7e7;
    -ms-border-radius: 17px;
    -moz-border-radius: 17px;
    -webkit-border-radius: 17px;
    -o-border-radius: 17px;
    -khtml-border-radius: 17px;
    border-radius: 17px;
    display: inline-block;
  }
  .sublayout-side-navigation h2 a {
    text-decoration: none;
    color: #525252;
  }
}
.sublayout-ti-nspire-legacy-product-detail-page{
	a.redbutton{
    background: none repeat scroll 0 0 #CC0F12;
    border: 3px solid #E7E7E7;
    border-radius: 17px;
    color: #FFFFFF;
    display: inline-block;
    font: bold 14px/17px Verdana,Helvetica,sans-serif;
    margin: 15px 0 0;
    padding: 7px 12px;
}
	.content {
		ul {padding: 0 0 20px 20px;
		}
		li > ul {list-style: square; padding-bottom: 0;
		}
	}
	.container-1{background: none;
	#column-1{width: 472px; height: auto; padding: 13px 7px 15px 0; float: left; margin: 25px 0 0 50px;
		}
	#column-2{width: 375px;
			.alt-hero-link img{float: none; padding: 40px 0 0 95px;
			}
		}
	}
	}
