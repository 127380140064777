﻿.sublayout-etsiteselector{ line-height: 1.25em;
	.header-etsectiongroup { position: relative; height: 137px; padding: 0 300px 0 18px; font-size: (12em/10.8); background-color: #000; background-repeat: no-repeat;
		@include rtl { padding: 0 18px 0 300px; }
		h1 { color: #cb0c0f; font-size: (20em/12); line-height: 1.25em; font-weight: bold; padding: 9px 0 2px; }
		h2 { color: #fff; font-weight: bold; font-size: 1.111em; line-height: 1.25em;}
		.content { color: #fff; line-height: 1.25em; }
		.scLooseFrameZone { overflow: visible; position: absolute; left: 0; right: 0; top: 0; bottom: 0; padding: 0 300px 0 18px; 
		}

	}
	.remember-country{ padding: 5px 18px; font-size: 1.112em;
		label{ padding-left: 5px; vertical-align: bottom; }
	}
	.site-selector-list{ font-size: 1.112em;
		h1{ color: #cb0c0f; font-weight: bold; font-size: (20em/12);}
		h2{ color: #cb0c0f; font-weight: bold; font-size: (16em/12); padding: 0 0 0.4em 0; }
		ul.site-categories{ list-style: none; padding: 5px 0 18px; border-top: #700709 1px solid; overflow: hidden;
			
			& > li { float: left; padding-left: 18px; width: 174px; 
				@include rtl { padding-left: 0; padding-right: 18px; }
			}
			ul { list-style: none;  li a{ line-height: 1.25em; } }
		}
	}
}



