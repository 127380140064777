﻿@import "style-products";
@import "style-home";
@import "style-events";

// FIXME: Unscoped styles

.contactbox {
margin: 0;
background: white url(/App_Themes/Default/Images/Callout/cnt-box-style6-body-right.gif) no-repeat bottom right;
}


.contactboxhead {
background: url(/App_Themes/Default/Images/Callout/position-head-right.gif) no-repeat top right;
}

.contactboxbody {
background: url(/App_Themes/Default/Images/Callout/cnt-box-style6-body-left.gif) no-repeat bottom left;
padding: 0px 10px 10px 10px;
color: #585858;
height: 1%;
color: #585858;
margin-bottom: 0px;
}

.widgetcopy {
font-family: Verdana, Arial, Helvetica, sans-serif;
font-size: 11px;
color: #525252;
font-weight: 400;
}

.widgetheading2 {
font-family: Arial, Helvetica, sans-serif;
font-size: 12px;
color: #525252;
font-weight: 700;
}

.hrcontact {
color: #CB0C0F;
height: 1px;
width: 257px;
}


.utilitylink {
font-family: Verdana,Arial, Helvetica, sans-serif;
font-size: 11px;
color: #CB0C0F;
font-weight: 400;
text-decoration: none;
}


.locationbox {
margin: 0;
background: white url(/App_Themes/Default/Images/Callout/cnt-box-style6-body-right.gif) no-repeat bottom right;
}

.locationboxhead {
background: url(/App_Themes/Default/Images/Callout/cnt-box-style6-head-right.gif) no-repeat top right;
}

.locationboxhead h2 {
background: url(/App_Themes/Default/Images/Callout/cnt-box-style6-head-left.gif) no-repeat top left;
margin: 0;
padding: 10px 0 7px 10px;
color: #CB0C0F;
font-size: 16px;
}


.locationboxline {
border-top: 1px solid #BABABA;
margin: 0 4px 0 0;
height: 1px;
border-left: 1px solid #BABABA;
}


.locationboxbody {
background: url(/App_Themes/Default/Images/Callout/cnt-box-style6-body-left.gif) no-repeat bottom left;
padding: 5px 10px 10px 10px;
color: #585858;
height: 108px;
}


.positionbox {
margin: 0;
background: white url(/App_Themes/Default/Images/Callout/cnt-box-style6-body-right.gif) no-repeat bottom right;
}

.positionboxhead {
background: url(/App_Themes/Default/Images/Callout/position-head-right.gif) no-repeat top right;
}


.positionboxhead h2 {
background: url(/App_Themes/Default/Images/Callout/position-head-left.gif) no-repeat top left;
margin: 0;
padding: 10px 10px 0px 10px;
color: #525252;
font-size: 14px;
}


.positionboxbody {
background: url(/App_Themes/Default/Images/Callout/cnt-box-style6-body-left.gif) no-repeat bottom left;
padding: 0px 0px 0px 0px;
color: #585858;
height: 1%;
color: #585858;
margin-bottom: 0px;
}
