﻿/// <reference path="../fn.scss"/>

#lightbox { position: fixed; text-align: center; margin: 0; width: 100%; height: 100%; right: 0; bottom: 0; top: 0; left: 0; background: #999; background: rgba(0,0,0,0.4); z-index: 10000; 
	
}
#lightbox-inner { position: relative; margin: 100px auto 0 auto; clear: both; float: none; display: inline-block; 
	.workshop-detail-lightbox {
		.sublayout-content-lightbox .content h1 { font-size: 20px; width: 600px; }
			table { width: 600px; margin: 20px 0 7px; padding: 1px; font-size: 10px; border-collapse: collapse;
				tr {
					td,
					th { text-align: left; border-right: dotted 1px #e0e0e1; border-bottom: solid 1px #e0e0e1; vertical-align: top; text-align: left; padding: 7px 0 6px 9px; 
						 &:first-child { border-left: solid 1px #e0e0e1; }

						 &:last-child { border-right: solid 1px #e0e0e1; }

					}

					&:first-child {
						td,
						th { border-top: solid 1px #49aed1; height: 14px; vertical-align: middle; @include gradient-top-bottom( #3dc4f1, #1a7cb9, #1a7cb9 ); color: #fff; font-size: 13px;
							 &:first-child { border-left: solid 1px #49aed1; }

							 &:last-child { border-right: solid 1px #49aed1; }

						}

					}

					&:last-child {
						td,
						th { border-bottom: solid 1px #e0e0e1;

						}

					}

				}

				@include odd( 'tr' ) { background: #f3f3f4; }

			}
		}

	.lightbox-content { text-align: left; position: relative; @include border-radius( 11px ); border: solid 2px #f5f5f5; background: #f5f5f5; overflow: hidden;
		&.image-lightbox { padding: 11px;}
		&.content-lightbox .sublayout-content-lightbox { padding: 24px;
			&.no-padding { padding: 24px 0; 
				.content > *{ padding-right: 24px; padding-left: 24px; }
			}
		}

		

		.content{ font-size: (12em/9);
			h1{ color: #cb0c0f;
				font-size: (27em/12);
				font-weight: 400;
				line-height: 1em;
				font-family: Arial;
				margin-bottom: 20px;
			}
			h2{ color: #cb0c0f;
				font-size: (18em/12);
				font-weight: 400;
				line-height: 1.11em;
				margin-bottom: 12px;
			}
			a.red-button { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }
		}

		.columns{ overflow: hidden;
			.column{
				width: 381px;
				float: left;
				margin-right: 24px;
			}
		}
		.columns-2 .column-2{ margin-right: 0; }
		
		.image-wrapper{ padding-top: 12px; padding-bottom: 12px; 
			figcaption{ padding: 10px;}
		}
		.gradient-v-ddd-fff-top{
			background: url(/App_Themes/Default/Images/bg/gradients/ddd-fff-vertical-gradient.png) top center repeat-x;
		}

		&.youtube-lightbox { padding: 34px 37px 34px 35px;
			.youtube-video { border: 4px solid #bcbcbc; @include border-radius(3px); }

		}

		.lightbox-scroll-arrow { display: block; position: absolute; width: 47px; height: 49px; background-image: url(/App_Themes/Default/Images/lightbox/scroll-arrows.png); background-repeat: no-repeat; overflow: hidden; text-indent: -9999px;
			&.lightbox-scroll-arrow-off { cursor: default; }
			&.lightbox-scroll-arrow-on { cursor: pointer; }

			&.lightbox-scroll-arrow-left {
				&:link,
				&:visited { background-position: 0 -47px;
					&.lightbox-scroll-arrow-on:hover { background-position: 0 0; }

				}

			}

			&.lightbox-scroll-arrow-right {
				&:link,
				&:visited { background-position: -51px -47px;
					&.lightbox-scroll-arrow-on:hover { background-position: -51px 0; }

				}

			}
			
		}

		&.gallery-lightbox { background: #ebebeb;
			.lightbox-gallery-wrapper { position: relative; overflow: hidden; display: block;
				.lightbox-media-wrapper { padding: 34px 37px 34px 35px;
					.lightbox-media { overflow: hidden; position: relative;
						.youtube-video { border: 4px solid #bcbcbc; @include border-radius(3px); }

					}

				}

				&.with-details { padding-right: 325px;
					.lightbox-details-wrapper {
						.lightbox-details { overflow: hidden; position: absolute; right: 35px; top: 34px; width: 290px;
							.etlightboxgalleryitem-heading { color: #d5121a; font: 21px/25px Arial,Helvetica,Verdana,sans-serif; margin: 0 0 8px; }

							.etlightboxgalleryitem-content { color: #646363; font: 15px/21px Arial,Helvetica,Verdana,sans-serif;
								p { padding: 0 0 8px; }

							}

						}

					}

				}

				&.with-thumbnails { padding-bottom: 183px;
					.lightbox-thumbnails-wrapper { position: absolute; right: 0; left: 0; bottom: 0; height: 183px; overflow: hidden;
						background: #d2d2d2;
						background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lYXJHcmFkaWVudCBpZD0iZzEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMCUiIHkxPSIwJSIgeDI9IjAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZDJkMmQyIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZWJlYmViIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2cxKSIgLz48L3N2Zz4=);
						background: -webkit-gradient(linear, center top, center bottom, color-stop(0%, #d2d2d2), color-stop(100%, #ebebeb));
						background: -webkit-linear-gradient(top, #d2d2d2 0%, #ebebeb 100%);
						background: -moz-linear-gradient(top, #d2d2d2 0%, #ebebeb 100%);
						background: -ms-linear-gradient(top, #d2d2d2 0%, #ebebeb 100%);
						background: -o-linear-gradient(top, #d2d2d2 0%, #ebebeb 100%);
						background: linear-gradient(to bottom, #d2d2d2 0%, #ebebeb 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2d2d2', endColorstr='#ebebeb',GradientType=0 );
						
						.lightbox-scroll-arrow { z-index: 10; top: 61px;
							&.lightbox-scroll-arrow-left { left: 0; }

							&.lightbox-scroll-arrow-right { right: 0; }

						}

						.lightbox-scroll-wrapper { overflow: hidden; position: relative; height: 183px;
							.lightbox-thumbnails { 
								.lightbox-thumbnail { display: block; float: left; width: 177px; padding: 21px; position: relative; cursor: pointer;
									img { display: block; margin: 0 auto 10px; border: solid 4px #bcbcbc; @include border-radius(3px); }

									.lightbox-thumbnail-caption { display: block; font-weight: bold; font-size: 1.2em; text-align: center; }

									&.selected {
										img { border-color: #db2b15; }

										.lightbox-thumbnail-caption { color: #db2b15; }

									}

								}

							}

						}

					}

				}

			}

		}

	}

	.lightbox-close { position: absolute; right: 2px; top: 2px; width: 22px; height: 22px; background: transparent url(/App_Themes/Default/Images/lightbox/lightbox-close.png) scroll 0 0 no-repeat; z-index: 9999; cursor: pointer; }


	.lightbox-content.content-lightbox .layout-lightbox.lightbox-mediacenter-social {
		.sublayout-content-lightbox {
			> .content { padding: 7px 28px;
				h1 { font-size: 36px; margin-bottom: 11px; }

				.content { padding-left: 0; padding-right: 0;
					&,
					& p { font-size: 15px; line-height: 1.6; }
				}

				.social-nav { width: 668px; margin: 69px auto 99px; @include flexbox; @include justify-content(space-between);
					> a { display: block; width: 60px; height: 60px; overflow: hidden; text-indent: -9999px; background: transparent none scroll no-repeat;
						&.social-nav-facebook  { background-image: url(/-/media/4C99AA4FE8FF4701A15EB433FA775E7C.ashx); }
						&.social-nav-instagram { background-image: url(/-/media/678BEE3B5AF94E6D97C46580578FD76A.ashx); }
						&.social-nav-linkedin  { background-image: url(/-/media/7AD9397ABE574C5C86D44945E921929D.ashx); }
						&.social-nav-pinterest { background-image: url(/-/media/689C55AD553D4DF7A1319E97598DF342.ashx); }
						&.social-nav-snapchat  { background-image: url(/-/media/A29B1C5BDF744EC0B00E7877CA543842.ashx); }
						&.social-nav-twitter   { background-image: url(/-/media/230CCAF8E1034B0CACEFEF85D7EE618E.ashx); }
						&.social-nav-youtube   { background-image: url(/-/media/4BECABAD978E4A23AA640B6A23407F3E.ashx); }
					}
				}
			}
		}
	}
}
