﻿#section-body{
	.events-layout,
	.event-landing{  font-family: Verdana, Arial, Helvetica, sans-serif; }
	.events-layout { position: relative;
		#column-left { width: 210px; padding: 4px 4px 12px 6px; float: left; overflow: visible; margin-right: 3px;
			h3,
			fieldset legend,
			fieldset h4 { font-weight: bold; font-size: 1.2em; padding: 6px 0 0 7px; clear: both; letter-spacing: -0.125mm; }

			fieldset legend,
			fieldset h4 { font-size: 1.2em; }

		}

		h2 { font-weight: bold; font-size: 1.2em; padding: 6px 0 8px 7px; clear: both; letter-spacing: -0.125mm; }

		#column-left h3 { color: #4b4b4b; clear: none; float: left; padding-top: 10px; padding-bottom: 7px; }

		#column-right { float: left;  margin-left: 3px; width: 733px; padding: 9px 1px 0 0;
			h3 { clear: both; color: #dc0d0f; font-size: 1.4em; font-weight: bold; padding: 0; }

			p { padding: 0.3em 0 1em 0; line-height: 1.45em; }

			ul { padding: 0 0 0 20px;
				li { list-style-type: none; }

			}

			fieldset legend { font-weight: bold; }
		}

	}

}

.featured-column { float: right; width: 230px; margin-left: 15px; margin-top: 50px;
	h3 { color: #d31602; font-size: 1.1em; margin-bottom: 2px; clear:both; font-weight: bold; }

	.featured-events {
		ul { width: 100%;
			.featured-event-details { padding: 0 0 8px!important; margin-bottom: 8px; margin-top: 8px; border-bottom: 1px dotted #999;
				h4 { margin-bottom: 5px;
					a { font-weight: bold; }
				}
			}
		}
	}
}

.hidden { display:none; }

.flash-area { position: relative;
	.flash-overlay { position: absolute; left: 13px; top: 13px; width: 286px; color: #525252; font-size: 0.9em; }
	.version-2_0 { height: 440px; width: 712px; }
}

.advanced-search-dialog { background: none repeat scroll 0 0 transparent; border: 0 none; margin: 0; overflow: visible; padding: 0; width: 603px; height: auto; }

.ui-helper-clearfix:after { clear: both; content: "."; display: block; height: 0; visibility: hidden; }

#section-body {
	.event-details#column-right {
		#results-list { width: 960px; background: url(/App_Themes/Default/images/events/tablist-edge-wide.gif) no-repeat 0 0; margin-top: 20px;
			.event-detail-li { width: 930px;
				.result-left { width: 700px;
					p { line-height: 1.5em; margin: 1em 0; }
				}
			}
		}
	}
}

.events-layout { 
	.container-one { padding: 4px 4px 12px 6px; overflow: visible; margin-right: 3px;
		#search { position: relative; text-align: right; z-index: 102; width: 211px;
			fieldset { background: url(/App_Themes/Default/images/events/fieldset-bg.gif) no-repeat 8px bottom; clear: both; position: relative; text-align: left; z-index: 101; width: 210px;
				h4 { color: #cb0c0f; font-weight: bold; }

				legend { color: #cb0c0f; font-weight: bold; display: block; height: 25px; clear: both; padding: 6px 0 0 7px; font-size: 1.2em; letter-spacing: -0.125mm;
					a { color: #cb0c0f; font-weight: bold; background: url(/App_Themes/Default/images/events/expand-arrow.gif) no-repeat right 6px; display: block; width: 194px; }
				}

				.placeholder { padding: 0 11px 9px; position: relative; width: 190px; overflow: hidden; margin: -7px 0 3px;
					.remove-button { background: url(/App_Themes/Default/images/events/remove-button-bg.gif) no-repeat 0 4px; clear: left; color: #2588cb; display: block; float: left; min-height: 14px; padding: 1px 0 1px 14px; line-height: 14px; }
					ul {
						li { color: #2588cb; cursor: default; line-height: 14px; list-style-type: none; position: relative; }
					}

					p { color: #2588cb; cursor: pointer; padding: 0; line-height: 14px; }
				}

				> .criteria-wrapper { clear:both; min-height: 30px; position: relative; width: 434px; z-index: 100;
					.criteria-wrapper-header { background: url(/App_Themes/Default/images/events/classification-header-bg.gif) no-repeat 0 0; clear: both; height: 30px; margin-top: -30px; width: 434px;
						h4 { font-size: 1.2em; line-height: 20px; padding: 6px 0 0 7px; letter-spacing: -0.125mm; }

						.collapse-link { background: url(/App_Themes/Default/images/events/collapse-arrow.gif) no-repeat 0 0; height: 5px; overflow:hidden; position: absolute; right: 13px; text-indent: -9999px; top: 14px; width: 11px; }
					}

					.criteria-wrapper-footer { background: url(/App_Themes/Default/images/events/classification-footer-bg.gif) no-repeat 0 bottom; clear: both; height: 10px; width: 434px; }

					> .criteria-wrapper-body { background: url(/App_Themes/Default/images/events/classification-body-bg.gif) repeat-y 0 0 #f0f0f0; clear: both; padding: 7px 9px; width: 416px;
						ul { float: left;
							li { float: left; width: 208px; height: 26px; margin-bottom: 5px;
								input { float: left; margin: 0 5px 5px; }

								label { line-height: 14px; }
							}
						}
					}
				}
			}

			fieldset#by-keyword {
				label { font-weight: bold; padding-right: 1em; font-size: 1em; 
					input[type="text"] { width: 288px; font-size: x-small; }
				}
			}

			fieldset#by-date {
				.criteria-wrapper {
					.criteria-wrapper-body {
						.criteria-wrapper {
							.criteria-wrapper-body {
								#range-selector { border: 1px solid #7f9db9; clear: both; display: block; margin: 0 0 6px 44px; padding: 1px 0; width: 259px; }

								label { display: block; float: left; font-weight: bold; line-height: 18px; padding: 0 9px 0 3px; width: 155px;
									span { display: inline;
										input.text { border: 1px solid #7f9db9; margin: 0 2px 0 9px; padding: 2px; text-align: center; width: 86px; }

										.ui-datepicker-trigger { cursor: pointer; vertical-align: bottom; }
									}
								}
							}
						}
					}
				}
			}

			input[type="submit"],
			input[type="button"] { background: url(/App_Themes/Default/images/general/button-bg.gif) repeat-x 0 0; border: 1px solid #828488; border-radius: 4px; clear: none; color: #6e6e6e; cursor: pointer; float: right; font-weight: bold; height: 22px; margin: 9px 5px 0 0; padding: 0 6px; text-align: center; }

			input[type="submit"] { background-position: right 0; padding-right: 32px; }

			h3.first-child { clear: none; float: left; color: #4b4b4b; padding-bottom: 7px; padding-top: 10px; padding-left: 7px; font-size: 1.2em; font-weight: bold; letter-spacing: -0.125mm; line-height: 22px; }
		}
	}

	.container-two { overflow:hidden; background: url(/App_Themes/Default/images/events/results-bg.gif) repeat-y left 0;
		.container-one { float: left; }

		#placeholder-column-one {
			h2 { color: #cb0c0f; font-weight: bold;
				a { color: #cb0c0f; font-weight: bold; }
			}
		}

		#placeholder-column-two { padding-left: 18px; margin-left: 3px; width: 715px; 
			.sublayout-event-list { width: 712px;
				#result-header { position: absolute; right: 1px; top: 3px; margin-top: 12px; margin-bottom: 12px; padding: 0; text-align: right; z-index: 5;
					.page-size-select { border: 1px solid #7f9db9; margin: 0 7px 0 1px; }
				}

				// a { margin-left: 3px; margin-right: 3px; }

				.home-link { background: url(/App_Themes/Default/images/events/home-link-icon.gif) no-repeat right 0; display: block; float: right; height: 12px; margin: 2px 0 0 7px; overflow:hidden; text-align: left; text-indent: -9999px; width: 12px; }
			}
		}

	}
}

.event-landing {
	.container-two {
		.container-one {
			#placeholder-column-one {
				#events-home {
					.flash-header { background: url(/App_Themes/Default/images/events/home-head-bg.gif) repeat-y right 0; min-height: 37px; width: 712px; clear:both; @include clearfix;
						p { font-weight: bold; margin: 0; padding: 12px 0 0 13px; float: left; }

						nav { float: right;
							a { background: url(/App_Themes/Default/images/events/button-bg.gif) repeat-x right 0; border: 1px solid #828488; border-radius: 4px; clear: none; color: #6e6e6e; display: inline-block; font-weight: bold; height: 22px; line-height: 20px; margin: 7px 10px 3px 0; padding: 0 47px 0 21px; text-align: center; float: left; }
													
							#advanced-search { background-position: 0 0; padding-right: 21px; }

						}
					}

					h2 { font-size: 1.5em; padding: 13px 0 8px 12px; float: left; color: #cb0c0f; font-weight: bold; clear:both; }

					#navigation-section { float: right; margin: 10px 0 3px 0;
						#navigation-events {
							li { color: #1973b4; float: left; list-style-type: none; padding: 0 8px; background: url(/App_Themes/Default/images/events/navigation-section-border.gif) no-repeat 0 2px;
								a { color: #1973b4; display: block; font-size: 1.1em; text-decoration: underline; }
							}
							li#section-usa { background-image: none; padding-left: 0; 
								a { background: url(/App_Themes/Default/images/events/icon-usa.gif) no-repeat 0 3px; padding: 1px 3px 0 28px; }
							}

							li#section-canada { background-image: none; padding-left: 0;
								a { background: url(/App_Themes/Default/images/events/icon-ca.gif) no-repeat 1px 3px; padding: 1px 0 0 33px; }
							}

							li#section-online { background-image: none; padding-left: 0;
								a { background: url(/App_Themes/Default/images/events/icon-online.gif) no-repeat 0 0; padding: 1px 0 0 20px; }
							}

							li#section-rss { background-image: none; padding-left: 0;
								a { background: url(/App_Themes/Default/images/events/icon-rss.gif) no-repeat 0 2px; padding: 1px 0 0 21px; }
							}
						}
					}
				}
			}

			#placeholder-column-two {
				.sublayout-etcalloutstack { width: 240px; margin: 4px; clear: both;

				}
			}
		}
	}
}

.ui-widget-overlay { background: url(/App_Themes/Default/images/jquery-ui/ui-bg_dots-small_65_a6a6a6_2x2.png) repeat 50% 50% #a6a6a6; }

.advanced-search-dialog { background: none!important; border: none!important; margin: 0; overflow: visible!important; padding: 0; top: 131px!important;
	.ui-dialog-titlebar { display: none; padding: 0.5em 1em 0.3em; position: relative;
		.ui-dialog-title { float: left; margin: 0.1em 16px 0.2em 0; }

		.ui-dialog-titlebar-close { height: 18px; margin: -10px 0 0; padding: 1px; position: absolute; right: 0.3em; top: 50%; width: 19px;
			span { display: block; margin: 1px; }
		}
	}

	#search { background: url(/App_Themes/Default/images/events/advanced-search-bg.gif) repeat-x 0 bottom #f0f0f0; border-color: #999 #000 #000; border-style: solid; border-width: 1px; display: none; font-size: 1.1em; overflow: visible; padding: 0 0 56px; position: relative; width: 670px!important; display: block;
		#tab-list { background: url(/App_Themes/Default/images/events/advanced-search-tab-bg.gif) repeat-x 0 0; border-color: #000; border-radius: 5px 5px 0 0; border-style: solid solid none; border-width: 1px 1px 0; left: -1px; position: absolute; top: -30px; width: 670px; z-index: 1001;
			li.selected { background: #f0f0f0; border-bottom: 0 none;
				a { height: 28px; color: #d3180f; }
			}

			li:first-child { border-left: none!important; border-radius: 5px 0 0 0; margin-right: 0;}

			li { background: url(/App_Themes/Default/images/events/advanced-search-tab-bg.gif) repeat-x 0 bottom #fff; border-color: #fff #999 #999 #999; border-style: none none none solid!important; border-width: 1px 0 1px 1px; float: left; list-style: none; position: relative;
				a { color: #999; display: block; font-size: 1em; font-weight: bold; height: 28px; line-height: 28px; outline: none; text-decoration: none; padding: 0 27px 0 14px; border: none; }

				span.indicator { background: #ce1700; border-radius: 5px; color: #fff; display: block; font-weight: bold; height: 12px; line-height: 12px; padding: 0 4px; position: absolute; right: 7px; top: 8px; }
			}
		}

		fieldset { clear: both; display: none; left: 1px; padding: 7px 16px; position: absolute; top: 1px; width: 638px;
			legend { display: none; }

			p { clear: both; color: #6e6e6e; font-size: 1.3em; font-weight: bold; padding: 0 0 1em; }

			ul { margin: 0; padding: 0;
				li { float: left; width: 190px; list-style: none;
					label { display: inline; height: 34px; line-height: 14px;
						input { float: left; margin: 0 0.4em 14px 0; }
					}

					label.hidden { display: none; height:0; line-height: 0;}
				}
			}

			label {
				input.text { margin-left: 1em; }
			}
		}

		#by-location {
			ul {
				li { width: 114px;

				}
			}
		}

		#by-proximity {
			label { font-weight: bold; padding-right: 1em;
				input.text { margin-left: 1em; }

				input { width: 68px; margin: 0 0 0 0.5em; }
			}

			select { margin-left: 4px; width: 46px; font-family: Arial,sans-serif; font-size: 1em;

			}
		}

		#by-date {
			#range-selector { border: 1px solid #7f9db9; clear: both; display: block; margin: 0 0 6px 44px; padding: 1px 0; width: 259px; }

			label { display: block; float: left; font-weight: bold; line-height: 18px; padding: 0 9px 0 3px; width: 155px;
				span { display: block; float: right;
					input.text { border: 1px solid #7f9db9; margin: 0 0.2em 0 0.5em; padding: 2px; text-align: center; width: 86px; }

					span { float: left; }

					.ui-datepicker-trigger { cursor: pointer; vertical-align: bottom; }
				}
			}
		}

		.buttons { position: absolute; bottom: 11px; left: 0; right: 0; text-align: center; width: 100%;
			input.button,
			input.submit { background: url(/App_Themes/Default/images/events/button-bg.gif) repeat-x 0 0; border: 1px solid #828488; border-radius: 4px; color: #6e6e6e; cursor: pointer; font-size: 1.1em; font-weight: bold; height: 22px; padding: 0 6px; text-align: center; margin: 0 9px; }
		
			input.submit { background-position: right 0; padding-right: 32px; }
		}
	}
}

#ui-datepicker-div { z-index: 9000!important; width: 193px; background: url(/App_Themes/Default/images/jquery-ui/ui-bg_flat_75_ffffff_40x100.png) repeat-x 50% 50% #fff; border: 1px solid #eee; color: #333;
	.ui-widget-header { background: url(/App_Themes/Default/images/jquery-ui/ui-bg_highlight-soft_15_cc0000_1x100.png) repeat-x 50% 50% #c00; border: 1px solid #e3a1a1; color: #fff; font-weight: bold; 
		.ui-datepicker-title {
			.ui-datepicker-month,
			.ui-datepicker-year { width: 47%; font-size: 1.1em;

			}

			select { font-size: 1em; margin: 1px 0; }
		}
	}

	.ui-datepicker-calendar { border-collapse: collapse; font-size: 1.1em;
		thead {
			tr {
				th { border: 0; font-weight: bold; padding: 0.7em 0.3em; text-align: center;
					span { font-family: Arial,sans-serif; }
				}
			}
		}

		tbody {
		 tr {
			td.disabled { background: none; opacity: 0.35; }
			td {
				.ui-state-default { background: url(/App_Themes/Default/images/jquery-ui/ui-bg_highlight-hard_100_eeeeee_1x100.png) repeat-x 50% 50% #eee; border: 1px solid #d8dcdf; color: #004276; font-weight: bold; }

				.ui-state-highlight { background: url(/App_Themes/Default/Images/jquery-ui/ui-bg_glass_55_fbf8ee_1x400.png) repeat-x 50% 50% #fbf8ee; border: 1px solid #fcd3a1; color: #444; }

				.ui-state-active { background: url(/App_Themes/Default/Images/jquery-ui/ui-bg_flat_65_ffffff_40x100.png) repeat-x 50% 50% #fff; border: 1px solid #eee; color: #c00; font-weight: bold; }
			}
		 }
		}
	}
}

.sublayout-event-list {
	#tab-list { height: 23px; margin: 14px 0 0; padding: 0!important; position: relative; z-index: 2; list-style-type: none;
		li { background: url(/App_Themes/Default/images/events/tab-bg.gif) repeat-x scroll 0 0 transparent; float: left; margin-right: 4px;
			a { border-color: #c4c4c4; border-radius: 6px 6px 0 0; border-style: solid solid none; border-width: 1px 1px 0; color: #424141; display: block; height: 22px; line-height: 22px; padding: 0 10px; }
			&.selected a { background: none repeat scroll 0 0 #fff; border-color: #979797 #979797 #fff; border-style: solid; border-width: 1px 1px 2px; }
		}

		> li > a { border-radius: 6px 6px 0 0; }
	}

	.no-results,
	#results-list { padding-top: 23px; background: url(/App_Themes/Default/images/events/tablist-edge.gif) no-repeat 0 0; border-top: 1px solid #979797; width: 676px;

	}

	.no-results { padding-left: 10px; }
}


#result-footer { clear: both; margin-top: 12px; padding: 0; text-align: right;
	.pagination { display: inline-block;
		p { display: inline; padding: 0 5px 0 0; line-height: 1.45em; }

		ul { display: inline; padding: 5px 0 0;
			li.first-child { background-image: none;

			}

			li { background: url(/App_Themes/Default/images/events/page-separator.gif) no-repeat 0 2px; display: inline; padding: 0 1px 0 6px;

			}
		}
	}

	.home-link { background: url(/App_Themes/Default/images/events/home-link-icon.gif) no-repeat right 0; display: block; float: right; height: 12px; margin: 2px 0 0 7px; overflow:hidden; text-align: left; text-indent: -9999px; width: 12px; }
}