﻿.scfForm {
	label.scfRadioButtonListLabel, span.scfRadioButtonListLabel, label.scfSingleLineTextLabel, label.scfEmailLabel, label.scfMultipleLineTextLabel, label.scfTelephoneLabel  {float: left;
		padding: 3px 10px 0 0;
		text-align: right;
		width: 200px;}
	.scfCheckBoxListLabel {font-weight : bold;}

	.scfSingleLineGeneralPanel .scfSingleLineTextBox,
	.scfEmailGeneralPanel .scfEmailTextBox,
	.scfMultipleLineGeneralPanel .scfMultipleLineTextBox,
	.scfTelephoneGeneralPanel .scfTelephoneTextBox {width : 247px; padding : 1px; margin-bottom : 6px;}
	span.scfCheckBoxListLabel {float : none;  text-align : left;}
	span.scfRadioButtonListLabel { text-align : right;}
	.halfAvailableWidth .scfRadioButtonListLabel {float : none; text-align: center;}
	.scfSubmitButtonBorder input{ margin-left: 150px;}
	div.scfRadioButtonListGeneralPanel, table.scfRadioButtonList, div.scfSingleLineGeneralPanel, div.scfEmailGeneralPanel  {width : auto;}
	span.scfRequired  {float: none; color: #CB0C0F;}

	.scfSectionBorderAsFieldSet { position: relative;
		&:after { position: absolute; content: " "; display: block; background: #eee; border-bottom: solid 1px #bebebe; right: 255px; left: 0; top: 0; height: 25px; z-index: 4; }

		.scfSectionLegend { position: relative; z-index: 5; font: bold 12px Arial,Helvetica,sans-serif; color: #525252;
			
		}
	}
}
@import "portugalimageform";
@import "french-seed";
@import "french-vpp";
@import "portugal-seed";
@import "sv-upgrade";