﻿/********* New Product Details Styles *********/
.hero-detail-page { font-family: Arial!important; margin: 0 auto;
	ul li { margin-bottom: 4px; }

	.content {
		&, p { font-size: 12px; line-height: 18px; }
	}	

	.container-block { float: left; height: auto; font-family: Verdana, Arial, Helvetica, sans-serif; padding: 30px 30px 0 30px !important; width: 900px; margin-bottom: 30px;
		h1 { color: #cb0c0f; font-size: 27px; font-weight: 400; line-height: 1em; padding-bottom: 20px; font-family: Arial; margin-bottom: 0 !important; }
		h2 { color: #dc320f; font-size: 16px; font-weight: 400; margin-top: 15px; padding-bottom: 8px; }
		p { padding-bottom: 12px; margin-bottom: 0!important; }
	}

	.intro-content-module { padding: 20px 10px 0; margin-bottom: 0; }

	.shadow-hr-line { background: url(/App_Themes/Default/Images/product-detail/hr-shadow-line.png) center top no-repeat; }

	.intro-content-image { float: left; margin-right: 30px; }
	
	.key-features-container { float: left; width: 100%; overflow: hidden; padding-top: 30px; min-height: 307px; padding-left: 277px; position: relative;
		img:first-child { float: left; margin-right: 30px; }
	}

	.key-features-content { float: left; width: 71%!important;
		h1 { color: #cb0c0f; font-size: 27px; padding: 5px 0 14px; line-height: 32px;}

		ul { width: 100%; padding: 0!important; font-style: Verdana; color: #525252; font-size: 20px; margin: 0 0 20px;
			li { background: url(/App_Themes/Default/Images/product-detail/list-bullet.png) no-repeat scroll 8px 7px transparent; font-size: 12px; line-height: 25px; font-family: Verdana, Helvetica, sans-serif; }

		}

		p { padding: 10px; }

		img { padding: 0 20px 0 0; margin: 0!important; }

	}

	.ExpandableImage { position: absolute; left: 0; }

	.promotional-sidebar { float: right; max-height: 325px; margin-left: 20px; width: 250px; overflow: hidden; padding: 0 0 30px 20px;
		a { display: block; float: left; color: #848484; text-decoration: none!important; margin-bottom: 10px;
			strong { color: #dc320f; display: block; }

		}

	}

	.promotional-image { display: block; float: left;
		img { padding-right: 15px; }

	}

	.promotional-content { display: block; font-size: 12px; color: #555555;
		.content { padding-left: 95px; }
		strong { color: #cb0c0f!important; }
	}

	.vr-hr { background: url(/App_Themes/Default/Images/product-detail/vertical-hr2.png) no-repeat scroll transparent; }

	.tab-container { background-position: center bottom; }

	.tab-row { float: left;
		ul.tab-list { background: url(/App_Themes/Default/Images/product-detail/tab-bg.png) repeat-x scroll 0 0 transparent; font-size: 13px; height: 52px;
			li { float: left; list-style-type: none; margin: 25px 0 0; height: 16px; border-left: 1px solid #ccc;
				&:first-child { border-left: 0 none; }

				a { color: #525252; padding: 6px 25px 13px; margin-top: -1px;
					&.active { background: none repeat scroll 0 0 #fff; height: 20px; }
				}

			}

		}
	}

	ul.tab-list li:last-child { border-right: 0; }

	.apps-heading { font: bold 21px Arial, Helvetica, sans-serif; }

	.image-container {
		figcaption { display: block; text-align: center; }
	}

	.etcontentmodule-onecolumn,
	.etcontentmodule-twocolumn,
	.etcontentmodule-threecolumn { background: url(/App_Themes/Default/Images/product-detail/module-bg-vertical-gradient.png) scroll no-repeat 0 0 transparent; min-height: 80px;
		.three-column-container { background: url(/App_Themes/Default/Images/product-detail/3col-separators.gif) scroll no-repeat 0 0 transparent; min-height: 350px; }
	}

	.right .image-container { float: right; width: 47%; text-align: right; padding: 0 10px; }
	.left .image-container { float: left; padding-right: 10px; }


	.two-column-h2 { font-family: verdana !important; font-size: 18px !important; color: #cb0c0f !important; }

	.first-column-text p { font-family: verdana; font-size: 12px; color: #525252; }

	.column { width: 430px; float: left;
		.right { padding-top: 40px; }

	}

	.etcontentmodule-twocolumn {
		h1 { padding-bottom: 0; }
		.top { width: -moz-max-content; margin: 0 auto; padding-bottom: 20px; }
		.right { float: right; width: 47%; text-align: right; padding: 0 10px; }
		.left { float: left; padding: 0 10px 0 0; }
	}

	.vertical-line { background: url(/App_Themes/Default/Images/product-detail/vertical-hr.png) no-repeat; float: left; min-height: 350px; width: 1%; margin-left: 20px; padding-right: 11px; }

	.space-left { margin-left: 30px; }

	.three-column-container { margin: 20px 0 0; overflow: hidden; width: 900px;
		.column { width: 274px; display: inline-block; margin: 0 0 0 39px; vertical-align: top;
			&:first-child { margin-left: 0; }
			h2 { font-size: 18px; line-height: 20px; color: #cb0c0f; }
			p { font-size: 12px; }
		}
	}

	.footerbanner-ad { float: left; width: 960px; text-shadow: 0 0 #f2f2f2;
		a:hover { text-decoration: none; }

		img { margin-top: -40px; }

		h1 { color: #f2f2f2; position: relative; padding-left: 20px; font: normal 24px Arial, Helvetica, sans-serif; top: 10px; }

	}

	.product-intro-container { position: relative; }

	.product-intro-content { float: left; position: relative; top: 0; width: 40%; min-height: 410px; padding: 20px 40px 20px 36px; color: #525252; }

	.HeroText { font-size: 18px; line-height: 24px; padding-top: 12px; margin-bottom: 30px; }

	.product-image { position: absolute; right: 0; vertical-align: top; }

	.product-intro-content {
		h1 { font-size: 36px; font-weight: 400; line-height: 42px; }

		p { font-size: 18px; line-height: 1.4em; margin: 20px 0 30px; }

	}

	a.gradient {
		float: left;
		background: #a90329; /* Old browsers */
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNhOTAzMjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI0NCUiIHN0b3AtY29sb3I9IiM4ZjAyMjIiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNmQwMDE5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-radial-gradient(center, ellipse cover, #a90329 0%, #8f0222 44%, #6d0019 100%); /* FF3.6+ */
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#a90329), color-stop(44%,#8f0222), color-stop(100%,#6d0019)); /* Chrome,Safari4+ */
		background: -webkit-radial-gradient(center, ellipse cover, #a90329 0%,#8f0222 44%,#6d0019 100%); /* Chrome10+,Safari5.1+ */
		background: -o-radial-gradient(center, ellipse cover, #a90329 0%,#8f0222 44%,#6d0019 100%); /* Opera 12+ */
		background: -ms-radial-gradient(center, ellipse cover, #a90329 0%,#8f0222 44%,#6d0019 100%); /* IE10+ */
		background: radial-gradient(ellipse at center, #a90329 0%,#8f0222 44%,#6d0019 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
		border-radius: 60px;
		color: #fff;
		font: bold 14px arial;
		padding: 8px 20px;
		margin: 34px 0 22px;
		clear: both;
	}

	.Button { margin: 30px 0 45px 30px; padding: 7px 12px 7px 12px; background: #cc0f12; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block;
		&, a, span { color: #fff; font-size: 14px; font-weight: 700; color: #fff; line-height: 17px; }

		.ButtonLeftPart,
		.ButtonRightPart { display: none; }

	}

	a.Button { padding-top: 7px; padding-bottom: 7px; }

	.activetabindex-4 .single-column-text h1 { color: #555; font-size: 20px; }

	.container-block {
		ul { margin-bottom: 12px; }
		ul:last-child { margin-bottom: 0; }
	}

	.specifications,
	.etcontentmodule-specifications {
		.specs-content { margin: 0 0 30px 30px; padding: 0 15px;
			img { clear: both; }
		}
		
		div.odd { float: left; width: 50%; }

		div.even { float: right; width: 50%; }

		ul li { list-style: none; background: url(/App_Themes/Default/Images/product-detail/list-bullet.png) no-repeat scroll 8px 7px transparent; padding-left: 20px; line-height: 20px; }

	}

	.right .single-column-text { float: left; width: 50%; }

	.left .single-column-text { float: right; width: 50%; }

	.accessories-h1 { font-family: Arial!important; font-size: 20px!important; color: #555!important; }
}

.hero-detail-page, .fatfooter {
	.clear  { clear: left; }

	.left-margin { margin-left: 130px; }

	.bottom-margin { margin-bottom: 30px; }

	.top-margin { margin-top: 10px; }

	&.clear-fix,
	.clear-fix { clear: both; zoom: 1; }

	&.clear-fix:after,
	.clear-fix:after { clear: both; }

	&.clear-fix:before,
	&.clear-fix:after,
	.clear-fix:before,
	.clear-fix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

}

.fatfooter { float: left; background: #666; width: 900px; padding: 25px 30px;
	.heading { font-weight: 700; margin-bottom: 5px; color: #e4e4e4; }

	> h1 { color: #f2f2f2; font: bold 16px Arial, Helvetica, sans-serif; padding-bottom: 10px; }

	ul { color: #fff; float: left; width: 213px;
		li { line-height: 14px; color: #b0b0b0; text-shadow: 0 0 #b0b0b0; padding: 3px 0; }
	}

	a { text-decoration: none; color: #b0b0b0; }

	.heading a { text-decoration: none; color: #e4e4e4; }

}