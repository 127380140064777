﻿.sublayout-etimagemap { position: relative;
	.etimagemap-image { position: relative; display: block; }

	.etimagemap-region { position: absolute; display: block; left: 0; top: 0;
		&.etimagemap-region-rectangle { background: transparent url(/App_Themes/Default/Images/spacer.gif) 0 0 scroll repeat; overflow: hidden; text-indent: -9999px; width: 0; height: 0; }

		&.etimagemap-region-textarea {
			&:link,
			&:visited {
				&:hover,
				&:active,
				&:focus { text-decoration: none; }

			}

			i,
			em { font-style: italic; }
			
			b,
			strong { font-weight: bold; }
			
			u { text-decoration: underline; }
		}
	}

}