﻿.sublayout-etdownloadssearchresults {
	.sublayout-etdownloadssearch { width: 450px; margin: 5px 0 0 17px; float: left; padding-bottom: 20px;
		label { display: block; font-weight: bold; text-align: right; padding: 5px 0 4px;
			select { width: 348px; padding: 1px 0; margin: 0 0 0 1px; border: 1px solid #a5acb2;

			}

		}
				
		a.submit { margin: 7px 0 0 100px; }

	}

	.criteria-wrapper { 
		a.promo { text-decoration: none; color: #525252; }
        .dc-side-promo { float: right;
        	.teaserimage { float: left; }
        	.content { width: 240px; float: left; padding: 5px 30px 10px 10px; 
				h3 {color: #cb0c0f; font-weight: bold; font-size: 1.2em;}
			}
        }

	}

	.placeholder-quickresults { width: 960px; overflow: hidden; padding: 10px 0 0 8px; @include border-radius( 5px 5px 0 0 );
		.sublayout-etquickresults { float: left; width: 305px; margin: 12px 0 0 9px; @include border-radius( 5px 5px 0 0 );
			h1, h2 { @include border-radius( 5px 5px 0 0 ); color: #fff; background: url(/App_Themes/Default/Images/Downloads-Activities/quick-results-header-bg.gif) repeat-x scroll center bottom #a3a3a3; font-weight: bold; padding: 7px 10px 6px; }

			ul { min-height: 150px; padding: 0 0 18px; background: url(/App_Themes/Default/Images/Downloads-Activities/quick-results-border.gif) no-repeat scroll center top transparent;
				li { padding: 8px; margin: 0 1px; line-height: 2em;
					&:nth-child(odd) { background: #fff; }

					&:nth-child(even) { background: #f2f2f2; }

				}

			}

		}

	}

	.sublayout-etdownloadssearchresults-listing { width: 932px; margin: 12px 0 0; background: #fff; margin: 18px 7px 0 17px; line-height: 1.4em;
		colgroup {
			col {
				&.column-name { width: 670px; }

				&.column-version { width: 128px; border-left: 1px solid #999; }

				&.column-lastupdate { width: 125px; border-left: 1px solid #999; }

				&.column-downloaded { width: 125px; border-left: 1px solid #999; }

			}

		}

		thead {
			tr {
				th { padding: 6px 10px; font-weight: bold; background: url(/App_Themes/Default/Images/Downloads-Activities/heading-bg.gif) repeat-x scroll center bottom #a3a3a3; border-bottom: solid 1px #fff; color: #fff;
					&:first-child { @include border-radius(5px 0 0 0); }

					&:last-child { @include border-radius(0 5px 0 0); }
				
				}

				.column-version { text-align: center; }
				
				.column-lastupdate { text-align: center; }

				.column-downloaded { text-align: center; }
			}

		}
				
		tbody { border-bottom: solid 1px #fff;
			tr { background: #fff; color: #525252;
				th,
				td { padding: 8px 10px; }

				th {
					a { font-weight: bold; }
				}

				.column-version { text-align: center; }
				
				.column-lastupdate { text-align: center; }

				.column-downloaded { text-align: center; }

				&:nth-child(odd) { background: #f2f2f2; }

				&:first-child { background: #d6d6d6; color: #6e6e6e;
					th { padding: 2px 10px 3px;
						a { color: #6e6e6e; font-weight: normal; display: block;
							.arrow { display: none; border: 4px solid transparent; line-height: 0; width: 0; height: 0; }

						}

					}

				}

			}

			&.collapsed {
				tr { display: none;
					&:first-child { display: table-row;
						th {
							a {
								.arrow { display: inline-block; border-left-color: #c00; vertical-align: baseline; margin: 0 2px; }

							}
						}

					}

				}

			}

			&.expanded {
				tr {
					&:first-child {
						th {
							a { font-weight: bold;
								.arrow { display: inline-block; border-top-color: #c00; vertical-align: bottom; margin: 0 4px 0 0; }

							}

						}

					}

				}

			}

		}

	}
	
	.section-nspire-update {
		padding-left: 20px; 
		strong { font-weight: bold; 
			a { font-weight: normal; }
		}
		#update-progress-outer {
			text-align: center;
			white-space: nowrap;
			#update-progress {
				margin: 0 auto;
				display: inline-block;
				#update-progress-prefix,
				#update-progress-suffix {
					display: inline-block;
					width: 200px;
					margin: 0;
					padding: 0;
					font-weight: bold;
					line-height: 27px; 
				}
				#update-progress-prefix {
					text-align: right; 
				}
				#update-progress-suffix {
					text-align: left; 
				}
				span {
					color: #979797;
					vertical-align: top; 
				}
				#update-progress-bar {
					display: inline-block;
					width: 509px;
					height: 16px !important;
					padding: 6px 0 5px;
					background: url(/App_Themes/Default/Images/Downloads-Activities/progress-bar-outer.gif) scroll 0 0 no-repeat transparent;
					position: relative;
					margin: 0 5px 8px;
					span {
						display: block;
						height: 16px;
						background: url(/App_Themes/Default/Images/Downloads-Activities/progress-bar-inner.gif) scroll 9px 0 no-repeat transparent;
						position: absolute;
						left: -2px;
						top: 6px;
						padding: 0 1px 0 2px; 
					}
				}
				 #update-sections {
					overflow: hidden;
					width: 495px;
					list-style-type: none;
					padding: 0 0 5px;
					margin: -4px auto 0;
					#update-software,
					#update-handheld {
						float: left;
						text-align: center;
						border-left: solid 1px #636363;
						border-right: solid 1px #636363;
						background: url(/App_Themes/Default/Images/Downloads-Activities/progress-label-bg.gif) 0 center repeat-x scroll transparent;
						span {
							font-weight: normal;
							display: inline-block;
							color: #636363;
							background: #fff;
							padding: 2px 7px 0;
							text-transform: uppercase;
							line-height: 12px;
							margin: 0 0 0 -2px;
							white-space: normal; 
						}
					}
					#update-software {
						clear: left;
						width: 246px;
						margin-right: 1px; 
					}
					#update-handheld {
						width: 244px; 
					}
				}
			}  
		}
		
		.os-button, .os-up-button, .os-down-button {
			height: 30px;
			overflow: hidden;
			line-height: 30px;
			border: 1px #ddd solid;
			border-bottom: 1px #999 solid;
			position: relative;
			display: inline-block;
			background: url("/App_Themes/Default/Images/Downloads-Activities/os-button-bg.png") repeat-x;
			a {
				padding: 0 15px;
				color: #555;
				font-size: 16px;
				&:link,
				&:visited,
				&:active,
				&:hover,
				&:hover {
					text-decoration: none;
				}
			}
			 
		}
		.os-down-button {
			a {
				line-height: 30px;
				display: inline-block;
				height: 30px;
				padding-left: 40px;
				background: url("/App_Themes/Default/Images/Downloads-Activities/os-down-button-bg.png") 1px 1px no-repeat; 
			}
		}
		.os-up-button {
			a {
				line-height: 30px;
				display: inline-block;
				height: 30px;
				padding-left: 40px;
				background: url("/App_Themes/Default/Images/Downloads-Activities/os-up-button-bg.png") 1px 1px no-repeat; 
			}
		}
	}

}
