﻿.sublayout-etmylanding { width: 960px; overflow: hidden;
	.etmysection-list { width: 954px; min-height: 380px; overflow: hidden; padding: 2px 0 10px 17px;
		
		&.sections-2{
			.sublayout-etmysection{ width: 446px;
				.etmysection-link{ width: 446px;
					&:link,
					&:visited{
						.etcontent-upper{ width: 446px;
							.etmaincontent { width: 361px; }
						}
					}
				}
			}
		}
		.sublayout-etmysection { float: left; margin: 0 0 7px; padding: 12px 15px 10px; width: 286px; min-height: 290px; overflow: hidden; border-left: 1px solid #6d6b6c;
			&:first-child { border-left: 0; }

			.etmysection-link { width: 286px; overflow: hidden;
				&:link,
				&:visited { color: #525252;
					.etpageheading { font-weight: bold; padding: 0 25px 1px 0; font-size: 1.2em; display: inline-block; }

					.etcontent-upper { width: 286px; overflow: hidden; position: relative; padding: 55px 0 16px;
						.etpagesubheading { clear: both; position: absolute; top: 7px; left: 0; }

						.etteaserimage { float: left; width: 60px; padding-right: 15px; }

						.etmaincontent { float: left; width: 201px; padding-right: 10px; min-height: 74px; }

					}
				}

				&:hover,
				&:active,
				&:focus { text-decoration: none;
					.etpageheading { color: #c1272d; background: url(/App_Themes/Default/Images/General/chevrons.png) scroll right 3px no-repeat transparent; } 
				}

			}

			.etcontent-lower { border-top: 1px solid #6d6b6c; padding: 10px;
				.etdictionary-myrecentdownload,
				.etdictionary-myrecentsave { font-weight: bold; padding: 7px 0 5px; }

				.etrecentdownloads-list { margin: 0 -10px;
					.etrecentdownloads-listitem { padding: 7px 10px; line-height: 1.4em;
						&:nth-child(even) { background: #f2f2f2; }

					}

				}

			}

			

		}

	}

}