﻿.etcallout-style-products {
	.sublayout-etcalloutstackgroup {
		> .scLooseFrameZone { overflow: visible; }
	}

	.sublayout-etcalloutstack {
		h1 { color: #af1200; font-weight: bold; font-size: 1.1em; }

		.etcalloutstack-list {
			.etcalloutstack-listitem { margin: 0 0 16px 0; }

			.etcalloutstack-editlistitem {
				.webedit-helper { display: block; text-align: center; margin: 4px; }

			}

		}

		> .scLooseFrameZone { overflow: visible; }

	}

	.etcallout-image {
		a { display: block;
			img { display: block; margin: 0 auto; }

		}

		.etslideshow-inner-1 { display: table-cell; vertical-align: middle;
			.etslideshow-inner-2 { margin: -10px auto 0; }

		}

	}

	.etcallout-content { text-align: left;
		section { background: url(/App_Themes/Default/Images/Callout/content-background.gif) no-repeat scroll 0 0 #f5f5f5; @include background-size( 100% 100% ); @include border-radius( 5px 5px 0 0 ); padding-bottom: 10px;
			a,
			a:link,
			a:visited {
				header { position: relative; margin: 0 0 10px; border: 10px solid #e4e4e4; @include border-image( url(/App_Themes/Default/Images/Callout/content-header-border.gif) 10 10 10 10 repeat stretch ); padding: 4px 0 3px; background: url(/App_Themes/Default/Images/Callout/content-header-background.gif) repeat-x scroll 0 0 #e4e4e4; @include background-size( contain ); @include border-radius( 5px 5px 0 0 );
					.hgroup {
						h1 { font-weight: bold; text-align: center; color: #cb0c0f; font-size: (18em/12); }

						h2 { font-weight: bold; text-align: center; color: #cb0c0f; font-size: (15em/12); margin-top: .5em; }

					}

				}

				.content { color: #036; padding: 0 13px; line-height: 1.6em; }

			}

			a:link,
			a:visited {
				&:focus,
				&:active,
				&:hover { text-decoration: none; }

			}

			.ethyperlinklist { padding: 5px 14px 8px;
				nav { padding: 3px 0 0;
					select { max-width: 100%; padding: 1px; border: inset 1px #f0f0f0; }

					ul {
						li {
							a { display: block; padding: 0 0 0 11px; line-height: 14px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 0 3px transparent; }

							a:link,
							a:visited {
								&:focus,
								&:active,
								&:hover { text-decoration: underline; }

							}
						}

					}

				}

				> .scLooseFrameZone { overflow: visible; }

				.webedit-helper { display: block; text-align: center; margin: 4px; }
			}

			> .scLooseFrameZone { overflow: visible; }

		}

	}

	.etcallout-productenhancement { text-align: left;
		section { background: url(/App_Themes/Default/Images/Callout/content-background.gif) no-repeat scroll 0 0 #f5f5f5; @include background-size( 100% 100% ); @include border-radius( 5px 5px 0 0 ); @include clearfix; padding-bottom: 10px; padding-left: 5px;
			header { margin: 0 0 10px -5px; border: 10px solid #e4e4e4; @include border-image( url(/App_Themes/Default/Images/Callout/content-header-border.gif) 10 10 10 10 repeat stretch ); padding: 4px 0 3px; background: url(/App_Themes/Default/Images/Callout/content-header-background.gif) repeat-x scroll 0 0 #e4e4e4; @include background-size( contain ); @include border-radius( 5px 5px 0 0 );
				h1 { font-weight: bold; text-align: center; color: #cb0c0f; font-size: (18em/12); }

				> .scWebEditInput { display: block; }

			}

			.etproductenhancement { position: relative; float: left; margin: 0 5px;
				a { display: block; width: 80px; padding-top: 100px; text-align: center;
					h2 { color: #1973b4; font-size: 1em; font-weight: normal; }

					img { position: absolute; top: 0; left: -1px; border: solid 1px #e2e7ec; }

				}

			}

			> .scLooseFrameZone { display: block; overflow: hidden; margin-left: -5px; padding-left: 5px; }

			.webedit-helper { display: block; text-align: center; margin: 4px 4px 4px -1px; }

		}

	}

	.etcallout-content {
		.teaserimage-top {
			.etcallout-body {
				.etcallout-teaserimage {
					img { display: block; margin: 0 auto; }

				}

			}

		}

		.teaserimage-right {
			.etcallout-body { position: relative;
				.content { margin-right: 53px; padding-right: 0; }

				.etcallout-teaserimage { position: absolute; right: 13px; top: 0; }

			}

		}

		.teaserimage-bottom {
			.etcallout-body { position: relative;
				.etcallout-teaserimage {
					img { display: block; margin: 0 auto; }

				}

			}

		}

		.teaserimage-left {
			.etcallout-body  { position: relative;
				.content { margin-left: 53px; padding-left: 0; }

				.etcallout-teaserimage { position: absolute; left: 13px; top: 0; }

			}

		}

	}

}
