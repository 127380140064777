﻿html {
	@include rtl { direction: rtl; }
}

#formDealer,
.layout-wrapper { position: relative; margin: 0 0 0 10px; z-index: 100;
	#header-site,
	#simple-header-site {  background: transparent url(/App_Themes/Default/Images/Frame/header.png) 0 0 scroll no-repeat; position: relative; z-index: 99;
		ul { list-style-type: none; }

		a {
			&,
			&:link,
			&:visited { text-decoration: none; color: #fff;
				&:hover { text-decoration: underline; color: #fff; }

			}
		}

		.scLooseFrameZone { display: inline-block; overflow: visible; }

		#site-logo { display: inline-block;
			@include rtl { margin-right: -15px; }
			.hgroup { color: #fff; padding: 19px 20px 0; position: relative; white-space: nowrap; text-decoration: none; background-repeat: no-repeat; background-position: 15px 13px; min-height: 0; @include clearfix;
				@include rtl { background-position: right 13px; }

				h1 { float: left; font-size: 1.6em; font-variant: small-caps; font-weight: bold; padding: 0; margin: 0 0 0 -16px; letter-spacing: -.2mm; visibility: hidden;
					@include rtl { float: right; }
				}

				h2 { float: left; width: 195px; height: 33px; border-left: solid 1px #e18a81; margin: -6px 0 0 0; padding: 13px 0 0 29px; font-size: 1.7em; top: 0; font-weight: normal;
					@include rtl { float: right; border-left: 0; border-right: solid 1px #e18a81; padding: 13px 19px 0 0; margin-right: 10px; }
					&:lang(zh-CN){font-size: 1.7em; font-weight: bold;}
				}

				h3 { width: 224px; font-size: 0.75em; letter-spacing: -.05mm; visibility: hidden; position: absolute; left: 19px; top: 48px; }

			}

		}

	}

	#header-site { height: 101px;
		#search-criteria-site { position: absolute; right: 1px; top: 25px; width: 200px; height: 21px; overflow: hidden;
			@include rtl { left: 12px; right: auto; }
			&:lang(zh-CN) { top: 16px; height: 30px; }

			input[type="search"],
			input[type="text"] { -webkit-appearance: textfield; display: block; float: left; border: 1px inset #bababa; width: 107px; height: 13px; margin: 0 2px 0 0; padding: 2px; vertical-align: top;
				@include rtl { float: right; }
				&:lang(zh-CN) { height: auto; }
			}

			input[type="submit"] { display: block; float: left; width: 80px; height: 20px; padding: 0 0 1px; border: 0; cursor: pointer; font-weight: bold; text-align: center; color: #fff; background: url(/App_Themes/Default/Images/Frame/search-button.gif) no-repeat scroll 0 0 #6997B9;
				&:lang(zh-CN){margin-top: 2px; line-height: 1em;}
				&:hover,
				&:focus { text-decoration: underline; }

			}

			.scLooseFrameZone { vertical-align: top; }
		}

		#navigation-account { position: absolute; right: 4px; top: 51px; font-size: .9em; height: 1.2em;
			@include rtl { left: 7px; right: auto; }
			&:lang(zh-CN){font-size: 1em;}
			ul { height: 1.2em; overflow: hidden;
				li { float: left; border-left: 1px solid #bababa; padding: 0 5px 0 8px; line-height: 1em;
					@include rtl { float: right; }
					&:first-child { border-left: 0;
						@include rtl { border-left: 1px solid #bababa; }
					}

					&:last-child {
						@include rtl { border-left: 0; }
					}

				}

			}

		}

		#navigation-account-wrapper { position: absolute; right: 4px; top: 51px;
			@include rtl { left: 4px; right: auto; }
			.scLooseFrameZone { height: 12px; display: block; }

			#navigation-account { position: static; left: 0; top: 0; }

		}


		#region-selector { color: #525252; position: absolute; right: 7px; top: 83px; z-index: 94; font-size: .9em;
			@include rtl { left: 7px; right: auto; }
			&:lang(zh-CN){font-size: 1em; top: 81px;}
			a { color: #525252; font-weight: bold; padding: 0 0 0 8px; background: url(/App_Themes/Default/Images/Frame/region-selector-arrow.gif) no-repeat scroll left 3px transparent;
				@include rtl { background: url(/App_Themes/Default/Images/Frame/region-selector-arrow-rtl.gif) no-repeat scroll right 3px transparent; padding: 0 8px 0 0; float: right; }
				&:lang(zh-CN){ background-position: 0 5px; }
			}

		}

		#navigation-primary { line-height: 26px; width: 952px; height: 26px; margin: 2px 0 0 6px; position: absolute; left: 0; top: 73px; z-index: 93;
			@include rtl { left: auto; right: 0; }
			ul { width: 952px; height: 26px; @include clearfix; z-index: 92;
				li { float: left; position: relative; height: 22px; z-index: 91;
					@include rtl { float: right; }
					a { display: block; padding: 0 8px 0 11px; margin: 0 1px 0 0; color: #525252; font-weight: bold; text-decoration: none; white-space: nowrap; background: url(/App_Themes/Default/Images/Frame/navigation-primary-border.gif) no-repeat scroll left top transparent; cursor: default;
						&:link,
						&:visited { cursor: pointer; }

						&.selected,
						&:hover,
						&:active,
						&:focus { background-color: #fff; color: #1973b4; }

					}

					&:first-child a { background-image: none;
						@include rtl { background-image: url(/App_Themes/Default/Images/Frame/navigation-primary-border.gif); }
					}

					&:last-child a {
						@include rtl { background-image: none; }
					}

					nav { background: #e2e2e2; display: none; position: absolute; left: 0; top: 26px; z-index: 90; border: solid 1px #bababa; border-top: 0;
						@include rtl { left: auto; right: 0; }
						ul { width: auto; height: auto; @include unclearfix;
							li { float: none; position: static; border-top: solid 1px #bababa; height: auto; margin: 0;
								@include rtl { float: none; }
								a { color: #1973b4; font-weight: normal; height: auto; line-height: 22px; padding: 2px 6px; background: none transparent;
									&:first-child {
										@include rtl { background-image: none; }
									}

									&:link,
									&:visited {
										&:hover,
										&:active,
										&:focus { background: #f7f7f7; }
									}

								}

							}

						}

					}

					&:hover {
						nav { display: block; }

					}

				}

			}

		}

		#navigation-primary-wrapper { width: 960px; position: absolute; left: 0; top: 73px;
			.scLooseFrameZone { height: 28px; display: block; }
			#navigation-primary { left: 0; top: 0;
				ul li {
					a {}

					.wrapper-navigation { position: absolute; left: 0; top: 25px; visibility: hidden; z-index: 90;

						.scLooseFrameZone { height: auto; }

						nav { display: block; position: relative; left: 0; top: 0; }

					}

				}

			}

		}

	}

	#header-site-mobile { position: relative; border-bottom: solid 5px #cb0c0f; overflow: hidden;
		$logo-width: 631px;
		$logo-height: 80px;
		$logo-aspect: $logo-width / $logo-height;
		$logo-margin-top: 1.35%;
		$logo-margin-right: 16px;
		$logo-margin-bottom: 1.35%;
		$logo-margin-left: 21px;

		$hamburger-color: #525252;
		$hamburger-stroke-width: 4px;
		$hamburger-stroke-spacing: 9px;
		$hamburger-padding-horizontal: 23px;
		$hamburger-height: $hamburger-stroke-spacing * 2 + $hamburger-stroke-width * 3;
		$hamburger-width-inner: 42px;
		$hamburger-width-outer: $hamburger-width-inner + $hamburger-padding-horizontal * 2;

		$media-mobile-max: 767px;

		#site-logo-mobile { float: left; display: block; overflow: hidden; text-indent: -9999px; background: transparent none center center scroll no-repeat; width: calc(100% - #{$hamburger-width-outer + $logo-margin-left + $logo-margin-right}); margin: $logo-margin-top $logo-margin-right $logo-margin-bottom $logo-margin-left; height: $logo-height; background-size: contain; }

		.hamburger { display: table-cell; height: $logo-height; padding: $logo-margin-top $hamburger-padding-horizontal $logo-margin-bottom; margin: 0; vertical-align: middle; float: left; border: 0 none; @include transition(background-color .5s ease); background: transparent url(/App_themes/Default/Images/spacer.gif) 0 0 scroll repeat; cursor: pointer; position: relative; z-index: 10; overflow: visible;
			i { display: block; position: relative; width: $hamburger-width-inner; border-top: solid $hamburger-stroke-width $hamburger-color; border-bottom: solid $hamburger-stroke-width $hamburger-color; padding: $hamburger-stroke-spacing 0;
				&:before { content: ""; display: block; line-height: 0; height: 0; border-bottom: solid $hamburger-stroke-width $hamburger-color; }

			}

		}

		.hamburger-menu { max-height: 0; padding: 8px; clear: both; position: relative; z-index: 5; margin: -16px 0; @include transition(all .5s ease); clear: both;
			#navigation-primary li a { display: block; text-align: right; padding: 4px; font-size: 140%; line-height: 2; }

			:link,
			:visited { color: #525252; text-decoration: none;
				&.selected,
				&:active,
				&:hover { color: #cb0c0f; }
			}
		}

		.hamburger.expanded { background-color: #d2d2d4;
			~ .hamburger-menu { max-height: 500px; margin: 1.35% 0 -1.35%; background: #d2d2d4; }
		}

	}

	#simple-header-site{ height: 73px; }

	.section-body { font-family: Arial, Helvetica, sans-serif;
		&:lang(zh-CN){ font-family: Arial, STXihei, 华文细黑, 宋体, SimSun, Helvetica, sans-serif;}
	}

	#footer-site, #simple-footer-site { clear: both; margin: 9px 0 0; padding: 11px 0 3px; background: url(/App_Themes/Default/Images/Frame/footer-border.gif) scroll no-repeat 0 0 transparent; @include clearfix;
		#navigation-utility { margin: 0 0 12px;
			ul { @include clearfix;
				li { float: left; position: relative; padding: 0 5px 0 6px; border-left: solid 1px #525252;
					@include rtl { float: right; }

					&:first-child { padding-left: 0; border-left: 0;
						@include rtl { padding-left: 6px; border-left: solid 1px #525252; }
					}B

					&:last-child {
						@include rtl { padding-left: 0; border-left: 0; }
					}

					a { display: block; color: #1973b4; cursor: default; line-height: 11px; white-space: nowrap;
						&:link,
						&:visited { cursor: pointer; }

					}

					&.has-nav {
						a { background: url(/App_Themes/Default/Images/Frame/navigation-utility-arrow.gif) scroll no-repeat 1px 2px transparent; padding-left: 8px;
							@include rtl { background: url(/App_Themes/Default/Images/Frame/navigation-utility-arrow-rtl.gif) scroll no-repeat right 2px transparent; padding-left: 0; padding-right: 8px; float: right; }
						}
						nav {
							a { background: none transparent; padding-left: 0;
								@include rtl { background: none transparent; padding-right: 0; float: none; }
							}
						}
					}

					nav { background: #e2e2e2; display: none; position: absolute; left: 10px; bottom: 11px; z-index: 90; border: solid 1px #bababa; border-bottom: 0;
						@include rtl { left: auto; right: 10px; }
						ul { @include unclearfix;
							li { float: none; position: static; border-left: 0; border-bottom: solid 1px #bababa; height: auto; padding: 0; margin: 0;
								@include rtl { float: none; }
								&:first-child {
									@include rtl { padding-left: 0; border-left: 0; }
								}

								a { line-height: 14px; padding: 2px 6px; background: none transparent;
									&:link,
									&:visited {
										&:hover,
										&:active,
										&:focus { background: #f7f7f7; }

									}

								}

							}

						}

					}

					&:hover {
						nav { display: block; }

					}

				}

			}

		}

		#navigation-utility-wrapper {
			.scLooseFrameZone { overflow-y: visible; }
			#navigation-utility {
				ul li {
					a {}

					.wrapper-navigation { position: absolute; left: 0; bottom: 11px; visibility: hidden; z-index: 90;

						.scLooseFrameZone { height: auto; }

						nav { display: block; position: relative; left: 0; top: 0; }

					}

				}

			}

		}

		#copyright { display: inline-block; vertical-align: top; margin: -1px 20px 12px 0; }

		#navigation-legal { display: inline-block; vertical-align: top; overflow: hidden; padding: 0 0 5px;
			a { display: inline-block; padding: 0 11px 0 6px; border-left: solid 1px #525252; color: #1973b4; cursor: default; line-height: 11px; white-space: nowrap; margin: 0 0 1em -5px;
				&:link,
				&:visited { cursor: pointer; }

				&.region-us,
				&.region-uk { padding-left: 30px; }
			}

		}


	}

}

#formDealer,
#layout-default { margin: 0 auto; font-size: 1.2em;
	@include rtl { margin: 0 10px 0 0; }
}


#layout-responsive { width: 100%; max-width: 960px; font-size: 1.2em;
	@media (max-width: 767px) {
		#footer-site { font-size: 1.5em;
			#navigation-legal,
			#navigation-utility,
			#navigation-account { float: none;
				a { display: block; padding: 10px; margin: 0 0 0 -10px; border: 0 none; line-height: 1; }

			}

			.column-1,
			.column-2,
			.etsocialmediahyperlink-list { float: none; }
		}
	}
}

#messageBar-container { z-index: 100; position: fixed; top: 0; left: 0; width: 100%; min-height: 24px; background: #ffffe1; border-bottom: #222 1px solid; font-size: 1.2em;
	#messageBar-close { position: absolute; z-index: 99; top: 3px; right: 5px; width: 16px; height: 16px; background: url(/App_Themes/Default/Images/Frame/messageBar-icons.gif) no-repeat -35px 0px; }

	#messageBar-icon {  position: absolute; z-index: 99; top: 3px; left: 5px; width: 16px; height: 16px; background: url(/App_Themes/Default/Images/Frame/messageBar-icons.gif) no-repeat 0px 0px; }

	#messageBar-link { display: block; padding: 5px 30px; width: auto; color: #222; font-size: (12em/10.8);
		&:hover { text-decoration: none; }

	}

}

html .debug { display: none; }

html.debug {
	.debug { display: block; }
	table.debug { margin: 4em 0 1em 0; font-size: 9px; border: solid 1px #aaa; color: #aaa; padding: 4px;
		th { font-weight: bold; }
		td { padding: 0 4px; }
	}
}

.region-us {
	padding-left: 24px;
	background: url(/App_Themes/Default/Images/us-flag-mini.gif) left center no-repeat;
}

.region-uk {
	padding-left: 24px;
	background: url(/App_Themes/Default/Images/uk-flag-mini.gif) left center no-repeat;
}

// Themes for the site
.site-theme-thinredline{
	body{ padding-bottom: 10px;}
	&,& body{
		background: #f8f8f8;
	}

	#formDealer,
	.layout-wrapper {
		background: #fff;
		@include box-shadow( 0px 0px 20px rgba(50,50,50,0.75) );
		margin: 0 auto ;

		@include rtl { margin: 0 auto; }
		#header-site, #simple-header-site{
			border-top: 3px solid #898989;
			background: #fff;
			color: #888;
			height: 97px;

			#site-logo{
				@include rtl { margin-right: 0px; }
				.hgroup{
					padding: 0 0 0 18px;
					height: 64px;
					color: #888;
					background-position: 18px center;

					@include rtl { background-position: right center; margin-right:18px; padding: 0; }

					h1{

						@include rtl { margin: 0; }
					}
					h2{
						padding: 0 0 0 30px;
						width: auto;
						margin: 0;
						background: url(/App_Themes/Default/Images/thin-red-line/bg-hgroup-left-border.png) left center no-repeat;
						border-left: none;
						font-size: 1.5em;
						height: 64px;
						line-height: 64px;

						@include rtl { float: right; border-left: none; border-right: none; background-position: right center; padding: 0 30px 0 0; }
					}
				}

			}

			#region-selector{
				color: #555;
				right: 18px;
				top: 67px;
				font-size: 1em;
				line-height: 30px;
				height: 30px;

				@include rtl { left: 18px; right: auto; }

				a{
					color: #555;
					background: none;
					padding: 0;
				}
			}

			#search-criteria-site{
				top: 27px;
				width: auto;
				right: 0px;
				padding-right: 18px;

				@include rtl { left: 0px; right: auto; padding: 0 0 0 18px; }

				input[type="submit"]{
					width: 24px;
					height: 20px;
					padding: 0;
					font-size: 0em;
					text-indent: -9999px;
					text-transform: capitalize;
					text-align: left;
					background: url(/App_Themes/Default/Images/thin-red-line/search-button.png) right center no-repeat;
					@include rtl { float: right; background-image: url(/App_Themes/Default/Images/thin-red-line/search-button-ar.png);}
				}

				input[type="text"], input[type="search"]{
					margin: 0;
					width: 130px;
					@include rtl { float: right; }
				}
			}

            #header-navigation-account { display: inline-block; vertical-align: top; padding: 7px 7px 0 0; overflow: hidden; float: right; width: auto;
				a { display: inline-block; line-height: 1.2em; padding: 0 10px 0 6px; border-left: 1px solid #bababa; margin: 0 0 1em -5px; white-space: nowrap; cursor: default; color: #1973b4;
					&:link,
					&:visited { cursor: pointer; }
					&.region-us,
					&.region-uk { padding-left: 30px; }
				}
			}

			#navigation-primary-wrapper { top: 64px;
				#navigation-primary { top: 0; }

			}

			#navigation-primary { border-top: 3px solid #e3020a; margin: 0; width: auto; height: 30px; line-height: 30px; top: 64px; background: url(/App_Themes/Default/Images/thin-red-line/bg-primary-nav.png) left center repeat-x; left: 0; right: 0;

				ul {
					width: 942px;
					height: 30px;
					li{
						height: 30px;
						&:first-child a{
							background-image: none;
							padding-left: 18px;
							@include rtl { padding: 0 18px 0 9px; }
						}
						a{
							color: #555;
							background: url(/App_Themes/Default/Images/thin-red-line/bg-primary-nav-item-border.png) left center no-repeat;
							padding: 0 9px 0 11px;
							@include rtl { padding: 0 11px 0 9px; font-weight: normal }

							&.selected,
							&:hover,
							&:active,
							&:focus{
								background-color: #fff;
								color: #e3020a;
							}
						}
						nav {
							background: #e2e2e2;
							position: absolute;
							left: 0;
							top: 30px;
							border: solid 1px #bababa;
							border-top: 0;

							@include rtl { left: auto; right: 0; }

							ul{
								width: auto;
								height: auto;
								@include unclearfix;
								li {
									height: auto;

									@include rtl { float: none; }
									a{
										&, &:first-child{
											padding: 2px 6px;
											@include rtl { padding: 2px 6px; }
										}
										background: none;
									}
								}
							}
						}
					}
				}
			}
		}

		#simple-header-site{
			height: 64px;
			border-bottom: 3px solid #e3020a;
		}

		#footer-site, #simple-footer-site{
			overflow: hidden;
			margin: 0 0 20px 0;
			border-top: 1px solid #888;
			background: none;
			padding: 10px 18px 20px 18px;

			.column-1{
				@include rtl {float:right;}
				float: left;
				width: 610px
			}
			.column-2{
				@include rtl {float:left;}
				float: right;
				width: 310px;
			}
			#copyright{ margin-bottom: 10px; margin-top: 0px; line-height: 1.2em;
				@include rtl{ direction: ltr; padding-right: 0px; margin-right: 0px;}
			}
			#navigation-legal, #navigation-utility{ ul li a{ line-height: 1.2em; } }

			#navigation-account { display: inline-block; vertical-align: top; padding-bottom: 10px; overflow: hidden; float: right; width: auto; margin-right: -8px;
				a { display: inline-block; line-height: 1.2em; padding: 0 10px 0 6px; border-left: 1px solid #bababa; margin: 0 0 1em -5px; white-space: nowrap; cursor: default;
					&:link,
					&:visited { cursor: pointer; }

					&.region-us,
					&.region-uk { padding-left: 30px; }

				}

			}
			.etsocialmediahyperlink-section { @include clearfix; overflow: hidden; width: 100%;
				@include rtl{  }
				h1 { float: left; color: #af1200; font-size: 1.1em; font-style: italic; line-height: 41px; margin: 0 16px 0 0;
					@include rtl { float: right; }
				}


				.etsocialmediahyperlink-list { float: right; margin-right: -8px; @include clearfix;
					@include rtl{ margin-right: 0px; margin-left: -7px;  float: left;}
					.etsocialmediahyperlink-listitem { float: left; margin: 0 8px 0 7px;
						a { display: block; }

					}

					.etsocialmediahyperlink-editlistitem { float: left;
						a { display: block; }

					}

				}

				> .scLooseFrameZone {
					> .scWebEditInput { display: block; float: left; }

				}
			}
		}
	}

	&.debug {
		table.debug { margin: 2em 18px 0 18px; font-size: 9px; border: none; color: #888; padding: 0 0 1em 0; direction: ltr; @include clearfix; border-collapse: collapse;
			th { font-weight: bold; }
			td { padding: 0; }
		}
	}
}
