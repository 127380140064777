﻿.sublayout-etdownloadbundle,
.sublayout-etdownloadssearchresults,
.sublayout-etmylanding,
.sublayout-etmyactivitieslisting{
	font-size: 1.111em;
	line-height: 1.4em;
	min-height: 444px;
	padding-bottom: 20px;

	input, textarea, select, button { font-size: 1em; }
}
