﻿.sublayout-generic-content #column-1{
	padding: 0px 20px 15px 15px;
  }
  .etfrenchvppform {
	font-size: 14px;
	font-family: lato;
	width: 900px;
	overflow: hidden;
	img{
	  margin-bottom: 10px;
	}
	.content {
	  font-size: 14px;
	  line-height: 1.4em;
	}
	.nobr {
	  white-space: nowrap;
	}
	.screen {
	  display: block;
	  clear: both;
	  padding: 0px 0px;
	}
	header {
	  padding: 0 30px 15px 0;
	  h2 {
		color: #c00;
		font-family: Lato-Light;
		font-weight: normal;
		font-size: 26px;
		display: block;
		clear: both;
	  }
	  h4 {
		color: #333;
		font-size: 14px;
		font-family: lato-Bold;
		display: block;
	  }
	  p {
		font-size: 14px;
		line-height: 1.4em;
	  }
	  .progressdiv {
		display: block;
		clear: both;
		padding-bottom: 100px;
		.progressbar {
		  counter-reset: step;
		  li {
			list-style-type: none;
			width: 10%;
			float: left;
			font-size: 12px;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			color: #aaaaaa;
			&:before {
			  width: 30px;
			  height: 30px;
			  content: counter(step);
			  counter-increment: step;
			  line-height: 30px;
			  border: 2px solid #aaaaaa;
			  display: block;
			  text-align: center;
			  margin: 0 auto 10px auto;
			  border-radius: 50%;
			  background-color: white;
			}
			&:after {
			  width: 100%;
			  height: 2px;
			  content: "";
			  position: absolute;
			  background-color: #aaaaaa;
			  top: 15px;
			  left: -50%;
			  z-index: -1;
			}
  
			&.active {
			  color: #00bbcc;
			  &:before {
				background: #00bbcc;
				border-color: #00bbcc;
				color: #fff;
			  }
			  &:after {
				background-color: #00bbcc;
			  }
			}
  
			&.completed {
			  color: #115566;
			  &:before {
				background: #115566;
				border-color: #115566;
				color: #fff;
			  }
			  &:after {
				background-color: #115566;
			  }
			}
  
			&:first-child:after {
			  content: none;
			}
		  }
		}
	  }
	}
	input {
	  &.hasDatepicker {
		background: #fff url(/assets/images/input-calendar.png) right 2px center
		  no-repeat;
	  }
	}
  
	h3 {
	  color: #333;
	  border-bottom: 0.2em solid #bebebe;
	  padding: 0.5em 0.7em 0.4em 0.9em;
	  margin: 0 0 12px;
	  font-size: 20px;
	  font-family: lato-Regular;
	  background: #eee;
	}
	h4 {
	  color: #333;
	  font-size: 14px;
	  font-family: lato-Bold;
	  display: block;
	}
  
	.asterisk {
	  color: #cb0c0f;
	  font-weight: bold;
	}
  
	.field {
	  @include clearfix;
	  margin: 1px 0 2px;
	  line-height: 30px;
	  input,
	  select,
	  textarea {
		border: 1px solid gray;
		border-radius: 3px;
		height: 30px;
		margin-bottom: 0px;
		width: 50%;
		padding-left: 10px;
		margin-left: 1px;
	  }
  
	  input[type="text"],
	  input[type="tel"],
	  input[type="email"],
	  textarea {
		border: 1px solid gray;
	  }
	  input,
	  select {
		float: left;
	  }
  
	  input.error,
	  select.error {
		border: 1px solid red;
	  }
  
	  input.valid,
	  select.valid {
		border: 1px solid green;
	  }
  
	  label {
		line-height: 20px;
		position: relative;
		display: block;
		font-weight: bold;
	  }
	  label.error {
		width: 200px;
		float: left;
		line-height: 30px;
		color: red;
		padding-left: 5px;
	  }
  
	  .asterisk {
		padding: 0 0 0 2px;
	  }
	}
  
	.field-annotation {
	  line-height: 30px;
	  p {
		margin-bottom: 0;
	  }
	}
  
	.field-single {
	  textarea {
		height: auto;
	  }
	  label {
		display: block;
		margin-right: 16px;
		.asterisk {
		}
	  }
	}
  
	.field-readonly {
	  input,
	  select,
	  textarea {
		border: 2px solid #eee;
		background: #eee;
		resize: none;
	  }
	}
  
	.field-checkbox,
	.field-radio {
	  line-height: 25px;
	  input {
		width: 13px;
		margin-bottom: 0;
		margin-top: 0;
		line-height: 20px;
		height: 20px;
		margin-right: 10px;
	  }
  
	  label {
		.asterisk {
		}
	  }
	  label.error {
		float: right;
		padding: 0 144px 0 0;
	  }
	}
  
	.field-dropdown {
	  label,
	  select,
	  input {
		display: block;
	  }
	}
	.field-checkbox.legal-stuff {
	  overflow: hidden;
	  margin: 10px 0;
	  input[type="checkbox"] {
		margin-right: 7px;
	  }
	  > label {
		float: left;
		width: 85%;
	  }
	  > label.error {
		width: 15%;
		position: absolute;
		right: 318px;
		line-height: 20px;
		padding: 0;
	  }
	}
	.field.field-radio.field-salutation input {
	  float: none;
	}
	input[name="email-address"] {
	  opacity: 0;
	  height: 0;
	  line-height: 0;
	  margin-left: -9999px;
	  display: block;
	}
	button {
	  margin: 10px 0;
	  width: auto;
	}
	button.submit:disabled {
	  opacity: 0.5;
	  cursor: not-allowed;
	}
	p.grey_bar {
	  font-weight: bold;
	  background-color: #eee;
	  padding: 0.5em 0.7em;
	  border-bottom: #bebebe 0.2em solid;
	}
	img.removefile {
	  cursor: pointer;
	  vertical-align: middle;
	}
	span.filename {
	  padding: 10px 0;
	  font-size: 18px;
	}
  
	.column-1 {
	  width: 550px;
	  float: left;
	}
  
	.column-2 {
	  padding: 0 0 0 15px;
	  width: 300px;
	  float: left;
	  a:link,
	  a:visited {
		display: block;
		color: #cb0c0f;
		position: relative;
		margin: 0 0 10px;
		&:after {
		  content: "";
		  display: inline-block;
		  width: 19px;
		  height: 9px;
		  background: transparent
			url(/App_Themes/Default/Images/General/chevrons.png) 0 0 scroll
			no-repeat;
		  margin: 0 0 0 5px;
		}
	  }
	  div.flexbox {
		display: flex;
		flex-direction: column;
		div.flex {
		  flex: 1;
		  text-align: center;
		  padding: 20px 0;
		}
	  }
	}
  
	.validation-message {
	  /*display: none; */
	  color: #cb0c0f;
	  font-weight: bold;
	  padding-left: 10px;    
	}
	.show-validation {
	  .validation-message {
		display: block;
	  }
	  .validation-element {
		outline: dotted 1px #cb0c0f;
	  }
	}
	.filesize-message {
	  display: none;
	}
	.invalid-message {
	  display: block;
	  color: #cb0c0f;
	  padding-left: 5px;
	}
  
	.products-summary,
	.contact-summary {
	  padding: 20px 0;
	  table {
		tr {
		  td,
		  th {
			border: 1px solid black;
			padding: 5px;
		  }
		}
	  }
	}
	.class-summary {
	  padding: 20px 0;
	  width: 650px;
	  table {
		tr {
		  th {
			background-color: #eee;
			padding: 2px 5px;
			border: 5px solid white;
			text-align: center;
		  }
		  td {
			padding: 2px 8px;
			input {
			  width: 100%;
			}
		  }
		  .class-type {
			width: 49%;
			padding: 2px 8px 2px 2px;
		  }
		  .class-column {
			width: 17%;
			input.percentage {
			  width: 80%;
			}
			span {
			  display: inline;
			  float: right;
			  font-size: 14px;
			}
		  }
		}
	  }
	}
	.content-container {
	  padding-top: 40px;
	  width: 950px;
	}
	.ui-autocomplete-input.validation-element {
	  border: red solid 3px;
	}
	.validation-element {
	  border: red solid 3px;
	}
	h3.header-section {
	  color: #034;
	  border-bottom: 0em;
	  font-size: 20px;
	  line-height: 26px;
	  font-family: lato-Regular;
	}
  }
  .ui-menu.etfrenchseedform-autocomplete {
	display: table;
	border-collapse: collapse;
	width: 270px;
	overflow: scroll;
	max-height: 230px;
	.ui-menu-item {
	  display: table-row;
	  border: solid 1px transparent;
	  .school-id,
	  .school-name {
		display: table-cell;
		vertical-align: top;
		padding: 0.4em 3px;
	  }
  
	  .school-id {
		text-align: left;
	  }
  
	  .school-name {
		text-align: right;
	  }
	}
  }
  .margin-sub-list {
	margin-left: 20px;
  }
  .diplay-standard-msg {
	background-color: #eee;
	font-weight: bold;
	font-size: 24px;
	line-height: initial;
	padding-left: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
  }
  .tabs-fold {
	color: #333;
	padding: 0.5em 0.7em 0.4em 0.9em;
	margin: 0 0 10px;
	background: #eee;
	cursor: pointer;
  }
  .tabs-fold:before {
	content: "+";
	margin-right: 20px;
  }
  .tabs-fold.open:before {
	content: "-";
	margin-right: 20px;
  }
  .bottom-discalimer {
	width: max-content;
  }
  .container {
	&.container-new {
	  background-color: #c6ecf0;
	  border-radius: 25px;
	  margin-top: 25px;
	  padding: 20px;
	  padding-left: 30px;
	  .validation-message {
		margin-bottom: 0px;   
	  }
	  .check-box-wrapper {
		.form-group.col-md-12.field.field-checkbox.legal-stuff{
		  margin: 10px 0px 0px 0px;        
		  padding: 0px;
		}
	  }
	  .etfrenchvppform{
		&.validation-message{
		  padding-right: 0px;
		  margin-bottom: 0px;
		}
	  }
	  .button-wrapper{
		text-align: center;
		.submitbutton{
		  border: solid 2px #c00;
		  left: 0px;
		  font-weight: normal;
		}
	  }
	  
	  .content p{
		font-size: 12px;
		line-height: 12px;
	  }
	  .form-group{
		input {
		  border-radius: 15px;
		}
		&.field-group{
		  padding-right: 13px;
		  &.field{
			&.field-single input[type=number]{            
			  /*max-width: 70px;
			  height: 30px;
			  border-radius: 0px;*/
			}
		  }
		  &.number-counter {
			margin-bottom: 30px;   
			width: -webkit-fill-available;   
			width: -moz-available;      
		  }
		  &.field-dropdown{                   
			&.dropdown-redius {
			  margin-bottom: 30px;   
				width: -webkit-fill-available;
				width: -moz-available;
			  input.extra-input-field{
				margin-top: 10px;
				width: -webkit-fill-available;
				width: -moz-available;
			  }         
			}                     
		  }
		  
		}
	  }    
	  .input-border-red.validation-element {
		border: #c00 solid 3px ;
		border-radius: 10px ;
	  }
	  .input-border-red.validation-element.ui-autocomplete-input {
		border: #c00 solid 3px ;
		border-radius: 10px ;
	  }
	  .field-group {
		@include clearfix;
		margin: 1px 0 2px;
		line-height: 30px;
		input,
		select,
		textarea {
		  border: 1px solid #aee4ea ;
		  border-radius: 3px;
		  height: 43px ;
		  margin-bottom: 0px;
		  width: -webkit-fill-available;
		  width: -moz-available;
		  padding-left: 10px;
		  margin-left: 1px;        
		}
		input, select{
		  border-radius: 15px;
		}
		input[type="text"],
		input[type="tel"],
		input[type="email"],
		textarea {
		  border: 1px solid #aee4ea ;
		  border-radius: 15px;
		}
		input,
		select {
		  float: left;
		}
	  
		input.error,
		select.error {
		  border: 1px solid red;
		}
	  
		input.valid,
		select.valid {
		  border: 1px solid green;
		}
	  
		label {
		  line-height: 20px;
		  position: relative;
		  display: block;
		  font-weight: bold;
		}
		label.error {
		  width: 200px;
		  float: left;
		  line-height: 30px;
		  color: red;
		  padding-left: 5px;
		}
	  
		.asterisk {
		  padding: 0 0 0 2px;
		}
	  }
	  .border-line {
		border-bottom: 1px solid;
		margin: 10px;
		padding-bottom: 5px;
		padding-top: 5px;
		margin-right: 10px ;
		color: #add;
		margin-left: 10px;
	  }
	  .sublayout-generic-content #column-1 {
		width: 645px;
		padding: 0px 20px 15px 15px;
		float: left;
	  }
	  .field-dropdown.dropdown-redius {
		label,
		select {
		  border-radius: 10px ;
		}
		input {
		  display: block;
		}
	  }
	}
  }
  @media (min-width: 992px) {
	.container {
	  &.container-new {
		max-width: 720px ;
	  }
	}
  }
  @media (max-width: 720px) {
	 
	.container-new {
	  padding-left: 30px ;
	  padding-right: 30px;
	}
  
	.field-group {
	  @include clearfix;
	  margin: 1px 0 2px;
	  line-height: 30px;
	  input,
	  select,
	  textarea {
		border: 1px solid gray;
		border-radius: 3px;
		height: 50px ;
		margin-bottom: 0px;
		width: 100% ;
		padding-left: 10px;
		margin-left: 1px;
		font-size: 16px;
	  }
  
	  input[type="text"],
	  input[type="tel"],
	  input[type="email"],
	  textarea {
		border: 1px solid #aee4ea ;
	  }
	  input,
	  select {
		float: left;
	  }
  
	  input.error,
	  select.error {
		border: 1px solid red;
	  }
  
	  input.valid,
	  select.valid {
		border: 1px solid green;
	  }
  
	  label {
		line-height: 20px;
		position: relative;
		display: block;
		font-weight: bold;
	  }
	  label.error {
		width: 200px;
		float: left;
		line-height: 30px;
		color: red;
		padding-left: 5px;
	  }
  
	  .asterisk {
		padding: 0 0 0 2px;
	  }
	}
  }
  @media (min-width: 320px) and (max-width: 480px) {
	.sublayout-generic-content #column-1{
	  width: -webkit-min-content;
	  width: -moz-min-content;
	  width: min-content;
	  padding: 0px 20px 15px 0px;
	}
	#layout-default {
	  &.layout-wrapper .main{
		width: 430px;      
	  }
	}
	.container.container-new {    
	  margin-top: 30px 0px;
	}
	
	.etfrenchvppform{
	  max-width: 400px;    
	}
	.bottom-discalimer {
	  width: 360px;    
	}
	.container {
	  &.container-new {
		max-width: 360px;
		margin: 30px 0px;
		.field-group{
		  select{
			width: 300px;          
		  }
		}
	  }
	  .container.container-new .form-group.field-group.field-dropdown.dropdown-redius {     
		max-width: 400px;      
	  }
	}   
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
	padding-top: 15px;
  }
  