﻿.page-success-story {
	.sublayout-generic-content.no-column-2 #column-1 { 
		margin: 0px 15px auto 10px;
		width: 900px;
		
	}
}
 
.sublayout-success-story {
	color: #525252;
	font-size: 16px;
	padding-top: 15px;

	.meta-information,
	.header {
		padding-left: 30px;
	}

	.meta-information {
		color: #797979;
		font-size: 18px;

		a:link,
		a:visited {
			color: #797979;
			text-decoration: none;
		}

		.divider {
			padding-left: 20px;
			padding-right: 20px;
		}

		.etsuccessstorydate {
			font-size: 12px;	
		}
	}

	.header-container {
		min-height: 260px;
		padding-top: 15px;
		position: relative;
		@include clearfix;

		.media-box {
			float: right;
			overflow: hidden;

			a {
				position: relative;
				bottom: -4px;
			}
		}

		.header {
			.etpageheading {
				color: #cb0c0f;
				font-size: 28px;
				font-weight: bold;
				line-height: 42px;
				padding-bottom: 10px;
			}

			.etpagesubheading {
				font-size: 16px;
				line-height: 24px;
				font-family: Verdana, Helvetica, sans-serif;
				padding-bottom: 15px;
			}

			.ethyperlinklist {
				.jump-list ul {
					padding-left: 20px;

					li a {
						background: #cb0c0f;
						color: #fff;
						font-size: 14px;
						font-weight: bold;
						padding: 7px 12px;
						margin: 15px 0px;
						border: 3px solid #e7e7e7;
						display: inline-block;
						@include border-radius(17px);
					}
				}
			}
		}
	}

	.story-container {
		background: url('/-/media/F16A8858F649422BA42A8821C5118180.ashx') repeat-x;
		padding-top: 20px;
		position: relative;
		@include clearfix;

		.etmaincontent {
			background: url('/-/media/4EE3BE3DF2184D8DA44D9701970BDC2F.ashx') top right no-repeat;
			font-family: Verdana, Helvetica, sans-serif;
			float: left;
			line-height: 26px;
			padding: 15px 25px 15px 30px;
			margin-right: 15px;
			width: 564px;

			h3 {
				color: #525252;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 24px;
				font-weight: normal;
				padding: 35px 0px 15px;
			}

			blockquote {
				padding: 20px 80px;

				p {
					color: #1571bc;
					font-size: 22px;
					line-height: 28px;
					margin-bottom: 15px;
				}

				.cite {
					position: relative;
					left: 130px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
		.sublayout-etcalloutstack {
			margin: 10px 20px;
			padding-top: 10px;

			.etcalloutstack-listitem {
				margin-bottom: 35px;

				.etcallout-content {
					.no-teaserimage {
						.etcallout-link {
							header {
								.hgroup h1 {
									color: #cb0c0f;
									font-family: Verdana, Helvetica, sans-serif;
									font-size: 24px;
									line-height: 30px;
									text-align: left;
								}
							}
							.etcallout-body {
								.content {
									padding-bottom: 15px;
								}
							}
						}

						.ethyperlinklist {
							.jump-list {
								ul li a {
									background: #cb0c0f;
									border: 3px solid #e7e7e7;
									@include border-radius(17px);
									color: #fff;
									display: inline-block;
									font-size: 14px;
									font-weight: bold;
									padding: 7px 12px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-device-width: 768px) {
	.sublayout-success-story {
		padding: 0px;

		.meta-information,
		.sublayout-etcalloutstack,
		.header .ethyperlinklist {
			display: none;
		}

		.header-container {
			padding-top: 0px;

			.media-box {
				float: none;
				width: 100%;

				a {
					bottom: auto;

					img {
						height: auto;
						width: 100%;
					}
				}
			}

			.header {
				padding: 15px 35px 0px;

				.etpageheading {
					font-size: 48px;
					font-weight: normal;
					line-height: 56px;
				}

				.etpagesubheading {
					font-size: 28px;
					line-height: 40px;
					padding-bottom: 0px;
				}
			}
		}

		.story-container {
			background: none;
			padding: 0px 45px 0px 5px;

			.etmaincontent {
				background: none;
				width: 100%;

				blockquote {
					.cite {
						left: 100px;
					}
				}
			}
		}
	}
}


.page-success-story-v2 {
	.sublayout-generic-content.no-column-2 #column-1 {
		margin: 0px;
		padding: 13px 0px 0px 0px;
		width: 960px; 
		
		
	} /* end of .sublayout-generic-content.no-column-2 #column-1 */
	
	.sublayout-generic-content.no-column-2 #column-2 {
		display: block;
		position: absolute;
		top: 480px;
		right: 10px;
		
		.etcalloutstack-listitem {
			margin-bottom: 20px;
			
			section {
				background: none;
				
				.etcallout-link header {
					background: none;
					border: 0px;
					border-radius: 0px;
					margin-bottom: 0px;
					
					.hgroup h1 {
						font-family: Verdana, Helvetica, sans-serif;
						font-size: 24px;
						font-weight: normal;
						text-align: left;
					}
				}
				
				.content {
					color: #525252;
					font-family: Verdana, Helvetica, sans-serif;
					font-size: 14px;
					margin-top: -10px;
					padding: 0px;
				}
				
				.ethyperlinklist nav ul li a {
					background: #cb0c0f;
					color: #fff;
					font: 14px/17px Verdana, Helvetica, sans-serif;
					font-weight: bold;
					padding: 7px 12px;
					border: 3px solid #e7e7e7;
					-ms-border-radius: 17px;
					-moz-border-radius: 17px;
					-webkit-border-radius: 17px;
					-o-border-radius: 17px;
					-khtml-border-radius: 17px;
					border-radius: 17px;
					display: inline-block;
				}
				
				.ethyperlinklist nav ul li a.success-story-textlink {
					background: none;
					border: 0px;
					color: #1571bc;
					font-family: Verdana, Helvetica, sans-serif;
					font-size: 13px;
					padding: 0px;
					
					&:before {
						color: #cb0c0f;
						content: '>\0000a0';
					}
				}
			}
		}
	} /* end of #column-2 */
} /* .page-success-story-v2 */

@media screen and (max-device-width: 768px) {
	.page-success-story {
		.sublayout-generic-content.no-column-2 #column-1 { 
			padding: 0px;
			margin: 0px;
			width: auto;
			
			.content-header h1{
				padding: 0px;
			}
			
			.sublayout-success-story {
				.meta-information { display: none; }
				
				.media-box {
					float: none;
					padding: 0px;

			img {
				width: 100%;
				height: auto;
			}

			span { display: none; }
		}

		.header, .story, .article {
			padding-left: 35px;
			padding-right: 35px;
		}

		.header {
			h2 {
				font-size: 44px;
				line-height: 56px;
			}

			p {
				font-size: 28px;
				line-height: 40px;
			}
		}

		.story, .article {
			p {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 35px;
			}
		}

		.story {
			background-image: none;
			width: auto;

			blockquote {
				font-style: normal;
				font-weight: normal;
				text-align: center;

				p {
					font-size: 26px;
					line-height: 24px;
					margin-bottom: 5px;
				}

				.cite {
					font-style: italic;
					font-size: 18px;
					line-height: 30px;
				}
			}

			h3 {
				font-size: 18px;
			}
		}

		.article {
			img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}

@media screen and (max-device-width: 768px) {
	.page-success-story-v2 {
		.sublayout-generic-content.no-column-2 #column-1 { 
			padding: 0px;
			margin: 0px;
			width: auto;
			
			.sublayout-success-story {
				.meta-information { display: none; }
				
				.media-box {
					float: none;
					padding: 0px;
				}
			}
		}
		
		.sublayout-generic-content.no-column-2 #column-2 {
			display: none;
		}
	}
}