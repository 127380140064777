@charset "utf-8";
@import "fn";
@import "reset";
@import "fonts";

.lighter { font-weight: lighter; }
.bold { font-weight: bold; }
.bolder { font-weight: bolder; }

.italic { font-style: italic; }
.oblique { font-style: oblique; }

.underline { text-decoration: underline; }
.strike { text-decoration: line-through; }
.overline { text-decoration: overline; }

@import "frame";
@import "base";
@import "content";

@import "section/default";

@import "module/default";

@import "webedit";

@import "jquery-ui.custom";

.main { overflow: hidden; width: 960px; margin: auto; }
.thin-footer { clear: both; }
