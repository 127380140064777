﻿.device-mobile {
	.sublayout-etsectiongroup-carousel {
		padding: 2.5%;
		margin: 0px auto;
		
		img { height: auto; width: 100%; }
		
		#productselect {
			border: 2px solid #cb0c0f;
			font-size: 4em;
			font-weight: bold;
			padding: 10px;
			width: 95%;
		}
		
		.scroll-wrapper {
			width: 100%;
			
			.scroller {
				.slide.selected {
					display: block; 
				}
				.slide {
					float: none;
					width: 100%;
					display: none;
					
					.right {
						float: none;
						margin: 0 auto;
						
						.sublayout-etimagemap { display: none; }
					}
					
					.left, .bottom {
						float: none;
						padding: 0px;
						width: 100%;
						
						.content {
							h1 {
								font-size: 4em;
								line-height: 1.2em;
								padding-top: 20px;
							}
							p {
								font-size: 1.6em;
								line-height: 1.6em;
							}
						}
						
						.etproductlistnav {
							ul li {
								background-image: none;
								padding-bottom: 15px;
								width: 90%;

								&:before {
									@include arrow-right(#cb0c0f,10px);
									display: inline-block;
									margin-right: 20px;
								}

								a { font-size: 2em; line-height: 1em; }
							}
						}
					}
				}
			}
		}
		
		p { font-size: 1.2em; line-height: 1.2em; }
	}
}
