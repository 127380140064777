﻿@import "lib/flexbox";

@mixin no-flexbox {
	html.no-flexbox.no-flexboxtweener.no-flexboxlegacy & { @content; }
}

@mixin has-flexbox {
	html.flexbox,
	html.flexboxtweener,
	html.flexboxlegacy { & { @content; } }
}

@mixin has-flexbox-full {
	html.flexbox & { @content; }
}

@mixin has-flexbox-tweener {
	html.no-flexbox.flexboxtweener & { @content; }
}

@mixin has-flexbox-legacy {
	html.no-flexbox.flexboxlegacy & { @content; }
}

@mixin border-radius( $radius ) { -ms-border-radius: $radius; -moz-border-radius: $radius; -webkit-border-radius: $radius; -o-border-radius: $radius; -khtml-border-radius: $radius; border-radius: $radius; }

@mixin border-image( $image ) { -ms-border-image: $image; -moz-border-image: $image; -webkit-border-image: $image; -o-border-image: $image; -khtml-border-image: $image; border-image: $image; }

@mixin box-shadow( $shadow ) { -ms-box-shadow: $shadow; -moz-box-shadow: $shadow; -webkit-box-shadow: $shadow; -o-box-shadow: $shadow; -khtml-box-shadow: $shadow; box-shadow: $shadow; }

@mixin background( $background ) { -pie-background: $background; background: $background; }

@mixin background-size( $size ) { -ms-background-size: $size; -moz-background-size: $size; -webkit-background-size: $size; -o-background-size: $size; -khtml-background-size: $size; background-size: $size; }

@mixin gradient-top-bottom( $start-color, $end-color, $fallback ) {
	background: #{$fallback};
	background: linear-gradient(to bottom,$start-color 0%,$end-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(StartColorStr='#{ie-hex-str($start-color)}',EndColorStr='#{ie-hex-str($end-color)}',GradientType=0);
}

@mixin gradient-left-right( $start-color, $end-color, $fallback ) {
	background: #{$fallback};
	background: linear-gradient(to right,$start-color 0%,$end-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(StartColorStr='#{ie-hex-str($start-color)}',EndColorStr='#{ie-hex-str($end-color)}',GradientType=1);
}

@mixin gradient-right-left( $start-color, $end-color, $fallback ) {
	background: #{$fallback};
	background: linear-gradient(to left,$start-color 0%,$end-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(StartColorStr='#{ie-hex-str($end-color)}',EndColorStr='#{ie-hex-str($start-color)}',GradientType=1);
}


@mixin gradient-top-middle-bottom( $start-color, $middle-color, $end-color, $fallback ) {
	background: #{$fallback};
	background: linear-gradient(to bottom, $start-color 0%, $middle-color 50%, $end-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(StartColorStr='#{ie-hex-str($start-color)}',EndColorStr='#{ie-hex-str($end-color)}',GradientType=0);
}

@mixin hide-dropdown-arrow {
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	appearance: none;
	&::-ms-expand { display: none; }
	&::expand { display: none; }
}

@mixin clearfix {
	&:before,
	&:after { content: ""; display: table; }
	
	&:after { clear: both; }

}

@mixin unclearfix {
	&:before,
	&:after { content: none; display: none; }

	&:after { clear: none; }

}

@mixin odd( $selector: '*', $n: 50 ) {
	$local-sel: '&';

	#{$selector}:nth-child(odd) { @content }

	#{$selector}:first-child {
		@for $i from 1 through $n {
			#{$local-sel} { @content }

			$local-sel: $local-sel + ' + ' + $selector + ' + ' + $selector;

		}

	}

}

@mixin even( $selector: '*', $n: 50 ) {
	$local-sel: '&';

	#{$selector}:nth-child(even) { @content }

	#{$selector}:first-child + #{$selector} {
		@for $i from 1 through $n {
			#{$local-sel} { @content }

			$local-sel: $local-sel + ' + ' + $selector + ' + ' + $selector;

		}

	}

}

@mixin transform( $transform... ) {
	-moz-transform: $transform;
	-webkit-transform: $transform;
	-o-transform: $transform;
	-ms-transform: $transform;
	transform: $transform;
}

@mixin transform-style( $transform-style... ) {
	-moz-transform-style: $transform-style;
	-webkit-transform-style: $transform-style;
	-o-transform-style: $transform-style;
	-ms-transform-style: $transform-style;
	transform-style: $transform-style;
}

@mixin transition( $transition... ) {
	-moz-transition: $transition;
	-webkit-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin box-sizing( $box-sizing ) { -ms-box-sizing: $box-sizing; -moz-box-sizing: $box-sizing; -webkit-box-sizing: $box-sizing; -khtml-box-sizing: $box-sizing; box-sizing: $box-sizing; }

@mixin opacity( $opacity ) {
	$opacity100: $opacity * 100;
	opacity: $opacity;
	filter: unquote("alpha(opacity=#{$opacity100})");
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity100})";
}

$rtl-languages: ar;
@mixin rtl {
	@each $lang in $rtl-languages {
		&:lang(#{$lang}) {
			@content;
		}
	}
}

@mixin arrow-right( $color, $width, $halfheight: $width/2 ) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	line-height: 0;
	border-left: solid $width $color;
	border-top: solid $halfheight transparent;
	border-right: 0 none;
	border-bottom: solid $halfheight transparent;
}

@mixin arrow-down( $color, $height, $halfwidth: $height/2 ) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	line-height: 0;
	border-left: solid $halfwidth transparent;
	border-top: solid $height $color;
	border-right: solid $halfwidth transparent;
	border-bottom: 0 none;
}

@mixin sibling-count( $num-siblings, $selector: '*' ) {
	#{$selector}:first-child:nth-last-child(#{$num-siblings}) {
		&,
		& ~ #{$selector} { @content; }
	}

}

$fg-body: #525252;
$fg-nav: #525252;
$bg-nav-on: #f7f7f7;

$bg-heading: #a3a3a3;
$bg-body: #fff;
$bg-btn-search: #6997b9;
$fg-hyperlink: #1973b4;
$bg-nav-flyout: #e2e2e2;

$fg-red-header: #cb0c0f;

$bg-row-head: #d6d6d6;
$bg-row-alt: #f2f2f2;
$border-text-search: #bababa; 
$border: #cacaca;

$fg-error: #d00;
$bg-error: #fdc;