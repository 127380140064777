﻿#dialog-software-license {
	a:link,
	a:visited { text-decoration: underline; }

	.salutation {}

	.etcontentbefore {}

	.license-lines { margin: 10px 0; padding: 0 0 0 18px;
		.license-line { line-height: 1.4em; padding: 5px 0;
			.etlineprefix {}
			.license-key { font-weight: bold; font-family: monospace; }
		}

	}

	.etcontentafter { }
}