﻿.dialog-key-eula.ui-dialog {
	.ui-dialog-buttonpane .ui-dialog-buttonset { background: #aaa; text-align: center; float: none; font-size: 120%; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-align-content: center; align-items: center; justify-content: space-around;
		.eula-captcha { width: 304px; height: 78px; padding: 1px 1px 0; -webkit-box-flex: 1; flex: 0 0 304px; }
		.ui-button { flex: 1 1 auto; margin: 0 10px; }
	}

	&.recaptcha-fallback {
		.ui-dialog-buttonpane .ui-dialog-buttonset { display: block;
			.eula-captcha { height: 422px; position: relative; top: -347px; margin-bottom: -280px; }

			.eula-captcha,
			.ui-button { display: inline-block; vertical-align: middle; }
		}
	}
}
