.update-path {
    font-family: Arial, Helvetica, sans-serif;
    padding: 30px;

    h3.column-title {
        color: #cb0c0f;
        font-size: 20px;
        line-height: 1.2em;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        padding-top: 5px;
    }

    a {
    	font-size: 11px;
	    color: #1973b4;
    }

    a.button-red {
        background: #cc0f12 none repeat scroll 0px 0px;
        border: 3px solid #e7e7e7;
        border-radius: 17px;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        font-size: 14px !important;
        font-family: Verdana, Helvetica, sans-serif;
        margin: 15px 0px 0px;
        padding: 7px 12px;
        text-decoration: none;
        position: relative;
    }

    .left-column {
        float: left;
        width: 660px;
    }

    .right-column {
        float: right;
        margin-top: 45px;
        text-align: center;
        width: 240px;
    }

    .question {
        font-weight: bold;
    }

    .continue-btn {
        background-color: #ffffff;
        border: 3px solid #e3020a;
        border-radius: 10px;
        font-size: 14px;
        margin-left: 400px;
        padding: 10px;
        width: 130px;
    }
    
    .question {
        font-weight: bold;
    }

    .q1-options {
        display: inline-block;
        padding: 30px 40px;

        div {
            display: inline-block;
            padding-right: 25px;
            text-align: center;
            width: 120px;
        }
    }

    .q2-options {
        ul{
            font-size: 14px;
            list-style: none outside none !important;
            margin: 10px 0 0;

            li {
                line-height: 14px;
	            padding-bottom: 10px;
            }
        }
    }

    .q3-options {
        ul {
            font-size: 14px;
	        list-style: none outside none !important;
            margin: 10px 0 0;

            li {
                line-height: 14px;
	            padding-bottom: 10px;
            }
        }
    }

    .handheld > p {
        line-height: 15px;
    }

    .left-badge-image {
        padding: 10px 15px;
    }

    .right-column-text {
	    padding-top: 10px;
	    width: 150px;
    }
    
    &.download-page {
        font-size: 12px;
        padding: 0px;

        h3.column-title {
            font-weight: normal;
            font-size: 24px;
            padding: 20px 0px 10px;
        }

        a {
            color: #cb0c0f;
        }

        p {
            font-size: 12px;
            line-height: 16.8px;
        }

        .check-links p {
            padding-left: 20px;
            padding-bottom: 10px;

            a {
                font-size: 12px;
                padding-left: 5px;
            }
        }

        .left-column {
            width: 670px;
        }

        .os-icon {
            height: 65px;
            float: left;
            width: 55px;
        }

        .divider {
            height: 15px;
            margin-left: -25px;
            padding-bottom: 20px;
            padding-top: 10px;
            width: 480px;
        }

        .download-button {
            height: 65px;
        }

        .os-down-button {
            margin-left: 15px;
            margin-top: 15px;
        }

        /* Copied from free-form template */
        .software, .handheld, .touchpad {
            border-bottom: 1px solid #979797;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }

        .section-nspire-update {
            font-size: 12px;
            line-height: 1.4em;
            font-family: Arial,Helvetica,sans-serif;
        }

        #STitle {
            font-size: 18px;
            color: #ca1500;
            padding-top: 10px;
            padding-bottom: 5px;
        }
        
        #Stxt {
            padding-left: 20px;
            font-size: 12px;
            width: 610px;
            line-height: 30px;
        }

        #cntnt {
            width: 570px;
            padding-left: 30px;
            float: inherit;
        }
        
        #SSTitle {
            font-size: 14px;
            color: #ca1500;
            padding-top: 20px;
            padding-bottom: 10px;
        }
        
        #cntnt-txt {
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 545px;
        }

        #content {
            clear: both;
            font-family: Arial,Helvetica,sans-serif;
            padding: 4px 7px 10px 17px;
            position: relative;
        }
        
        .twistyOpen, .twistyClosed {
            cursor: pointer;
            cursor: hand;
        }

        .promo-heading {
            font-weight: normal !important;
            font-size: 18px !important;
            line-height: 20px !important;
        }
        
        .promo-sub-heading {
		    font-weight: bold;
	    }

        .learn-more-link {
            color: #dc0d0f !important;
            font-weight: bold;
        }
    
        .lightbox{
	    	color:#1973B4 !important;
        }
            
	    .steps{
		    font-weight:bold !important;
		    color:#525252;
        }
            
        .versions{
            color:#525252;
        }

        .pad25{
            padding-left:25px;
            padding-bottom:10px;
        }

        .padbottom10{
            padding-bottom:10px;
        }

        .none{
            display:none;
        }
    }
}