﻿.dialog-modal{
	.dialog-modal-form{ 
		form { width: 400px; overflow: hidden; margin: 0 auto; 
			li{ list-style-type: none; }
			li > label { clear: left; float: left; width: 200px; line-height: 20px; margin: 0 0 0 40px; }
			input[type="checkbox"] { float: left; width: 20px; height: 20px; margin: auto; }
			label { clear: left; float: left; width: 100px; line-height: 20px; margin: 0 0 5px; }
			input,
			select { float: right; width: 250px; height: 17px; margin: 0 0 3px; }
			input[type="submit"] { clear: both;  height:20px; margin: 20px auto; display: block; font-size: 12px;}
			.teaser {line-height: 16px;}
		}
	}
}
