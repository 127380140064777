﻿#placeholder-etproductcategory { overflow: hidden; width: 960px;
	.sublayout-content-header {
		header { position: relative; height: 137px; padding: 0 300px 0 34px; font-size: (12em/10.8); background-color: #000; background-repeat: no-repeat;
			h1 { color: #cb0c0f; font-size: (20em/12); font-weight: bold; padding: 9px 0 2px; }
			h2 { color: #fff; font-weight: bold; font-size: 1.111em; line-height: 1.25em; padding-bottom: 5px;}
			.content { color: #fff; line-height: 1.25em; }

			.scLooseFrameZone { overflow: visible; position: absolute; left: 0; right: 0; top: 0; bottom: 0; padding: 0 300px 0 34px; }

		}
			
	}

	.sublayout-etproduct-list { overflow: hidden; width: 982px; padding: 22px 0;
		.etproduct { position: relative; float: left; width: 249px; min-height: 58px; padding: 18px 112px 0 108px; border: solid 1px #ccc; border-left: 0; margin: 13px 20px 0px 0; background: #f0f0f0; font-size: (12em/10.8);
			&:nth-child(odd) { clear: left; }

			> a:first-child { color: #cb0c0f; display: block; }

			header { position: relative; left: 0; top: -32px; padding: 17px 0 0; margin: 0 0 -28px;
				h1 { color: #cb0c0f; font-size: (14em/12); font-weight: 700; margin-bottom: 1px; }

				.hero { position: absolute; left: -83px; top: 0; }

			}

			.content { line-height: 1.25em; float: left; }

			nav { position: absolute; right: 0; top: -1px; width: 111px;
				ul {
					li {
						a { display: block; padding: 0 0 0 21px; border-bottom: 1px solid #a4cdef; line-height: 20px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 3px 6px transparent; }

						.scLooseFrameZone,
						.scEnabledChrome { display: block; }

					}

				}

			}

		}

		.scLooseFrameZone { display: inline-block; overflow: visible; }

		> .scLooseFrameZone {
			> .webedit-helper { display: block; margin: 2px 10px; padding: 2px 0; text-align: center; width: 939px; }

			> .scLooseFrameZone { vertical-align: top; }

		}

	}
	
}