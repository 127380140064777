﻿.section-body #events-layout #column-right h3 { font-weight: bold; font-size: 1.4em; padding: 0; clear: both; color: #dc0d0f; }

#search fieldset,
#search .criteria-wrapper-body,
#results-list li,
#events-home .section-body #event-sidebar #promo ul li { @include clearfix; }

#results .sublayout-event-list { padding-left: 18px; width: 715px; }

.section-body {
	h3 { font-weight: bold; font-size: 1.4em; padding: 0; clear: both; color: #dc0d0f; }

	.website-US & #results-list li.national-event { background: transparent url(/App_Themes/Default/images/events/US/icon-national-event.gif) 2px 13px scroll no-repeat; }
	.website-CANADA & #results-list li.national-event { background: transparent url(/App_Themes/Default/images/events/CANADA/icon-national-event.gif) 2px 13px scroll no-repeat; }

	.sublayout-event-list #results-list { padding: 4px 0 0 0; }
	#results-list li { width: 680px; padding: 0 0 0 28px; margin-bottom: 18px; border-top: solid 1px #b4b4b4; clear: both; position: relative;
		&.national-conference { background: transparent url(/App_Themes/Default/images/events/icon-national-event.gif) 3px 12px scroll no-repeat; }
		&.non-ti-event {}
		&.ti-event { background: transparent url(/App_Themes/Default/images/events/icon-ti-event.gif) 3px 12px scroll no-repeat; }
		&.t3-event { background: transparent url(/App_Themes/Default/images/events/icon-t3-event.gif) 2px 12px scroll no-repeat; }
		&:first-child,
		&.first-child { border-top: 0 none; }
		a { color: #2384ca; }
		h4 { width: 460px; padding: 0 12px 8px 0; font-weight: bold; font-size: 1.3em; clear: both; color: #dc0d0f; }
		.result-left { float: left; width: 461px; padding: 11px 12px 0 0; }
		.result-left li { padding: 0 0 0 20px; list-style-type: disc; }
		.result-right { float: left; width: 191px; background: transparent url(/App_Themes/Default/images/events/result-right-bg.gif) 0 39px no-repeat; padding: 0 0 0 15px; min-height: 156px;
			&,
			p,
			li { line-height: 1.6em; margin: 0; }
		}
		.date { display: block; font-weight: bold; padding-bottom: 6px; }
		address { padding: 0 0 1.3em 0; clear: both;
			.location-name { font-weight: bold; display: block; }
		}
		.event-links { padding: 0;
			li { padding: 0; margin: 0; }
		}
		li { width: auto; min-height: 0; padding: 0; margin: 0; border: 0 none; clear: none; }
		.event-link-bar { position: static; padding: 0; width: 191px; margin-top: 10px; z-index: 3;
			&:hover,
			&.hover { z-index: 2; }
			li { float: left; padding: 1px 3px 0 1px; width: 17px; height: 22px; overflow: visible; }
			li.calendar { width: 17px; padding: 1px 0 0 2px; padding-right: 3px; }
			li a { display: block; width: 17px; height: 17px; }
			li.calendar a,
			li.map a,
			li.email a,
			li.print a,
			li.facebook a,
			li.twitter a { background: transparent url(/App_Themes/Default/images/events/icons-event.gif) 0 0 scroll no-repeat; overflow: hidden; text-indent: -9999px; }
			li.calendar a { background-position: 0 0; }
			li.map a { background-position: -17px 0; }
			li.email a { background-position: -34px 0; }
			li.print a { background-position: -51px 0; }
			li.facebook a { background-position: -68px 0; }
			li.twitter a { background-position: -85px 0; }
			li.calendar:hover a,
			li.calendar.hover a,
			li.calendar a:hover,
			li.calendar a:active,
			li.calendar a:focus { background-position: 0px -16px; text-decoration: none; }
			li.map a:hover,
			li.map a:active,
			li.map a:focus { background-position: -17px -16px; text-decoration: none; }
			li.email a:hover,
			li.email a:active,
			li.email a:focus { background-position: -34px -16px; text-decoration: none; }
			li.print a:hover,
			li.print a:active,
			li.print a:focus { background-position: -51px -16px; text-decoration: none; }
			li.facebook a:hover,
			li.facebook a:active,
			li.facebook a:focus { background-position: -68px -16px; text-decoration: none; }
			li.twitter a:hover,
			li.twitter a:active,
			li.twitter a:focus { background-position: -85px -16px; text-decoration: none; }
			li.addthis { width: auto!important; padding-left: 0px; }
			li.add-this div { position: absolute; left: 0; top: 22px; width: 161px; display: block; z-index: 3; color: #666; padding: 1px; font-size: 0.8em; }
			li:hover div,
			li.hover div { display: block; }
			li div p { padding: 0 3px 4px 3px; }
			li div ul { padding: 0; }
			li div ul li { float: none; padding: 0 0 7px 0; width: auto; height: auto; }
			li div ul li a { background-image: none; text-indent: 0; width: auto; height: auto; color: #8c8c8c; }
			li div ul li a:hover,
			li div ul li a:focus,
			li div ul li a:active { background-color: #fff !important; }
			li div ul li.yahoo a,
			li div ul li.windows-live a,
			li div ul li.ical a,
			li div ul li.aol a,
			li div ul li.google a { display: block; padding: 4px 3px 0 29px; height: 18px; background: transparent url(/App_Themes/Default/images/events/icons-calendar.gif) 3px 2px scroll no-repeat; overflow: visible; text-indent: 0; }
		}
	}
}

#results .section-body #results-list li .event-link-bar li div ul {
	li.yahoo a { background-position: 3px 2px; }
	li.windows-live a { background-position: 3px -18px; }
	li.ical a { background-position: 3px -38px; }
	li.aol a { background-position: 3px -58px; }
	li.google a { background-position: 3px -80px; }
}

#results-list li .relevance-score { position: absolute; width: 22px; height: 13px; line-height: 13px; padding: 3px 0; left: 0; top: 9px; background: #dc0d0f; color: #fff; font-weight: bold; font-size: 0.8em; text-align: center; white-space: nowrap; }
#site-wrapper .section-body #column-right #no-results { clear: both; padding: 0.4em 1em;
	li { list-style-type: disc; padding: 0; }
}