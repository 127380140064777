﻿.sublayout-portugal-image-form { padding: 13px 20px 15px 15px; width: 750px; font-size: 12px; font-family: arial,helvetica,sans-serif;
	.content {
		> h1 { color: #CB0C0F; font-size: 1.85185em; font-weight: bold; padding-bottom: 0.5em; width: auto;}
		span{ color: red; }
		.required-fields{ font-style: italic; }
		.grey_bar { background: none repeat scroll 0 0 #EEEEEE; border-bottom: 0.2em solid #BEBEBE; font: bold 12px Arial,Helvetica,sans-serif; padding: 0.5em 0.7em 0.4em 0.9em; width: 75em; }
		.contact-info{ margin-bottom: 12px;
			label{ display: block; text-align: right; width: 400px; margin: 3px;}
		}
		.offer{
			> label {display: block; margin: 12px 0; }
			.acquired{
				label { display: block; width: 735px;}
			}
			.portugese { margin-bottom: 12px;
				> p {width: auto;}
				label{ display: block; text-align: right; width: 400px; margin: 3px;
					input{ width: 30px; }
				}
			}
		}
		.additional{
			h3{ color: #525252; font-size: 14px; }
			div { margin: 12px 0;
				p { margin: 0; }
				label + p { margin-top: 12px; }
				textarea { width: 300px; height: 100px; }
			}
		}
		.portugal-legal {
			> label { display: block; margin: 5px 0; }
		}
                .error-message {
			color: red; font-size: 12px;
		}
	}
}