﻿.etform, .et30xmultiviewform, .etfrenchsvform, .etfrenchseedform { padding: 20px 0px; font-size: 14px; font-family: lato; width: 900px; overflow: hidden;
	.content { font-size: 14px; }
	.nobr { white-space: nowrap; }

	header { padding: 0 30px 15px 0;
		h2 { color: #c00; font-family: Lato-Light;font-weight: normal; font-size: 26px; }
	}

	h3 { color: #333; border-bottom: 0.2em solid #bebebe; padding: 0.5em 0.7em 0.4em 0.9em; margin: 0 0 12px; font-family: Lato-Light; font-size: 20px;; background: #eee; }

	.asterisk { color: #cb0c0f; font-weight: bold; }
	.smartview-form  {
		padding: 20px;
		background: linear-gradient( #f2f2f2, #fff );
		.columns{
			clear: both;
			position: relative;
			padding: 20px;
			&:after {
				clear: both;
				content: " ";
				display: block;
				height: 0;
				visibility: hidden;
			}
			.column:not(:first-child):after {
				background: linear-gradient(45deg, rgba(255,0,0,0) 0%, #ccc 50%, rgba(0,255,0,0) 100%);
				bottom: 0;
				content: " ";
				margin-left: -30px;
				position: absolute;
				top: 0;
				width: 1px;
				
			}
		}
	}
	.column-1 { width: 550px; float: left;
		.field { @include clearfix; margin: 1px 0 2px; line-height: 30px;
			input,
			select,
			textarea { border: 1px solid gray; border-radius: 3px; height: 30px; margin-bottom: 10px; width: 50%; padding-left: 10px; margin: 0 5px 0 1px; }

			input[type="text"],
			input[type="tel"],
			input[type="email"],
			textarea {  border: 1px solid gray; }

			input,
			select { float: left; }

			input.error, select.error { border: 1px solid red; }

			input.valid, select.valid { border: 1px solid green; }

			label { line-height: 20px; position: relative; display: block; font-weight:bold; }
			label.error { width: 200px; float: left; line-height: 30px; color: red; padding-left: 5px; }

			.asterisk { padding: 0 0 0 2px; }
		}

		.field-annotation { line-height: 30px;
			p { margin-bottom: 0; }

		}

		.field-single {
			input,
			select,
			textarea {  }

			label { display:block;  margin-right: 16px;
				.asterisk { }

			}

		}

		.field-readonly {
			input,
			select,
			textarea { border: 2px solid #eee; background: #eee; resize: none; }

		}

		.field-checkbox, .field-radio {
			line-height: 20px;
			input {  width: 13px; margin-bottom: 0; margin-top: 0; line-height: 20px; height: 20px;}
           
			label {  
				.asterisk {  }

			}
			label.error {
				float: right;
				padding: 0 144px 0 0;
			}
		}

		.field-dropdown {
			label,
			select,
			input { display: block; }

			select,
			input { }

			input {  }

		}
		.field-checkbox.legal-stuff {
			overflow: hidden;
			margin: 10px 0;
			> label {
				float: left;
				width: 85%;
			}
			> label.error {
				width: 15%;
				position: absolute;
				right: 318px;
				line-height: 20px;
				padding: 0;
			}
		}

		input[name="email-address"] { opacity: 0; height: 0; line-height: 0; margin-left: -9999px; display: block; }

		button { margin: 10px 0; }
		button.submit:disabled { opacity: .5; cursor: not-allowed;}
		p.grey_bar { font-weight: bold; background-color: #eee; padding: .5em .7em; border-bottom: #bebebe 0.2em solid; }
		
	}

	.column-2 { padding: 0 0 0 15px; width: 300px; float: left;
		a:link,
		a:visited { display: block; color: #cb0c0f; position: relative; margin: 0 0 10px;
			&:after { content: ""; display: inline-block; width: 19px; height: 9px; background: transparent url(/App_Themes/Default/Images/General/chevrons.png) 0 0 scroll no-repeat; margin: 0 0 0 5px; }

		}
		div.flexbox { display: flex; flex-direction: column;
			div.flex { flex: 1; text-align: center; padding:20px 0;}
		}


	}
	span.validation-message {
    	width: 200px; line-height: 30px; color: red; font-weight: bold; display: block;
	}

}

.ui-menu.etfrenchseedform-autocomplete { display: table; border-collapse: collapse; width: 232px;
	.ui-menu-item { display: table-row; border: solid 1px transparent;
		.school-id,
		.school-name { display: table-cell; vertical-align: top; padding: .4em 3px; }

		.school-id { text-align: left; }

		.school-name { text-align: right; }

	}
}