﻿.sublayout-etfootermodule { width: 960px; clear: both; overflow: hidden; padding: 27px 0 0;
	> .scLooseFrameZone { margin: -27px 0 0; padding: 27px 0 0; }

	.etfootermoduleheading { font-size: 16px; margin: 0 0 16px 30px; }

	.sublayout-etfootercolumn { float: left;
		.etfootercolumnsection { padding-bottom: 25px;
			> .scLooseFrameZone { margin: 0 -10px -25px; padding: 0 10px 25px; }

			.etfootercolumnsectionheading { padding: 0; margin: 0 0 7px; font-size: 12px; line-height: 16px; }

			.jump-list {
				li { margin: 0 0 4px;
					&,
					a,
					a:link,
					a:visited { display: block; line-height: 18px; font: 12px/16px Verdana,Arial,Helvetica,sans-serif; }

					> .scLooseFrameZone { display: block; }

				}

			}

			.etsocialmediahyperlink-section {
				.etsocialmediahyperlink-list { @include clearfix; margin: 5px 0 0;
					.etsocialmediahyperlink-listitem { float: left; margin: 0 16px 0 0;

					}

				}

			}

		}

	}

	@for $column-count from 0 through 4 {
		$effective-column-count: max( $column-count, 1 );
		&.columns-#{$column-count} {
			$column-padding-top: 0;
			$column-padding-right: 15px;
			$column-padding-bottom: 0;
			$column-padding-left: 30px;
			$outer-width: 930px;
			$column-width: ( ( $outer-width - ( ( $column-padding-left + $column-padding-right ) * $effective-column-count ) ) ) / $effective-column-count;

			.sublayout-etfootercolumn { width: $column-width; padding: #{$column-padding-top} #{$column-padding-right} #{$column-padding-bottom} #{$column-padding-left};
				> .scLooseFrameZone { margin: #{-$column-padding-top} #{-$column-padding-right} #{-$column-padding-bottom} #{-$column-padding-left}; padding: #{$column-padding-top} #{$column-padding-right} #{$column-padding-bottom} #{$column-padding-left}; }

			}

		}

	}

}

.etfootermoduletheme-basic { background: #fff;
	.etfootermoduleheading { color: #cb0c0f; font-weight: bold; }

	.etfootercolumnsectionheading {
		&,
		a,
		a:link,
		a:visited { color: #cb0c0f; font-weight: bold; }
	}

	a,
	a:link,
	a:visited { color: #525252; }

}

.etfootermoduletheme-dark { background: #666;
	.etfootermoduleheading { color: #fff; font-weight: bold; }

	.etfootercolumnsectionheading {
		&,
		a,
		a:link,
		a:visited { color: #fff; font-weight: bold; }

	}

	a,
	a:link,
	a:visited { color: #ccc; }

	a:link,
	a:visited {
		&:hover,
		&:active,
		&:focus { color: #fff; }

	}

}

.etfootermoduletheme-classic { background: #fff url(/App_Themes/Default/images/product-family/fat-footer-bg.gif) no-repeat;
	.etfootermoduleheading,  {
		&,
		a,
		a:link,
		a:visited { color: #717171; font-weight: bold; }
	}

	.etfootercolumnsectionheading {
		&,
		a,
		a:link,
		a:visited { font-size: 1em; color: #919191; font-weight: bold; }
	}

	a,
	a:link,
	a:visited { color: #919191; 
		&.highlighted{ color: #cb0c0f; }
	}

}

