﻿
.location-wrapper {
font-family: Arial, Helvetica, sans-serif;
font-size: 12px;
color: #525252;
font-weight: 400;
}



.location-wrapper-body {
display: table-row-group;
vertical-align: middle;
border-color: inherit;
}


.location-wrapper-row {
display: table-row;
vertical-align: inherit;
border-color: inherit;
}

.location-wrapper-column {
font-family: Arial, Helvetica, sans-serif;
font-size: 12px;
color: #525252;
font-weight: 400;
display: table-cell;
vertical-align: inherit;
}

