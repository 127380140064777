﻿.ebookdetails #layout-default { width: 1064px;
	#header-site { position: relative; }
}

#navigation-secondary { clear: both; height: 30px; display: block; width: 100%; background: #6c6c6c; background: -moz-linear-gradient(top, #6c6c6c 0%, #858585 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6c6c6c), color-stop(100%, #858585)); background: -webkit-linear-gradient(top, #6c6c6c 0%, #858585 100%); background: -o-linear-gradient(top, #6c6c6c 0%, #858585 100%); background: -ms-linear-gradient(top, #6c6c6c 0%, #858585 100%); background: linear-gradient(to bottom, #6c6c6c 0%, #858585 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c6c6c', endColorstr='#858585',GradientType=0 );
	ul li { float: left; margin: 0 5px;
		a { color: #e7e7e7; font-size: 11px; font-weight: bold; padding: 9px 10px; display: block; }
	}
}

#layout-default #navigation-secondary ul li {
	a { color: #e7e7e7; font-size: 11px; font-weight: bold; padding: 9px 10px; display: block; }
	&:hover,
	a:hover,
	&.active { background: #777; background: -moz-linear-gradient(top, #777 10%, #9d9d9d 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #777), color-stop(100%, #9d9d9d)); background: -webkit-linear-gradient(top, #777 10%, #9d9d9d 100%); background: -o-linear-gradient(top, #777 10%, #9d9d9d 100%); background: -ms-linear-gradient(top, #777 10%, #9d9d9d 100%); background: linear-gradient(to bottom, #777 10%, #9d9d9d 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#77777', endColorstr='#9d9d9d',GradientType=0 ); text-decoration: none; color: #fff; position: relative; }
	&.has-nav {
		&:hover nav { display: block; }
		nav { position: absolute; width: 100%; padding: 0 0 5px 0; z-index: 1; }
		ul li { float: none; margin: 0; min-width: 100px; background: #9c9c9c;
			&:hover { background: #6d6d6d; }
			a {
				&:hover { background: #6d6d6d; }
				font-size: 11px; color: #e8e8e8; font-weight: normal; padding: 5px 8px; }
		}
	}
}

#navigation-secondary ul li.has-nav nav { display: none; }

#section-body #digitalBook { min-height: 400px; margin-top: 10px; border-right: 1px solid #C1C1C1; border-top: 1px solid #C1C1C1; border-left: 1px solid #C1C1C1; border-top-right-radius: 5px; border-top-left-radius: 5px; }

#digitalBook.rightSidebar .digitalBookContent { float: left; width: 625px; padding: 15px; }

.digitalBookContent {
	.pageTitle { color: #cb0c0f; font-family: Arial,Helvetica,Verdana,sans-serif; font-size: 20px; margin-bottom: 15px; }
	article { margin-bottom: 10px; float: left; }
}

#digitalBook.rightSidebar .digitalBookContent article { width: 650px; }

.digitalBookContent article {
	.articleTitle { font-weight: bold; padding-bottom: 3px; border-bottom: 1px solid #ccc; color: #000; }
	.articleLeft { float: left; width: 700px; }
	p { color: #525252; line-height: 18px; }
}

#digitalBook.rightSidebar .digitalBookContent article .articleLeft { width: 470px; margin-right: 30px; }

.digitalBookContent article {
	p i { font-style: italic; }
	.articleRight { display: block; margin-top: 10px; }
	.articleViewmore { color: #1973b4; font-weight: bold; background: url(/App_Themes/Default/Images/Dealer-Portal/btn_download.gif) no-repeat left center; padding-left: 10px; }
}

#digitalBook {
	&.noSidebar .digitalBookContent article .articleRight { float: right; width: 200px; }
	.digitalBook-rgtClmn { width: 175px; float: right; min-height: 275px; padding: 15px 25px; background: #e1e1e1; background: -moz-linear-gradient(top, #e1e1e1 0%, white 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e1e1e1), color-stop(100%, white)); background: -webkit-linear-gradient(top, #e1e1e1 0%, white 100%); background: -o-linear-gradient(top, #e1e1e1 0%, white 100%); background: -ms-linear-gradient(top, #e1e1e1 0%, white 100%); background: linear-gradient(to bottom, #e1e1e1 0%, white 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e1e1e1', endColorstr='#fffff',GradientType=0 ); border-radius: 5px;
		.valid { color: #0f0; font: 11px/14px Arial,Helvetica,sans-serif; margin: 0 0 8px; }
		.invalid { color: #f00; font: bold 11px/14px Arial,Helvetica,sans-serif; margin: 0 0 8px; }

		h3 { font-weight: bold; font-size: 12px; color: #000; margin-bottom: 10px; }
		input {
			&[type="text"] { border: 1px solid #ccc; color: #757575; margin-bottom: 10px; width: 175px; height: 25px; font-size: 12px; }
			&[type="submit"] { background: #cb0c0f; border-radius: 5px; color: #fff; border: none; padding: 5px 10px; margin-bottom: 15px; }
		}
		p { color: #999; line-height: 18px; }
	}
}

.digitalBookContent {
	p { margin: 0 0 10px 0; clear: both; font-size: 1.2em; }
	strong { font-weight: bold; }
	em { font-style: italic; }
}

.noSidebar {
	.digitalBookTOC { float: left; width: 425px; padding: 15px;
		.content a { font-family: Verdana,Arial,Helvetica,sans-serif; }

	}

	@include odd('.digitalBookTOC', 10) { clear: left; }
	.digitalBookContent { padding: 15px; }
}

.ebookdetails .noSidebar .digitalBookContent { padding: 0;
	h2 { padding: 15px; }
}

.noSidebar {
	.digitalBookContent iframe { border: medium none; height: 635px; margin: 0 auto; width: 1064px; }
	.digitalBookTOC .content ul { list-style-type: none;
		ul { list-style-type: none; }
	}
}

.digitalBookTOC .pageTitle { color: #cb0c0f; font-size: 1.5em; font-weight: bold; padding: 4px 0 12px; }

.rightSidebar .digitalBook-rightContent { float: right; padding: 50px 0 20px; position: static; width: 280px; }

.digitalBookContent .digitalBook-welcomeText { font-size: 12px; line-height: 18px; color: #436d8c; border-bottom: 1px dotted #999; padding-bottom: 10px; margin-bottom: 20px; }

#ebook-home-section { margin-top: 10px; position: relative;
	.ebook-home-banner { position: absolute; }
}

#ebook-home-content { color: #FFF; height: 465px; left: 0; padding: 0; position: relative; width: 960px;
	.bannerText { position: relative; top: 60px; }
	h2 { color: #FFF; float: right; font-size: 3.7em; letter-spacing: -0.35mm; line-height: 1em; padding: 0px 0 20px 410px; position: static; text-indent: -91px; width: 289px; }
	p {
		&.tagline { color: #FFF; font-weight: bold; padding: 0 0 0 585px; }
		font-family: Arial,Helvetica,Verdana,sans-serif; font-size: 1.5em; padding: 0 0 0 370px; line-height: 1.45em; padding: 0.3em 0 1em; }
	ul { padding: 0 0 0 15px;
		&.content-area { color: #2A2A2A; float: right; margin: 65px 0 0; position: relative; width: 660px;
			li {
				&.second-child { height: 172px; margin: 0; width: 422px; }
				float: left; height: 126px; list-style: none outside none; margin: 0; padding: 0; }
		}
		li { list-style-type: none; }
		&.content-area {
			li {
				&.second-child h3 { padding: 2px 15px; }
				h3 { border: 0 none; color: #900; font-size: 1.3em; font-weight: bold; position: absolute; top: 7px; margin: 0 0 0 9px; }
			}
			h3 a { color: #900; font-size: 1em; font-weight: bold; margin: 0; padding: 0; text-decoration: none; }
			li.second-child p { margin: 5px 30px 0 215px; }
			p { background: none repeat scroll 0 0 #CECCCC; font-family: Arial,Helvetica,Verdana,sans-serif; font-size: 0.9139em; margin-left: 3px; padding: 0 2px 0.5em 10px; position: absolute; top: 40px; width: 170px; }
			li.third-child { height: 172px; margin: 0 15px; width: 206px; }
		}
	}
}

#navigation-wrapper { background: none repeat scroll 0 0 transparent; border: medium none; bottom: 0; float: left; margin: 0 0 30px; position: absolute; }

#navigation-site { margin: 0; position: static;
	li { margin: 10px 0;
		a { color: #039; font-weight: bold; }
		&.has-nav ul { display: none; }
	}
}

#site-wrapper a {
	&:focus,
	&:active,
	&:link:hover { text-decoration: underline; }
}

.digitalBookContent .contact-us {
	.content-block { clear: both; margin: 0; overflow: hidden; padding: 0; }
	.contact-left { float: left; margin: 8px 10px 0 0; text-align: right; width: 100px; }
	input { background: none repeat scroll 0 0 #EFEFEF; }
	.contact-right { float: left; margin: 5px 0 0; width: 300px; }
	input[type="submit"] { margin: 5px 0 0 115px; }
	.content-block span { color: red; left: 10px; position: relative; top: 8px; }
}
