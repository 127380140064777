﻿@font-face {
	font-family: 'TI84PlusCEKeys';
	src: url('/-/media/9F9F0CEC94824F9B8B1A689784591FDE.ashx');
	src: url('/-/media/B6688435321346F6BB473771E8B512EF.ashx') format('truetype');
}
@font-face {
	font-family: 'TI83PremiumCEKeys';
	src: url('/-/media/7E0151B0146B49C3A9B5A70CF860522E');
	src: url('/-/media/621ED7F466F04B618FD8545AF26F0FAB') format('truetype');
}
@font-face {
	font-family: 'TI84EmuKeys';
	src: url('/-/media/25A9D7F946224C0CB7F00755D8CDFE39.ashx') format('truetype');
}
.TI83PremiumCEKeys {
	font-family: TI83PremiumCEKeys;
}
.TI84PlusCEKeys {
	font-family: TI84PlusCEKeys;
}
.TI84EmuKeys {
	font-family: TI84EmuKeys;
}