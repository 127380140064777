﻿.about-texas-instruments {

	font-family: Verdana;
	font-size: 12px;
	padding:  40px;
	line-height: 18px;

	h1 {
		font-family: Arial;
		font-size: 30px;
		color: #d51800;
		line-height: 28px;
	}

	.main-column {
		width: 66%;
		float: left;
	}

	.right-column {
		width: 30%;
		float: right;
		padding-left: 15px;
	}

	.no-right-column {
		
		.main-column {
			width: 100%;	
		}
		.right-column { display: none; }

	}

}