﻿.sublayout-multi-generic-content, .sublayout-product-details{
	.sublayout-side-navigation{ 
		h2{ font-size:(16em/10.8); padding: 3px 0 6px 18px; }
		ul{ font-family:Verdana,Arial, Helvetica, sans-serif; background: url("/App_Themes/Default/Images/General/menu-rule.gif") no-repeat right bottom;
			li{ background: url("/App_Themes/Default/Images/General/menu-rule.gif") no-repeat right top;  padding: 7px 3px 6px 0; 
			
				&.selected{
					&  a{ background: url("/App_Themes/Default/Images/General/minus.gif") no-repeat 5px 1px;}
					li a { background: none;}
				}

				&  a{  display: block; padding-left: 18px; min-height: 16px; background: url("/App_Themes/Default/Images/General/plus.gif") no-repeat 5px 1px;}

				&  a{	color: #039; cursor: pointer; font-weight: bold;
						&:link, &:visited{color: #039; }
						&:only-child{background: url("/App_Themes/Default/Images/General/single.gif") no-repeat 5px 1px;}
						&:hover{ text-decoration: underline;}
				}
			
				nav{
					ul{ background: none; padding-left: 16px;
						li{ background: none;  padding: 0 0 1px 0;  
							&  a{ padding:2px 0 1px 2px; background: none;
								&:link, &:visited{font-weight: normal;}
								&:only-child{background: none;}
							}
							& a:hover,
							&.selected a{background: #A4CDEF; text-decoration: none; }
						}
					
					}
				}

				span.scWebEditInput{ background: none!important; padding-left: 0!important;}
					
			}
		}

		ul.collapsible{
			li{
				&.selected{
					html.js & nav{ display: block;}
				}
				html.js & nav{ display: none;}
			}
		}
		ul.hoverable{
			li:hover{
				html.js & nav, html.no-js & nav{display: block;}

			}
		}
	}
}