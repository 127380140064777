﻿.etsvupgradeform { padding: 0 15px 15px; font: 12px Arial,Helvetica,sans-serif; width: 930px; overflow: hidden;
	.nobr { white-space: nowrap; }

	header { padding: 15px 30px 15px 0;
		h2 { color: #cb0c0f; font: bold 20px Arial,Helvetica,sans-serif; }
	}

	h3 { color: #525252; border-bottom: 0.2em solid #bebebe; padding: 0.5em 0.7em 0.4em 0.9em; margin: 0 0 12px; font: bold 12px Arial,Helvetica,sans-serif; background: #eee; }

	.asterisk { color: #cb0c0f; font-weight: bold; }

	.column-1 { width: 580px; float: left;
		.validation-message { color: #cb0c0f; display: block; position: absolute; right: 0; top: 0; }

		.fieldgroup { clear: both; @include clearfix; }

		.field { @include clearfix; margin: 1px 0 2px 0; position: relative;
			input,
			select,
			textarea { box-sizing: border-box; vertical-align: central; }

			input[type="text"],
			input[type="tel"],
			input[type="email"],
			textarea { border-width: 2px; }

			.validation-element { outline: dotted 1px #cb0c0f;

				+ label { outline: dotted 1px #cb0c0f; }
			}

			input,
			select { height: 20px; }

			label { line-height: 20px; position: relative; }

			.asterisk { padding: 0 0 0 2px; }
		}

		.field-annotation { margin-left: 206px; line-height: 20px;
			p { margin-bottom: 0; }

		}

		.field-dropdown,
		.field-single {
			input,
			select,
			textarea { float: left; width: 232px; padding: 1px 0; }

			label { float: left; width: 190px; text-align: right; margin-right: 16px;
				.asterisk { position: absolute; right: -8px; }

			}

		}

		.field-readonly {
			input,
			select,
			textarea { border: 2px solid #eee; background: #eee; resize: none; }

		}

		.field-radio { margin-top: 4px; margin-bottom: 2px;
			input[type="radio"] { float: left; width: 13px; margin: 0 0 0 173px; }
			input[type="text"] { clear: left; width: 232px; padding: 1px 0; margin: 2px 0 0 206px; }

			label { float: left; width: 371px; margin-left: 23px;
				.asterisk { position: absolute; left: -9px; }

			}

			.validation-element { outline: none 0; }

		}

		.field-checkbox { margin-top: 4px; margin-bottom: 6px;
			input { float: left; width: 13px; margin: 0 0 0 173px; }

			label { float: left; width: 371px; margin-left: 23px;
				.asterisk { position: absolute; left: -9px; }

			}

			.validation-element { outline: none 0; }

		}

		input[name="email-address"] { opacity: 0; height: 0; line-height: 0; margin-left: -9999px; display: block; }

		button { margin: 0 0 0 208px; }
		button.submit:disabled { opacity: .5; cursor: not-allowed;}
	}

	.column-2 { padding: 0 0 0 50px; width: 300px; float: left;
		a:link,
		a:visited { display: block; color: #cb0c0f; position: relative; margin: 0 0 10px;
			&:after { content: ""; display: inline-block; width: 19px; height: 9px; background: transparent url(/App_Themes/Default/Images/General/chevrons.png) 0 0 scroll no-repeat; margin: 0 0 0 5px; }

		}

	}

}