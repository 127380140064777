﻿.sublayout-etsectiongroup { overflow: hidden; width: 960px;
	.header-etsectiongroup { position: relative; height: 137px; padding: 0 300px 0 34px; font-size: (12em/10.8); background-color: #000; background-repeat: no-repeat;
		h1 { color: #cb0c0f; font-size: (20em/12); font-weight: bold; padding: 9px 0 2px; }
		h2 { color: #fff; font-weight: bold; font-size: 1.111em; line-height: 1.25em;}
		.content { color: #fff; line-height: 1.25em; }

		.scLooseFrameZone { overflow: visible; position: absolute; left: 0; right: 0; top: 0; bottom: 0; padding: 0 300px 0 34px; }

	}

	.etsectiongroup-body { overflow: hidden; width: 960px;
		.etsection-list-outer { float: left; width: 760px;
			.etsection-list { overflow: hidden; width: 780px;
				.etcontentlinksection,
				.ethyperlinksection,
				.etproductcategorysection,
				.etproductsection,
				.etproductcontentsection { position: relative; float: left; width: 270px; min-height: 91px; padding: 18px 0 15px 80px; margin: -2px 40px 0px 0; background: url(/App_Themes/Default/Images/Section-Group/etsection-border.gif) no-repeat scroll 3px 0 transparent; font-size: (12em/10.8);
					&:nth-child(odd), &.odd { clear: left; }

					> a:first-child { color: #cb0c0f; }

					header { position: relative;
						h1 { color: #cb0c0f; font-size: (14em/12); font-weight: 700; margin-bottom: 1px; }
						h1, h1 a{ color: #cb0c0f;}
						.hero { position: absolute; left: -80px; top: -1px; }

					}

					.content { line-height: 1.25em; }

					nav { padding: 3px 0 0;
						select { width: 265px; padding: 1px; border: inset 1px #f0f0f0; }

						ul {
							li {
								a { display: block; padding: 0 0 0 11px; line-height: 14px; background: url(/App_Themes/Default/Images/General/red-arrow.gif) no-repeat scroll 0 3px transparent; }

							}

						}

					}

				}

				.scLooseFrameZone { display: inline-block; overflow: visible; }

				> .scLooseFrameZone { width: 740px;
					> .webedit-helper { display: block; margin: 2px 10px; padding: 2px 0; text-align: center; width: 939px; }

					> .scLooseFrameZone { vertical-align: top; width: 370px; }

				}

			}

		}

		.sublayout-etcalloutstack { float: left; overflow: hidden; width: 200px; margin: 16px 0 0 0;
			.etcallout {

				> .scLooseFrameZone { display: block; }
			}

			> .scLooseFrameZone { display: block; }
		}
	}
}