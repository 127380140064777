﻿
.sublayout-two-column-callout-left{
	#placeholder-column-one,
	#placeholder-column-two{ float: left; min-height: 100px;}
}

.sublayout-two-column-callout-right{
	#placeholder-column-one,
	#placeholder-column-two{ float: left; min-height: 100px;}
}

.sublayout-three-column{
	#placeholder-column-one,
	#placeholder-column-two,
	#placeholder-column-three{ float: left; min-height: 100px;}

}
