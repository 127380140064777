﻿#contentHolder { background: url("/App_Themes/Default/Images/Student-Zone/navbg2.png") repeat-y scroll left top transparent; min-height: 465px; position: relative; width: 960px; font-family: Arial;
	.secondLevelNavigation { background: url("/App_Themes/Default/Images/Student-Zone/navbg.jpg") repeat scroll 0 0 transparent; float: left; height: 405px; margin: 0; padding: 55px 0 0; width: 175px; }
	aside .leftMenu {
		ul { margin: 0;
			li { line-height: 30px;
				a { display: block; color: #525252; font-weight: bold; padding-left: 15px; }
			}
		}
		li {
			&.active { background: url("/App_Themes/Default/Images/Student-Zone/selection.png") repeat-y scroll left top transparent;
				> a { color: #CB0C0F; text-decoration: none; }
			}
			a {
				&:hover, &.active { color: #CB0C0F; text-decoration: none; }
			}
			ul { display: none; padding-left: 10px; }
			&.active ul { display: block; }
			ul li { background: url("/App_Themes/Default/Images/Student-Zone/expandedselection.png") repeat scroll right top transparent; color: #525252; font-size: 10px; line-height: 18px; }
		}
	}
	.mainContent { min-height: 465px; border: 1px solid #DFDFDF; margin: 0 0 0 175px; position: relative; overflow: hidden;
		.contentBgImg { position: absolute; left: 0; top: 0; }
	}
	.downloadContent .contentText { left: 20px; top: 10px;
		p { padding: 5px 0 0; font-weight: bold; }
		h2 { color: #CB0C0F; font-size: 24px; }
	}
	.contentText { left: 510px; position: relative; top: 160px; width: 240px;
		h2 { color: #CB0C0F; font-size: 27px; }
		p { color: #525252; padding: 10px 0 0 20px; line-height: 1.3em; }
	}
	.promoContent li {
		h3 { color: #CB0C0F; font-family: "Myriad Pro",verdana; font-size: 20px; margin: 0 0 5px; }
		p { color: #525252; font-family: Arial,Helvetica,Verdana,sans-serif; font-size: 12px; line-height: 14px;
			a { color: #1973B4;
				&.facebookIcon { padding-top: 5px; }
			}
		}
	}
	.downloadContent {
		.promoContent { left: 0px; padding-right: 0; }
		li h5 { font-family: Arial; font-weight: bold; line-height: 1.3em; }
	}
	.engineeringContent .contentText { left: 535px; position: relative; right: -3px; top: 180px; width: 215px;
		h2 { color: red; font-size: 16px; }
		p { padding: 0px; }
	}
	.content { padding: 20px 0 0 20px;
		h2 { color: #CB0C0F; font-size: 24px; font-weight: bold; }
		h3 { color: #CB0C0F; font-size: 1.17em; font-weight: bold; }
		.sidebarsHolder { margin-top: 20px; }
		.leftsideBar { float: left; width: 420px; }
		h4 { color: #CB0C0F; font-family: Arial,Helvetica,Verdana,sans-serif; font-size: 1em; font-weight: bold; margin: 0 0 2px 0; }
		p { color: #666666; font-size: 12px; line-height: 1.45em; }
		> ul { padding: 10px 15px 0;
			> li { list-style: disc; }
		}
		.rightsideBar { float: right; padding: 0 20px 0 0; width: 287px; text-align: center; }
	}

	.testmonialContent {
		h2 { color: #CB0C0F; font-family: arial; font-size: 24px; font-weight: bold; padding: 20px 30px 0; }
		ul.thumbnailsList { background: none repeat scroll 0 0 #E2E2E2; border-radius: 5px 5px 5px 5px; float: left; margin: 10px 15px 0 30px; padding: 5px;
			li { padding: 5px;
				&.selected { background: none repeat scroll 0 0 #9F9F9F; border-radius: 5px 5px 5px 5px; }
				img { display: block; cursor: pointer; }
			}
		}
		#parenthesisleft { float: left; margin: 10px 0 0 20px; }
		#parenthesisright { bottom: 15px; position: absolute; right: 5px; }
		.thumbnailDetails { margin: 0 0 0 60px; padding: 5px 0 0 10px; width: 508px; position: relative; overflow: hidden;
			.testimonials li {
				img { float: left; margin: 0 5px 5px 0; padding: 5px; }
				p { color: #666666; font-size: 12px; margin: 0; line-height: 1.45em; }
				.description { margin: 30px 0 0 0;
					p { padding: 0 0 5px; }
				}
			}
		}
	}

	.calculatorsContent {
		.tabContainer { width: 748px; overflow: hidden;
			h2 { color: #CB0C0F; font-size: 24px; font-weight: bold; padding: 20px 0 0 15px; }
		}
		ul.tabs { background: url("/App_Themes/Default/Images/Student-Zone/tabline.png") no-repeat scroll left bottom transparent; border-bottom: medium none; border-right: medium none; top: 290px; left: 30px; margin: 0 0 0 0 !important; padding: 0 0 23px !important; position: absolute; height: 25px; float: left; width: 98%;
			li { background: url("/App_Themes/Default/Images/Student-Zone/unselectedtab.png") no-repeat scroll left bottom transparent; border: medium none; float: left; height: 24px; text-align: center; vertical-align: bottom; width: 91px; list-style-type: none !important; }
		}
	}

	.mainContent .content ul.tabs li { background: url("/App_Themes/Default/Images/Student-Zone/unselectedtab.png") no-repeat scroll left bottom transparent; border: medium none; float: left; height: 24px; text-align: center; vertical-align: bottom; width: 91px; list-style-type: none !important; }

	.calculatorsContent ul.tabs li.active, .mainContent .content ul.tabs li.active { background: url("/App_Themes/Default/Images/Student-Zone/selectedtab.png") no-repeat scroll left bottom transparent; }

	.calculatorsContent ul.tabs li a, .mainContent .content ul.tabs li a { color: #666666; font-weight: bold; padding: 8px 10px 0; display: block; }

	.calculatorsContent ul.tabs li a:hover, .mainContent .content ul.tabs li a:hover { text-decoration: none; }

	.slidingContent { overflow: hidden; position: relative; top: 0px; left: 0px; margin: 0 0 0 20px; padding: 0px;
		li { float: left; list-style: none outside none; width: 748px;
			.leftParenthesisBg { background: url("/App_Themes/Default/Images/Student-Zone/parenthesisleft.png") no-repeat scroll left 0 transparent; display: block; height: 280px; overflow: hidden; padding: 20px 0 0 0; text-align: center; position: relative; left: 10px; }
			.calc-left { float: left; margin: 30px 0 0 10px; width: 350px;
				h3 { font-size: 1.17em; font-weight: bold; color: #CB0C0F; }
				p { color: #666666; font-size: 12px; margin: 0 0 20px; line-height: 1.45em; }
			}
		}
	}

	.slideshow-list { clear: both; overflow: hidden; width: 748px; margin: 0; padding: 0 0 0 30px;
		li { float: left; height: 150px; list-style-type: none !important; margin: 23px 0 0; position: relative; text-align: center; width: 102px;
			&.selected { background: url("/App_Themes/Default/Images/Student-Zone/calcselector.png") no-repeat scroll 0 0 transparent; }
			img { margin-top: -8px; }
			span { color: #525252; }
			img.wide { padding-bottom: 19px; padding-top: 20px; }
		}
	}

	.software-list li img.wide { padding-bottom: 19px; padding-top: 20px; }

	.tabContainer > li { display: none; }

	.listingContainer { padding: 0 17px; overflow: hidden; }

	.lists {
		h2 { color: #CB0C0F; font-size: 24px; padding: 20px 0 0; text-indent: 0; width: auto; }
		h3 { color: #CB0C0F; font-size: 1.17em; font-weight: bold;
			a { color: #1973B4; font-weight: bold; }
		}
		.bold { font-size: 12px; }
		overflow: hidden; padding: 10px 0 0 !important; list-style-type: none !important;
		> li { float: left; padding: 5px 10px 10px 0; width: 230px; }
	}

	.mainContent .content {
		ul.tabs { background: url("/App_Themes/Default/Images/Student-Zone/tabline.png") no-repeat scroll left bottom transparent; padding: 0 0 23px !important; height: 25px; width: 100%; }
		table { border: none; border-collapse: collapse; font-size: 12px;
			th { text-align: left; border: 1px solid #999; }
			td { text-align: center; border-left: 1px solid #999; border-right: 1px solid #999; width: 75px; font-weight: bold;
				&.feature-column { text-align: left; width: 200px; font-weight: normal; }
				&.blank-cell { border: none; }
			}
			tr.bottom-row td { border-bottom: 1px solid #999; }
			tbody tr.odd td { background: #EEE; }
		}
	}

	.twoCol { bottom: 0; left: 20px !important; padding-right: 0 !important; position: absolute; right: 0;
		> li { background: url("/App_Themes/Default/Images/Student-Zone/roundrecttwoCol.png") no-repeat scroll 0 0 transparent; min-height: 135px; margin: 0; padding: 10px 15px 5px 20px; width: 345px; float: left; color: #525252; }
		li {
			&.second { float: right; }
		}
	}

	ul.arrowedList { padding-left: 15px;
		li { list-style-image: url("/App_Themes/Default/Images/Student-Zone/bullet_redarrow.png"); color: #525252; padding: 2px 0; margin: 0; }
	}

	.threeCol { bottom: 0; padding: 0 15px; position: absolute; right: 0;
		> li { background: url("/App_Themes/Default/Images/Student-Zone/roundrect.png") no-repeat scroll 0 0 transparent; color: #525252; float: left; height: 135px; margin: 0 8px; padding: 15px 10px 0; width: 215px; }
	}

	.more { background: url("/App_Themes/Default/Images/Student-Zone/bullet_redarrow.png") no-repeat scroll 0 2px transparent; color: #525252; display: inline-block; padding-left: 8px;
		&:link,
		&:visited { color: #1973b4; }

	}

	.lists > li ul { padding: 0 15px;
		li { color: #525252; list-style-image: url("/App_Themes/Default/Images/Student-Zone/bullet_redarrow.png"); margin: 0; padding: 2px 0; }
	}
}
