﻿.teks {
    .sublayout-etccsspage .sublayout-etccssherocarousel .sublayout-etccssherocarouselslidehero article h1 {
        color: #c02634;
        font: 17px/26px Arial,Helvetica,sans-serif;
        padding: 0 0 14px;
    }
}
.Science, .student-parent{ 
    .sublayout-etccsspage, .sublayout-etccsspage{
        .sublayout-etccssherocarousel{ top: 0; }
        .etccssnavigation{
            .etccssnavigation-list{
                .etccssnavigation-listitem{
                    .sublayout-etccssteaser{
                        .etteasercontent { width: 178px;}
                    }
                }
            }
        }
    }
}
.sublayout-etccsspage {
    .etccss-staticlanding{ height: 331px; position: relative; top: -16px; margin-bottom: -16px; background: url("/-/media/2B919621035B46C3B7410649532F83CD.ashx") no-repeat scroll right top rgba(0, 0, 0, 0);
        .etccss-staticlanding-content{ padding-top: 40px;
            > h1{ color: #be1e2d; font: 22px/16.25px Arial,Helvetica,sans-serif; margin: 16px 20px 17px; }
            h2 { font: 28px/24px Arial,Helvetica,sans-serif; margin: 5px 20px; color: #525252;} 
            .content { color: #525252;  margin: 0 60px;
                h2{ margin: 5px 0 17px 0; }
                .ajax-content, .ajax-pd, .ajax-tech { position: absolute; display: block; height: 30px; }
                .ajax-content {  top: 227px; left: 814px; width: 83px; }
                .ajax-pd{ top: 61px; left: 730px; width: 202px; }
                .ajax-tech{ top: 169px; left: 477px; width: 107px; }
                p{ font: 16px/24px Arial,Helvetica,sans-serif; width: 450px; }
            }
        }
        .etccss-staticlanding-slidewrapper{ width: 334px; overflow: hidden; margin: 68px 20px 0 20px;
            > nav { position:absolute; left: 97px; top: 355px; overflow: hidden;
                a { display: block; float: left; font: bold 14px/14px Arial,Helvetica,sans-serif; height: 14px; margin: 0 0 0 20px; padding: 1px 2px; text-align: center; cursor: pointer; color: #BE1E2D; }
                a.selected-slide{ color: #fff; background-color: #BE1E2D; }
            }
            .etccss-staticlanding-scrollwrapper{ width: 960px; overflow: hidden; height: 67px;
                .etcarousel-wrapper { height: 62px;
                    .etcallout-link{
                        h1 { font: 14px/12px Arial,Helvetica,sans-serif; position: relative; left: 75px; top: 10px; }
                        .etcallout-teaserimage { height: 27px; }
                        .content { position: relative; left: 76px; top: 2px; width: 320px; margin-bottom: -12px; }
                    }
                }
                .etcarousel-buttons{ top: 62px; left: 55px; }
            }
        }
    } 
    .etccssherocarousel-wrapper { position: relative; width: 960px; background: url("/App_Themes/Default/Images/ccss/verticalgradienttop-bg.png") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
        .etccssherocarousel-scrollwrapper { width: 960px; overflow: hidden; }
        .etccssherocarousel-pagination {  top: 55px; z-index: 101; display: inline-block;
            .etccssherocarousel-pagination-slide { display: block; overflow: hidden; margin: 0 0 0 20px; color: #BE1E2D; font: bold 14px/14px Arial,Helvetica,sans-serif; float: left; text-align: center; padding: 0 2px; height: 14px;
                &.selected { background: #BE1E2D; color: white; }
            }
            .etccssherocarousel-pagination-heroslide { text-indent: -9999px; border: solid 1px #dddfdf; width: 12px; height: 12px; padding: 0; margin: 0 -6px 0 0; }
        }

        .slide-arrow { position: absolute; display: block; width: 41px; height: 46px; background: transparent url(/App_Themes/Default/Images/ccss/previous-next-slide.png) scroll 0 0 no-repeat; z-index: 10; top: 243px; cursor: pointer; }

        .slide-arrow-previous { left: 15px; }

        .slide-arrow-next { right: 15px; background-position: -41px 0; }
    }

    .etmoduleheadings {
        .etmoduleheading { color: #be1e2d; font: 21px/24px Arial,Helvetica,sans-serif; margin: 16px 20px 17px; }
        .etmodulesubheading { color: #58595b; font: 18px/24px Arial,Helvetica,sans-serif; margin: 0 20px; display: inline-block; }
    }

    .sublayout-etccssherocarousel { position: relative; width: 960px * 9; max-height: 400px; overflow: hidden; z-index: 4; top: -17px; margin-bottom: -17px;
        .etccssherocarouselslide { position: relative; width: 960px; float: left;
            article { margin: 29px 0 0 21px;  position: relative; z-index: 6; @include clearfix; }

            .sublayout-etimagemap { position: absolute; left: 0; top: 0; z-index: 5; }

            .etslideheading { padding: 5px 0 0 0; color: #58595b; font: bold 14px/21px Arial,Helvetica,sans-serif; }

            .etslidesubheading { color: #58595b; font: 14px/16.8px Arial,Helvetica,sans-serif; }

            .column-1,
            .column-2,
            .column-3 {
                .content { font: 11px/18px Verdana,sans-serif; }

            }

            .column-1 { padding: 0 15px 0 0;
                .etslideheading { color: #be1e2d; font: 18px/20.5px Arial,Helvetica,sans-serif; }
                h2 { padding: 0; }
                h3 { margin: 0; }
            }

            .column-2 { float: left; width: 440px; padding: 0 0 0 11px; margin: 17px 0 0 0;
                .etslidecolumn2image{ margin-left: 75px; }

                .etslidecolumn2heading { text-align: center; color: #777; font: bold 14px/16.8px Arial,Helvetica,sans-serif; padding: 2px 14px 10px 15px; }

                .etslidecolumn2content { padding: 0 15px 0 15px; }

            }

            .column-3 { float: left; width: 440px; padding: 0 0 0 11px; margin: 17px 0 0 0;
                
                .etslidecolumn3image{ margin-left: 75px; }

                .etslidecolumn3heading { text-align: center; color: #777; font: bold 14px/16.8px Arial,Helvetica,sans-serif; padding: 2px 14px 10px 15px; }

                .etslidecolumn3content { padding: 0 15px 0 15px; }

            }

            .previous-slide,
            .next-slide { position: relative; display: inline-block; border: solid 1px #c6c8ca; border-right: none 0; @include border-radius(7px); color: #cf2425; font: 15px/17px Arial; @include box-shadow( 0 0 3px 3px #ededee ); @include gradient-top-bottom( #fff, #d2d4d5, #d2d4d5 );
                &:after { content: " "; display: block; position: absolute; width: 18px; bottom: 0; top: 0; }

            }

            .previous-slide { padding: 4px 9px 5px 30px; margin: 10px 0 3px 11px; float: left;
                &:after { left: 8px; background: transparent url(/App_Themes/Default/Images/ccss/previous-slide.png) scroll center center no-repeat; }

            }

            .next-slide { padding: 4px 30px 5px 9px; margin: 10px 11px 3px 0; float: right;
                &:after { right: 8px; background: transparent url(/App_Themes/Default/Images/ccss/next-slide.png) scroll center center no-repeat; }

            }

        }

        .sublayout-etccssherocarouselslidehero {
            article { margin: 29px 555px 33px 26px;
                h1 { color: #c02634; font: 24px/26px Arial,Helvetica,sans-serif; padding: 0 0 14px; }
                .content { color: #525252; font: 12px/20.25px Arial;
                    ul { list-style-type: none; padding: 0 0 2px;
                        li { background: transparent url(/App_Themes/Default/Images/ccss/chevrons-bullet.png) 0 5px scroll no-repeat; padding: 1px 0 11px 19px; line-height: 18px;
                            strong { color: #c1272d; }

                        }

                    }

                }

                .learn-more { position: relative; display: inline-block; border: solid 1px #c6c8ca; border-right: none 0; @include border-radius(7px); color: #cf2425; font: 15px/17px Arial; padding: 5px 43px 4px 8px; margin: 10px 0 3px 22px; @include box-shadow( 0 0 0 3px #ededee ); @include gradient-top-bottom( #fff, #d2d4d5, #d2d4d5 );

                    &:before,
                    &:after { content: " "; display: block; border: solid 1px #ae1c29; border-left: none 0; @include border-radius(0 7px 7px 0); position: absolute; width: 32px; bottom: 0; top: 0; right: 0; }

                    &:before { @include gradient-top-bottom( #be1e2d, #941823, #a91b28 ); }

                    &:after { right: -1px; background: transparent url(/App_Themes/Default/Images/ccss/learn-more-chevrons.png) scroll center center no-repeat; }

                }

            }

        }

    }

}

.sublayout-etccssnavigation { position: relative; border-top: solid 1px #e4e4e4; float: left; clear: left;
    &:before { content: " "; display: block; @include gradient-top-bottom( rgba( #c5c5c5, 0 ), rgba( #c5c5c5, .47 ), transparent ); height: 7px; width: 960px; border-bottom: solid 1px #d7d7d7; z-index: 10; position: relative; top: -8px; margin: 0 0 -8px; pointer-events: none;

    }

    .etccssnavigation {
        .etccssnavigation-list { width: 960px; overflow: hidden;
            &.listitems-1 { display: none; }

            @for $i from 1 through 4 {
                &.listitems-#{$i} {
                    .etccssnavigation-listitem { width: 960px / $i; }

                }

            }

            .etccssnavigation-listitem { position: relative; float: left; min-height: 172px;
                .etccssnavigationitem { display: block; position: relative; z-index: 11; color: #cf2425; font: 16px/16.25px Arial,Helvetica,sans-serif; @include gradient-top-bottom( #fff, #e5e5e5, transparent ); padding: 9px 40px 8px 14px; border-top: solid 1px #e4e4e4; border-bottom: solid 1px #dbdcdc;
                    &:before { content: " "; display: block; width: 18px; height: 18px; background: transparent url(/App_Themes/Default/Images/ccss/tab-arrow.png) scroll 0 0 no-repeat; position: absolute; right: 10px; top: 8px; }
                    &:after { content: " "; display: block; height: 5px; @include gradient-top-bottom( #dddfdf, rgba( #dddfdf, 0 ), transparent ); position: absolute; right: 0; left: 0; bottom: -6px; z-index: 20; }

                }

                &.selected {
                    .etccssnavigationitem { @include gradient-top-middle-bottom( #fff, #e5e5e5, #fff, transparent ); border-bottom: 0 none;
                        &:before { background-position: -18px 0; }
                        &:after { display: none; }
                    }
                }

                & + .etccssnavigation-listitem {
                    &:before { content: " "; display: block; width: 9px; height: 172px; background: transparent url(/App_Themes/Default/Images/ccss/tab-separator.png) scroll 0 1px no-repeat; position: absolute; left: -5px; top: 0; z-index: 12; }
                }

                .sublayout-etccssteaser { width: 100%; overflow: hidden;
                    .etteaserintro { font: 13px/20.25px Arial,Helvetica,sans-serif; margin: 20px 15px; }

                    .etteaserimage { float: left; max-width: 100px; padding: 0 0 0 12px; }

                    .etteasercontent { float: left; width: 296px; padding: 2px 0 0 20px; font: 11px/18px Arial,Helvetica,sans-serif;
                        p { margin: 0 0 6px; }
                        img{ float: left; }
                        .css-teasercontent{ float: left; width: 190px; margin-left: 5px;}
                        ul{ padding: 0; margin-bottom: 15px;
                            li{list-style: none;}
                        }
                    }

                    .learn-more { display: block; padding: 0 0 0 19px; margin: 0 0 0 1px; font: bold 12px/20px Arial,Helvetica,sans-serif; color: #c52325; background: transparent url(/App_Themes/Default/Images/ccss/chevrons-bullet.png) 0 6px scroll no-repeat; }

                }

            }

        }

    }

}

.etccsspage-ajax { position: relative; float: left; width: 960px; margin: 38px 0 0 -960px; z-index: 15;
    &.loading { min-height: 235px; margin-top: 0; padding-top: 38px;
        &:before { content:" "; display: block; right: 0; left: 0; bottom: 0; top: 38px; position: absolute; z-index: 998; background: #ccc; opacity: .5; filter:alpha(opacity=50); }
            
        &:after { content:" "; display: block; right: 0; left: 0; bottom: 0; top: 0; position: absolute; z-index: 999; background: transparent url(/App_Themes/Default/Images/General/ajax-loader.gif) center 110px scroll no-repeat; padding: 69px 0 0; }

    }

}

.sublayout-etccsstab { position: relative; z-index: 15; background: #fff; margin: 0; min-height: 400px;
    .sublayout-etccsstabhero { width: 960px; overflow: hidden; min-height: 120px;
        .etccsstabhero-maincontent { padding: 21px 28px 3px; }

        .etpageheading { color: #d23233; font: 24px Arial,Helvetica,sans-serif; margin: 0 0 8px; }

        .etmaincontent { font: 12px/20px Verdana,sans-serif; min-height:80px; 
            .prod-d img {float: right; width: 221px; margin-top: -30px;} 
            ul{ padding: 0 0 20px 20px;
                li { list-style: none;
                    .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px rgba(0, 0, 0, 0); color: #C52325;display: block;font: bold 12px/20px Arial,Helvetica,sans-serif;margin: 0 0 0 1px;padding: 0 0 0 19px; float: left;}
                    span { float: right; width: 720px;}
                }
                li:after{ content:""; display: block; height: 0; clear:both; }
            }
        }


        &.etheroimageside-top,
        &.etheroimageside-bottom {
            .etheroimage { display: block; text-align: center; }

        }

        &.etheroimageside-left,
        &.etheroimageside-right {
            .etccsstabhero-maincontent { display: table-cell; vertical-align: top; }

            .etheroimage { display: table-cell; vertical-align: top;
                .etheroimage-inner { height: 1px; }

            }

        }

    }

    .etccsscontentmodule { position: relative; clear: both; padding: 3px 0 0; position: relative;
        .etccsscontentmodule-inner { position: relative; }
    }

    .sublayout-etccsscontentmodule-carousel {
        .etmoduleheading { color: #d23233; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 0 22px 28px; float: left; }

        .etccsscarouselnavigation { float: right; @include clearfix; margin: 0 24px 0 0;
            .etccsscarouselnavigation-item { position:relative; display: block; color: #d23233; font: 13px/25px Arial,Helvetica,sans-serif; white-space: nowrap; float: left; @include border-radius( 2px ); padding: 0 9px; z-index: 3;
                &:before { content: " "; display: block; width: 1px; height: 13px; background: #979797; position: absolute; left: -1px; top: 7px; z-index: 4; }

                &:first-child:before { display: none; }

                &.selected { color: #fff; background: #d23233; z-index: 5;
                    &:before { display: none; }

                }

            }

        }

        .etccsscarouselslides-wrapper { position: relative; width: 960px; clear: both;
            .etccsscarouselslides-scrollwrapper { width: 960px; overflow: hidden; }

            .slide-arrow { position: absolute; display: block; width: 41px; height: 46px; background: transparent url(/App_Themes/Default/Images/ccss/previous-next-slide.png) scroll 0 0 no-repeat; z-index: 10; top: 125px; cursor: pointer; }

            .slide-arrow-previous { left: 15px; }

            .slide-arrow-next { right: 15px; background-position: -41px 0; }
        }


        .etccsscarouselslides { clear: both; width: 960px; overflow: hidden; position: relative; @include clearfix; }

        .sublayout-etccsscarouselslide { width: 485px; float: left;
            .etslideimage { position: relative; text-align: center; height: 285px;
                &:before { content: " "; display: block; position: absolute; z-index: 3; right: 0; left: 0; bottom: 0; height: 77px; @include gradient-top-bottom( #fff, #cacaca, transparent ); }
                &:after { content: " "; display: block; position: absolute; z-index: 4; width: 2px; left: -7px; bottom: 1px; height: 365px; @include gradient-top-bottom( #eeeeef, #afb0b0, #afb0b0 ); }

                .etslideimage-inner { position: relative; z-index: 4; display: inline-block; height: 403px; margin: 0 0 0 -10px;
                    img { vertical-align: bottom; }

                }

            }
            
            article { padding: 15px 65px 15px 27px;
                .etslideheading { font: bold 16px/30.25px Arial,Helvetica,sans-serif; }

                .etslidesubheading { font: 12px/22px Verdana,sans-serif; margin: 0 0 17px; }

                .etslidecontent { min-height: 134px; padding: 0 10px 0 0; font: 12px/21px Verdana,sans-serif;
                
                    .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px rgba(0, 0, 0, 0); color: #C52325; display: block;font: bold 12px/20px Arial,Helvetica,sans-serif; margin: 0 -10px 0 0; padding-left: 15px;}
                    .etccss-download { background: url("/-/media/A2F2C8237E0C49AE87E765A85D0D77CC.ashx" ) scroll no-repeat 0 50%; padding-left: 38px; margin-top: 20px;}
                    a:link, a:visited { color: #c02634; }

                }

            }

        }

    }


    .sublayout-etccsstopicmodule { background: transparent url(/App_Themes/Default/Images/ccss/verticalgradienttop-bg.png) scroll 0 0 repeat-x; min-height: 350px;
        .etccsscontentmodule-inner { width : 4800px;
            
            .etccsstopicsection { float:left;
                .etccsstopiccontentmodule { padding: 21px 28px 3px;
                    .etmoduleheading { color: #d23233; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 10px 18px 0px; float: left; }
                    .etmoduletopic { color: #585858; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 0 18px 0px; }
                    .etmaincontent { font: 12px/20px Verdana,sans-serif; 
                        .topic-content-left {width:650px; float:left; border-right: 2px solid #afb0b0; padding-right:15px;}
                        .topic-content-right {width: 200px; float: right;}
                    }
                }
            
                .etccssgradessection { position: relative; min-height: 300px; display : block; width:960px;
                    .etccssgrademodule { position: relative; width:50%; float: left;
                        &:after { content: " "; display: block; position: absolute; z-index: 4; width: 2px; left: -7px; top: 54px; height: 170px; @include gradient-top-bottom( #eeeeef, #afb0b0, #afb0b0 ); }
                        .etccssgradeheading { color: #585858; font: 20px/20.25px Arial,Helvetica,sans-serif; text-align: center; padding: 20px;}
                        .sublayout-etccssactivityslide { width: 50%; float: left;
                            .etslideimage { position: relative; text-align: center; height: 164px;
                                &:before { content: " "; display: block; position: absolute; z-index: 3; right: 0; left: 0; bottom: 0; height: 77px; @include gradient-top-bottom( #fff, #cacaca, transparent ); }
                                .etslideimage-inner { position: relative; z-index: 4; display: inline-block; height: 164px; margin: 0 0 0 -10px;
                                    img { vertical-align: bottom; width:190px; height:164px;}
                                }
                            }

                            article { padding: 10px 20px 10px 25px;
                                .etslideheading { font: bold 14px/20px Arial,Helvetica,sans-serif; }
                                .etslidesubheading { font: 12px/18px Verdana,sans-serif; margin: 5px 0 10px; }
                                .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px; color: #C52325; display: block;font: bold 12px/20px Arial,Helvetica,sans-serif; padding: 0 0 10px 15px;}
                                .etslidecontent { min-height: 134px; padding: 0 10px 0 0; font: 12px/21px Verdana,sans-serif;                       
                                    .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px; color: #C52325; display: block;font: bold 12px/20px Arial,Helvetica,sans-serif; padding: 0 0 10px 15px;}
                                    .etccss-download { background: url("/-/media/A2F2C8237E0C49AE87E765A85D0D77CC.ashx" ) scroll no-repeat 0 50%; padding-left: 38px; margin-top: 20px;}
                                    a:link, a:visited { color: #c02634; }

                                }

                            }
                        }
                        
                    }
                }
            }

            
            .sublayout-etccssactivitycontentmodule { float:left; width:960px; min-height: 300px;
                .topicsection { padding: 20px 0 20px 28px;
                    .etmoduletopic { color: #d23233; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 10px 18px 0px; float : left;}
                    .etmaincontent { font: 12px/20px Verdana,sans-serif;  width: 650px; clear : left; }
                    .etccssactivitynavigation { float: right; @include clearfix; margin: 0 24px 0 0;
                        .etccssactivitynavigation-item { position:relative; display: block; color: #d23233; font: 13px/25px Arial,Helvetica,sans-serif; white-space: nowrap; float: left; @include border-radius( 2px ); padding: 0 9px; z-index: 3;
                            &:before { content: " "; display: block; width: 1px; height: 13px; background: #979797; position: absolute; left: -1px; top: 7px; z-index: 4; }

                            &:first-child:before { display: none; }

                            &.selected { color: #fff; background: #d23233; z-index: 5;
                                &:before { display: none; }

                            }

                        }

                    }
                }
                .etslideimage { float:left; padding: 0 28px;}
                article { min-height: 250px; width: 480px; padding: 0px 80px 20px 10px; float: right;
                    .etslideheading { font-weight: bold;font-size: 1.8em; padding: 10px 0; }
                    .etslidesubheading {font: bold 14px/18px Arial,Helvetica,sans-serif; padding-bottom: 10px;}
                    .etslidecontent { min-height: 134px; padding: 0 10px 0 0; font: 12px/21px Verdana,sans-serif;                       
                        .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px; color: #C52325; display: block;font: bold 12px/20px Arial,Helvetica,sans-serif; padding-left: 15px;}
                        .etccss-download { background: url("/-/media/A2F2C8237E0C49AE87E765A85D0D77CC.ashx" ) scroll no-repeat 0 50%; padding-left: 38px; margin-top: 20px;}
                        a:link, a:visited { color: #c02634; }
                    }

                }

                

            }   
        }

    
        
    }

    .sublayout-etccsscontentmodule-imagemap { padding: 0; background: none;
    
    }

    .sublayout-etccsscontentmodule-variablecolumn { @include clearfix;
        .etmoduleheading { color: #d23233; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 21px 0 22px 30px; }

        .sublayout-etccsscontentcolumn {
            &.etcolumnimageside-top,
            &.etcolumnimageside-bottom {
                .etcolumnimage { display: block; text-align: center; padding-right: 11px; }

            }

            &.etcolumnimageside-left,
            &.etcolumnimageside-right {
                .etccsscontentcolumn-maincontent { display: table-cell; vertical-align: top; }

                .etcolumnimage { display: table-cell; vertical-align: top;
                    .etcolumnimage-inner { height: 1px; }

                }

            }

            &.etcolumnimageside-left {
                .etcolumnimage { padding-right: 20px; }

            }

            &.etcolumnimageside-right {
                .etcolumnimage { padding-left: 20px; }
            }

            &.etcolumnimageside-top {
                .etcolumnimage { margin-bottom: 24px; }
            }

            .etccsstopicsection { float:left;
                .etccsstopiccontentmodule { padding: 21px 28px 3px;
                    .etmoduleheading { color: #d23233; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 10px 18px 0px; float: left; }
                    .etmoduletopic { color: #585858; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 0 18px 0px; }
                    .etmaincontent { font: 12px/20px Verdana,sans-serif; 
                        .topic-content-left {width:650px; float:left; border-right: 2px solid #afb0b0; padding-right:15px;}
                        .topic-content-right {width: 200px; float: right;}
                    }
                }
            
                .etccssgradessection { position: relative; min-height: 300px; display : block; width:960px;
                    .etccssgrademodule { position: relative; width:50%; float: left;
                        &:after { content: " "; display: block; position: absolute; z-index: 4; width: 2px; left: -7px; top: 54px; height: 170px; @include gradient-top-bottom( #eeeeef, #afb0b0, #afb0b0 ); }
                        .etccssgradeheading { color: #585858; font: 20px/20.25px Arial,Helvetica,sans-serif; text-align: center; padding: 20px;}
                        .sublayout-etccssactivityslide { width: 50%; float: left;
                            .etslideimage { position: relative; text-align: center; height: 164px;
                                &:before { content: " "; display: block; position: absolute; z-index: 3; right: 0; left: 0; bottom: 0; height: 77px; @include gradient-top-bottom( #fff, #cacaca, transparent ); }
                                .etslideimage-inner { position: relative; z-index: 4; display: inline-block; height: 164px; margin: 0 0 0 -10px;
                                    img { vertical-align: bottom; width:190px; height:164px;}
                                }
                            }

                            article { padding: 10px 20px 10px 25px;
                                .etslideheading { font: bold 14px/20px Arial,Helvetica,sans-serif; color: #585858;}
                                .etslidesubheading { font: 12px/18px Verdana,sans-serif; margin: 5px 0 10px; }
                                .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px; color: #C52325; display: block;font: bold 12px/20px Arial,Helvetica,sans-serif; padding: 0 0 5px 15px;}
                                .etslidecontent { min-height: 134px; padding: 0 10px 0 0; font: 12px/21px Verdana,sans-serif;                       
                                    .learn-more { background: url("/App_Themes/Default/Images/ccss/chevrons-bullet.png") no-repeat scroll 0 6px; color: #C52325; display: block;font: bold 12px/20px Arial,Helvetica,sans-serif; padding: 0 0 10px 15px;}
                                    .etccss-download { background: url("/-/media/A2F2C8237E0C49AE87E765A85D0D77CC.ashx" ) scroll no-repeat 0 50%; padding-left: 38px; margin-top: 20px;}
                                    a:link, a:visited { color: #c02634; }

                                }

                            }
                        }
                        
                    }
                }
            }
        }

        .etccsscolumns { @include clearfix; }

        .etccsscolumn { float: left; }

        @for $column-count from 0 through 4 {
            $effective-column-count: max( $column-count, 1 );
            &.columns-#{$column-count} {
                $column-padding-right: 15px;
                $column-padding-bottom: 0;
                $first-column-padding-left: 27px;
                $column-padding-left: 5px;
                $last-column-padding-right: 15px;
                $outer-width: 960px;
                $column-width: ( ( $outer-width - ( ( $column-padding-left + $column-padding-right ) * $effective-column-count ) ) - $first-column-padding-left + $column-padding-left - $last-column-padding-right + $column-padding-right ) / $effective-column-count;
                
                .etccsscolumn { width: $column-width; padding-right: $column-padding-right; padding-bottom: $column-padding-bottom; padding-left: $column-padding-left; }

                .column-#{$effective-column-count} { padding-right: $last-column-padding-right; }

                .column-1 { padding-left: $first-column-padding-left; }

                @for $column-index from 2 through $effective-column-count {
                    .etccsscolumn.column-#{$column-index}:before {
                        left: $first-column-padding-left - $column-padding-left + ( ( $column-padding-left + $column-width + $column-padding-right ) * ( $column-index - 1 ) - 11px );

                    }

                }

            }

        }

    }

}

.etccsscontentcolumntheme-basic, .etccsscontentcolumntheme-nodivider { margin: 21px 0 0;
    &.no-etcolumnheading { margin-top: 0; }

    .etcolumnheading { color: #d23233; font: 20px/20.25px Arial,Helvetica,sans-serif; margin: 0 0 12px; }

    .etmaincontent { font: 12px/20px Verdana,sans-serif; padding: 0 10px 40px 1px; overflow: hidden;
        > img { float: left; }
        .featured-webinar{ color: #C02634; font-weight: bold; width: 332px; background: url("/-/media/6C23688CB9664094BD22346489E362DE.ashx") scroll no-repeat 0 26%; padding-left: 48px;
            span{ color: #525252; }
        }
        .etccss-download { background: url("/-/media/A2F2C8237E0C49AE87E765A85D0D77CC.ashx" ) scroll no-repeat 0 50%; padding-left: 38px; margin-top: 20px;}
        p { margin: 6px 0 8px; }
        .float-left { float: left; }
        img.float-left{ padding-right: 10px; }
        .float-right { float: right; width: 265px; }
        .float-none { float: none; width: 421px; margin-left: 40px; }
        ul.chevrons-bullet { padding-left: 0; list-style-type: none;
            li { padding: 0 0 5px 26px; background: transparent url(/App_Themes/Default/Images/ccss/chevrons-bullet.png) scroll 11px 6px no-repeat; font: bold 12px/20px Arial,Helvetica,sans-serif;}
        }
        ul { padding-left: 0; list-style-type: none; 
            li { padding: 0 0 5px 26px; background: transparent url(/App_Themes/Default/Images/ccss/list-item-chevron.png) scroll 11px 6px no-repeat;

            }

        }

        .intro { font: bold 16px/20px Arial,Helvetica,sans-serif; padding: 0 0  }

        .learn-more { display: block; padding: 0 0 0 19px; margin: 0 0 0 1px; font: bold 12px/20px Arial,Helvetica,sans-serif; color: #c52325; background: transparent url(/App_Themes/Default/Images/ccss/chevrons-bullet.png) 0 6px scroll no-repeat; }

        a:link,
        a:visited { color: #c02634; }

        img.center { clear: both; display: block; }

        img.left { float: left; margin: 5px 20px 5px 0; }

        img.right { float: right; margin: 9px 0 5px 5px; }

    }

}

.etccssvariablecolumncontentmoduletheme-verticalgradienttop { background: transparent url(/App_Themes/Default/Images/ccss/verticalgradienttop-bg.png) scroll 0 0 repeat-x;
    .etccsscolumn:before { content: " "; display: block; @include gradient-top-bottom( #d8d9da, #fff, #d8d9da ); width: 2px; position: absolute; top: -4px; bottom: 6px; margin-left: -1px; }
    .etccsscontentcolumntheme-nodivider:before { background: none; }
    .etccsscolumn.column-1:before { display: none; }

}

.etccssvariablecolumncontentmoduletheme-bottomhalf {
    .etccsscolumn:before { content: " "; display: block; @include gradient-top-bottom( #d8d9da, #fff, #d8d9da ); width: 2px; position: absolute; top: -4px; bottom: 6px; margin-left: -1px; }
    .etccsscontentcolumntheme-nodivider:before { background: none; }
    .etccsscolumn.column-1:before { display: none; }

}