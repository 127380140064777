﻿#dialog-sign-in {
	.optional { padding: 5px 0 15px;
		.content {}
		input[type="button"] { clear: both; display: block; margin: 0 auto; }
	}
	.error { padding: 5px 0; }
	form { padding: 5px 0 0;
		.error { display: block; clear: both; }
		label[for="username"] { display: block; float: left; clear: left; text-align: right; width: 85px; padding: 2px 10px 0 0; }
		input[name="username"] { display: block; float: left; margin: 0 0 10px; }
		label[for="password"] { display: block; float: left; clear: left; text-align: right; width: 85px; padding: 2px 10px 0 0; }
		input[name="password"] { display: block; float: left; margin: 0 0 18px; }
		input[type="submit"] { clear: both; margin: 0 0 0 100px; }
	}
	.ethyperlinklist { margin: 6px 0 0; height: 14px;
		.jump-list {
			ul { width: 100%; overflow: hidden;
				li { float: left; margin: 0 10px 0 0;
					a { color: #1973b4; }
				}
			}
		}
	}
}