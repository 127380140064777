﻿.errorPageContent {
  padding: 25px 0 400px 25px;
  font: 1.2em verdana;
  .imgHolder {
    width: 296px;
    float: left;
  }
  .contentHolder {
    overflow: hidden;
    float: left;
    margin: 0 0 0 20px;
    p {
      font-weight: bold;
      font-size: 1em !important;
    }
    h3 {
      font-family: arial;
      font-size: 1.9em;
      margin-bottom: 15px;
    }
    h4 {
      font-weight: bold;
      margin: 25px 0 5px;
    }
    ul {
      margin: 0 0 0 20px;
      li {
        margin: 5px 0;
      }
    }
  }
}