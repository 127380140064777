﻿.sublayout-homepage { position: relative; @include clearfix; width: 960px; min-height: 444px;
	.ethomepagecontent { width: 713px; float: left;
		.ethomepagecontent-list { width: 713px; position: relative;
			.ethomepagecontent-listitem { left: 0; top: 0; width: 713px; overflow: hidden;
				.ethomepagecontentmodule { left: 0; top: 0; width: 713px; min-height: 444px;
					.ethomepagecontent-headings { position: relative;
						.scWebEditInput { display: block; }
						
						.ethomepagecontent-heading { position: relative; }

						.ethomepagecontent-subheading { position: relative; }

					}

					.ethomepagecontent-maincontent { position: relative;
						&.content a{ display: inline; }

						ul { list-style: none; padding: 0;
							li { background: none scroll no-repeat 0 0 transparent; margin: 11px 0; padding: 0 0 0 29px; font-size: 0.9em;
								
								a { display: block;

								}

								ul { list-style: none; @include clearfix;
									li { background: none; float: left; padding: 0; font-size: 1em; line-height: 1em; border-left: 1px solid #fff; margin: 0; padding: 0 0 0 5px;
										&:first-child { border-left: 0; padding-left: 0; padding-right: 5px; }

										a { display: block;

										}

									}

								}

							}

						}
					
					}

					.ethomepagecontent-productsimage { position: absolute; left: 0; top: 0; }

					.ethomepagecontent-productlinks { position: absolute; left: 0; top: 0;
						.ethomepagecontent-productlink { position: absolute;
							a { display: block; overflow: hidden; text-indent: -9999px; background: url(/App_Themes/Default/Images/spacer.gif) scroll repeat 0 0 transparent; }

						}

					}

					> .scLooseFrameZone { width: 713px; }

				}

				.ethomepagecontentmodule-a {
					.ethomepagecontent-headings {
						.ethomepagecontent-heading { padding: 18px 30px 0;
							h1 { font-size: 2.6em; text-align: center; }
						}

						.ethomepagecontent-subheading { padding: 13px 30px 0; left: 337px; margin-right: 337px;
							h2 {  font-size: 1.6em; font-weight: bold; }
						}

					}

					.ethomepagecontent-maincontent { font-size: 1.3em; line-height: 1.7em; padding: 10px 30px 0; left: 435px; margin-right: 435px; }

					.ethomepagecontent-productsimage { top: 71px; left: 22px; width: 429px; height: 339px; }

					.ethomepagecontent-productlinks {
						.ethomepagecontent-backgroundproductlink { left: 20px; top: 70px;
							a { width: 313px; height: 308px; }

						}

						.ethomepagecontent-leftproductlink { left: 254px; top: 146px;
							a { width: 106px; height: 269px; }

						}

						.ethomepagecontent-leftproductpurchaselink { left: 283px; top: 334px;
							a { width: 69px; height: 16px; padding: 4px; }

						}

						.ethomepagecontent-rightproductlink { left: 342px; top: 137px;
							a { width: 104px; height: 278px; }

						}

						.ethomepagecontent-rightproductpurchaselink { left: 369px; top: 325px;
							a { width: 69px; height: 16px; padding: 4px; }

						}

					}

				}

				.ethomepagecontentmodule-b {
					.ethomepagecontent-headings {
						.ethomepagecontent-heading { padding: 23px 30px 0; left: 342px; margin-right: 342px;
							h1 { font-size: 2.55em; line-height: 1.2em; }
						}

						.ethomepagecontent-subheading { padding: 1px 30px 0; left: 362px; margin-right: 362px;
							h2 { font-size: 1.6em; font-weight: bold; }
						}

					}

					.ethomepagecontent-maincontent { font-size: 1.3em; line-height: 1.2em; padding: 10px 30px 0; left: 362px; margin-right: 362px; }
					
					.ethomepagecontent-productsimage { top: 5px; left: 5px; width: 374px; height: 440px; }

					.ethomepagecontent-productlinks {
						.ethomepagecontent-leftproductlink { left: 44px; top: 45px;
							a { width: 160px; height: 358px; }

						}

						.ethomepagecontent-leftproductpurchaselink { left: 130px; top: 318px;
							a { width: 67px; height: 26px; padding: 4px; }

						}

						.ethomepagecontent-rightproductlink { left: 184px; top: 25px;
							a { width: 160px; height: 358px; }

						}

						.ethomepagecontent-rightproductpurchaselink { left: 270px; top: 296px;
							a { width: 67px; height: 26px; padding: 4px; }

						}

					}

				}

				.ethomepagecontentmodule-c {
					.ethomepagecontent-headings {
						.ethomepagecontent-heading { padding: 22px 30px 0; left: 342px; margin-right: 342px;
							h1 { font-size: 2.4em; line-height: 1.2em; }
						}

						.ethomepagecontent-subheading { padding: 3px 40px 0 30px; left: 362px; margin-right: 362px;
							h2 { font-size: 1.6em; font-weight: bold; line-height: 1.3em; }
						}

					}

					.ethomepagecontent-maincontent { font-size: 1.3em; line-height: 1.7em; padding: 11px 30px 0; left: 362px; margin-right: 362px; }
					
					.ethomepagecontent-productsimage { top: 0px; left: 1px; width: 374px; height: 440px; }

					.ethomepagecontent-productlinks {
						.ethomepagecontent-backgroundproductlink { left: 15px; top: 22px;
							a { width: 299px; height: 246px; }

						}

						.ethomepagecontent-leftproductlink { left: 198px; top: 141px;
							a { width: 100px; height: 218px; }

						}

						.ethomepagecontent-rightproductlink { left: 272px; top: 130px;
							a { width: 100px; height: 218px; }

						}

					}

				}

				.ethomepagecontentmodule-imagemap {
					.ethomepagecontent-headings { position: absolute; z-index: 7;
						.ethomepagecontent-heading {
							h1 { font-size: 2.3em; line-height: 1.2em;
								&:first-line { font-size: 1.3em; }

							}

						}

						.ethomepagecontent-subheading { padding: 13px 0 0;
							h2 { font-size: 1.6em; font-weight: bold; }

						}

					}

				}

				.ethomepagecontenttheme-purple { 
					.ethomepagecontent-inner-1 { width: 713px; overflow: visible; background: transparent;
						.ethomepagecontent-inner-2 { width: 713px; min-height: 444px; background: url(/App_Themes/Default/Images/Home/Purple/content-bg.jpg) scroll no-repeat 0 0 transparent; color: #fff;
							.ethomepagecontent-maincontent {
								ul {
									li { background-image: url(/App_Themes/Default/Images/Home/Purple/list-item-bg.png);
										a,
										a:link,
										a:visited { color: #fff;

										}

										ul {
											li { background-image: none;
												a,
												a:link,
												a:visited { color: #fff;

												}

											}

										}

									}

								}

							}

						}

					}

				}
		
				.ethomepagecontenttheme-blue {
					.ethomepagecontent-inner-1 { width: 713px; overflow: visible; background: transparent;
						.ethomepagecontent-inner-2 { width: 713px; min-height: 444px; background: url(/App_Themes/Default/Images/Home/Blue/content-bg.jpg) scroll no-repeat 0 0 transparent; color: #fff;
							.ethomepagecontent-maincontent {
								ul {
									li { background-image: url(/App_Themes/Default/Images/Home/Blue/list-item-bg.png);
										a,
										a:link,
										a:visited { color: #fff;

										}

										ul {
											li { background-image: none;
												a,
												a:link,
												a:visited { color: #fff;

												}

											}

										}

									}

								}

							}

						}

					}

				}

				.ethomepagecontenttheme-gray {
					.ethomepagecontent-inner-1 { width: 713px; overflow: visible; background: #fff;
						.ethomepagecontent-inner-2 { width: 713px; min-height: 444px; background: url(/App_Themes/Default/Images/Home/Gray/content-bg.png) scroll no-repeat 0 0 transparent; color: #4b4b4b;
							.ethomepagecontent-maincontent {
								ul {
									li { background-image: url(/App_Themes/Default/Images/Home/Gray/list-item-bg.png);
										a,
										a:link,
										a:visited { color: #4b4b4b;

										}

										ul {
											li { background-image: none;
												a,
												a:link,
												a:visited { color: #4b4b4b;

												}

											}

										}

									}

								}

							}

						}

					}

				}

				.ethomepagecontenttheme-aqua {
					.ethomepagecontent-inner-1 { width: 713px; overflow: visible; background: transparent;
						.ethomepagecontent-inner-2 { width: 713px; min-height: 444px; background: url(/App_Themes/Default/Images/Home/Aqua/content-bg.jpg) scroll no-repeat 0 0 transparent; color: #fff;
							.ethomepagecontent-maincontent {
								ul {
									li { background-image: url(/App_Themes/Default/Images/Home/Blue/list-item-bg.png);
										a,
										a:link,
										a:visited { color: #fff;

										}

										ul {
											li { background-image: none;
												a,
												a:link,
												a:visited { color: #fff;

												}

											}

										}

									}

								}

							}

						}

					}

				}

			}

			.ethomepagecontent-editlistitem { position: absolute; left: 0; top: 0; width: 713px;
				> .webedit-helper { display: table-cell; width: 713px; text-align: center; vertical-align: middle; }

			}

		}

	}

	.sublayout-etcalloutstackgroup { width: 227px; padding: 27px 2px 0 18px; float: left; background: url(/App_Themes/Default/Images/Home/callouts-bg.gif) scroll repeat-x 0 0 #fff; }

	.etsocialmediahyperlink-section { position: absolute; right: 257px; top: 332px; @include clearfix;
		h1 { float: left; color: #af1200; font-size: 1.1em; font-style: italic; line-height: 41px; margin: 0 16px 0 0; }

		.etsocialmediahyperlink-list { float: left; @include clearfix;
			.etsocialmediahyperlink-listitem { float: left; margin: 0 8px 0 7px;
				a { display: block; }

			}

			.etsocialmediahyperlink-editlistitem { float: left;
				a { display: block; }

			}

		}

		> .scLooseFrameZone {
			> .scWebEditInput { display: block; float: left; }

		}

	}

}

.sublayout-homepage-v2 { width: 960px; position: relative;
	.etcallout-style-home2 { display: none; }

	.ethomepagecontent { width: 960px; position: relative;
		.ethomepagecontent-list { width: 960px; position: relative; overflow: hidden;
			.ethomepagecontent-listitem { position: relative; left: 0; top: 0; width: 960px; height: 340px; margin-right: -960px; overflow: hidden; float: left; display: none;
					&:first-child { display: block; }

			}

		}

	}

	.ethomepagecontent-navigation { position: absolute; right: 0; top: 0; width: 200px; right: 19px; top: 31px; background: transparent url(/App_Themes/Default/Images/Home/v2/nav-border.png) 0 0 scroll repeat-x; z-index: 10;
		&:before { display: block; content: " "; width: 2px; height: 9px; position: absolute; background: transparent url(/App_Themes/Default/Images/Home/v2/nav-top.png) 0 0 scroll no-repeat; right: 0; top: -8px; }
		&:after { display: block; content: " "; width: 3px; height: 9px; position: absolute; background: transparent url(/App_Themes/Default/Images/Home/v2/nav-bottom.png) 0 0 scroll no-repeat; right: 0; bottom: -8px; }

		.ethomepagecontent-navigationlist { background: transparent url(/App_Themes/Default/Images/Home/v2/nav-right.png) right 0 scroll repeat-y;
			.ethomepagecontent-navigationlistitem { position: relative; width: 200px; height: 60px; display: table; @include transition( all 200ms ease-in );

				.ethomepagecontent-navigationlink { position: relative; width: 164px; height: auto; display: table-cell; vertical-align: middle; padding: 0 18px 2px; font-size: 15px; line-height: 19px; font-family: Arial,Verdana,Helvetica,sans-serif; @include transition( all 200ms ease-in ); background: transparent url(/App_Themes/Default/Images/Home/v2/nav-shadow.png) 0 bottom scroll no-repeat;
					&,
					&:link,
					&:visited { background-color: rgba( 255, 255, 255, .55 ); filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#8cffffff', endColorstr='#8cffffff'); color: #666; text-decoration: none; cursor: pointer; }

				}

				&.selected,
				&:hover { margin-left: -20px; width: 220px;
					.ethomepagecontent-navigationlink {
						&,
						&:link,
						&:visited { background-color: rgba( 255, 255, 255, .8 ); filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ccffffff', endColorstr='#ccffffff'); padding-right: 38px; color: #000; }

					}

				}

			}

		}

	}

}

.sublayout-homepage-stack {
	.etcallout-style-home2 { display: none; }

	.ethomepagecontent-list { position: relative; margin: 0 0 101px; padding: 0 0 14px;
		&:after { content: ""; display: block; position: absolute; left: 0; right: 0; bottom: -74px; height: 74px; @include gradient-top-bottom( rgba( 0, 0, 0, .25 ), rgba( 0, 0, 0, 0 ), #535353 ); }

	}

	.ethomepagecontentmodule-block {
		.ethomepagecontent-moduleimage {
			img { width: 100%; height: auto; }

		}

		.ethomepagecontent-inner { text-align: center; color: #525252; padding: 24px 33px 40px 30px;

			.ethomepagecontent-moduleheading { margin: 0 0 12px;
				&,
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 { font-size: 48px; line-height: 1.27; }

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 { margin: 0; }

			}

			.ethomepagecontent-modulecontent,
			.ethomepagecontent-modulecalltoaction { font-size: 35px;
				&,
				p { line-height: 1.5; margin: 0; }

			}

			.ethomepagecontent-modulecalltoaction {
				&,
				:link,
				:visited { color: #cb0c0f; }

			}

		}

	}

}