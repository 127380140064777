﻿
#section-body {
  .bannerWrapper {
    background-repeat: no-repeat;
    font-size: 30px;
    height: 230px;
    width: 960px;
    position: relative;
    img {
      position: absolute; }
    .bannerText {
	  padding-right: 18px;
      position: absolute;
      p {
        padding-top: 30px;
        font-size: 30px;
        font-family: arial; } } 
	.bannerLink {
	  font-size: 18px;
      position: absolute;
      right: 45px;
      text-align: center;
      top: 185px;
	  a {
		line-height: 1.2em;
		color: #CB0C0F; } } }
  .gccContent {
    margin: 35px 20px 0 0;
    aside {
      float: left; }
    .contentWrapper {
      padding-top: 20px;
	  padding-right: 0px;
      overflow: hidden;
      width: auto;
      .headerText p {
        font-size: 30px;
        font-family: arial; }
      p {
        font-size: 18px;
        font-family: Arial;
        a {
          color: #CB0C0F; }
        sup {
          font-size: 10px;
          line-height: 4px;
          vertical-align: super; } } } } }

.navigationSideBar {
  background: url("/App_Themes/Default/Images/GCC/navigation-sidebar-bg.gif") repeat-y scroll left top transparent;
  float: left;
  margin-bottom: 0px;
  padding: 16px 15px 0px;
  width: 147px;
  h2 {
    margin: 0 0 10px 0; } }

html:lang(ar) .navigationSideBar h2 a {
  font-size: 2.1em; }

.navigationSideBar {
  h2 a {
    color: #CB0C0F;
    font-weight: bold;
    line-height: 1.4em;
    font-size: 1.3em;
    display: block; }
  a.btn {
    background: url("/App_Themes/Default/Images/GCC/button-bg.gif") repeat-x scroll 0 0 #e5e5e5;
    border: 1px solid #CCCCCC;
    border-radius: 6px 6px 6px 6px;
    clear: none;
    color: #5C5C5C;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin: 15px 5px 0 0;
    padding: 7px 20px;
    text-align: center; } }

html:lang(ar) .contentWrapper {
  font-size: 1.6em;
  background: none; 
  padding-right: 18px; }

.contentWrapper {
  float: right;
  padding: 25px 30px 0 28px;
  width: 725px;

  font-family: Arial;
  background: url(/App_Themes/Default/Images/nspired-learning/content-bg.gif) repeat-x scroll 0 0 transparent;
  h3 {
    color: #CB0C0F;
    font-size: 1.7em;
    font-weight: bold;
    line-height: 1.15em;
    padding: 0 0 7px; } }

h3.activitiesTitle {
  color: #CB0C0F;
  font-size: 1.7em;
  font-weight: bold;
  line-height: 1.15em;
  padding: 0 0 7px; }

.contentWrapper p {
  font-size: 1.2em;
  line-height: 1.4em; }

html:lang(ar) #section-body #subjectsList {
  float: right; }

#section-body {
  #subjectsList {
    background: url("/App_Themes/Default/Images/GCC/subjects-bg.png") repeat-x scroll 0 0 transparent;
    border-radius: 8px 8px 8px 8px;
    font-size: 13px;
    height: 27px;
    margin: 10px 0 20px;
    padding: 0 8px;
    float: left;
    > li {
      float: left;
      margin: 4px 30px 0 0;
      padding: 3px 10px;
      &.last {
        margin-right: 0; } } }
  #subjectsList > li.selected {
    background: none repeat scroll 0 0 #FFFFFF;
    border-radius: 8px 8px 8px 8px; }
  #subjectsList > li a {
    color: #5C5C5C;
    font-size: 13px;
    font-weight: bold; } }

html:lang(ar) #section-body #gradesList {
  float: right; }

#section-body {
  #gradesList {
    font-size: 1.1em;
    margin: 0 0 20px;
    clear: both;
    float: left;
    li {
      float: left;
      border-right: 1px solid #5C5C5C;
      padding: 0 20px;
      &.last {
        border-right: none; }
      a {
        color: #5C5C5C;
        font-size: 13px;
        font-weight: bold; }
      &.selected a {
        color: #CB0C0F; } } }
  .listHeader {
    background: url("/App_Themes/Default/Images/GCC/table-header-bg.png") repeat-x scroll 0 0 transparent;
    overflow: hidden;
    height: 27px;
    border-radius: 4px 4px 0 0;
    clear: both; } }

html:lang(ar) #section-body {
  .leftHeader {
    float: left;
    margin-left: 40px;
    padding-top: 0; }
  .rightHeader {
    float: right;
    margin-right: 20px;
    padding-top: 0; } }

#section-body {
  .leftHeader {
    color: white;
    float: right;
    font-size: 1.3em;
    font-weight: bold;
    margin-right: 40px;
    padding-top: 5px; }
  .rightHeader {
    color: white;
    float: left;
    font-size: 1.3em;
    font-weight: bold;
    margin-left: 20px;
    padding-top: 5px; } }

html:lang(ar) #section-body #activitiesList > li {
  background: url("/App_Themes/Default/Images/GCC/line.png") repeat-y scroll 195px 0 transparent; }

#section-body {
  .contentWrapper #activitiesList > li {
    background: url("/App_Themes/Default/Images/GCC/line.png") repeat-y scroll 529px 0 transparent; }
  #activitiesList > li {
    overflow: hidden;
    background: url("/App_Themes/Default/Images/GCC/line.png") repeat-y scroll 755px 0 transparent;
    width: 100%;
    &.noActivities {
      background: none !important;
      p {
        text-align: center; } } } }

html:lang(ar) #section-body #activitiesList .contentContainer {
  float: right;
  border-right: none; }

#section-body #activitiesList {
  .contentContainer {
    float: left;
    border-left: none; }
  .imgHolder {
    display: inline-block;
    padding: 20px 0 5px 10px;
    vertical-align: top; } }

.contentWrapper #activitiesList {
  .imgHolder embed {
    width: 200px;
    height: 200px; }
  .contentHolder {
    display: inline-block;
    vertical-align: top;
    width: 305px !important;
    padding: 20px 5px; } }

#section-body #activitiesList .contentHolder {
  h4 {
    font-size: 1.3em;
    margin: 0 0 5px 0;
    font-weight: bold; }
  p {
    font-size: 1.2em;
    line-height: 1.4em; }
  strong { font-weight: bold; 
	a { font-weight: normal; }
	} }

.contentWrapper #activitiesList .btnHolder {
  display: inline-block;
  padding: 20px 0px;
  width: 165px;
  text-align: center; }

#section-body {
  #activitiesList .btnHolder {
    display: inline-block;
    padding: 20px 0px;
    width: 165px;
    text-align: center;
    a {
      line-height: 0;
      font-size: 0;
      color: transparent; } }
  .orangeBtn {
    background: url("/App_Themes/Default/Images/GCC/button_slidingDoor-left.gif") no-repeat scroll left 1px transparent;
    display: inline-block;
    font: 11px/13px Verdana;
    height: 18px;
    padding-left: 4px;
    text-decoration: none;
    span {
      background: url("/App_Themes/Default/Images/GCC/button_slidingDoor-right.gif") no-repeat scroll right 0 transparent;
      color: #FFFFFF;
      display: block;
      padding: 3px 18px 2px 5px;
      text-transform: uppercase; } } }

html:lang(ar) #paginationWrapper {
  line-height: 6px; }

#paginationWrapper {
  margin: 10px 0;
  clear: both;
  label {
    color: #5C5C5C;
    float: left;
    font-size: 13px;
    font-weight: bold;
    padding-left: 10px; } }

#paginationList {
  overflow: hidden;
  li {
    list-style: none;
    float: left;
    border-right: 1px solid #CACACA;
    padding: 0 5px;
    &:last-child {
      border: none; }
    a {
      font-weight: bold;
      font-size: 13px;
      color: #1973B4; }
    &.selected a {
      color: #5C5C5C; } } }

.btnHolder ul {
  display: inline-block;
  li {
    float: left;
    a {
      display: block; } } }

.launchBtn {
  background: url("/App_Themes/Default/Images/GCC/doc-player-icon.png") no-repeat scroll 0 0 transparent;
  display: inline-block;
  line-height: 15px;
  padding-left: 18px; }

#section-body {
  overflow: hidden;
  #activitiesList {
    .contentHolder {
      width: 360px;
      display: inline-block;
      vertical-align: top;
      padding: 20px 5px; }
    .btnHolder {
      width: 165px;
      float: right; } } }

html:lang(ar) #section-body #activitiesList .btnHolder {
  width: 185px;
  float: left; }

#section-body {
  img.activitiesLogo {
    float: right; 
  }
  .activities-container {
    background: url(/App_Themes/Default/Images/nspired-learning/content-bg.gif) repeat-x scroll 0 0 transparent;
    padding: 16px 30px 0 15px;
    font-family: Arial; 
	a.ovalbutton {
	 background: url(/App_Themes/Default/Images/GCC/button_slidingDoor-left.gif) no-repeat scroll left top transparent;
	 display: block;
	 float: right;
	 font: 11px/13px Verdana;
	 height: 18px;
	 padding-left: 4px;
	 position: relative;
	 text-decoration: none;
	 top: 2px; 
	 &:active, &:link, &:visited {
	  color: #F5F5F5; }
	 &:hover {
	  text-decoration: underline; }
	 span {
	  background: url(/App_Themes/Default/Images/GCC/button_slidingDoor-right.gif) no-repeat scroll right top transparent;
	  display: block;
	  letter-spacing: 1px;
	  padding: 2px 18px 3px 5px;
	  text-transform: uppercase;}}}
}

html:lang(ar) #section-body .activities-container, #section-body .contentWrapper .activities-container {
  background: none;
  padding: 0; }
