﻿.eightyfour {
  .etproducttabfoldertheme-basic .sublayout-etproducttabfolderhero { min-height: 0; } 
} 

.eightyfour-ce {
  .etproducttabfoldertheme-basic .sublayout-etproducttabfolderhero { min-height: 0; }

  .etproducttabtheme-hero .sublayout-etproducttabhero { background: transparent url(/-/media/643D077C0F694FF9883165D58EC59C40.ashx) center top scroll no-repeat; padding-top: 232px;
    + .sublayout-etproductcontentmodule-variablecolumn { padding-bottom: 0; }

  }

}

.t3-2016 {
  .sublayout-t3page {

    .sublayout-t3contenthero { background: none; height: auto; overflow: hidden; 
      
      .t3contentheader { height: 80px; display: block; background: transparent url(/-/media/1248BFBDEBC840268B87AA754BB75B84.ashx) center top scroll no-repeat;
        .t3contentheadernav { display: block; height: 80px; padding: 0;
          .t3pageheading { width: 350px; float: left; font: 22px Arial,Helvetica,sans-serif; color: #fff;  margin: 25px 0 0 80px; }

          .t3pagesubheading { width: 518px; float: left;  margin-top: 30px; font: 17px Arial,Helvetica,sans-serif; color: #fff; border-left: 2px solid #fff; padding-left: 10px;
            .header-links {  float: right;  font: bold normal 14px/20px arial,Helvetica,sans-serif; margin-top: -20px;
              a.header-link { color: #fff; padding: 0 10px; text-decoration: none;
               &:first-child { border-right: 2px solid white; }
              }
            }
          }          
        }
      }

      .t3contenthero { height:408px; position: relative;
        
        ul.jecarousel, ul.jeslideshow { height: 408px;
          li { width: 960px; position: absolute;}
        }

        .jecarousel-buttons, .jeslideshow-buttons { bottom: auto; height: 25px; list-style: none outside none; position: absolute; left: 450px; top: 370px; z-index: 101;
          li {
            &.selected{
                background: #FF8787;
            }
            background: none repeat scroll 0 0 #FF3E3E;
            text-indent: -9999px;
            display: block;
            width: 10px;
            height: 10px;
            margin: 5px 3px;
            outline: 0;
            -moz-border-radius: 6px;
            -webkit-border-radius: 6px;
            border-radius: 6px;
            float:left;

            span {
                display: none;
            }
          }
        }

      }

    }

    .sublayout-t3tabnavigation {
      .etproducttabnavigation { background: transparent url(/App_Themes/Default/Images/general/t3-tab-nav-bg.jpg) 0 0 scroll no-repeat; height:55px; display: block; overflow: hidden; position: relative;

        ul { height: 55px; width: 960px;            
          li { float: left; text-align: center; width: 192px; height: 55px;
            a { display: block; color: #888888; font: 17px/19px Arial,Helvetica,sans-serif; text-decoration: none; padding-top: 15px;}
            &.active a,
            a:hover,
            a:active,
            a:focus { color: #fff; text-decoration: none; }
            &.active {
              background: transparent url(/-/media/060CA70F86DB425A89BF076532E620BF.ashx) 80px 43px scroll no-repeat;
            }
            &.arrow { background: none; }
          }
        }
      }
    }

    &.stickyHeaderTop { padding-top: 80px;
		.t3contentheader { position: fixed; top: 0px; z-index: 2001; }
	}
    &.stickyNavTop { padding-top: 135px;
		.sublayout-t3tabnavigation { position: fixed; top: 80px; z-index: 2001; }
	}
    
    .sublayout-etproducttab {
      .sublayout-etproducttabhero {
        background-color: white;
      }
      .etproductcontentmodule {   background-image: none;
        .etproductcontentmodule-inner {
          .etmoduleheading {  font: 400 normal 42px/50px Arial, Helvetica, sans-serif;  text-align: center; color: #dc0d0f; margin:0px }
      
          .etproductcolumns {
            .sublayout-etproductcontentcolumn { width: 900px; margin: 0 30px; padding: 0;
              .etproductcontentcolumn-maincontent {
                .etmaincontent { 
                  p { font: 14px/25px Verdana, Arial, Helvetica, sans-serif; padding: 0px 40px;  text-align: center;}
                  h2 { color: #dc0d0f; font: normal 24px/30px Arial, Helvetica, sans-serif; text-align: center; }
                  h3 { color: #525252; font: normal 24px/30px Arial, Helvetica, sans-serif; text-align: center; padding: 10px 0; }
                  a.button {
                    background: #cc0f12;
                    color: #fff;
                    font: normal 17px/19px Arial, Helvetica, sans-serif;
                    margin: 15px 0 0;
                    padding: 7px 12px 7px 12px;
                    display: inline-block;
                    -ms-border-radius: 4px;
                    -moz-border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -o-border-radius: 4px;
                    -khtml-border-radius: 4px;
                    border-radius: 4px;                    
                    text-align: center;  
                    white-space: nowrap;
                    text-decoration: none;
                  }
                  .promo-section { padding: 20px 0;  text-align: center;
                    ul { display: -webkit-box; display: -moz-box;  display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; margin: 20px auto;
                      li { -webkit-box-flex: 1; -moz-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1;background: none; }
                    }
                  }
                  
                  .speakers { display: -webkit-box; display: -moz-box;  display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between;
                    .speaker { -webkit-box-flex: 1; -moz-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; text-align: center; }
                  }
                  
                  .t3-content-Image-slider{ background: transparent url(/-/media/8E1832CDD87448D2993C62E97C087729.ashx) 0 0 scroll no-repeat; margin: 50px -30px; height:310px; width:960px; 
                    a.slider-control.control-next { right: 40px; background: url(/-/media/4606EBD61AB24F099D597B1EB1EEC17E.ashx) no-repeat scroll 0 0; }
                    a.slider-control.control-prev { left: 40px; background: url(/-/media/0B271FEF459F4E648DB146BC8456E4A4.ashx) no-repeat scroll 0 0; }
                    a.slider-control {
                      text-decoration: none;
                      position: absolute;
                      background: url(/App_Themes/Default/Images/ccss/previous-next-slide.png) no-repeat scroll 0 0;
                      cursor: pointer;
                      display: block;
                      height: 46px;
                      top: 320px;
                      width: 41px;
                      z-index: 10;
                      clear: both;
                    }

                    ul { margin:0; padding:0; position: relative;
                      li {margin:0; padding:0; height: 310px; width:960px; float: left; background-image: none;
                        .content-slide { width:600px; display: table; height: 310px; position: absolute; margin: 0 180px;
                          .content-slide-text { display: table-cell; text-align: center; vertical-align: middle; font-size: 20px; color: #fff; line-height: 30px;}
                        }
                        
                      }
                    }
                  }

                  .t3-content-slider-wrapper { background: transparent url(/-/media/8E1832CDD87448D2993C62E97C087729.ashx) 0 0 scroll no-repeat; margin: 0px -30px -35px -30px; height:310px; width:960px; position: relative;
                    .t3-content-slider { margin: 0 auto; height:310px; width:650px; overflow:hidden;
                      a.slider-control.control-next { right: 40px; background: url(/-/media/4606EBD61AB24F099D597B1EB1EEC17E.ashx) no-repeat scroll 0 0; }
                      a.slider-control.control-prev { left: 40px; background: url(/-/media/0B271FEF459F4E648DB146BC8456E4A4.ashx) no-repeat scroll 0 0; }
                      a.slider-control {
                        text-decoration: none;
                        position: absolute;                  
                        cursor: pointer;
                        display: block;
                        height: 72px;
                        top: 120px;
                        width: 72px;
                        z-index: 10;
                        clear: both;
                      }

                      ul { margin:0; padding:0; position: relative;
                        li {margin:0; padding:0; height: 310px; width:650px; float: left; background-image: none;
                          .content-slide { height: 310px; position: absolute; 
                            .content-slide-text { text-align: center; vertical-align: middle; font-size: 20px; color: #fff; line-height: 30px;}
                          }
                          
                        }
                      }
                    }
                  }

                  .t3-Marriott-slider-wrapper { background: transparent url(/-/media/8E1832CDD87448D2993C62E97C087729.ashx) 0 0 scroll no-repeat; margin: 0px -30px 0 -30px; height:500px; width:960px; position: relative;
                    .t3-content-slider { margin: 0 auto; height:500px; width:960px; overflow:hidden;
                      a.slider-control.control-next { right: 40px; background: url(/-/media/4606EBD61AB24F099D597B1EB1EEC17E.ashx) no-repeat scroll 0 0; }
                      a.slider-control.control-prev { left: 40px; background: url(/-/media/0B271FEF459F4E648DB146BC8456E4A4.ashx) no-repeat scroll 0 0; }
                      a.slider-control {
                        text-decoration: none;
                        position: absolute;                  
                        cursor: pointer;
                        display: block;
                        height: 72px;
                        top: 210px;
                        width: 72px;
                        z-index: 10;
                        clear: both;
                      }

                      ul { margin:0; padding:0; position: relative;
                        li {margin:0; padding:0; height: 500px; width:960px; float: left; background-image: none;
                          .content-slide { height: 500px; position: absolute; 
                            .content-slide-text { text-align: center; vertical-align: middle; font-size: 20px; color: #fff; line-height: 30px;}
                          }
                          
                        }
                      }
                    }
                  }

                  
                  .t3-schedule {  display: table-cell; display: -webkit-box; display: -moz-box;  display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; margin: 30px 0px;
                    .day-schedule { -webkit-box-flex: 1; -moz-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; border-right: 1px solid black; text-align: center;
                      &:last-child { border-right: none; }
                      h3 { text-align: center; color: #525252; font: normal 20px/36px Arial, Helvetica, sans-serif;}
                      ul { text-align: center; padding: 0 5px; margin: 0;
                        li { font-size: 14px; color: #525252; font-family: initial; background: none; padding: 5px 0; font-family: Arial, Helvetica, sans-serif;}
                      }
                    }
                  }

                  div.leftaline { padding-left: 30px; text-align: left;
                    h3 {text-align: left; color: #525252; font: normal 20px/30px Arial, Helvetica, sans-serif; display: inline-block; }
                    p { padding: 0; text-align: left; }
                    ul.arrow-list { text-align: left; padding: 0 20px; margin: 0;
                      li {
                        list-style: none;
                        background: transparent url(/App_Themes/Default/Images/ccss/chevrons-bullet.png) 0px 9px scroll no-repeat;
                        padding: 5px 20px;
                        margin: 0;
                        line-height: 16px;
                        font-size: 14px;  
                      }
                    }
                  }

                  .t3-flexbox {  display: table-cell; display: -webkit-box; display: -moz-box;  display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; margin: 30px 0px;
                    .t3-flex { -webkit-box-flex: 1; -moz-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1; text-align: center;
                      a { text-decoration: none;}
                      a:-webkit-any-link{ text-decoration:none; }
                      .leftaline { padding-left: 30px; text-align: left;
                        p { padding: 0; text-align: left; }
                        h3 {text-align: left; color: #525252; font: normal 20px/36px Arial, Helvetica, sans-serif;}
                        ul.arrow-list { text-align: left; padding: 0 20px; margin: 0;
                          li {
                            list-style: none;
                            background: transparent url(/App_Themes/Default/Images/ccss/chevrons-bullet.png) 0px 9px scroll no-repeat;
                            padding: 5px 20px;
                            margin: 0;
                            line-height: 16px;
                            font-size: 14px; 
                          }
                        }
                      }
                      p { padding: 0 10px; }
                      h3 { text-align: center; color: #525252; font: normal 20px/36px Arial, Helvetica, sans-serif;}
                      ul { text-align: center; padding: 0; margin: 0;
                        li { font-size: 14px; color: #525252; font-family: initial; background: none; padding: 5px 0; font-family: Arial, Helvetica, sans-serif;}
                      }
                    }
                    .t3-flex.verticle-line { border-right: 1px solid black; }
                  }
                }
              }
            }
          }
        }
      }
      
    }
    .sublayout-etfootermodule.columns-4 {
      .sublayout-etfootercolumn.column-1 {
        width: 250px;
        .etfootercolumnsection { margin: 0 -10px 0 0;}
      }
      .sublayout-etfootercolumn { padding: 0 15px 0 20px; }
    }  
  }

}

.t3-2017 {
	.sublayout-t3page {
		.sublayout-t3contenthero {
			.t3contentheader {
				background: transparent url(/-/media/516AC86C246343D8821AA5B41FCF82DB.ashx) center top scroll no-repeat;
			}
		}
	}
}

.sublayout-t3page { position: relative; 
  sup {
    vertical-align: super;
    font-size: 10px;
  }
  .sublayout-t3contenthero { background: transparent url(/-/media/7767334BC7554B92ADC3E6A0E68D1B8F.ashx) 0 0 scroll no-repeat; height: 310px; overflow: hidden; 
    .t3contentheader { height: 58px; display: block;
      .t3contentheadernav { display: block; height: 25px; padding: 18px 10px;
        .t3pageheading { width: 300px; float: left; font: 17px Arial,Helvetica,sans-serif; color: #FFFFFF; }

        .t3pagesubheading { width: 300px; float: left; font: 17px Arial,Helvetica,sans-serif; color: #000000; }

        .ethyperlinklist {display: block; height: 20px; float: right;  
          ul { color: #fff;
            li { background: url(/App_Themes/Default/Images/general/t3-register-bug.png) no-repeat scroll 5px 4px; height: 20px; list-style: none outside none;
              a { color: #fff; float: right; font: bold 17px Arial,Helvetica,sans-serif; text-decoration: none; padding-left:20px;
              }
            }
          }
        }
      }
    }

    .t3contenthero { height:252px;
      ul.jecarousel, ul.jeslideshow { min-height: 252px;
        li { width: 960px; background-repeat: no-repeat; }
      }
    }

    .jecarousel-buttons, .jeslideshow-buttons { bottom: auto; height: 25px; list-style: none outside none; position: absolute; left: 450px; top: 283px; z-index: 101;
      li {
        &.selected{
            background: #FF8787;
        }
        background: none repeat scroll 0 0 #FF3E3E;
        text-indent: -9999px;
        display: block;
        width: 10px;
        height: 10px;
        margin: 5px 3px;
        outline: 0;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        float:left;
        span {
            display: none;
        }
      }
    }
  }
  
  .sublayout-t3tabnavigation {
    .etproducttabnavigation { background: transparent url(/App_Themes/Default/Images/general/t3-tab-nav-bg.jpg) 0 0 scroll no-repeat; height:55px; display: block; overflow: hidden; position: relative;

      ul { height: 55px; width: 960px;            
        li { float: left; text-align: center; width: 192px;
          a { display: block; color: #888888; font: 17px/19px Arial,Helvetica,sans-serif; text-decoration: none; padding-top: 15px;}
          &.active a,
          a:hover,
          a:active,
          a:focus { color: #fff; text-decoration: none; }
          &.arrow { background: transparent url(/App_Themes/Default/Images/General/t3-tab-nav-active.png) 0 0 scroll no-repeat; position: absolute; display: block; width: 21px; height: 12px;  left: 0; bottom: 0; @include transition(left .5s ease-in); }

          &.active { 
            + .arrow { left: #{192 * 4 + 90}px;  }
            + li + .arrow { left: #{192 * 3 + 90}px;  }
            + li + li + .arrow { left: #{192 * 2 + 90}px;  }
            + li + li + li + .arrow { left: #{192 * 1 + 90}px;  }
            + li + li + li + li + .arrow { left: #{192 * 0 + 90}px;  }
          }
        }
      }
    }
  }
  
  .sublayout-etproducttabhero.etheroimageside-left {
    .etheroimage { float: left; margin: 40px 0 50px 80px !important; }      
  }

  .sublayout-etproducttabhero {
    padding: 0 0 0 0; 
    background-color: #e3e4e5;
    width:960px;

    .etheroimage { float: left; margin: 40px 0 50px 80px; }

    .etproducttabhero-maincontent {
      .t3modulecontent { padding: 10px 30px 20px 0px; float: right; 
        p { line-height: 1.8em; font-size: 14px;}
        .t3sessionsection { min-height:250px; margin:30px 20px;
          .t3sessionsectionimage { width:250px; float:left;}
          .t3sessionsectionlist { width:600px; float:right;
            ul {
              li {
                background: url(/App_Themes/Default/Images/product-detail/list-double-bullet.png) no-repeat scroll 8px 8px transparent;
                line-height: 1.8em;
                font-size: 14px;
                list-style: none outside none;
                margin: 0 0 2px;
                padding: 0 0 0 20px;
                ul {
                  li {
                    background: url(/App_Themes/Default/Images/product-detail/list-bullet.png) no-repeat scroll 8px 10px transparent;
                    line-height: 1.8em;
                    font-size: 14px;
                    list-style: none outside none;
                    margin: 0 0 2px;
                    padding: 0 0 0 20px;
                  }
                }
              }
            }
          }
        }

      }

    }        
  }
  .etproducttab {
  .red-button {
        background: #cc0f12;
        color: white !important;
        font: 14px/17px Verdana,Helvetica,sans-serif;
        font-weight: bold;
        margin-left: 100px;
        padding: 7px 12px 7px 12px;
        border: solid 3px #e7e7e7;
        -ms-border-radius: 17px;
        -moz-border-radius: 17px;
        -webkit-border-radius: 17px;
        -o-border-radius: 17px;
        -khtml-border-radius: 17px;
        border-radius: 17px;
        display: inline-block;
      }
    .sublayout-etproductcontentmodule-variablecolumn.columns-3 {
      .etproductcolumn {
        background: none;
        padding: 10px 0px 10px 60px;
        width: 245px;
      }
    }
    .sublayout-etproductcontentmodule-variablecolumn.columns-2 {
      .etproductcolumn {
        background: none;
      }
    }
    .etproductcontentmodule-inner {
      .etmoduleheading {
        color: #666766;
        font: bold italic 36px/36px Arial,Helvetica,sans-serif;
        margin: 0 30px 21px;
      }
      .airporttransport { width:500px; float:right;
        div { margin:10px 0; }
        table { background-color: #e3e4e5; width:420px;
          th { background-color: #6a6a6a; color:white; padding: 20px;}
          td { padding:3px 20px;}
        }
      }
      .lightgraytext { color:#8c8c8c; font: italic 12px/18px Verdana,Helvetica,sans-serif; }
      .blacktext { color:black; font: 12px/18px Verdana,Helvetica,sans-serif; } 
      .registernowbutton {
        background: #cc0f12;
        color: white !important;
        font: 14px/17px Verdana,Helvetica,sans-serif;
        font-weight: bold;
        margin-left: 100px;
        padding: 7px 12px 7px 12px;
        border: solid 3px #e7e7e7;
        -ms-border-radius: 17px;
        -moz-border-radius: 17px;
        -webkit-border-radius: 17px;
        -o-border-radius: 17px;
        -khtml-border-radius: 17px;
        border-radius: 17px;
        display: inline-block;
      }

      .etproductcontentcolumn-maincontent {
          .sessionssectionodd { width: 960px; clear: both; margin-left: -30px; padding: 20px 0px; background-color:#EEEEEE; }
        .sessionssectioneven { width: 960px; clear: both; margin-left: -30px; padding: 20px 0px; background-color:#e3e4e5; }
        .sessionleftcolumn { width:200px; display: inline-block; padding-left: 25px; vertical-align: top;}
        .sessionmiddlecolumn { width:500px; display: inline-block;
          .sessiontitle { font: bold 17px/30px Arial,Helvetica,sans-serif; color:#666766; }
          .sessionpresenter {font: bold 12px/30px Arial,Helvetica,sans-serif; color:#000000;}
          .sessiondescription { font: italic 14px/30px Arial,Helvetica,sans-serif; color:#5C5C5C;}
        }
        .sessionrightcolumn { width:225px; display: inline-block; vertical-align: top;}
      }         
    }
    .etproductvariablecolumncontentmoduletheme-darkgray { background:none; background-color: #333333; }
    .etproductvariablecolumncontentmoduletheme-lightgray { background-color: #e3e4e5; }
  }

}


.sublayout-etproducttabfolder {
   .scLooseFrameZone { overflow: hidden; }

   .etproducttabfolder-ajax { position: relative;
      &.loading { 
         &:before { content:" "; display: block; right: 0; left: 0; bottom: 0; top: 34px; position: absolute; z-index: 998; background: #ccc; opacity: .5; filter:alpha(opacity=50); }
         
         &:after { content:" "; display: block; right: 0; left: 0; bottom: 0; top: 0; position: absolute; z-index: 999; background: transparent url(/App_Themes/Default/Images/General/ajax-loader.gif) center 45px scroll no-repeat; padding: 69px 0 0; }

      }

   }

   .sublayout-etproducttabfolderhero {
      .ethyperlinklist {
         .webedit-helper { margin: 4px 2px 3px; }

         .jump-list { padding: 0 0 15px 30px;
            ul {
               li {
                  a { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }

               }

            }

         }

      }

   }

}

.t3contenthero .sublayout-etproducttabfolderhero { min-height: 400px; }

.etproducttabfoldertheme-basic {
   .sublayout-etproducttabfolderhero { padding: 20px 540px 8px 36px; /* min-height: 400px; */ background: transparent none 0 0 scroll no-repeat;
      .etproducttabfolderheading { margin: 0 0 9px;
      
         &,
         p { font-size: 36px; line-height: 42px; font-weight: 400; }

      }

      .etproducttabfolderintroductioncontent { font-size: 18px; line-height: 24px; margin-bottom: 20px; padding-top: 12px;
         p { margin: 20px 0 0; line-height: 1.4em; }

      }

   }

   .sublayout-etproducttabnavigation { position: relative; z-index: 101;
      ul { position: relative; width: 940px; height: 29px; padding: 0 0 0 20px; background: #f9f9f9 url(/App_Themes/Default/Images/product-detail/product-tab-navigation-bg.png) 0 0 scroll repeat-x;

         li { float: left; margin: 8px 0 0; height: 16px; border-left: 1px solid #ccc;
            > .scLooseFrameZone { margin: -8px 0 0; padding: 8px 0 0; height: 22px; }

            &:first-child { border-left: 0 none; }

            a { display: block; padding: 5px 25px 13px; color: #525252; font-size: 13px; line-height: 18px; margin: -6px 0 0; }

            &.active a,
            a:hover,
            a:active,
            a:focus { background: #fff; }

         }

      }

   }

}

.sublayout-etproducttab { position: relative; z-index: 100; background: transparent url(/App_Themes/Default/Images/product-detail/hr-shadow-line.png) 0 0 scroll no-repeat;
   .content {
      &,
      p,
      li { font: 12px/18px Verdana,Arial,Helvetica,sans-serif; }

      ul { list-style: none; padding: 0; margin: 0 0 20px;
         li { background: transparent url(/App_Themes/Default/Images/product-detail/list-bullet.png) 8px 7px scroll no-repeat; padding: 0 0 0 20px; line-height: 20px; list-style: none; margin: 0 0 2px; }

         ul { padding: 0 0 0 20px; margin-bottom: 0; }

      }
   }

}

.sublayout-etproducttabhero { padding: 28px 0 0 30px; overflow: hidden; width: 930px;
   .etproducttabtheme-basic { 
      .etheroimageside-left {
         .etheroimage { float: left; margin: 0 0 0 30px; }
      }
   }

   &.etcolumnimageside-left {
      .etcolumnimage { float: left; margin: 0 10px 0 0; }

   }


   &.etheroimageside-right {
      .etheroimage { float: right; margin: 0 0 0 10px; }

   }

   &.etheroimageside-top {
      .etheroimage { }

   }

   &.etheroimageside-bottom {
      .etheroimage { }

   }

   .etproducttabhero-maincontent { padding: 0 20px; overflow: hidden;
      .etpageheading { color: #cb0c0f; padding: 5px 0 14px 0; font-weight: 400; font-size: 27px; line-height: 1em; }

      .etmaincontent {
         .scWebEditInput { display: block; }
      }

      .sublayout-etcalloutstack { float: right; width: 240px; margin: -4px -9px 0 20px; padding: 10px 0 31px 20px; background: transparent url(/App_Themes/Default/Images/product-detail/vertical-hr2.png) 0 11px scroll no-repeat;
         .etcallout-content { position: relative; padding: 1px 0 10px;
            .etcallout-link:hover,
            .etcallout-link:active,
            .etcallout-link:focus { text-decoration: none; }

            header {
               .hgroup {
                  h1,
                  h2 { color: #cb0c0f; font-weight: bold; }

               }

            }

            .teaserimage-left { padding-left: 95px;
               .etcallout-teaserimage { position: absolute; left: 0; top: 1px; }

            }

            .teaserimage-right { padding-right: 95px;
               .etcallout-teaserimage { position: absolute; right: 0; top: 1px; }

            }

            .content,
            .content p,
            .content li,
            h1,
            h2 { font: 12px/18px Arial,Helvetica,sans-serif; }

            .etcallout-body { color: #555; }

         }

      }

      .ethyperlinklist {
         .webedit-helper { margin: 4px 2px 3px; }
         .jump-list { padding: 26px 0 15px 30px;
            ul {
               li { padding: 0;
                  a { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }
               }
            }
         }
      }
   }

}

.etproducttabtheme-basic {
   .sublayout-etproducttabhero { padding: 30px 0 0;
      &.etheroimageside-left {
         .etheroimage{ margin: 0 0 0 30px; float: left;}
      }
      .etheroimage { margin: 5px 0 0; }

      .etproducttabhero-maincontent {
         .etpageheading {  }

      }
   }
}

.etproducttabtheme-hero {
   .sublayout-etproducttabhero {

      &.etheroimageside-left,
      &.etheroimageside-right {
         .etheroimage { height: 1px; float: left; }

      }

      .etproducttabhero-maincontent {
         .etpageheading { line-height: 32px; font-weight: bold; margin:0px; font-family: Arial, Helvetica, sans-serif; }

      }

   }

}

.etproductcontentmodule { clear: both; background: transparent url(/App_Themes/Default/Images/product-detail/content-module-divider.png) 0 1px scroll no-repeat; padding: 3px 0 0; position: relative;
   &.webedit-helper { margin: 0 2px; }

   .etproductcontentmodule-inner { padding: 26px 0 0;
      > .scLooseFrameZone { margin: -26px 0 -33px 0; padding: 26px 0 33px 0; }

      .webedit-helper { margin: 0 4px; display: block; text-align: center; }

      .etmoduleheading { color: #cb0c0f; font: 27px/27px Arial,Helvetica,sans-serif; margin: 0 30px 21px; }

      .etproductcolumns { @include clearfix;
         .etproductcolumn { float: left;

         }

      }

   }

}

.sublayout-etproductcontentmodule-variablecolumn { padding-bottom: 33px;
   .sublayout-etproductcontentcolumn { background: transparent url(/App_Themes/Default/Images/product-detail/vertical-hr.png) 10px 0 scroll no-repeat;
      &.column-1 { background: none; }

      .etcolumnheading { color: #cb0c0f; margin: 0 0 12px; font: 18px/20px Verdana,Arial,Helvetica,sans-serif; }

      .etmaincontent {
         .scWebEditInput { display: block; }
         a {
          .learn-more {  padding: 0 0 0 21px; margin: 0px 0px 0px 1px; display: block; font: bold 12px/20px Arial,Helvetica,sans-serif; background: url(/App_Themes/Default/Images/more-arrows.gif) no-repeat 0px 6px; color: #c52325; }
         }

         ul { padding: 0 0 0 20px; }

    h1.split { color: #cb0c0f; font: 27px/27px Arial,Helvetica,sans-serif;
      &:before { content: ""; display: block; background: #fff url(/App_Themes/Default/Images/product-detail/content-module-divider.png) 0 0 scroll no-repeat; height: 25px; }

    }

      }

      .learnmore-link,
      .download-link { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }

      .ethyperlinklist {
         .webedit-helper { margin: 4px 2px 3px; }

         .jump-list { padding: 26px 0 15px 30px;
            ul {
               li {
                  a { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }

               }

            }

         }

      }

      .etcolumnimage { display: block; }

      .etproducttabtheme-basic { 
         .etcolumnimageside-left.sublayout-etproducttabhero {
            .etcolumnimage { float: left; margin: 0 0 0 30px; }
            .etproducttabhero-maincontent { padding: 0 20px;
               .etpageheading { padding: 5px 0 14px 0; }
            }
   
         }
      }

      &.etcolumnimageside-left {
         .etcolumnimage { float: left; margin: 0; }
      }
    
    &.etcolumnimageside-left { display: -webkit-flex; display: flex;
    .etcolumnimage { margin: 0; -webkit-flex: 1 0 auto; flex: 1 0 auto;}
    
    .etproductcontentcolumn-maincontent { -webkit-flex: 0 1 auto; flex: 0 1 auto; }

    .no-flexbox & {
      .etcolumnimage {float: left; margin: 0;}
      
    }
    
   }    

      &.etcolumnimageside-right {
         .etcolumnimage { float: right; margin: 0 0 0 10px; }

      }

      &.etcolumnimageside-top {
         .etcolumnimage { margin: 0 auto 17px; }

      }

      &.etcolumnimageside-bottom {
         .etcolumnimage { }

      }

   }

   .sublayout-etproductspecificationscolumn {
      .etproductspecifications {
         .sublayout-etproductspecification {
            .etproductspecificationimage { display: block; }

            .etproductspecificationheading { font: 21px/25px Arial,Helvetica,sans-serif; font-weight: bold; }

            .etproductspecificationcontent {
               ul { padding: 0 0 0 12px; }

            }

         }

      }

   }

   &.columns-1 {
      h1.etcolumnheading { font: 27px/27px Arial,Helvetica,sans-serif; margin: 0 0 10px; }

   }

   @for $column-count from 0 through 4 {
      $effective-column-count: max( $column-count, 1 );
      &.columns-#{$column-count} {
         $column-padding-top: 0;
         $column-padding-right: 15px;
         $column-padding-bottom: 0;
         $column-padding-left: 30px;
         $last-column-padding-right: 36px;
         $outer-width: 960px;
         $column-width: ( ( $outer-width - ( ( $column-padding-left + $column-padding-right ) * $effective-column-count ) ) - $last-column-padding-right + $column-padding-right ) / $effective-column-count;

         .etproductcolumn { width: $column-width; padding: #{$column-padding-top} #{$column-padding-right} #{$column-padding-bottom} #{$column-padding-left};
            > .scLooseFrameZone { margin: #{-($column-padding-top + 10px)} #{-($column-padding-right + 10px)} #{-$column-padding-bottom} #{-($column-padding-left - 10px)}; padding: #{($column-padding-top + 10px)} #{($column-padding-right + 10px)} #{($column-padding-bottom + 15px)} #{($column-padding-left - 10px)}; }

         }

         .column-#{$effective-column-count} { padding-right: $last-column-padding-right;
            > .scLooseFrameZone { margin-right: -$last-column-padding-right; padding-right: $last-column-padding-right; }

         }

      }

   }

}

.etproductvariablecolumncontentmoduletheme-verticalgradienttop {
   .etproductcontentmodule-inner { background: transparent url(/App_Themes/Default/Images/product-detail/verticalgradienttop-bg.png) 0 0 scroll no-repeat; min-height: 41px; }

}

.etproductvariablecolumncontentmoduletheme-blank { background: transparent none 0 0 scroll no-repeat;
   .etproductcontentmodule-inner { background: transparent none 0 0 scroll no-repeat; }

}

.etproductvariablecolumncontentmoduletheme-none { background: transparent none 0 0 scroll no-repeat;
   .etproductcontentmodule-inner { background: transparent none 0 0 scroll no-repeat; }

}

.etproductcontentcolumntheme-fineprint {
   .content,
   .content p,
   .content li,
   h1,
   h2 { font-size: 10px; }

}

.sublayout-etproductcontentmodule-banner { padding: 0; background: transparent none;
   .etproductcontentmodule-inner { padding: 0 0 2px;
      > .scLooseFrameZone { margin: -5px; padding: 5px;
         .etmoduleheading { margin: 5px; }

      }

      .etmoduleheading { position: absolute; font: 24px/28px Arial,Helvetica,sans-serif; margin: 0; left: 20px; top: 15px; }

   }

   &.etproductbannercontentmoduletheme-whitetext { background: #fff;
      .etmoduleheading { color: #fff; }

   }

   &.etproductbannercontentmoduletheme-redtext { background: #fff;
      .etmoduleheading { color: #cb0c0f; }

   }

   &.etproductbannercontentmoduletheme-graytext { background: #fff;
      .etmoduleheading { color: #525252; }

   }

   &.etproductbannercontentmoduletheme-blacktext { background: #fff;
      .etmoduleheading { color: #000; }
   
   }

}

.sublayout-etproductcontentmodule-relationship {
   .etproductrelationship-contentmodules {
      > .webedit-helper { margin: 6px 2px; }
   }

}

.sublayout-blogcontent {
  min-height: 450px;
  overflow: hidden;
  ul.jecarousel, ul.jeslideshow {
    min-height: 450px;
    li {
      width: 960px;
      background-repeat: no-repeat;
    }
  }
  li.left .sublayout-etproducttabfolderhero {
    padding: 20px 20px 8px 536px;
  }
  .jecarousel-buttons, .jeslideshow-buttons {
    bottom: auto;
    height: 25px;
    list-style: none outside none;
    position: absolute;
    right: 20px;
    top: 525px;
    z-index: 101;
    li {
      &.selected {
        background: none repeat scroll 0 0 #CB0C0F;
        height: 8px;
        width: 8px;
      }
      background: none repeat scroll 0 0 transparent;
      border: 1px solid #525252;
      display: block;
      float: left;
      height: 8px;
      margin: 0 0 0 5px;
      width: 8px;
      span {
        display: none;
      }
    }
  }
}

.sublayout-etformmodule-reference .etformmodulereference {
  .scfForm {
    padding: 20px 0 20px 20px;
    width: 50%;
  }
  .scfTitleBorder {
    color: #CB0C0F;
    font: 27px/27px Arial, Helvetica, sans-serif;
  }
  .scfSingleLineTextBorder {
    padding-bottom: 25px;
  }
}

.device-mobile {
	.sublayout-etproducttabfolder {
		.etproducttabfolder {
			.etproducttabfolderintroductioncontent a span.RedButton, 
			.ethyperlinklist .jump-list ul li a { font-size: 2.2em; line-height: 1.1; padding: 15px 17px; text-decoration: none; }

			.sublayout-etproducttabfolderhero { font-size: 1.2em; line-height: 1.1; padding: 20px 0px;
				.content {
					.etproducttabfoldermobileheroimage img { height: auto; width: 100%; }
					.etproducttabfolderheading, .etproducttabfolderintroductioncontent p { padding: 0px 20px; }
					.etproducttabfolderintroductioncontent { padding: 20px 15px 0px; }
					.etproducttabfolderheading { font-size: 3.4em; padding-top: 20px; }
					.etproducttabfolderintroductioncontent p, .etproducttabfolderintroductioncontent strong { font-size: 1.8em; line-height: 1.1; }
					.etproducttabfolderintroductioncontent strong strong { font-size: .55em; }
				}
			}

			.etproducttabtheme-hero {
				.sublayout-etproducttabhero,
				.sublayout-etproductcontentmodule-banner { display: none; }
			}

			.etproductcontentmodule .etproductcontentmodule-inner .etproductcolumns {
				.etproductcolumn { float: none; padding: 0px 20px; width: 100%; 
					.etcolumnimage { float: none; 
						img { height: auto; width: 90%; }
					}
					.etproductcontentcolumn-maincontent {
						.etcolumnheading { font-size: 3.6em; line-height: 1.1; padding: 20px 25px 20px 0px; }
						.etmaincontent { font-size: 2em; line-height: 1.1; padding-right: 25px;
							ul li, p, p span { font-size: 1em !important; line-height: 1.1; }
							.learn-more { font-size: .8em; }
							.TableHeading { font-size: 1.2em; }
						}
					}
				}
			}
		}
	}
}