﻿.sublayout-etmydownloadslisting, .sublayout-etmyactivitieslisting{
	.etdownloadheader { padding: 0px 0px 18px 18px;
		.etpageheading {
			float: left;
			color: #CB0C0F;
			font-size: 1.3em;
			font-weight: bold;
			letter-spacing: -0.125mm;
		}
	}
}

.sublayout-etmyactivitieslisting{
	.activity-search-results tbody tr td h4 a{
		font-size: 1em
	}
	
	.no-results { padding: 0 18px; }
}