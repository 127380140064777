﻿.generic-content-module {
	
	font-family: Verdana;
	font-size: 12px;
	background: url('/App_Themes/Default/Images/press/hr-rule.png') no-repeat -10px 0;
	line-height: 18px;

	h2
	{
		font-size: 20px;
		color: #d51800;
	}

	figure[class*="right"] 
	{
		float: right;
	}

	figure[class*="left"] 
	{
		float: left;
	}

	figure[class*="top"] 
	{
		padding-bottom: 20px;
	}

	.module-content
	{
		color: #525252;
	}

	.links
	{
		padding-left: 18px;
		a 
		{
			font-weight: 700;
			color: #d51800;
			background-image: url('/App_Themes/Default/Images/press/chevrons2.png');
			background-repeat: no-repeat;
			background-position: 0px 4px;
			padding-left: 15px;
			height: 20px;
		}
	}

}