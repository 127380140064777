﻿@import "../../fn";

.ui-dialog {
	.ui-dialog-title { font-size: 15px; line-height: 17px; }
	.loading { background:transparent url(/App_Themes/Default/Images/General/ajax-loader.gif) center center scroll no-repeat;
		* { visibility: hidden !important; }
	}
	.error { color: #f00; font-weight: bold; white-space: pre-line; display: block; clear: none; }
	input[type="button"],
	input[type="reset"],
	input[type="submit"],
	button,
	.button,
	.reset,
	.submit { display: inline-block; width: auto; padding: 0 17px; border: 1px solid #828488; @include border-radius(4px); color: #6e6e6e; cursor: pointer; font-weight: bold; height: 22px; line-height: 22px; text-align: center; background: url(/App_Themes/Default/Images/General/button-bg.gif) no-repeat scroll 0 0 transparent; white-space: nowrap; }

	.ui-dialog-content { display: flex !important; font-size: 12px; line-height: 15px;
		> .ui-icon { width: 16px; height: 16px; margin: 0 8px 0 0; }

	}
}


@media print {
	.ui-dialog {
		.ui-dialog-content { display: none; }
		.dialog-modal { display: block !important; background: #fff !important; position: fixed !important; width: 100% !important; height: 100% !important; right: 0 !important; bottom: 0 !important; left: 0 !important; top: 0 !important; }
	}
	.ui-widget-overlay { display: none; }
}

@import "sign-in";
@import "software-license";
@import "form-dialog";
@import "eula";