﻿.sublayout-etproductcontentsection { color: #333; position: relative;
	.etproductcontentsection { padding: 1px 0 5px; position: relative;
		&:after { clear: both; display: block; width: 960px; height: 16px; background: transparent url(/App_Themes/Default/Images/Product-Content-Section/section-separator.png) scroll 16px 0 no-repeat; content: " "; position: absolute; left: 0; bottom: -9px; }

		.sublayout-etproductcontentsectionhero { position: relative; overflow: hidden; width: 960px; padding: 0;
			.etproductcontentsectionhero-content { width: 341px; float: left; position: relative; z-index: 5;
				.etpageheading { font-size: 36px; font-weight: 400; line-height: 42px; margin: 45px 0 0 39px; color: #444; }

				.etmaincontent { font-size: 17px; line-height: 26px; margin-bottom: 30px; padding: 0 0 0 41px; width: 300px;

				}

			}

			.sublayout-etimagemap { float: left; margin-left: -341px; z-index: 4; width: 960px; }

		}

	}

	.etproductcontentsectionmodule-list { clear: both; margin: -3px 0 0;
		.sublayout-etproductcontentsectionmodule { z-index: 5; background-repeat: no-repeat; clear: both; width: 960px; position: relative; padding: 2px 0 0;

			&.etmodulebackgroundimageanchor-top { background-position: 0 top; }

			&.etmodulebackgroundimageanchor-bottom { background-position: 0 bottom; }

			&.etmodulebackgroundimageside-left {
				.etproductcontentsectionmodule { padding-left: 509px; width: 451px; }
			}

			&.etmodulebackgroundimageside-right {
				.etproductcontentsectionmodule { padding-left: 34px; width: 926px; }
			}
			&:last-child { 
				.etproductcontentsectionmodule {
					&:after { content : none; }
				}
			};
			.etproductcontentsectionmodule { position: relative; z-index: 6; margin: 0; width: 960px;
				&:before { clear: both; display: block; width: 960px; height: 16px; background: transparent url(/App_Themes/Default/Images/Product-Content-Section/section-separator.png) scroll 16px 0 no-repeat; content: " "; position: absolute; left: 0; top: -42px; }
				&:after { clear: both; display: block; width: 960px; height: 16px; background: transparent url(/App_Themes/Default/Images/Product-Content-Section/section-separator.png) scroll 16px 0 no-repeat; content: " "; position: absolute; left: 0; bottom: -10px; }

				.etmoduleheading { font-size: 36px; font-weight: 400; line-height: 42px; margin: 35px 0 0 -1px; color: #cb1e02; }

				.etmodulecontent { width: 400px; margin: 9px 0 0; font-size: 15px; padding: 10px 0px;
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 { color: #cb1e02; }

					h1 { font-size: 36px; font-weight: 400; line-height: 42px; }

					h2 { font-size: 18px; padding: 5px 0 2px 0; margin: 0; }

					ul { width: 440px; padding: 0 0 0 7px; list-style-type: none; font-size: 13px; line-height: 20px;
						> li { background: transparent url(/App_Themes/Default/Images/product-detail/list-bullet.png) 8px 7px scroll no-repeat; padding: 0 0 0 20px; line-height: 20px; list-style: none; margin: 0 0 2px; }					
					}
				}

				.ethyperlinklist {
					.webedit-helper { margin: 4px 2px 3px; }

					.jump-list { padding: 1px 0 15px; margin: 0 0 0 -2px;
						ul {
							li {
								a { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }

							}

						}

					}

				}

			}

		}

	}

}