﻿* { @include box-sizing(content-box); }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section { display: block; }

body { line-height: 1; }

dl,
ol,
ul { list-style: none; }

blockquote,
q { quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after { content: ''; content: none; }

html,
body,
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime-local"],
input[type="number"],
input[type="range"],
input[type="color"],
textarea,
select { background-color: #fff; color: #525252; }

input,
textarea,
select,
button { font-size: 12px; font-family: Arial, Helvetica, sans-serif; 
	&:lang(zh-CN){font-size: 13px; font-family: Arial, Helvetica, STXihei, 华文细黑, 宋体, SimSun, sans-serif;}
	&:lang(ar){font-size: 13px;}
}

html,
body { font-size: 9px; font-family: Verdana, Arial, Helvetica, sans-serif; 
	&:lang(zh-CN){font-size: 10.5px; font-family: Verdana, Arial, Helvetica, STXihei, 华文细黑, 宋体, SimSun, sans-serif;}
	&:lang(ar){font-size: 10.5px;}
}


table { border-collapse: collapse; border-spacing: 0;
	thead,
	tbody {
		th,
		td { text-align: left; font-weight: normal; }
	}
}

input[type="checkbox"] { background: transparent none; vertical-align: middle; }

input[type="radio"] { vertical-align: bottom; background: transparent; }

a:link,
a:visited { color: #1973b4; cursor: pointer; text-decoration: none;
	&:focus,
	&:active,
	&:hover { text-decoration: underline; }

}

a.aspNetDisabled {
	&,
	&:link,
	&:visited { cursor: default;
		&:focus,
		&:active,
		&:hover { text-decoration: none; }

	}

}

select:disabled,
select[disabled],
select.aspNetDisabled { background: #fff; color: #ccc; }

.main {
	input[type="button"],
	input[type="reset"],
	input[type="submit"],
	button,
	.button,
	.reset,
	.submit { display: inline-block; width: auto; padding: 0 18px; border: 1px solid #828488; @include border-radius(4px); color: #6e6e6e; cursor: pointer; font-weight: bold; height: 22px; line-height: 22px; text-align: center; background: url(/App_Themes/Default/Images/General/button-bg.gif) no-repeat scroll 0 0 transparent; white-space: nowrap; }
	.submitbutton 
	{ 
		font-family: Lato-bold;
		font-size: 16px;
		border-radius: 30px;
		-moz-border-radius: 30px;
		-webkit-border-radius: 30px;
		padding: 0px 24px 0px 14px;
		margin-bottom: 20px;
		display: inline-block;
		position: relative;
		color: #c00;
		text-align: center;
		border: solid 2px #c00;
		background-color: transparent;
		box-shadow: #c00;
		left:35%;
		height: 37px;
   		line-height:21px;
		background: url("");
		
	}
	.submitbutton:after {
		content: "»";
		font-size: 20px;
		letter-spacing: 2px;
		font-family: Lato;
		padding: 0 0 0 5px;
		position: absolute;
		top: 6px;
	}
	input[type="submit"],
	.submit { padding-right: 44px; background-position: right 0; }

}

a img { border: 0; }
p { margin: 0.25em 0; }

// Et tu, Firefox?
::-moz-focus-inner,
::-moz-focus-outer { border: 0; padding: 0; outline: 0; }


.device-mobile * { -moz-text-size-adjust: none; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; font-size-adjust: none; }
