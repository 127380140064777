﻿// TODO: Default content styles.
.content, html.form-page body { line-height: 1.4em;
	table { 
		caption { }

		thead {
			tr {
				th { }

				td { }

				th,
				td { }

			}

		}
		
		tfoot {
			tr {
				th { }

				td { }

				th,
				td { }

			}

		}

		tbody {
			tr {
				th { }

				td { }

				th,
				td { }

			}

		}

		th { font-weight: bold; }

		td { }

		th,
		td { vertical-align: top; }

	}

	ul { list-style-type: disc; padding: 0 0 0 20px;
		ul { list-style-type: circle;
			ul { list-style-type: square; }

		}

	}

	ol { list-style-type: decimal; padding: 0 0 0 20px;
		ol { list-style-type: lower-roman;
			ol { list-style-type: lower-latin;

			}

		}

	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 { font-weight: bold; font-size: 1.2em; margin: 0 0 .25em;

	}

	p { margin: 0 0 1em; }

	img { }

	b,
	strong { font-weight: bold; }

	i,
	em { font-style: italic; }

	u { text-decoration: underline; }

	s,
	strike,
	del { text-decoration: line-through; }

	small { font-size: x-small; }

	big { font-size: x-large; }

	abbr[title] { border-bottom: 1px dotted; }

	sup { vertical-align: super;
		small { font-size: .6em }
	}
	sub { vertical-align: sub;
		small { font-size: .6em }
	}
	input, textarea, select, button { font-size: 100%;}

	a.arrow{ padding-left: 12px; background: url("/App_Themes/Default/Images/General/red-arrow.gif") no-repeat left 2px;  }
	.bodyheading, .bodyheading2{font-size: (14em/12); font-weight: bold;}
	.bodyheading3, .bodybold{font-weight: bold;}
	.warningcopy { font-weight: bold; color: #cb0c0f; }
	.smallcopy { font-size: (9em/12); }
	.subhead {color: #cb0c0f;  font-weight: bold;}

	.indent{ padding-left: 1.5em; }
	

	table.light-border-table{border-width: 0 0 1px 1px; border-spacing: 0; border-collapse: collapse; border-style: solid; border-color: #BABABA;
		th { background-color: #EEE; }
		td, th { margin: 0; padding: 4px; border-width: 1px 1px 0 0; border-style: solid; border-color: #BABABA;}
	}
	table.dark-border-table {border-width: 0 0 1px 1px; border-spacing: 0; border-collapse: collapse; border-style: solid; border-color: #525252;
		td, th { background-color: transparent; margin: 0; padding: 4px; border-width: 1px 1px 0 0; border-style: solid; border-color: #525252;
		}
	}
	table.no-border-table{ border: 0;
		td, th { border: 0; }
	}
}
