﻿.presscenter {
	
	padding: 32px 24px 38px 42px;
	font-family: Verdana;
	font-size: 12px;
	color: #525252;
	line-height: 18px;

	h2
	{
		font-size: 20px;
		color: #d51800;
	}

	.maincolumn 
	{
		h1 { font-family: Arial; color: #d51800; font-size: 30px; line-height: 35px;}
		width: 66%;
		float: left;
		p { margin: 0; }
	}

	.vertical-line { background: url(/App_Themes/Default/Images/product-detail/vertical-hr.png) no-repeat; float: left; min-height: 350px; width: 1%; margin-left: 10px; padding-right: 10px; }

	.rightcolumn 
	{
		width: 30%;
		float: right;
		padding-top: 30px;
		p { margin: 0; }
		.sideheading { font-size: 20px; color: #d51800; }
	}

	.no-right-column
	{
		.maincolumn { width: 100%; }
		.vertical-line, .rightcolumn { display: none; }
	}

	.links
	{
		padding-left: 18px;
		a 
		{
			font-weight: 700;
			color: #d51800;
			background-image: url('/App_Themes/Default/Images/press/chevrons2.png');
			background-repeat: no-repeat;
			background-position: 0px 4px;
			padding-left: 15px;
			height: 20px;
		}
	}

.press-listing-content-module {
	
	background: url('/App_Themes/Default/Images/press/hr-rule.png') no-repeat -10px 0;

	.presslistings
	{
		padding-left: 15px;
	}

}

}

.press-release-news-item {

	padding: 15px 300px 15px 30px;
	font-size: 12px;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 18px;
	
	figure[class*="right"] 
	{
		float: right;
	}

	figure[class*="left"] 
	{
		float: left;
	}

	figure[class*="top"] 
	{
		padding-bottom: 20px;
	}

	h1 
	{
		font-size: 1.85185em;
		font-weight: bold;
		color: #CB0C0F;
		padding-bottom: .5em;
		line-height: 26px;
	}

	.maincontent
	{
		line-height: 14px;
	}

	.footnote
	{
		font-size: 10px;
	}

	.links
	{
		padding-left: 18px;
		a 
		{
			font-weight: 700;
			color: #d51800;
			background-image: url('/App_Themes/Default/Images/press/chevrons2.png');
			background-repeat: no-repeat;
			background-position: 0px 4px;
			padding-left: 15px;
		}
	}

}
