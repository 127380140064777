﻿.sublayout-etdownloadbundledetails { width: 936px; overflow: hidden; padding: 0 7px 0 17px;
	.etdownloadbundleheader { width: 960px; overflow: hidden; padding-bottom: 20px;
		.etpageheading { float: left; color: #cb0c0f; font-size: 1.3em;  font-weight: bold; letter-spacing: -.125mm; }
		.back-to-results { float: left; font-size: 1.1em; margin: 2px 0 0;
			&:before { content: ' '; display: inline-block; width: 0; height: 0; margin: 0 4px 0 8px; border: solid 4px transparent; border-right-color: #c00; position: relative; left: 0; top: -1px; }

		}
	}

	.etdownloadbundledetails-main-top { width: 100%; overflow: hidden; margin: 0 0 9px 0;
		.etheroimage { float: right; margin: 0 0 0 20px; }

		.etmaincontent { line-height: 1.4em; 
			.dc-side-promo { float: right; /*width: 400px;*/
				.teaserimage { /*width: 150px; */ float: left; }
				.content {width: 240px; float: left;
					h3 {font-size: 1.2em; color: #cb0c0f;}
				}
			}
		}

		.eula-notice { padding: 3px 0 0 15px; clear: both;
			&:before { content: ' '; display: block; width: 0; height: 0; margin: 0 -20px 0 8px; border: solid 4px transparent; border-left-color: #c00; float: left; position: relative; left: -21px; top: 4px; }

			.read { font-weight: bold; color: #1973b4; }
		}

		.eteula { display: none; float: left; }
		a.red-button { background: #cc0f12; color: #fff; font: 14px/17px Verdana,Helvetica,sans-serif; font-weight: bold; margin: 15px 0 0; padding: 7px 12px 7px 12px; border: solid 3px #e7e7e7; @include border-radius(17px); display: inline-block; }
	}

	.bundle-files { margin: 0 5px 15px 0; width: 100%; border-bottom: 1px solid #999;
		colgroup {
			col {
			    &.column-pdf,
			    &.column-onlinehelp,
				&.column-version,
				&.column-size,
				&.column-appsspaces { border-left: 1px solid #999; width: 70px; }

			}

		}

		thead {
			tr {
				th { padding: 8px 10px; font-weight: bold; background: url(/App_Themes/Default/Images/Downloads-Activities/heading-bg.gif) repeat-x scroll center bottom #a3a3a3; border-bottom: solid 1px #fff; color: #fff; border-left-color: transparent;
					&:first-child { @include border-radius(5px 0 0 0); }

					&:last-child { @include border-radius(0 5px 0 0); }

				}
				.column-pdf,
			    .column-onlinehelp,
				.column-version,
				.column-size,
				.column-appsspaces { text-align: center; border-left: 1px solid #999; width: 70px;}

			}

		}

		tbody {
			tr {
				td,
				th { padding: 10px 10px 8px; }

				th { line-height: 1.3em; font-weight: bold;
					a.download-file { display: block; font-weight: bold; background: url(/App_Themes/Default/Images/Downloads-Activities/icon-download.png) no-repeat scroll 0 0 transparent; padding: 0 0 0 20px; }
					.license-required { display: block; margin: 4px 0 0 20px;
						a { font-weight: bold; }
					}
				}
				.column-pdf,
			    .column-onlinehelp,
				.column-version,
				.column-size,
				.column-appsspaces { text-align: center; border-left: 1px solid #999; width: 70px;
					a {font-weight: bold;}
				}
				.column-pdf {
					a.download-pdf { background: transparent url(/App_Themes/Default/Images/Downloads-Activities/icon-pdf-download.png) 0 0 scroll no-repeat; 
						display: block; overflow: hidden; width: 24px; height: 24px; text-indent: -9999px; margin: 0 auto 3px;
					}
				}

			}

			&.etdownloaditems {
				tr {
					&:nth-child(odd) { background: #f2f2f2; }

				}

			}

			&.etrequired,
			&.etrelatedsoftware,
			&.etguidebooks { border-top: 1px solid #999;
				tr {
					&:nth-child(even) { background: #f2f2f2; }

					&:first-child {
						th { padding: 14px 10px 6px; }

					}

				}

			}

		}

	}

	.feature-summary {
		h2 { font-weight: bold; padding: 0 0 5px 0; }
	}

	.with-language-selector {
		.etdownloadbundledetails-main { width: 675px; float: left; }

		.language-selector { float: left; width: 230px; margin: 0 0 0 26px;
			h2 { padding: 5px 10px 4px; font-weight: bold; background: url(/App_Themes/Default/Images/Downloads-Activities/heading-bg.gif) repeat-x scroll center bottom #a3a3a3; color: #fff; @include border-radius( 5px 5px 0 0 ); }
			
			nav { background: url(/App_Themes/Default/Images/Downloads-Activities/language-selector-bg.png) repeat-x scroll 0 0 transparent; min-height: 151px;
				ul { padding: 0 0 0 20px;
					li { padding: 3px 0; line-height: 1.3em;
						a { font-weight: bold }

						&.selected { list-style-type: disc;
							&, a { color: #cb0c0f; }

						}

					}

				}

			}

		}

	}

}