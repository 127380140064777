﻿
.sublayout-etcertificate{
	a {
		color: #1973B4;
	}

	ul {
		margin: 0;
		list-style-type: none;
		padding: 0;
	}

	.container {
		width: 960px;
	}

	header {
		height: 74px !important;
	}

	h1 {
		color: #CB0C0F;
		font-size: 1.8em;
		margin: 20px;
	}

	.wrapper {
		background: #d0e0ec; /* Old browsers */
	background: -moz-linear-gradient(top,  #d0e0ec 0%, #f2f6fa 80%, #ffffff 90%, #ffffff 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d0e0ec), color-stop(80%,#f2f6fa), color-stop(90%,#ffffff), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #d0e0ec 0%,#f2f6fa 80%,#ffffff 90%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #d0e0ec 0%,#f2f6fa 80%,#ffffff 90%,#ffffff 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #d0e0ec 0%,#f2f6fa 80%,#ffffff 90%,#ffffff 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #d0e0ec 0%,#f2f6fa 80%,#ffffff 90%,#ffffff 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0e0ec', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

		display: inline-block;
		width: 100%;
	}
	.event-description{
		padding: 15px;
		font-size: 1.1em;
	}

	.wrapper .left-block {
		width: 59%;	
		min-height: 360px;
		background-color: #FFF;	
		padding: 5px;
		border-radius: 5px;
		float: left;
		margin: 8px 7px;
	}

	.wrapper .right-block {
		width: 37%;	
		margin-left: 10px;
		float: left;
		margin: 8px 7px;
	}

	.left-block h3 {
		border-bottom: 1px dotted black;
		color: #CB0C0F;
		font-style: italic;
		margin: 25px 0 0 15px;
		padding-bottom: 5px;
	}
	.left-block p {
		margin: 20px 0 0 15px;
	}

	.right-block div {
		background-color: #FFFFFF;
		border-radius: 5px 5px 5px 5px;
		height: auto;
		padding: 50px 20px 70px;
		position: relative;
	}
	.right-block label {
		font-weight: bold;
		padding-top: 30px;
		font-size: 1.1em;
	}
	.right-block input[type="text"], .right-block input[type="date"] {
		background: url("/App_Themes/Default/Images/Certificates/text-background.gif") no-repeat scroll 0 0 transparent;
		display: block;
		height: 28px;
		line-height: 28px;
		margin: 6px 0 0;
		padding: 0 9px;
		width: 292px;
	}
	.right-block p {
		font-size: 11px;
		line-height: 18px;
		margin-top: 20px;
		padding: 0 25px;
	}
	.right-block p.first {
		margin-top: 25px;
	}

	.right-block .generate {
		background: url("/App_Themes/Default/Images/Certificates/submit.gif") no-repeat scroll 0 0 transparent !important;
		border: 0 none !important;
		color: #FFFFFF !important;
		cursor: pointer !important;
		display: block !important;
		font-size: 19.07px !important;
		font-weight: normal !important;
		height: 28px !important;
		left: 3px !important;
		padding: 12px !important;
		position: absolute !important;
		//top: 124px !important;
		width: 330px !important;
		text-decoration: none !important;    
		text-align: center;
		margin-top: 20px;
	}
}