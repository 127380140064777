﻿.sublayout-etdownloadsactivitiesheader { position: relative; font-family: Arial,Helvetica,sans-serif;
	h1 { color: #cb0c0f; font-size: 1.3em; font-weight: bold; padding: 15px 18px 16px; }
	nav {
		ul { width: 954px; padding: 0 4px 13px 2px; overflow: hidden; background: url(/App_Themes/Default/Images/Downloads-Activities/header-tab-bar-bg.gif) no-repeat scroll 6px bottom transparent;
			li { float: left; margin: 0 0 1px 4px; border: solid 1px #c8c8c8; border-bottom: solid 1px #979797; @include border-radius( 5px 5px 0 0 ); background: url(/App_Themes/Default/Images/Downloads-Activities/header-tab-bg.gif) repeat-x scroll 0 bottom transparent;
				a { display: block; line-height: 22px; padding: 0 10px; color: #525252; }

				&.selected { border-color: #979797 #979797 #fff; background: #fff; }

				&.home { float: right; border: 0 none; background: transparent;
					a { color: #1973b4; height: 16px; line-height: 10px; padding: 0 20px; margin: 8px 0 0; background: url(/App_Themes/Default/Images/Downloads-Activities/header-home-icon.gif) no-repeat scroll right 0 transparent; }

				}

			}

		}

	}

}