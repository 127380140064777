﻿.sublayout-etsectiongroup-carousel{
	font-family: Verdana, Geneva, sans-serif;
	.etsectiongroup-heading{ padding: 30px 30px 5px 30px; 
		h1{ color: #CB0C0F; font-size: 24px; font-family: arial,helvetica,sans-serif; }
		.etsectiongroup-text{ width: 450px; line-height: 16px; }
	}
	.etsectiongroup-body{ background: url( "/-/media/8DFF54B70FBC4480B5A24C822F1F678B.ashx" ) repeat-x scroll; height: 185px; position: relative;
		.active-product-carat{ background: url( "/-/media/5A8BE305CF3B452D8EC1E76D949B5672.ashx" ) scroll no-repeat; height: 35px; width: 82px; position: absolute; top: 159px; margin-left: 28px; }
	}
	.etsection-list:after{ content:""; display: block; clear: both; }
	.etsection-list{ padding: 0 30px; 
		.etproductcategorysection, .etproductcontentsection { width: 100px; float: left; margin-top: 25px; 	
			a { color: #525252; text-decoration: none; }
			a:hover{ color:  #CB0C0F; }
			a.selected{ color:  #CB0C0F; }
			.hero {
				h1 { text-align: center; display: block; font: 12px/14px arial,helvetica,sans-serif; margin-top: 15px; }
				img { margin: 0 auto; display: block; }
			}
		}
	}
	.scroll-wrapper{ width: 960px; overflow: hidden; padding: 30px 0;
		.scroller{ @include clearfix;
			.slide { width: 960px; float: left; @include clearfix;
				.left, .right { width: 400px; float: left;  }
				.right{ overflow: hidden; }
				.left { padding: 0 30px 0 60px; 
					.content {
						h1{ font: 16px/16.25px Arial,Helvetica,sans-serif; color: #CF2425; }
					}
				}
				.etproductlistnav {
					ul{
						li { list-style:none; background: url("/App_Themes/Default/images/pd/expand-list-selector.png") scroll no-repeat; padding-left: 30px; margin: 5px 0; line-height: 16px;
							.new-product { display: inline-block; color: #cb0c0f; padding: 0 3px 0 0; font-weight: bold; }
						}
					}
				}
				.bottom { padding: 0 30px 0 60px; clear: both;
					.etproductlistnav {
						ul { @include clearfix;
							li { float: left; width: 405px; }
						}
					}
				}

				.slide-arrow{ background: url("/App_Themes/Default/Images/ccss/previous-next-slide.png") no-repeat scroll 0 0;cursor: pointer;display: block;height: 46px;position: absolute;top: 510px;width: 41px;z-index: 10; clear:both; }
				.slide-arrow-next{ background-position: -41px 0; right: 15px; }
				.slide-arrow-previous{ left: 15px; }
			}
		}
	}
}